<div class="container-fluid" [formGroup]="ricaviEcostiForm">
    <div class="row">
        <div class="col-12" *ngIf="lineaBpSaved">
            <h2 class="saved">Linea business salvata</h2>
        </div>
    </div>
    <div class="row margin-top-20 nopadding" id="saveLinee">

        <div class="col-3 offset-9 d-flex justify-content-end align-items-center margin-bottom-10 margin-top-10">
            <button
                *ngIf="businessPlanTemp!=null && lineaDisabled && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-secondary margin-right-20" type="button" (click)="abilitaLinea()">
                Abilita linea
            </button>
            <button
                *ngIf="businessPlanTemp!=null && !lineaDisabled && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-secondary margin-right-20" type="button" (click)="resetLinea()">
                Disabilita linea
            </button>
            <button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-secondary" type="button" (click)="Annulla()">
                Annulla modifiche
            </button>
        </div>
    </div>
    <!--SU BASE-->
    <hr>
    <div class="row margin-top-20 nopadding" *ngIf="!lineaDisabled">
        <!-- style="width: 94.45% !important;" -->
        <div class="col-5 col-xl-4">
            <h2 class="labelLineeBusiness">Scelta valorizzazione voci Ricavi/Costi:&nbsp;&nbsp;<i
                    style="margin-top:-10px" id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                    placement="top" container="body" [ngbTooltip]="listaTips[7]?.descrizione"></i></h2>
            <select formControlName="valorizzazione" type="text" class="form-control form-select margin-top-10"
                [ngClass]="{ 'is-invalid':submitted && f.valorizzazione.errors }"
                (change)="onChangeValorizzazione();onChange()">
                <option [ngValue]="valorizzazione" *ngFor="let valorizzazione of listaValorizzazioneFiltered">
                    {{valorizzazione.descrizione}}
                </option>
            </select>
            <div *ngIf="submitted && f.valorizzazione.errors" class="invalid-feedback margin-left-20">
                <div *ngIf="f.valorizzazione.errors.required">Campo obbligatorio</div>
            </div>
        </div>

        <div [ngClass]="[idRuolo == 0 ? 'col-7 col-xl-8':'col-5 offset-2 offset-xl-3']"
            *ngIf="f.valorizzazione && (idRuolo== 0 || idRuolo ==2) && (!lineabusiness.idTipologiaAtt || f.valorizzazione.value.id == 1 )">
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col" [ngClass]="[idRuolo == 0 ? '' : 'width-25']"></th>
                        <th *ngIf="(f.valorizzazione.value.id == 2 && f.oscillazioniLinea.value) || ( f.valorizzazione.value.id == 1 && f.oscillazioniComplessive.value)"
                            scope="col" class="text-left responsive-size-label"
                            [ngClass]="[idRuolo == 0 ? '' : 'width-25']">%
                            Ribasso
                        </th>
                        <th *ngIf="(f.valorizzazione.value.id == 2 && f.oscillazioniLinea.value) || ( f.valorizzazione.value.id == 1 && f.oscillazioniComplessive.value)"
                            scope="col" class="text-left responsive-size-label"
                            [ngClass]="[idRuolo == 0 ? '' : 'width-25']">%
                            Rialzo
                        </th>
                        <th *ngIf="((f.valorizzazione.value.id == 2 && f.oscillazioniLinea.value) || ( f.valorizzazione.value.id == 1 && f.oscillazioniComplessive.value)) && idRuolo == 0"
                            scope="col" class="text-left responsive-size-label"
                            [ngClass]="[idRuolo == 0 ? '' : 'width-25']">%
                            Scarto&nbsp;<i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true" container="body"
                                placement="top" ngbTooltip={{listaTips[8]?.descrizione}}></i>
                        </th>
                        <th *ngIf="(f.valorizzazione.value.id == 2 && f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2) || (f.valorizzazione.value.id == 1 && f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==2)"
                            [ngClass]="[idRuolo != 0 ? 'width-25' : (f.valorizzazione.value.id== 2 && !f.oscillazioniLinea.value) || (f.valorizzazione.value.id== 1 && !f.oscillazioniComplessive.value)? 'drift-width' : '']"
                            scope="col" class="text-left responsive-size-label">% Drift&nbsp;<i id="icon"
                                class="fa fa-1x fa-info-circle" aria-hidden="true" container="body" placement="top"
                                ngbTooltip={{listaTips[9]?.descrizione}}></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr formGroupName="quantitaVenduta"
                        *ngIf="f.valorizzazione.value.id== 2 && (idRuolo == 0 || (lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1))">
                        <th *ngIf="(f.oscillazioniLinea.value || f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2) && !(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)"
                            scope="row" class="align-middle text-right" style="width: 25%;">Q.tà venduta:
                        </th>
                        <th *ngIf="(f.oscillazioniLinea.value || f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2) && (lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)"
                            scope="row" class="align-middle text-right" style="width: 25%;">Resa:
                        </th>
                        <td *ngIf="f.oscillazioniLinea.value"><input formControlName="ribasso" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('quantitaVendutaRibasso')"
                                [ngClass]="{ 'is-invalid':submitted && quantitaVenduta.controls.ribasso.errors,'warning-control':warningQuantitaVendutaRibasso }" />
                            <div *ngIf=" submitted && quantitaVenduta.controls.ribasso.errors" class="invalid-feedback">
                                <div *ngIf="quantitaVenduta.controls.ribasso.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningQuantitaVendutaRibasso" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Ribasso sotto il {{valoreMax.ribasso}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniLinea.value"><input formControlName="rialzo" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('quantitaVendutaRialzo')"
                                [ngClass]="{ 'is-invalid':submitted && quantitaVenduta.controls.rialzo.errors ,'warning-control':warningQuantitaVendutaRialzo }" />
                            <div *ngIf=" submitted && quantitaVenduta.controls.rialzo.errors" class="invalid-feedback">
                                <div *ngIf="quantitaVenduta.controls.rialzo.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningQuantitaVendutaRialzo" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Rialzo sotto il {{valoreMax.rialzo}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniLinea.value && idRuolo == 0"><input formControlName="scarto"
                                type="number" class="form-control" min="0"
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('quantitaVendutaScarto')"
                                [ngClass]="{ 'is-invalid':submitted && quantitaVenduta.controls.scarto.errors ,'warning-control':warningQuantitaVendutaScarto}" />
                            <div *ngIf=" submitted && quantitaVenduta.controls.scarto.errors" class="invalid-feedback">
                                <div *ngIf="quantitaVenduta.controls.scarto.errors.required">
                                    Campo obbligatorio</div>
                            </div>
                            <div *ngIf="warningQuantitaVendutaScarto" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                lo Scarto sotto il {{valoreMax.scarto}}%
                            </div>
                        </td>
                        <td *ngIf="f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2"
                            [ngClass]="{'drift-width': !f.oscillazioniLinea.value}"><input formControlName="drift"
                                type="number" class="form-control" (keyup)="checkValMax('quantitaVendutaDrift')"
                                [ngClass]="{ 'is-invalid':submitted && quantitaVenduta.controls.drift.errors ,'warning-control':warningQuantitaVendutaDrift }" />
                            <div *ngIf=" submitted && quantitaVenduta.controls.drift.errors" class="invalid-feedback">
                                <div *ngIf="quantitaVenduta.controls.drift.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningQuantitaVendutaDrift" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Drift sotto il {{valoreMax.drift}}%
                            </div>
                        </td>
                    </tr>
                    <tr formGroupName="prezzoIniziale"
                        *ngIf="f.valorizzazione.value.id== 2 && (f.oscillazioniLinea.value || f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2)">
                        <th scope="row" class="align-middle text-right" style="width: 25%;">Ricavi:
                        </th>

                        <td *ngIf="f.oscillazioniLinea.value"><input formControlName="ribasso" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('prezzoInizialeRibasso')"
                                [ngClass]="{ 'is-invalid':submitted && prezzoIniziale.controls.ribasso.errors ,'warning-control':warningPrezzoInizialeRibasso }" />
                            <div *ngIf=" submitted && prezzoIniziale.controls.ribasso.errors" class="invalid-feedback">
                                <div *ngIf="prezzoIniziale.controls.ribasso.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningPrezzoInizialeRibasso" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Ribasso sotto il {{valoreMax.ribasso}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniLinea.value"><input formControlName="rialzo" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('prezzoInizialeRialzo')"
                                [ngClass]="{ 'is-invalid':submitted && prezzoIniziale.controls.rialzo.errors  ,'warning-control':warningPrezzoInizialeRialzo }" />
                            <div *ngIf=" submitted && prezzoIniziale.controls.rialzo.errors" class="invalid-feedback">
                                <div *ngIf="prezzoIniziale.controls.rialzo.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningPrezzoInizialeRialzo" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Rialzo sotto il {{valoreMax.rialzo}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniLinea.value && idRuolo == 0"><input formControlName="scarto"
                                type="number" class="form-control" min="0.1"
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('prezzoInizialeScarto')"
                                [ngClass]="{ 'is-invalid':submitted && prezzoIniziale.controls.scarto.errors  ,'warning-control':warningPrezzoInizialeScarto}" />
                            <div *ngIf=" submitted && prezzoIniziale.controls.scarto.errors" class="invalid-feedback">
                                <div *ngIf="prezzoIniziale.controls.scarto.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningPrezzoInizialeScarto" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                lo Scarto sotto il {{valoreMax.scarto}}%
                            </div>
                        </td>
                        <td *ngIf="f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2"
                            [ngClass]="{'drift-width': !f.oscillazioniLinea.value}"><input formControlName="drift"
                                type="number" class="form-control" (keyup)="checkValMax('prezzoInizialeDrift')"
                                [ngClass]="{ 'is-invalid':submitted && prezzoIniziale.controls.drift.errors  ,'warning-control':warningPrezzoInizialeDrift}" />
                            <div *ngIf=" submitted && prezzoIniziale.controls.drift.errors" class="invalid-feedback">
                                <div *ngIf="prezzoIniziale.controls.drift.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningPrezzoInizialeDrift" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Drift sotto il {{valoreMax.drift}}%
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="f.valorizzazione.value.id== 2 && (f.oscillazioniLinea.value || (f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2))"
                        formGroupName="costoIniziale">
                        <th scope="row" class="align-middle text-right" style="width: 25%;">
                            Costi:
                        </th>

                        <td *ngIf="f.oscillazioniLinea.value"><input formControlName="ribasso" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('costoInizialeRibasso')"
                                [ngClass]="{ 'is-invalid':submitted && costoIniziale.controls.ribasso.errors  ,'warning-control':warningCostoInizialeRibasso}" />
                            <div *ngIf=" submitted && costoIniziale.controls.ribasso.errors" class="invalid-feedback">
                                <div *ngIf="costoIniziale.controls.ribasso.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningCostoInizialeRibasso" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Ribasso sotto il {{valoreMax.ribasso}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniLinea.value"><input formControlName="rialzo" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('costoInizialeRialzo')"
                                [ngClass]="{ 'is-invalid':submitted && costoIniziale.controls.rialzo.errors ,'warning-control':warningCostoInizialeRialzo }" />
                            <div *ngIf=" submitted && costoIniziale.controls.rialzo.errors" class="invalid-feedback">
                                <div *ngIf="costoIniziale.controls.rialzo.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningCostoInizialeRialzo" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Rialzo sotto il {{valoreMax.rialzo}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniLinea.value && idRuolo == 0"><input formControlName="scarto"
                                type="number" class="form-control" min="0"
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('costoInizialeScarto')"
                                [ngClass]="{ 'is-invalid':submitted && costoIniziale.controls.scarto.errors ,'warning-control':warningCostoInizialeScarto }" />
                            <div *ngIf=" submitted && costoIniziale.controls.scarto.errors" class="invalid-feedback">
                                <div *ngIf="costoIniziale.controls.scarto.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningCostoInizialeScarto" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                lo Scarto sotto il {{valoreMax.scarto}}%
                            </div>
                        </td>
                        <td *ngIf="f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==2"
                            [ngClass]="{'drift-width': !f.oscillazioniLinea.value}"><input formControlName="drift"
                                type="number" class="form-control" (keyup)="checkValMax('costoInizialeDrift')"
                                [ngClass]="{ 'is-invalid':submitted && costoIniziale.controls.drift.errors ,'warning-control':warningCostoInizialeDrift}" />
                            <div *ngIf=" submitted && costoIniziale.controls.drift.errors" class="invalid-feedback">
                                <div *ngIf="costoIniziale.controls.drift.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningCostoInizialeDrift" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Drift sotto il {{valoreMax.drift}}%
                            </div>
                        </td>
                    </tr>

                    <tr formGroupName="ricavi"
                        *ngIf="f.valorizzazione.value.id== 1 && (f.oscillazioniComplessive.value || f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==2)">
                        <th scope="row" class="align-middle text-right" style="width: 25%;">Ricavi:
                        </th>
                        <td *ngIf="f.oscillazioniComplessive.value"><input formControlName="ribasso" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('ricaviRibasso')"
                                [ngClass]="{ 'is-invalid':submitted && ricavi.controls.ribasso.errors ,'warning-control':warningRicaviRibasso }" />
                            <div *ngIf=" submitted && ricavi.controls.ribasso.errors" class="invalid-feedback">
                                <div *ngIf="ricavi.controls.ribasso.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningRicaviRibasso" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Ribasso sotto il {{valoreMax.ribasso}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniComplessive.value"><input formControlName="rialzo" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('ricaviRialzo')"
                                [ngClass]="{ 'is-invalid':submitted && ricavi.controls.rialzo.errors  ,'warning-control':warningRicaviRialzo }" />
                            <div *ngIf=" submitted && ricavi.controls.rialzo.errors" class="invalid-feedback">
                                <div *ngIf="ricavi.controls.rialzo.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningRicaviRialzo" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Rialzo sotto il {{valoreMax.rialzo}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniComplessive.value && idRuolo == 0"><input formControlName="scarto"
                                type="number" class="form-control" min="0.1"
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('ricaviScarto')"
                                [ngClass]="{ 'is-invalid':submitted && ricavi.controls.scarto.errors  ,'warning-control':warningRicaviScarto}" />
                            <div *ngIf=" submitted && ricavi.controls.scarto.errors" class="invalid-feedback">
                                <div *ngIf="ricavi.controls.scarto.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningRicaviScarto" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                lo Scarto sotto il {{valoreMax.scarto}}%
                            </div>
                        </td>
                        <td *ngIf="f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==2"
                            [ngClass]="{'drift-width': !f.oscillazioniComplessive.value}"><input formControlName="drift"
                                type="number" class="form-control" (keyup)="checkValMax('ricaviDrift')"
                                [ngClass]="{ 'is-invalid':submitted && ricavi.controls.drift.errors  ,'warning-control':warningRicaviDrift}" />
                            <div *ngIf=" submitted && ricavi.controls.drift.errors" class="invalid-feedback">
                                <div *ngIf="ricavi.controls.drift.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningRicaviDrift" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Drift sotto il {{valoreMax.drift}}%
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="f.valorizzazione.value.id== 1 && (f.oscillazioniComplessive.value || (f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==2))"
                        formGroupName="costi">
                        <th scope="row" class="align-middle text-right" style="width: 25%;">
                            Costi:
                        </th>

                        <td *ngIf="f.oscillazioniComplessive.value"><input formControlName="ribasso" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('costiRibasso')"
                                [ngClass]="{ 'is-invalid':submitted && costi.controls.ribasso.errors  ,'warning-control':warningCostiRibasso}" />
                            <div *ngIf=" submitted && costi.controls.ribasso.errors" class="invalid-feedback">
                                <div *ngIf="costi.controls.ribasso.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningCostiRibasso" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Ribasso sotto il {{valoreMax.ribasso}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniComplessive.value"><input formControlName="rialzo" type="number"
                                class="form-control" min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('costiRialzo')"
                                [ngClass]="{ 'is-invalid':submitted && costi.controls.rialzo.errors ,'warning-control':warningCostiRialzo }" />
                            <div *ngIf=" submitted && costi.controls.rialzo.errors" class="invalid-feedback">
                                <div *ngIf="costi.controls.rialzo.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningCostiRialzo" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Rialzo sotto il {{valoreMax.rialzo}}%
                            </div>
                        </td>
                        <td *ngIf="f.oscillazioniComplessive.value && idRuolo == 0"><input formControlName="scarto"
                                type="number" class="form-control" min="0"
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMax('costiScarto')"
                                [ngClass]="{ 'is-invalid':submitted && costi.controls.scarto.errors ,'warning-control':warningCostiScarto }" />
                            <div *ngIf=" submitted && costi.controls.scarto.errors" class="invalid-feedback">
                                <div *ngIf="costi.controls.scarto.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningCostiScarto" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                lo Scarto sotto il {{valoreMax.scarto}}%
                            </div>
                        </td>
                        <td *ngIf="f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==2"
                            [ngClass]="{'drift-width': !f.oscillazioniComplessive.value}"><input formControlName="drift"
                                type="number" class="form-control" (keyup)="checkValMax('costiDrift')"
                                [ngClass]="{ 'is-invalid':submitted && costi.controls.drift.errors ,'warning-control':warningCostiDrift}" />
                            <div *ngIf=" submitted && costi.controls.drift.errors" class="invalid-feedback">
                                <div *ngIf="costi.controls.drift.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            <div *ngIf="warningCostiDrift" class="warning-label">
                                <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo
                                di
                                mantenere
                                il Drift sotto il {{valoreMax.drift}}%
                            </div>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
        <div class="col-12" *ngIf="f.valorizzazione && idRuolo== 0">

            <div *ngIf="f.valorizzazione.value.id == 2 && f.evoluzioneUnitaria.value && f.evoluzioneUnitaria.value.id==1"
                class="row d-flex align-items-center">

                <div class="col-3 col-xl-2">
                    <h3 class="responsive-size-label">Drift
                        annuale(%)</h3>
                </div>
                <div class="col-9 col-xl-10">
                    <div formArrayName="driftQuantitaInizialeAnnuale"
                        class="row margin-top-10 d-flex align-items-center">
                        <div class="col-2"><label class="responsive-size-label"><strong
                                    *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Q.ta&grave;
                                    venduta:</strong>
                                <strong *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Resa
                                    previsionale:</strong>
                            </label></div>
                        <div class="col-2 col-xl-1"
                            *ngFor="let annoFormCosti of driftQuantitaInizialeAnnualeFormArray;let i =index">
                            <label>% al {{arrayAnni[i]}}</label>
                            <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                                [ngClass]="{ 'is-invalid':submitted && driftQuantitaInizialeAnnualeFormArray[i].errors,'warning-control':warningQuantitaInizialeDriftAnnuale[i]}"
                                (keyup)="checkValMaxDriftQuantitaInizialeAnnuale(i)" />
                            <div *ngIf=" submitted && driftQuantitaInizialeAnnualeFormArray[i].errors"
                                class="invalid-feedback">
                                <div *ngIf="driftQuantitaInizialeAnnualeFormArray[i].errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 margin-bottom-10 margin-top-10" *ngIf="warningQuantitaInizialeDriftAnnualeTot">
                    </div>
                    <div class="warning-label col-4 margin-bottom-10 margin-top-10"
                        *ngIf="warningQuantitaInizialeDriftAnnualeTot">
                        <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                        il Drift sotto il {{valoreMax.drift}}%
                    </div>
                    <div formArrayName="driftPrezzoInizialeAnnuale" class="row margin-top-10 d-flex align-items-center">
                        <div class="col-2"><label class="responsive-size-label"><strong
                                    *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Prezzo
                                    iniziale:</strong>
                                <strong *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Ricavi
                                    previsionali:</strong>

                            </label>
                        </div>
                        <div class="col-2 col-xl-1"
                            *ngFor="let annoFormCosti of driftPrezzoInizialeAnnualeFormArray;let i =index">
                            <label>% al {{arrayAnni[i]}}</label>
                            <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                                [ngClass]="{ 'is-invalid':submitted && driftPrezzoInizialeAnnualeFormArray[i].errors,'warning-control':warningPrezzoInizialeDriftAnnuale[i]}"
                                (keyup)="checkValMaxDriftPrezzoInizialeAnnuale(i)" />
                            <div *ngIf=" submitted && driftPrezzoInizialeAnnualeFormArray[i].errors"
                                class="invalid-feedback">
                                <div *ngIf="driftPrezzoInizialeAnnualeFormArray[i].errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 margin-bottom-10 margin-top-10" *ngIf="warningPrezzoInizialeDriftAnnualeTot">
                    </div>
                    <div class="warning-label col-4 margin-bottom-10 margin-top-10"
                        *ngIf="warningPrezzoInizialeDriftAnnualeTot">
                        <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                        il Drift sotto il {{valoreMax.drift}}%

                    </div>
                    <div formArrayName="driftCostoInizialeAnnuale" class="row margin-top-10 d-flex align-items-center">
                        <div class="col-2"><label class="responsive-size-label"><strong
                                    *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Costo
                                    iniziale:</strong>
                                <strong *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Costi
                                    previsionali:</strong>
                            </label>
                        </div>
                        <div class="col-2 col-xl-1 margin-bottom-10"
                            *ngFor="let annoFormCosti of driftCostoInizialeAnnualeFormArray;let i =index">
                            <label>% al {{arrayAnni[i]}}</label>
                            <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                                [ngClass]="{ 'is-invalid':submitted && driftCostoInizialeAnnualeFormArray[i].errors,'warning-control':warningCostoInizialeDriftAnnuale[i]}"
                                (keyup)="checkValMaxDriftCostoInizialeAnnuale(i)" />
                            <div *ngIf=" submitted && driftCostoInizialeAnnualeFormArray[i].errors"
                                class="invalid-feedback">
                                <div *ngIf="driftCostoInizialeAnnualeFormArray[i].errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 margin-bottom-10 margin-top-10" *ngIf="warningCostoInizialeDriftAnnualeTot">
                    </div>
                    <div class="warning-label col-4 margin-bottom-10 margin-top-10"
                        *ngIf="warningCostoInizialeDriftAnnualeTot">
                        <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                        il Drift sotto il {{valoreMax.drift}}%

                    </div>
                </div>


            </div>

            <div *ngIf="f.valorizzazione.value.id == 1 && f.evoluzioneComplessiva.value && f.evoluzioneComplessiva.value.id==1"
                class="row d-flex align-items-center">

                <div class="col-3 col-xl-2">
                    <h3 class="responsive-size-label">Drift
                        annuale(%)</h3>
                </div>
                <div class="col-9 col-xl-10">
                    <div formGroupName="ricavi">
                        <div formArrayName="driftRicaviAnnuale" class="row margin-top-10 d-flex align-items-center">
                            <div class="col-2"><label class="responsive-size-label"><strong>Ricavi:</strong>
                                </label>
                            </div>
                            <div class="col-2 col-xl-1"
                                *ngFor="let annoFormRicavi of driftRicaviAnnualeFormArray;let i =index">
                                <label>% al {{arrayAnni[i]}}</label>
                                <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                                    [ngClass]="{ 'is-invalid':submitted && driftRicaviAnnualeFormArray[i].errors,'warning-control':warningRicaviDriftAnnuale[i]}"
                                    (keyup)="checkValMaxDiftRicaviAnnuale(i)" />
                                <div *ngIf=" submitted && driftRicaviAnnualeFormArray[i].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="driftRicaviAnnualeFormArray[i].errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 margin-bottom-10 margin-top-10" *ngIf="warningRicaviDriftAnnualeTotale">
                    </div>
                    <div class="warning-label col-4 margin-bottom-10 margin-top-10"
                        *ngIf="warningRicaviDriftAnnualeTotale">
                        <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                        il Drift sotto il {{valoreMax.drift}}%

                    </div>
                    <div formGroupName="costi">
                        <div formArrayName="driftCostiAnnuale" class="row margin-top-10 d-flex align-items-center">
                            <div class="col-2"><label class="responsive-size-label"><strong>Costi:</strong>
                                </label>
                            </div>
                            <div class="col-2 col-xl-1 margin-bottom-10"
                                *ngFor="let annoFormCosti of driftCostiAnnualeFormArray;let i =index">
                                <label>% al {{arrayAnni[i]}}</label>
                                <input type="number" [formControlName]="i" class="form-control" style="width: 100%;"
                                    [ngClass]="{ 'is-invalid':submitted && driftCostiAnnualeFormArray[i].errors,'warning-control':warningCostiDriftAnnuale[i]}"
                                    (keyup)="checkValMaxDiftCostiAnnuale(i)" />
                                <div *ngIf=" submitted && driftCostiAnnualeFormArray[i].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="driftCostiAnnualeFormArray[i].errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="row margin-bottom-10 margin-top-10" *ngIf="warningCostiDriftAnnualeTotale">
                    <div class="col-1 margin-bottom-10 margin-top-10" *ngIf="warningCostiDriftAnnualeTotale">
                    </div>
                    <div class="warning-label col-4 margin-bottom-10 margin-top-10"
                        *ngIf="warningCostiDriftAnnualeTotale">
                        <strong style="font-size: 1em">Warning!</strong> Valore elevato. Consigliamo di mantenere
                        il Drift sotto il {{valoreMax.drift}}%

                    </div>
                </div>


            </div>
        </div>

    </div>
    <div class="row margin-top-10" style="margin-left: 0px;margin-right: 0px;" *ngIf="!lineaDisabled && f.valorizzazione && f.valorizzazione.value && (businessPlanTemp.idContoEconomico == 0 || !lineabusiness.lineaEsistente) && !lineabusiness.idTipologiaAtt">
        <div class="col-2">
            <label>Anno inizio attivit&agrave;</label>
            <input formControlName="annoInizio" type="number" class="form-control"
            [ngClass]="{ 'is-invalid':submitted && f.annoInizio.errors }"
            (change)="onChangeAnnoInizio()"/>
            <div *ngIf="submitted && f.annoInizio.errors"
            class="invalid-feedback">
            <div
                *ngIf="f.annoInizio.errors.required">
                Campo
                obbligatorio
            </div>
        </div>
        </div>
        <div class="col-2">
            <label>Trimestre inizio attivit&agrave;</label>
            <select formControlName="trimestreInizio"
                class="form-control form-select"
                [ngClass]="{ 'is-invalid':submitted && f.trimestreInizio.errors }">
                <option [ngValue]="trimestre.id"
                *ngFor="let trimestre of trimestreList;let j=index">
                {{trimestre.descrizione}}
            </option>
            </select>
            <div *ngIf="submitted && f.trimestreInizio.errors"
            class="invalid-feedback">
            <div
                *ngIf="f.trimestreInizio.errors.required">
                Campo
                obbligatorio
            </div>
        </div>
        </div>
        <div class="col-8">
            <div class="row" style="margin-left: 0px; margin-right: 0px">
                <h3>Latenza produttiva &nbsp;&nbsp;<i id="icon"
                    class="fa fa-1x fa-info-circle margin-right-10" aria-hidden="true"
                    placement="top" container="body"
                    [ngbTooltip]="listaTips[9]?.descrizione"></i>&nbsp;&nbsp;</h3>

            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col">1° Quarter</th>
                        <th scope="col">2° Quarter</th>
                        <th scope="col">3° Quarter</th>
                        <th scope="col">4° Quarter</th>
                        <th scope="col">5° Quarter</th>
                        <th scope="col">6° Quarter</th>
                        <th scope="col">7° Quarter</th>
                        <th scope="col">8° Quarter</th>
                    </tr>
                </thead>
                <tbody>
                    <tr formArrayName="latenza">

                        <td
                            *ngFor="let lat of latenzaFormArray;let j =index">
                            <input [formControlName]="j" type="number" class="form-control"
                                [ngClass]="{ 'is-invalid':submitted && f.latenza.controls[j].errors }"
                                (change)="onChangeLatenza(j)"/>
                            <div *ngIf="submitted && f.latenza.controls[j].errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="f.latenza.controls[j].errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>

            </table>
</div>
        </div>

    </div>
    <!-- <div id="container-lineebp-body-bp"> -->
    <div *ngIf="f.valorizzazione && f.valorizzazione.value.id==1 && !lineaDisabled" class="margin-top-20">
        <!--  id="addPaddingInStickyRicavi" -->
        <!--
        <div class="row">
            <div class="col-5 d-flex align-items-center">
                <h2 class="align-middle labelLineeBusiness">Inserimento valori ricavi/costi complessivi :
                    &nbsp;&nbsp;<i style="margin-top:-10px" id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                        placement="top" container="body" [ngbTooltip]="listaTips[11]?.descrizione"></i></h2>
            </div>
        </div>
        -->
        <div class="row">
            <!--
            <div class=" col-4 col-xl-5 d-flex align-items-center margin-bottom-20 margin-top-20 margin-right-20">
                <h3 class="align-middle labelLineeBusiness">Abilita oscillazioni per Linea di
                    Business&nbsp;&nbsp;<i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top"
                        container="body" [ngbTooltip]="listaTips[12]?.descrizione"></i> </h3>
                <input class="margin-left-20" type="checkbox" formControlName="oscillazioniComplessive"
                    (change)="onChangeOscillazioniComplessive()">
            </div>
            -->
            <div class="col-4 col-xl-3 margin-bottom-20" *ngIf="idRuolo == 0">
                <label class="align-middle">Evoluzione:</label>
                <select formControlName="evoluzioneComplessiva" type="text" value="" class="form-control form-select"
                    [ngClass]="{ 'is-invalid':submitted && f.evoluzioneComplessiva.errors }" style="width:100%;"
                    (change)="onChangeEvoluzioneComplessiva()">
                    <option [ngValue]="evoluzioneComplessiva" *ngFor="let evoluzioneComplessiva of listaEvoluzione">
                        {{evoluzioneComplessiva.descrizione}}
                    </option>
                </select>
                <div *ngIf="submitted && f.evoluzioneComplessiva.errors" class="invalid-feedback">
                    <div *ngIf="f.evoluzioneComplessiva.errors.required">Campo obbligatorio</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-2" [formGroup]="ricavi">
                <h3>Ricavi iniziali <i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true" container="body"
                        placement="top" ngbTooltip={{listaTips[9]?.descrizione}}></i></h3>
                <input type="text" class="form-control" formControlName="ricaviIniziali" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid':submitted && ricavi.controls.ricaviIniziali.errors,'warning-control':warningLinea }"
                    onkeyup="if(this.value<0){this.value= this.value *-1}" (keyup)="checkMaggiore()" />
                <div *ngIf=" submitted && ricavi.controls.ricaviIniziali.errors" class="invalid-feedback">
                    <div *ngIf="ricavi.controls.ricaviIniziali.errors.required">Campo obbligatorio</div>
                </div>
            </div>

            <div class="col-5">
                <h3 class="text-center">Costi iniziali <i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                        container="body" placement="top" ngbTooltip={{listaTips[9]?.descrizione}}></i></h3>
                <div class="row">
                    <div class="col-8 flex-justify-center">Costi fissi aziendali assegnati alla LDB</div>
                    <div class="col-4">
                        <input type="text" class="form-control" formControlName="costiFissi" min="0" currencyMask
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />

                    </div>
                    <div class="col-8 flex-justify-center margin-top-10">Altri costi di produzione</div>
                    <div class="col-4 margin-top-10">
                        Importo
                    </div>
                    <div class="col-8 flex-center-right margin-top-10 font-tabella">Materie prime e di consumo</div>
                    <div class="col-4 margin-top-10 " [formGroup]="costi">
                        <input type="text" class="form-control" formControlName="costiMaterieIniziali" min="0"
                            onkeyup="if(this.value<0){this.value= this.value *-1}" currencyMask
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                            [ngClass]="{ 'is-invalid':submitted && costi.controls.costiMaterieIniziali.errors,'warning-control':warningLinea}"
                            (keyup)="checkMaggiore();onChangeCostiComplessivo()" />
                        <div *ngIf=" submitted && costi.controls.costiMaterieIniziali.errors" class="invalid-feedback">
                            <div *ngIf="costi.controls.costiMaterieIniziali.errors.required">Campo obbligatorio</div>
                        </div>
                    </div>
                    <div class="col-8 flex-center-right margin-top-10 font-tabella">Servizi esterni</div>
                    <div class="col-4 margin-top-10" [formGroup]="costi">
                        <input type="text" class="form-control" formControlName="costiServiziIniziali" min="0"
                            onkeyup="if(this.value<0){this.value= this.value *-1}" currencyMask
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                            [ngClass]="{ 'is-invalid':submitted && costi.controls.costiMaterieIniziali.errors,'warning-control':warningLinea}"
                            (keyup)="checkMaggiore();onChangeCostiComplessivo()" />
                        <div *ngIf=" submitted && costi.controls.costiMaterieIniziali.errors" class="invalid-feedback">
                            <div *ngIf="costi.controls.costiMaterieIniziali.errors.required">Campo obbligatorio</div>
                        </div>
                    </div>
                    <div class="col-8 flex-justify-center margin-top-10">Totale costi LDB</div>
                    <div class="col-4 margin-top-10">
                        <input type="text" class="form-control" formControlName="totCosti" min="0" currencyMask
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />
                    </div>
                </div>


            </div>

            <div class="col-5">
                <div class="row d-flex flex-column">
                    <div class="col-12 nopadding d-flex align-items-center">

                        <h3>Distribuzione trimestrale vendite (%):</h3>
                    </div>
                </div>
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col font-tabella">1° trimestre</th>
                            <th scope="col font-tabella">2° trimestre</th>
                            <th scope="col font-tabella">3° trimestre</th>
                            <th scope="col font-tabella">4° trimestre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr formArrayName="percTrimVenduto">

                            <td *ngFor="let trim of percTrimVendutoFormArray;let i =index">
                                <input [formControlName]="i" type="number" class="form-control"
                                    [ngClass]="{ 'is-invalid':submitted && percTrimVendutoFormArray[i].errors }"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                <div *ngIf="submitted && percTrimVendutoFormArray[i].errors" class="invalid-feedback">
                                    <div *ngIf="percTrimVendutoFormArray[i].errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div *ngIf="!sommaCentoVenduto" class="errorePerc">
                        La somma delle percentuali trimestrali deve essere pari al 100%
                    </div>
                </div>
                <ng-container *ngIf="presenzaMagazzino">
                    <div class="row d-flex flex-column">
                        <div class="col-12 nopadding align-items-center">

                            <h3>Distribuzione trimestrale reintegro scorte (%):</h3>
                        </div>
                    </div>
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col font-tabella">1° trimestre</th>
                                <th scope="col font-tabella">2° trimestre</th>
                                <th scope="col font-tabella">3° trimestre</th>
                                <th scope="col font-tabella">4° trimestre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr formArrayName="percTrimMagaz">

                                <td *ngFor="let trim of percTrimMagazFormArray;let i =index">
                                    <input [formControlName]="i" type="number" class="form-control"
                                        [ngClass]="{ 'is-invalid':submitted && percTrimMagazFormArray[i].errors }"
                                        onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                    <div *ngIf="submitted && percTrimMagazFormArray[i].errors" class="invalid-feedback">
                                        <div *ngIf="percTrimMagazFormArray[i].errors.required">Campo obbligatorio
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <div class="row">

                        <div *ngIf="!sommaCentoMagaz" class="errorePerc">
                            La somma delle percentuali trimestrali deve essere pari al 100%
                        </div>
                    </div>
                </ng-container>

            </div>

        </div>
        <div class="row" *ngIf=" warningLinea">
            <div class="col-2"></div>
            <div class="col-3 warning-label">
                <strong style="font-size: 1em">Warning!</strong> I Costi inseriti risultano maggiori dei Ricavi
            </div>
        </div>
    </div>

    <!--IN DISTINTA BASE-->
    <div *ngIf="f.valorizzazione.value && f.valorizzazione.value.id==2  && !lineaDisabled" class=" margin-top-20"
        id="addPaddingInStickyRicavi">
        <div class="row">
            <!--

            <div class="col-3 col-xl-2 d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <h3 class="align-middle">Abilita oscillazioni&nbsp;&nbsp; <i style="margin-top:-10px" id="icon"
                            class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                            [ngbTooltip]="listaTips[13]?.descrizione"></i></h3>
                    <input class="margin-left-20" type="checkbox" formControlName="oscillazioniLinea"
                        (change)="onChangeOscillazionilinea()">
                </div>

            </div>
            -->
            <div class="col-4 col-xl-3 .margin-right-20" *ngIf="idRuolo == 0">
                <label class="align-middle">Evoluzione:</label>
                <select formControlName="evoluzioneUnitaria" type="text" value="" class="form-control form-select"
                    [ngClass]="{ 'is-invalid':submitted && f.evoluzioneUnitaria.errors }" style="width:100%;"
                    (change)="onChangeEvoluzioneUnitaria()">
                    <option [ngValue]="evoluzioneUnitaria" *ngFor="let evoluzioneUnitaria of listaEvoluzione">
                        {{evoluzioneUnitaria.descrizione}}
                    </option>
                </select>
                <div *ngIf="submitted && f.evoluzioneUnitaria.errors" class="invalid-feedback">
                    <div *ngIf="f.evoluzioneUnitaria.errors.required">Campo obbligatorio</div>
                </div>
            </div>
        </div>

        <div class="row margin-top-20">
            <div class="col-5">
                <div class="row d-flex flex-column">
                    <div class="col-12 nopadding d-flex align-items-center">

                        <h3>Distribuzione trimestrale vendite (%):</h3>
                    </div>
                </div>
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col font-tabella">1° trimestre</th>
                            <th scope="col font-tabella">2° trimestre</th>
                            <th scope="col font-tabella">3° trimestre</th>
                            <th scope="col font-tabella">4° trimestre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr formArrayName="percTrimVenduto">

                            <td *ngFor="let trim of percTrimVendutoFormArray;let i =index">
                                <input [formControlName]="i" type="number" class="form-control"
                                    [ngClass]="{ 'is-invalid':submitted && percTrimVendutoFormArray[i].errors }"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                <div *ngIf="submitted && percTrimVendutoFormArray[i].errors" class="invalid-feedback">
                                    <div *ngIf="percTrimVendutoFormArray[i].errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div *ngIf="!sommaCentoVenduto" class="errorePerc">
                        La somma delle percentuali trimestrali deve essere pari al 100%
                    </div>
                </div>
            </div>
            <div class="col-2 font-tabella nopadding flex-justify-center">
                <div> 
                    Costi fissi <span *ngIf="!lineabusiness.idTipologiaAtt">aziendali assegnati</span>
                    <input type="text" class="form-control" formControlName="costiFissi" min="0" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />

                </div>
            </div>
            <div class="col-5" *ngIf="presenzaMagazzino">
                <div class="row d-flex flex-column">
                    <div class="col-12 nopadding align-items-center">

                        <h3>Distribuzione trimestrale reintegro scorte (%):</h3>
                    </div>
                </div>
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col font-tabella">1° trimestre</th>
                            <th scope="col font-tabella">2° trimestre</th>
                            <th scope="col font-tabella">3° trimestre</th>
                            <th scope="col font-tabella">4° trimestre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr formArrayName="percTrimMagaz">

                            <td *ngFor="let trim of percTrimMagazFormArray;let i =index">
                                <input [formControlName]="i" type="number" class="form-control"
                                    [ngClass]="{ 'is-invalid':submitted && percTrimMagazFormArray[i].errors }"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                <div *ngIf="submitted && percTrimMagazFormArray[i].errors" class="invalid-feedback">
                                    <div *ngIf="percTrimMagazFormArray[i].errors.required">Campo obbligatorio
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div class="row">

                    <div *ngIf="!sommaCentoMagaz" class="errorePerc">
                        La somma delle percentuali trimestrali deve essere pari al 100%
                    </div>
                </div>
            </div>
        </div>
        <div class="row margin-top-10" *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">
            <div class="col-2 flex-justify-center">Totale ricavi iniziali</div>
            <div class="col-2">
                <input type="text" class="form-control" [(ngModel)]="ricaviTot" [ngModelOptions]="{standalone: true}" min="0"
                currencyMask [disabled]="true"
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"/>
            </div>
            <div class="col-2 flex-justify-center">Totale costi iniziali</div>
            <div class="col-2">
                <input type="text" class="form-control" [(ngModel)]="costiTot" [ngModelOptions]="{standalone: true}" min="0"
                currencyMask [disabled]="true"
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"/>
            </div>
            <div class="col-2 flex-justify-center">Margine lordo iniziale</div>
            <div class="col-2">
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="margineTot" [ngModelOptions]="{standalone: true}" min="0"
                currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 1 , suffix: ' %', allowNegative: true }"/>
            </div>
</div>

        <!-- prodotti -->
        <div formArrayName="prodotti" style="padding-right: 0px;"
            *ngIf="f.valorizzazione.value && f.valorizzazione.value.id==2  && !lineaDisabled">
            <div *ngFor="let prodotto of prodottiFormArray;let i = index" [formGroupName]="i" class=" margin-top-20">
                <hr>
                <h2 *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Prodotto {{i+1}}</h2>
                <h2 *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                    {{prodottiTempArray[i].descrizione}}</h2>
                <div class="row no padding">
                    <div class="col-3" *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">
                        <label class="responsive-size-label">Descrizione prodotto</label>
                        <input type="text" formControlName="nomeProdotto" class="form-control"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.nomeProdotto.errors }" />
                        <div *ngIf=" submitted && prodotto.controls.nomeProdotto.errors" class="invalid-feedback">
                            <div *ngIf="prodotto.controls.nomeProdotto.errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-2" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                        <label class="responsive-size-label">Provincia</label>
                        <select formControlName="provincia" class="form-control form-select" style="width: 100%;">
                            <option [ngValue]="provincia.id" *ngFor="let provincia of listaProvince">
                                {{provincia.provincia}}
                            </option>
                        </select>
                    </div>

                    <div class="col-2" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                        <label class="responsive-size-label">Ettari</label>
                        <input type="number" formControlName="ettari" class="form-control" />

                    </div>
                    <div class="col-2"
                        *ngIf="idRuolo == 0 || lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                        <label class="responsive-size-label"
                            *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Resa previsionale
                            complessiva (Q.li)</label>
                        <label class="responsive-size-label"
                            *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Q.tà
                            venduta</label>
                        <input type="number" class="form-control" formControlName="quantitaVendutaProdotto" min="0"
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.quantitaVendutaProdotto.errors }" />
                        <div *ngIf=" submitted && prodotto.controls.quantitaVendutaProdotto.errors"
                            class="invalid-feedback">
                            <div *ngIf="prodotto.controls.quantitaVendutaProdotto.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <label class="responsive-size-label"
                            *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">Ricavi
                            previsionali complessivi (€)</label>
                        <label class="font-tabella"
                            *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">Ricavi
                            iniziali (€)</label>
                        <input type="text" class="form-control" currencyMask min="0" style="min-width:120px;"
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                            formControlName="prezzoInizialeProdotto"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.prezzoInizialeProdotto.errors,'warning-control':warningProdotti[i] }"
                            (keyup)=" checkMaggioreProdotti(i);onChangeRicaviProdotti();" />

                        <div *ngIf=" submitted && prodotto.controls.prezzoInizialeProdotto.errors"
                            class="invalid-feedback">
                            <div *ngIf="prodotto.controls.prezzoInizialeProdotto.errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-5 col-xl-4" *ngIf="!(lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1)">
                        <div class="row">
                            <div class="col-6 flex-center-right font-tabella">Costi di produzione</div>
                            <div class="col-6 font-tabella">
                                Importo
                            </div>
                            <div class="col-6 flex-center-right margin-top-10 font-tabella">Materie prime e di consumo</div>
                            <div class="col-6 margin-top-10 ">
                                <input type="text" class="form-control" formControlName="costoMaterieProdotto" min="0"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}" currencyMask
                                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                    [ngClass]="{ 'is-invalid':submitted && prodotto.controls.costoMaterieProdotto.errors,'warning-control':warningLinea}"
                                    (keyup)=" checkMaggioreProdotti(i); onChangeCostoProdotti();" />
                                <div *ngIf=" submitted && prodotto.controls.costoMaterieProdotto.errors" class="invalid-feedback">
                                    <div *ngIf="prodotto.controls.costoMaterieProdotto.errors.required">Campo obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-6 flex-center-right margin-top-10 font-tabella">Servizi esterni</div>
                            <div class="col-6 margin-top-10">
                                <input type="text" class="form-control" formControlName="costoServiziProdotto" min="0"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}" currencyMask
                                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                    [ngClass]="{ 'is-invalid':submitted && prodotto.controls.costoServiziProdotto.errors,'warning-control':warningLinea}"
                                    (keyup)=" checkMaggioreProdotti(i);onChangeCostoProdotti();" />
                                <div *ngIf=" submitted && prodotto.controls.costoServiziProdotto.errors" class="invalid-feedback">
                                    <div *ngIf="prodotto.controls.costoServiziProdotto.errors.required">Campo obbligatorio</div>
                                </div>
                            </div>
                        </div>

                    </div>

                        
                    
                    <div class="col-2" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                        <label class="responsive-size-label">Costi previsionali
                            complessivi (€)</label>
                        <input type="text" class="form-control warning-control" currencyMask style="min-width:120px;"
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                            formControlName="costoMaterieProdotto"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.costoMaterieProdotto.errors ,'warning-control':warningProdotti[i] }"
                            (keyup)=" checkMaggioreProdotti(i)" />
                        <!--  -->
                        <div *ngIf=" submitted && prodotto.controls.costoMaterieProdotto.errors"
                            class="invalid-feedback">
                            <div *ngIf="prodotto.controls.costoMaterieProdotto.errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </div>

                    <div class="col-2" *ngIf="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1">
                        <label class="responsive-size-label">Modalit&agrave; di vendita</label>
                        <select formControlName="canaleDistribuzione" class="form-control form-select"
                            [ngClass]="{ 'is-invalid':submitted && prodotto.controls.canaleDistribuzione.errors }"
                            style="width: 100%;">
                            <option [ngValue]="canale.id" *ngFor="let canale of listaCanaliDistribuzione">
                                {{canale.descrizione}}
                            </option>
                        </select>
                        <div *ngIf=" submitted && prodotto.controls.canaleDistribuzione.errors"
                            class="invalid-feedback">
                            <div *ngIf="prodotto.controls.canaleDistribuzione.errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </div>

                    <div class="text-left warning-label" *ngIf=" warningProdotti[i]"
                        [ngClass]="lineabusiness.idTipologiaAtt && lineabusiness.idTipologiaAtt == 1 ? 'col-6 offset-6': idRuolo == 0 ? 'col-5 offset-8' : 'col-5 offset-7 offset-xl-6'">
                        <strong style="font-size: 1em">Warning!</strong><br> I costi inseriti risultano maggiori dei
                        ricavi
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- </div> -->
    <!--  <hr style="margin-top: 10px;"> -->