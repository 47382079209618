<!--START Assunzioni smart bp-->
<div class="row sezione-vetrina-tabella" *ngIf="this.smartBp">

    <div class="d-flex" *ngIf="idRuoloUtente != 6">

        <div class="col-12 padding-top-bottom-10 padding-left-right-15">
            <div id="valutazioneCr" class="row margin-0">
                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">
                    <div class="col min-height-25 margin-top-10 font-bold"></div>
                    <div class="col min-height-25 margin-top-10 font-bold">Valore iniziale </div>
                    <div class="col min-height-25 margin-top-10 font-bold">Rialzo </div>
                    <div class="col min-height-25 margin-top-10 font-bold">Ribasso </div>
                    <div class="col min-height-25 margin-top-10 font-bold">Drift </div>
                </div>
                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Fatturato mercato per azienda</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.valoriIn[0]|number:'1.0-0':'it'}} €</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.rialzo[0]*100.
                        |number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.ribasso[0]*100.
                        |number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.drift[0]*100.|number:'1.0-2':'it'}} %</div>
                </div>
                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Percentuale costi variabili</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.valoriIn[1]*100.|
                        number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.rialzo[1]*100.|number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.ribasso[1]*100.
                        |number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.drift[1]*100.|number:'1.0-2':'it'}} %</div>
                </div>
                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Costi fissi</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.valoriIn[2]
                        |number:'1.0-0':'it'}} €</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.rialzo[2]*100.|number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.ribasso[2]*100.|number:'1.0-2':'it'}} %
                    </div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.drift[2]*100.|number:'1.0-2':'it'}} %</div>
                </div>
                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Rotazione magazzino</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.valoriIn[3]|number:'1.0-2':'it'}}</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.rialzo[3]*100.|number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.ribasso[3]*100.|number:'1.0-2':'it'}} %
                    </div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.drift[3]*100.|number:'1.0-2':'it'}} %</div>
                </div>
                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Indice di performance</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.valoriIn[4]|number:'1.0-2':'it'}}</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.rialzo[4]*100.|number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.ribasso[4]*100.|number:'1.0-2':'it'}} %
                    </div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.drift[4]*100.|number:'1.0-2':'it'}} %</div>
                </div>

                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Giorni incasso crediti</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.giorniCreditiIn|number:'1.0-2':'it'}}</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.rialzoGiorniCrediti*100.|number:'1.0-2':'it'}}
                        %</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.ribassoGiorniCrediti*100.|number:'1.0-2':'it'}}
                        %</div>
                    <div class="col min-height-25"></div>
                </div>

                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Giorni pagamento debiti</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.giorniDebitiIn
                        |number:'1.0-2':'it'}}</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.rialzoGiorniDebiti*100.
                        |number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.ribassoGiorniDebiti*100.
                        |number:'1.0-2':'it'}} %</div>
                    <div class="col min-height-25"></div>
                </div>

                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Rischi autoliquidanti iniziali</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.autoliqIn
                        |number:'1.0-0':'it'}} €</div>
                    <div class="col min-height-25"></div>
                    <div class="col min-height-25"></div>
                    <div class="col min-height-25"></div>
                </div>

                <div class="row row-cols-5 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Rischi a scadenza iniziali</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.finCassaIn
                        |number:'1.0-0':'it'}} €</div>
                    <div class="col min-height-25"></div>
                    <div class="col min-height-25"></div>
                    <div class="col min-height-25"></div>
                </div>


            </div>

        </div>

    </div>

    <div class="d-flex" *ngIf="idRuoloUtente == 6">

        <div class="col-6 padding-top-bottom-10 padding-left-right-15" style="margin:auto">
            <div id="valutazioneCr" class="row margin-0">
                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">
                    <div class="col min-height-25 margin-top-10 font-bold"></div>
                    <div class="col min-height-25 margin-top-10 font-bold">Valore iniziale </div>

                </div>
                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Fatturato</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.valoriIn[0]*this.assunzioniSmart.valoriIn[4]|number:'1.0-0':'it'}}
                        €</div>
                </div>
                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Percentuale costi variabili</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.valoriIn[1]*100.|
                        number:'1.0-2':'it'}} %</div>
                </div>
                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Costi fissi</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.valoriIn[2]
                        |number:'1.0-0':'it'}} €</div>
                </div>
                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Rotazione magazzino</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.valoriIn[3]|number:'1.0-2':'it'}}</div>
                </div>
                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Giorni incasso crediti</div>
                    <div class="col min-height-25">
                        {{this.assunzioniSmart.giorniCreditiIn|number:'1.0-2':'it'}}</div>

                </div>

                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Giorni pagamento debiti</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.giorniDebitiIn
                        |number:'1.0-2':'it'}}</div>

                </div>

                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Rischi autoliquidanti iniziali</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.autoliqIn
                        |number:'1.0-0':'it'}} €</div>

                </div>

                <div class="row row-cols-2 margin-0 text-center" style="background-color: var(--soft-grey);">

                    <div class="col min-height-25 font-bold">Rischi a scadenza iniziali</div>
                    <div class="col min-height-25">{{this.assunzioniSmart.finCassaIn
                        |number:'1.0-0':'it'}} €</div>

                </div>


            </div>

        </div>

    </div>
</div>
<!--END tabella Assunzioni smart bp-->
<!--START Assunzioni bp completo-->
<div class="row sezione-vetrina-tabella" *ngIf="!this.smartBp">

    <div [formGroup]="formAssunzioni" style="margin-bottom: 10px">

        <ng-container *ngIf="ambitoAssunzioni == 1" formArrayName="lineeBusiness" >
            <div class="row margin-0" *ngFor="let linea of lineeBusinessFormArray; let i = index" [formGroupName]="i"
                style="background-color: var(--soft-grey);">
                <h2>{{nomiLinee[i]}}</h2>
                <div class="row margin-0 text-center">

                    <div class="col-6">

                        <div class="row row-cols-3 margin-0">
                            <div class="col min-height-25 font-bold">
                                <label>Ricavi</label>
                                <input formControlName="ricavi" type="text" class="form-control" currencyMask
                                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.ricavi.errors}" />
                                <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.ricavi.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="lineeBusinessFormArray[i].controls.ricavi.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </div>
                            <div class="col min-height-25 font-bold"><label>Costi variabili</label>
                                <input formControlName="costiVar" type="text" class="form-control" currencyMask
                                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.costiVar.errors }" />
                                <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.costiVar.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="lineeBusinessFormArray[i].controls.costiVar.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </div>
                            <div class="col min-height-25 font-bold"><label>Costi fissi</label>
                                <input formControlName="costiFissi" type="text" class="form-control" currencyMask
                                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.costiFissi.errors }" />
                                <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.costiFissi.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="lineeBusinessFormArray[i].controls.costiFissi.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table class="table table-borderless" *ngIf="idRuoloUtente < 6">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col" class="text-left responsive-size-label">
                                        % Ribasso
                                    </th>
                                    <th scope="col" class="text-left responsive-size-label">%
                                        Rialzo
                                    </th>
                                    <th scope="col" class="text-left responsive-size-label">% Drift&nbsp;<i id="icon"
                                            class="fa fa-1x fa-info-circle" aria-hidden="true" container="body"
                                            placement="top"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" class="align-middle text-right">Ricavi:
                                    </th>

                                    <td><input formControlName="ribassoRicavi" type="number" class="form-control"
                                            min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                            [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.ribassoRicavi.errors }" />
                                        <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.ribassoRicavi.errors"
                                            class="invalid-feedback">
                                            <div
                                                *ngIf="lineeBusinessFormArray[i].controls.ribassoRicavi.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </td>
                                    <td><input formControlName="rialzoRicavi" type="number" class="form-control" min="0"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                                            [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.rialzoRicavi.errors }" />
                                        <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.rialzoRicavi.errors"
                                            class="invalid-feedback">
                                            <div
                                                *ngIf="lineeBusinessFormArray[i].controls.rialzo.rialzoRicavi.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </td>
                                    <td><input formControlName="driftRicavi" type="number" class="form-control"
                                            [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.driftRicavi.errors }" />
                                        <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.driftRicavi.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lineeBusinessFormArray[i].controls.driftRicavi.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row" class="align-middle text-right">
                                        Costi:
                                    </th>

                                    <td><input formControlName="ribassoCosti" type="number" class="form-control" min="0"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                                            [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.ribassoCosti.errors }" />
                                        <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.ribassoCosti.errors"
                                            class="invalid-feedback">
                                            <div
                                                *ngIf="lineeBusinessFormArray[i].controls.ribassoCosti.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </td>
                                    <td><input formControlName="rialzoCosti" type="number" class="form-control" min="0"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                                            [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.rialzoCosti.errors }" />
                                        <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.rialzoCosti.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lineeBusinessFormArray[i].controls.rialzoCosti.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </td>
                                    <td><input formControlName="driftCosti" type="number" class="form-control"
                                            [ngClass]="{ 'is-invalid':submitted && lineeBusinessFormArray[i].controls.driftCosti.errors }" />
                                        <div *ngIf=" submitted && lineeBusinessFormArray[i].controls.driftCosti.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lineeBusinessFormArray[i].controls.driftCosti.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </td>
                                </tr>


                            </tbody>

                        </table>

                        

                    </div>
                    <div class="col-6" >
                        <div class="row margin-0 text-center">
                            <div class="row d-flex flex-column">
                                <div class="col-12 nopadding d-flex align-items-center">
        
                                    <h3>Distribuzione trimestrale vendite (%):</h3>
                                </div>
                            </div>
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col font-tabella">1° trimestre</th>
                                        <th scope="col font-tabella">2° trimestre</th>
                                        <th scope="col font-tabella">3° trimestre</th>
                                        <th scope="col font-tabella">4° trimestre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr formArrayName="percTrimVenduto">
        
                                        <td *ngFor="let trim of percTrimVendutoFormArray(i); let j =index">
                                            <input [formControlName]="j" type="number" class="form-control"
                                                [ngClass]="{ 'is-invalid':submitted && percTrimVendutoFormArray(i)[j].errors }"
                                                onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                            <div *ngIf="submitted && percTrimVendutoFormArray(i)[j].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="percTrimVendutoFormArray(i)[j].errors.required">
                                                    Campo
                                                    obbligatorio
                                                </div>
                                            </div>
                                        </td>
        
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">
                                <div *ngIf="!sommaCentoVenduto" class="errorePerc">
                                    La somma delle percentuali trimestrali deve essere pari al 100%
                                </div>
                            </div>
        
                        </div>
                        <div class="row margin-0 text-center">
        
                            <div class="row d-flex flex-column">
                                <div class="col-12 nopadding d-flex align-items-center">
        
                                    <h3>Magazzino</h3>
                                </div>
                            </div>
                            <div class="row row-cols-5 margin-0">
                                <div class="col">
                                    <label>Indice di rotazione</label>
                                    <input type="number" class="form-control" formControlName="indiceRotazione" min="0"
                                        onkeyup="if(this.value<0){this.value= this.value *-1}"
                                        [ngClass]="{ 'is-invalid':submitted && this.lineeBusinessFormArray[i].controls.indiceRotazione.errors}" />
                                    <div *ngIf=" submitted && this.lineeBusinessFormArray[i].controls.indiceRotazione.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="this.lineeBusinessFormArray[i].controls.indiceRotazione.errors.required">Campo
                                            obbligatorio
                                        </div>
                                    </div>
        
                                </div>
                                <div class="col">
                                    <label>Soglia minima (%)</label>
                                    <input type="number" class="form-control" formControlName="sogliaMagaz" min="0"
                                        onkeyup="if(this.value<0){this.value= this.value *-1}"
                                        [ngClass]="{ 'is-invalid':submitted && this.lineeBusinessFormArray[i].controls.sogliaMagaz.errors}" />
                                    <div *ngIf=" submitted && this.lineeBusinessFormArray[i].controls.sogliaMagaz.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="this.lineeBusinessFormArray[i].controls.sogliaMagaz.errors.required">Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                    <div *ngIf=" submitted && this.erroreSoglia[i]" class="invalid-feedback">
                                        Il valore non pu&ograve; superare il {{this.valoreMax['sogliaMagaz']}}%
        
                                    </div>
                                </div>
                                <div class="col" *ngIf="idRuoloUtente < 6">
                                    <label>Ribasso (%)</label>
                                    <input type="number" class="form-control" formControlName="ribassoMagaz" min="0"
                                        onkeyup="if(this.value<0){this.value= this.value *-1}"
                                        [ngClass]="{ 'is-invalid':submitted && this.lineeBusinessFormArray[i].controls.ribasso.errors }" />
                                    <div *ngIf=" submitted && this.lineeBusinessFormArray[i].controls.ribasso.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="this.lineeBusinessFormArray[i].controls.ribasso.errors.required">Campo
                                            obbligatorio
                                        </div>
        
                                    </div>
                                </div>
                                <div class="col" *ngIf="idRuoloUtente < 6">
                                    <label>Rialzo (%)</label>
                                    <input type="number" class="form-control" formControlName="rialzoMagaz" min="0"
                                        onkeyup="if(this.value<0){this.value= this.value *-1}"
                                        [ngClass]="{ 'is-invalid':submitted && this.lineeBusinessFormArray[i].controls.rialzo.errors}" />
                                    <div *ngIf=" submitted && this.lineeBusinessFormArray[i].controls.rialzo.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="this.lineeBusinessFormArray[i].controls.rialzo.errors.required">Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>
                                <div class="col" *ngIf="idRuoloUtente < 6">
                                    <label>Drift (%)&nbsp;&nbsp; <i id=" icon" class="fa fa-1x fa-info-circle"
                                            aria-hidden="true" container="body" placement="top"></i></label>
                                    <input type="number" class="form-control" formControlName="driftMagaz" min="0"
                                        [ngClass]="{ 'is-invalid':submitted && this.lineeBusinessFormArray[i].controls.drift.errors}" />
                                    <div *ngIf=" submitted && this.lineeBusinessFormArray[i].controls.drift.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="this.lineeBusinessFormArray[i].controls.drift.errors.required">Campo
                                            obbligatorio
                                        </div>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </ng-container>
        <ng-container *ngIf="ambitoAssunzioni == 2">
            
            <div class="row d-flex flex-column margin-0" style="background-color: var(--soft-grey);">
                <div class="col-12 d-flex align-items-center">

                    <h2>Pianificazione incassi/pagamenti per arco temporale:</h2>
                </div>
            </div>
            <table class="table table-borderless margin-0" style="background-color: var(--soft-grey);">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">% a meno di 30 giorni</th>
                        <th scope="col">% tra 30 e 60 giorni</th>
                        <th scope="col">% tra 60 e 90 giorni</th>
                        <th scope="col">% tra 90 e 120 giorni</th>
                        <th scope="col">% tra 120 e 150 giorni</th>
                        <th scope="col">% a più di 150 giorni</th>

                    </tr>
                </thead>
                <tbody>
                    <tr formGroupName="giorniIncassoCrediti"
                        [ngClass]="{'border': !sommaCentoCrediti,'border-danger': !sommaCentoCrediti}">
                        <th scope="row" class="align-middle text-center" style="width: 15%">Giorni
                            incasso
                            crediti
                        </th>
                        <td><input formControlName="a30Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a30Giorni.errors }" />
                            <div *ngIf="submitted && giorniIncassoCrediti.controls.a30Giorni.errors"
                                class="invalid-feedback">
                                <div *ngIf="giorniIncassoCrediti.controls.a30Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="a60Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a60Giorni.errors }" />
                            <div *ngIf="submitted && giorniIncassoCrediti.controls.a60Giorni.errors"
                                class="invalid-feedback">
                                <div *ngIf="giorniIncassoCrediti.controls.a60Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="a90Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a90Giorni.errors }" />
                            <div *ngIf="submitted && giorniIncassoCrediti.controls.a90Giorni.errors"
                                class="invalid-feedback">
                                <div *ngIf="giorniIncassoCrediti.controls.a90Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="a120Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a120Giorni.errors }" />
                            <div *ngIf="submitted && giorniIncassoCrediti.controls.a120Giorni.errors"
                                class="invalid-feedback">
                                <div *ngIf="giorniIncassoCrediti.controls.a120Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="a150Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a150Giorni.errors }" />
                            <div *ngIf="submitted && giorniIncassoCrediti.controls.a150Giorni.errors"
                                class="invalid-feedback">
                                <div *ngIf="giorniIncassoCrediti.controls.a150Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="oltre150Giorni" type="number" class="form-control"
                                min=0 onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.oltre150Giorni.errors }" />
                            <div *ngIf="submitted && giorniIncassoCrediti.controls.oltre150Giorni.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="giorniIncassoCrediti.controls.oltre150Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div *ngIf="!sommaCentoCrediti" class="erroreCrediti">
                    La somma delle percentuali dei crediti deve essere 100%
                </div>
            </div>
            <table class="table table-borderless margin-0" style="background-color: var(--soft-grey);">
                <tbody>
                    <tr formGroupName="giorniPagamentoFornitori"
                        [ngClass]="{'border': !sommaCentoPagamenti,'border-danger': !sommaCentoPagamenti}">
                        <th scope="row" class="align-middle text-center" style="width: 15%">Giorni
                            pagamento
                            fornitori
                        </th>
                        <td><input formControlName="a30Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a30Giorni.errors}" />
                            <div *ngIf="submitted && giorniPagamentoFornitori.controls.a30Giorni.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="giorniPagamentoFornitori.controls.a30Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="a60Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a60Giorni.errors}" />
                            <div *ngIf="submitted && giorniPagamentoFornitori.controls.a60Giorni.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="giorniPagamentoFornitori.controls.a60Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="a90Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a90Giorni.errors}" />
                            <div *ngIf="submitted && giorniPagamentoFornitori.controls.a90Giorni.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="giorniPagamentoFornitori.controls.a90Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="a120Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a120Giorni.errors }" />
                            <div *ngIf="submitted && giorniPagamentoFornitori.controls.a120Giorni.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="giorniPagamentoFornitori.controls.a120Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="a150Giorni" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a150Giorni.errors }" />
                            <div *ngIf="submitted && giorniPagamentoFornitori.controls.a150Giorni.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="giorniPagamentoFornitori.controls.a150Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>
                        <td><input formControlName="oltre150Giorni" type="number" class="form-control"
                                min=0 onkeyup="if(this.value<0){this.value= this.value *-1}"
                                [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.oltre150Giorni.errors }" />
                            <div *ngIf="submitted && giorniPagamentoFornitori.controls.oltre150Giorni.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="giorniPagamentoFornitori.controls.oltre150Giorni.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div *ngIf="!sommaCentoPagamenti" class="errorePagamenti">
                    La somma delle percentuali dei pagamenti deve essere 100%
                </div>
            </div>

            <div class="row margin-0" style="background-color: var(--soft-grey);">
                <div class="col-12 d-flex align-items-end justify-content-start labelCampiInputMesiDeb">
                  <h2 style="font-weight:bold ;">Totale crediti  al {{'31/12/' + annoCreditiDebiti}} :
                    {{totaleCrediti | number:
                    '1.0-0':'it'}} €
                  </h2>
                </div>
                            
                <div formGroupName="creditiFormGroup">
                  <div formArrayName="arrayCreditizia" class="nopadding" id="container-situazione-finaziaria-body">
                    <div class="row">
                      <div class="col-12 erroreCrediti" *ngIf=" submitted && erroreSommaTotale==true">
                        La somma delle voci di credito è diversa dal totale riportato sul bilancio.
                      </div>
                    </div>
                    <div *ngFor="let credito of creditiFormArray;let i = index" [formGroupName]="i">
                      <div class="row d-flex justify-content-between">
                        <div class="col-12">
                          <h2> {{nomeCredito[i]}}</h2>
                          Totale: {{totaleCredito[i] | number:
                            '1.0-0':'it'}} €
                        </div>
                      </div>
                      <div class="col-12 erroreCrediti" *ngIf=" submitted && erroreSommaParziale[i]==true">
                        La somma della suddivisione deve essere uguale al totale di
                        {{nomeCredito[i]}}
                      </div>
                      <hr>

                      <div formArrayName="suddivisioneCrediti">
                        <div *ngFor="let suddivisione of credito.controls.suddivisioneCrediti.controls;let j=index"
                          [formGroupName]="j">
                          <div class="row  margin-top-10 d-flex justify-content-start align-items-end">
                            <div class="col-2">
                              <label *ngIf="j==0">Descrizione</label>
                              <input type="text" class="form-control" formControlName="descrizione"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.descrizione.errors}">
                            </div>
                            <div class="col-1">
                              <label *ngIf="j==0">Importo</label>
                              <input type="text" class="form-control" formControlName="importo" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.importo.errorssubmitted || submitted && erroreSommaParziale[i] ==true}"
                                (keyup)="setValoreRinegoziatoCredito(i,j)">
                            </div>
                            <div class="col-2" *ngIf="this.tipoCreditoList[i] != 4">
                              <label *ngIf="j==0">Importo Rinegoziato</label>
                              <input type="text" class="form-control" formControlName="importoRinegoziato" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.importoRinegoziato.errors}"
                                (keyup)="valoreRinegoziatoMax(i,j)">
                            </div>
                            <div class="col-1" *ngIf="this.tipoCreditoList[i] != 4">
                              <label *ngIf="j==0">Anno</label>
                              <input type="number" class="form-control" formControlName="annoInizioRimborso"
                              (change)="onChangeAnno(i,j)"
                              [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.annoInizioRimborso.errors}">
                            </div>
                            <div class="col-2">
                              <label *ngIf="j==0">Mese inizio incasso</label>
                              <select class="form-select form-control" formControlName="meseInizioRimborso"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.meseInizioRimborso.errors}">
                                <option [ngValue]="k" *ngFor="let mese of listaMesi;let k=index">{{mese |date:'MMMM' : 'it'}}
                                </option>
                              </select>
                            </div>
                            <div class="col-1">
                              <label *ngIf="j==0">Rate residue</label>
                              <select class="form-select form-control" formControlName="numeroRate"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.numeroRate.errors}">
                                <option [ngValue]="numero" *ngFor="let numero of listaNumeroRate">{{numero}}</option>
                              </select>
                            </div>
                            <div class="col-2">
                              <label *ngIf="j==0">Tipologia rata</label>
                              <select class="form-select form-control" formControlName="tipologiaRata"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.tipologiaRata.errors}">
                                <option [ngValue]="tipologiaRata" *ngFor="let tipologiaRata of tipoRateList">
                                  {{tipologiaRata.descrizione}}</option>
                              </select>
                            </div>
                          </div>
            
                        </div>
                      </div>
            
            
            
                      <hr>
                    </div>
            
                  </div>
                </div>
            
            
              </div>

            <div class="row margin-0" style="background-color: var(--soft-grey);">
                <div class="col-12 d-flex align-items-end justify-content-start labelCampiInputMesiDeb">
                  <h2 style="font-weight:bold ;">Totale debiti  al {{'31/12/' + annoCreditiDebiti}} :
                    {{totaleDebiti | number:
                    '1.0-0':'it'}} €
                  </h2>
                </div>

                <div formGroupName="debitiFormGroup">
                  <div formArrayName="arrayDebitoria" class="nopadding" id="container-situazione-finaziaria-body" >
                    <div class="row">
                      <div class="col-12 erroreDebiti" *ngIf=" submitted && erroreSommaTotale==true">
                        La somma delle voci di debito è diversa dal totale riportato sul bilancio.
                      </div>
                    </div>
                    <div *ngFor="let debito of debitiFormArray;let i = index" [formGroupName]="i">
                      <div class="row d-flex justify-content-between">
                        <div class="col-12">
                          <h2> {{nomeDebito[i]}}</h2>
                          Totale: {{totaleDebito[i] | number:
                            '1.0-0':'it'}} €
                        </div>
                      </div>
                      <div class="col-12 erroreDebiti" *ngIf=" submitted && erroreSommaParziale[i]==true">
                        La somma della suddivisione deve essere uguale al totale di
                        {{nomeDebito[i]}}
                      </div>
                      <hr>

                      <div *ngIf="this.tipoDebitoList[i] == 4"
                        formArrayName="suddivisioneAutoliq">
                        <div class="row d-flex justify-content-between margin-top-10">
                          <div class="col-8">
                            <h3 class="nopadding">Fidi Autoliquidanti</h3>
                          </div>
                        </div>
                        <div *ngFor="let autoliq of debito.controls.suddivisioneAutoliq.controls;let j=index" [formGroupName]="j">
            
                          <div class="row margin-top-10 align-items-end">
                            <div class="col-2">
                              <label *ngIf="j == 0">Descrizione</label>
                              <input type="text" class="form-control" formControlName="autoliqDescrizione"
                                [ngClass]="{ 'is-invalid':submitted && autoliq.controls.autoliqDescrizione.errors}">
                            </div>
                            <div class="col-2">
                              <label *ngIf="j == 0">Accordato</label>
                              <input type="text" class="form-control" formControlName="autoliqAccordato" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                [ngClass]="{ 'is-invalid':submitted && autoliq.controls.autoliqAccordato.errors }"
                                (keyup)="setResiduoAutoliquidante(i,j)">
                            </div>
                            <div class="col-2">
                              <label *ngIf="j == 0">Utilizzato</label>
                              <input type="text" class="form-control" formControlName="autoliqUtilizzato" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                [ngClass]="{ 'is-invalid':submitted && autoliq.controls.autoliqUtilizzato.errors || submitted && erroreSommaParziale[i] ==true}"
                                (keyup)="setResiduoAutoliquidante(i,j)">
                            </div>
                            <div class="col-2" *ngIf="j == 0">
                              <label>Disponibilit&agrave;</label>
                              <input type="text" class="form-control" formControlName="autoliqRimanenze" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }">
                            </div>
                          </div>
                        </div>
                        <hr>
                      </div>
            

                      <div *ngIf="this.tipoDebitoList[i] == 4"
                        formArrayName="suddivisioneRevoca">
                        <div class="row d-flex justify-content-between margin-top-10">
                          <div class="col-8">
                            <h3 class="nopadding">Fidi a Revoca</h3>
                          </div>
                        </div>
                        <div *ngFor="let revoca of debito.controls.suddivisioneRevoca.controls;let j=index" [formGroupName]="j">
                          <div class="row margin-top-10 align-items-end">
                            <div class="col-2">
                              <label *ngIf="j==0">Descrizione</label>
                              <input type="text" class="form-control" formControlName="revocaDescrizione"
                                [ngClass]="{ 'is-invalid':submitted && revoca.controls.revocaDescrizione.errors}">
                            </div>
                            <div class="col-2">
                              <label *ngIf="j==0">Accordato</label>
                              <input type="text" class="form-control" formControlName="revocaAccordato" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                [ngClass]="{ 'is-invalid':submitted && revoca.controls.revocaAccordato.errors}"
                                (keyup)="setResiduoRevoca(i,j)">
                            </div>
                            <div class="col-2">
                              <label *ngIf="j==0">Utilizzato</label>
                              <input type="text" class="form-control" formControlName="revocaUtilizzato" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                [ngClass]="{ 'is-invalid':submitted && revoca.controls.revocaUtilizzato.errors || submitted && erroreSommaParziale[i] ==true}"
                                (keyup)="setResiduoRevoca(i,j)">
                            </div>
                            <div class="col-2">
                              <label *ngIf="j==0">Disponibilit&agrave;</label>
                              <input type="text" class="form-control" formControlName="revocaRimanenze" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }">
                            </div>
                          </div>
                        </div>
                        <hr>
                      </div>
            

                      <div formArrayName="suddivisioneDebiti">
                        <div class="row d-flex justify-content-between margin-top-10">
                          <div class="col-8" *ngIf="this.tipoDebitoList[i] == 4">
                            <h3 class="nopadding">Rischi a scadenza</h3>
                          </div>
                        </div>
                        <div *ngFor="let suddivisione of debito.controls.suddivisioneDebiti.controls;let j=index"
                          [formGroupName]="j">
            
                          <div class="row margin-top-10 d-flex justify-content-start align-items-end">
                            <div class="col-2">
                              <label *ngIf="j==0">Descrizione</label>
                              <input type="text" class="form-control" formControlName="descrizione"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.descrizione.errors}">
                            </div>
                            <div [ngClass]="this.tipoDebitoList[i] == 4?'col-2':'col-1'">
                              <label *ngIf="j==0">Importo</label>
                              <input type="text" class="form-control" formControlName="importo" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.importo.errorssubmitted || submitted && erroreSommaParziale[i] ==true}"
                                (keyup)="setValoreRinegoziatoDebito(i,j)">
                            </div>
                            <div class="col-2" *ngIf="this.tipoDebitoList[i] != 4">
                              <label *ngIf="j==0">Importo Rinegoziato</label>
                              <input type="text" class="form-control" formControlName="importoRinegoziato" currencyMask
                                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.importoRinegoziato.errors}"
                                (keyup)="valoreRinegoziatoMax(i,j)">
                            </div>
                            <div class="col-1">
                              <label *ngIf="j==0">Anno</label>
                              <input type="number" class="form-control" formControlName="annoInizioRimborso"
                              (change)="onChangeAnno(i,j)"
                              [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.annoInizioRimborso.errors}">
                            </div>
                            <div class="col-2">
                              <label *ngIf="j==0">Mese inizio rimborso</label>
                              <select class="form-select form-control" formControlName="meseInizioRimborso"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.meseInizioRimborso.errors}">
                                <option [ngValue]="k" *ngFor="let mese of listaMesi;let k=index">{{mese |date:'MMMM' : 'it'}}
                                </option>
                              </select>
                            </div>
                            <div class="col-1">
                              <label *ngIf="j==0">Rate residue</label>
                              <select class="form-select form-control" formControlName="numeroRate"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.numeroRate.errors}">
                                <option [ngValue]="numero" *ngFor="let numero of listaNumeroRate">{{numero}}</option>
                              </select>
                            </div>
                            <div class="col-2">
                              <label for="rate" *ngIf="j==0">Tipologia rata</label>
                              <select class="form-select form-control" formControlName="tipologiaRata"
                                [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.tipologiaRata.errors}">
                                <option [ngValue]="tipologiaRata" *ngFor="let tipologiaRata of tipoRateList">
                                  {{tipologiaRata.descrizione}}</option>
                              </select>
                            </div>
                          </div>
                        </div>                            
                      </div>
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
        </ng-container>
    </div>
</div>
<!--END tabella Assunzioni bp completo-->