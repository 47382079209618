import { ListaBplanComponent } from './lista-bplan/lista-bplan.component';
import { PrezziECostiComponent } from './prezziecosti/prezziecosti.component';
import { QuestionarioComponent } from './questionario/questionario.component';
import { AnalisimccComponent } from './analisimcc/analisimcc.component';
import { AnalisicrComponent } from './analisicr/analisicr.component';
import { PresaIncaricoCrComponent } from './presaincaricocr/presaincaricocr.component';
import { IntestatarioComponent } from './intestatario/intestatario.component';
import { BilancioComponent } from './bilancio/bilancio.component';
import { LoginUserGuard } from './loginUser.guard';
import { FunzioniComponent } from './funzioni/funzioni.component';
/* ---------------------------
 * MODULO di ROUTING delle APP
 * --------------------------- */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { BoardFrontofficeComponent } from './board-frontoffice/board-frontoffice.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { AnagraficaProdottiComponent } from './anagraficaprodotti/anagraficaprodotti.component';
import { RisultatiQuestionarioComponent } from './risultati-questionario/risultati-questionario.component';
import { EbaComplianceComponent } from './eba-compliance/eba-compliance.component';
import { ModelloUniversaleComponent } from './modello-universale/modello-universale.component';
import { EnergiaGasComponent } from './energia-gas/energia-gas.component';
import { CensimentoClienteComponent } from './censimento-cliente/censimento-cliente.component';
import { ListaClientiComponent } from './lista-clienti/lista-clienti.component';
import { CensimentoRichiedenteComponent } from './censimento-richiedente/censimento-richiedente.component';
import { GestioneAnagraficaComponent } from './gestione-anagrafica/gestione-anagrafica.component';
import { ConfigurazioneAmbienteComponent } from './configurazione-ambiente/configurazione-ambiente.component';
import { ModelloCommercialeComponent } from './modello-commerciale/modello-commerciale.component';
import { ListaBplanOldComponent } from "./lista-bplan-old/lista-bplan-old.component";
import { QuestionarioOldComponent } from "./questionario_old/questionario-old.component";
import { ConfigurazioneBusinessPlanComponent } from './configurazione-business-plan/configurazione-business-plan.component';
import { ListaUtentiComponent } from './lista-utenti/lista-utenti.component';
import { ListaBplanNuovaComponent } from './lista-bplan-nuova/lista-bplan-nuova.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { BilancioManualeComponent } from './bilancio-manuale/bilancio-manuale.component';
import { InputBenchmarkComponent } from './benchmark/input-benchmark/input-benchmark.component';
import { ListaSmartBpComponent } from './lista-smart-bp/lista-smart-bp.component';
import { CensimentoSmartComponent } from './censimento-smart/censimento-smart.component';
import { SmartBpComponent } from './smart-bp/smart-bp.component';
import { RoleUserGuard } from './roleUser.guard';
import { SituazioneFinanziariaComponent } from './configurazione-smart-bp/situazione-finanziaria/situazione-finanziaria.component';
import { BilancioBacktestingComponent } from './backtesting/bilancio-backtesting/bilancio-backtesting.component';
import { ConfrontoBacktestingComponent } from './backtesting/confronto-backtesting/confronto-backtesting.component';
import { CentroStudiComponent } from './centro-studi/centro-studi.component';
import { ListaCrisiImpresaComponent } from './crisi-impresa/lista-crisi-impresa/lista-crisi-impresa.component';
import { ConfCrisiImpresaComponent } from './crisi-impresa/conf-crisi-impresa/conf-crisi-impresa.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  //{ path: 'profile', component: ProfileComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'user', component: BoardUserComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'frontoffice', component: BoardFrontofficeComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'admin', component: BoardAdminComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'funzioni', component: FunzioniComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'bilancio', component: BilancioManualeComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'intestatario', component: IntestatarioComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'presaincaricocr', component: PresaIncaricoCrComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'analisicr', component: AnalisicrComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'analisimcc', component: AnalisimccComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  // { path: 'questionario', component: QuestionarioComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'questionario', component: QuestionarioOldComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'prezziecosti', component: PrezziECostiComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  // { path: 'anagraficaprodotti', component: AnagraficaProdottiComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'preview', component: RisultatiQuestionarioComponent, canActivate: [LoginUserGuard] },
  //{ path: 'pratiche', component: ListaBplanComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'pratiche', component: ListaBplanOldComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'eba', component: EbaComplianceComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'gas', component: EnergiaGasComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  //{ path: 'modelloUniversale', component: ModelloUniversaleComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'censimentocliente', component: CensimentoClienteComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'gestioneclienti', component: ListaClientiComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'censiementorichiedente', component: CensimentoRichiedenteComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'gestioneanagrafica', component: GestioneAnagraficaComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'configurazioneambiente', component: ConfigurazioneAmbienteComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'modelloCommerciale', component: ModelloCommercialeComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'configurazioneBP', component: ConfigurazioneBusinessPlanComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'gestioneUtenti', component: ListaUtentiComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'listaBplanNuova', component: ListaBplanNuovaComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'tooltip', component: TooltipComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'benchmark', component: InputBenchmarkComponent, canActivate: [LoginUserGuard,RoleUserGuard] },
  { path: 'smartBp', component: ListaSmartBpComponent, canActivate: [LoginUserGuard] },
  { path: 'situazioneFinanziariaSmart', component: SituazioneFinanziariaComponent, canActivate: [LoginUserGuard] },
  { path: 'bilancioBacktesting', component: BilancioBacktestingComponent, canActivate: [LoginUserGuard] },
  { path: 'confrontoBacktesting', component: ConfrontoBacktestingComponent, canActivate: [LoginUserGuard] },
  { path: 'centroStudi', component: CentroStudiComponent, canActivate: [LoginUserGuard] },
  { path: 'listaCrisi', component: ListaCrisiImpresaComponent, canActivate: [LoginUserGuard] },
  { path: 'confCrisi', component: ConfCrisiImpresaComponent, canActivate: [LoginUserGuard] },

  //{ path: 'listaSmartBp', component: SmartBpComponent, canActivate: [LoginUserGuard] },
  //{ path: 'censimentoSmart', component: CensimentoSmartComponent, canActivate: [LoginUserGuard] },
  { path: '**', redirectTo: 'funzioni', pathMatch: 'full' }// prendi ogni url relativo che non conosce(**) e ridireziona su /funzioni
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],

  exports: [RouterModule]
})
export class AppRoutingModule { }
