import { Component, OnInit } from '@angular/core';
import { BilancioService } from '../../_services/bilancio.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToolTipService } from '../../_services/tool-tip.service';
import { ConfigurazioneBusinessPlanService } from '../../_services/configurazione-business-plan.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToolTip } from '../../interface/toolTip';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BacktestingService } from '../../_services/backtesting.service';
import { PraticheNuova } from '../../interface/Pratiche-nuova';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bilancio-backtesting',
  templateUrl: './bilancio-backtesting.component.html',
  styleUrls: ['./bilancio-backtesting.component.css']
})
export class BilancioBacktestingComponent implements OnInit {

  dataBilObj: any;
  dataCrObj: any
  private _idRichiedenteTemp: number;
  submitted = false;
  selectedIndex = 0; /* serve per farlo partire da bilancio */

  listaBilanci: any[] = [];
  bilancioCaricato: File;
  crCaricato: File;
  listaCr: any[] = [];
  faTrashAlt = faTrashAlt;


  /* varibili upload file */
  fileBilancio: File;
  errorBilancio: string;
  messaggioerroreBilancio: string = ""
  buttonDisabledBilancio = true
  dataInserimentoBilancio = null;
  currentData = new Date().getFullYear();
  listaQuarter= [];
  listaTips: ToolTip[] = [];
  warningBilancioVecchio: boolean = false
  bpSubscription: Subscription
  tipsSubscription: Subscription
  messaggioSuccessoCr: any = "";
  backtestingForm!: UntypedFormGroup;
  bilancioPresente: boolean = false;
  businessPlan: PraticheNuova = null;
  annoBilancio: number = 0;

  constructor(
    private bilancioService: BilancioService,
    private formBuilder: UntypedFormBuilder,
    private toolTipService: ToolTipService,
    private backtestingService: BacktestingService,
    private configurazioneBPService: ConfigurazioneBusinessPlanService,
    private router: Router) {


  }
  get f() { return this.backtestingForm.controls; }

  ngOnDestroy() {
    this.bpSubscription.unsubscribe()
  }
  ngOnInit(): void {

    this.backtestingForm = this.formBuilder.group({
      quarterBilancio: ['', Validators.required],
      caricaBilancio: [''],
      bilancio: [''],
    })

    
    this.bpSubscription = this.backtestingService.currentBusinessPlan.subscribe(bp => {
      this.businessPlan = bp;
      if(bp != null){
      this.configurazioneBPService.getIndicatoriGuida(bp.id,bp.idAziendaRichiedente).subscribe(ind => {
        this.annoBilancio = ind.idAnnoIniziale == 1 ? new Date(bp.dataCreazione).getFullYear() : new Date(bp.dataCreazione).getFullYear() + 1;
        if(this.annoBilancio == new Date().getFullYear()){
          let ultimoQuarter = Math.ceil((new Date().getMonth() + 1)/3) - 1;
        this.listaQuarter.push({id: ultimoQuarter, descrizione: ultimoQuarter + "° quarter " + this.annoBilancio})
        if(ultimoQuarter > 1) this.listaQuarter.push({id: ultimoQuarter - 1, descrizione: ultimoQuarter - 1 + "° quarter " + this.annoBilancio})
      }else if(this.annoBilancio == new Date().getFullYear() - 1){
        this.listaQuarter.push({id: 4, descrizione: 4 + "° quarter " + this.annoBilancio})
        this.listaQuarter.push({id: 3, descrizione: 3 + "° quarter " + this.annoBilancio})

      }else{
        this.router.navigate(["/listaBplanNuova"])

      }
      })
    }else{
      this.router.navigate(["/listaBplanNuova"])
    }
    });

    
  }

  getDataBilancio() {
    this.backtestingService.getDataLastBilancioBacktest(this.businessPlan.idAziendaRichiedente, this.annoBilancio, this.f.quarterBilancio.value.id*3).subscribe(dataBil => {
        if (dataBil != null) {
          this.dataInserimentoBilancio = new DatePipe('it').transform(dataBil.dataCreazione, 'dd/MM/yyyy');
          this.bilancioPresente = true;
        } else {
          this.dataInserimentoBilancio = null;
          this.bilancioPresente = false;

        }
      })
   

  }
  onChangeQuarter() {
    this.f.quarterBilancio.updateValueAndValidity()
    if (this.f.quarterBilancio.value) {
      this.f.caricaBilancio.setValue(1)
      this.getDataBilancio();

    }
  }

  onChangeCaricaBilancio(tipoCaricamento: number) {
    this.f.caricaBilancio.updateValueAndValidity()
    if (tipoCaricamento == 1) {
      this.f.bilancio.setValidators(Validators.required)
      this.errorBilancio = null;
      this.messaggioerroreBilancio = ""
    } else if (tipoCaricamento == 2) {
      this.errorBilancio = null;
      this.f.bilancio.clearValidators()
    }
    this.f.bilancio.updateValueAndValidity();
  }

  getFileBilancio(event) {
    if (event.target.files.length > 1) {
      this.errorBilancio = "Non è possibile caricare più di un file."
      this.buttonDisabledBilancio = true;
    } else {
      let fileName = event.target.files[0].name
      /* console.log(fileName) */
      let split = fileName.split('.');/* spezzo il nome al . nell array split */
      let ext = split[split.length - 1];
      if (ext.toLowerCase() != 'xbrl') {
        this.buttonDisabledBilancio = true;
        this.errorBilancio = 'Il file non è compatibile. Carica un file .xbrl'
      } else {
        this.errorBilancio = null;
        this.buttonDisabledBilancio = false;
        this.fileBilancio = event.target.files[0]

      }
    }

  }

  CaricaBilancio() {
    const formdata: FormData = new FormData();
    formdata.append('file', this.fileBilancio);
    formdata.append('idRichiedente', (this._idRichiedenteTemp).toString());
    this.bilancioCaricato = null
    this.messaggioerroreBilancio = ""
    this.bilancioService.caricaXbrl(formdata).subscribe(response => {
      if (response.response == "salvato con successo") {
        this.bilancioService.callPython().subscribe((response1) => {
          if (response1.response == "successo") {
            this.bilancioCaricato = this.fileBilancio
            this.getDataBilancio()
          } else {
            //messaggio errorBilancioe in response.errorBilancioe
            this.messaggioerroreBilancio = "Errore nel caricamento. Contattare l'amministrazione."
            /* implementare chiamata per cancellare il record nella tabella ga_xbrl */
          }
        })

      } else {
        this.messaggioerroreBilancio = "Errore nel caricamento"
      }
    });

  }

  deleteFileBilancio() {
    this.bilancioCaricato = null
  }

  goToBilancio() {
    this.router.navigate(['/bilancio']);
  }

  confronta() {
    this.backtestingService.loadBilancioBacktesting({anno: this.annoBilancio, mese: this.f.quarterBilancio.value.id*3});
    this.router.navigate(['/confrontoBacktesting']);
  }

}
