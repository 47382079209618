<div class="container-fluid container-overflow">
    <div class="row nopadding" *ngIf="idRichiedenteTemp==undefined; else elseContent">
      <div class="col-12 nopadding d-flex align-items-center margin-top-10">
        <h3> Inserisci l'azienda di cui vuoi configurare l'ambiente.</h3>
      </div>
    </div>
    <ng-template #elseContent>
      <div class="row nopadding">
        <div class="col-8 d-flex align-items-end justify-content-start">
          <h2 style="font-weight:bold ;">Totale debiti al {{'31/12/' + anniBilancio}} :
            {{totaleDebiti | number:
            '1.0-0':'it'}} €
          </h2>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end margin-top-10" *ngIf="!crisiImpresa">
          <button class="btn btn-success" [disabled]="!debitiformGroup.dirty" (click)="onSubmitDebiti()">Salva</button>
          <button class="btn btn-secondary" style="margin-left: 20px" type="button" (click)="annullaDebiti()">
            Annulla modifiche
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 align-items-center " *ngIf="!bilancioAbbreviato;else bilAbbreviato">
          <h2>Ripartizione debiti da bilancio:</h2>
        </div>
        <ng-template #bilAbbreviato>
          <div class="col-4 col-xl-3 align-items-center ">
            <h2>Selezionare le voci di debito&nbsp;<i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                placement="top" container="body" ngbTooltip="??"></i>&nbsp;:
            </h2>
          </div>
          <div class="col-1"><button type="button" (click)="aggiornaListaCheckBox()"
              class="btn btn-primary dropdown-toggle">
              Modifica <span class="caret"></span>
            </button></div>
          <div *ngIf="openCheckBoxComponent==true" class="col-12"><app-check-box-list
              [listaCheckBox]="listaTipoDebitoBean"
              (listaCheckBoxModificata)="ModificaListaDebiti($event)"></app-check-box-list></div>
        </ng-template>
        <hr>
      </div>
      <div [formGroup]="debitiformGroup">
        <div formArrayName="arrayDebitoria" class="nopadding" id="container-situazione-finaziaria-body">
          <div class="row">
            <div class="col-12 erroreDebiti" *ngIf=" submitted && erroreSommaTotale==true">
              La somma delle voci di debito è diversa dal totale riportato sul bilancio.
            </div>
          </div>
          <div *ngFor="let debito of debitiFormArray;let i = index" [formGroupName]="i">
            <div class="row d-flex justify-content-between">
              <div class="col-12">
                <h2> {{nomeDebito[i]}}</h2>
              </div>
            </div>
            <div class="row margin-top-10 ">
              <div class="col-2">
                <label class=" nopadding">Totale</label>
                <input type="text" class="form-control" formControlName="totale" currencyMask
                  [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                  [ngClass]="{ 'is-invalid':submitted && (debito.controls.totale.errors || erroreSommaParziale[i]==true ||erroreSommaTotale ==true )}">
              </div>
              <div class="col-2 d-flex justify-content-center">
                <button class="btn btn-primary mr-1" type="button" (click)="suddividiDebito(i)"
                  *ngIf="!this.debitoSuddiviso[i]">Suddividi</button>
              </div>
            </div>
            <div class="col-12 erroreDebiti" *ngIf=" submitted && erroreSommaParziale[i]==true">
              La somma della suddivisione deve essere uguale al totale di
              {{nomeDebito[i]}}
            </div>
            <ng-container *ngIf="!debitoSuddiviso[i];else suddivisioneContent">
              <div class="row margin-top-10 d-flex flex-row">
                <div class="col-2"><label class=" nopadding">Entro l'esercizio successivo</label></div>
                <div class="col-2"><input type="text" class="form-control" formControlName="entroEsercizio" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid':submitted && debito.controls.entroEsercizio.errors }">
                </div>
              </div>
              <div class="row margin-top-10 d-flex flex-row">
                <div class="col-2"><label class=" nopadding">Oltre l'esercizio successivo</label></div>
                <div class="col-2"><input type="text" class="form-control" formControlName="oltreEsercizio" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid':submitted && debito.controls.oltreEsercizio.errors }">
                </div>
              </div>
            </ng-container>
            <hr>
            <ng-template #suddivisioneContent>
              <!-- autoliquidanti -->
              <div *ngIf="this.tipoDebitoList[i] == 4"
                formArrayName="suddivisioneAutoliq">
                <div class="row d-flex justify-content-between margin-top-10">
                  <div class="col-8">
                    <h3 class="nopadding">Fidi Autoliquidanti</h3>
                  </div>
                </div>
                <div *ngFor="let autoliq of debito.controls.suddivisioneAutoliq.controls;let j=index" [formGroupName]="j">
  
                  <div class="row margin-top-10">
                    <div class="col-2">
                      <label>Descrizione</label>
                      <input type="text" class="form-control" formControlName="autoliqDescrizione"
                        [ngClass]="{ 'is-invalid':submitted && autoliq.controls.autoliqDescrizione.errors}">
                    </div>
                    <div class="col-2">
                      <label>Accordato</label>
                      <input type="text" class="form-control" formControlName="autoliqAccordato" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                        [ngClass]="{ 'is-invalid':submitted && autoliq.controls.autoliqAccordato.errors }"
                        (keyup)="setResiduoAutoliquidante(i,j)">
                    </div>
                    <div class="col-2">
                      <label>Utilizzato</label>
                      <input type="text" class="form-control" formControlName="autoliqUtilizzato" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                        [ngClass]="{ 'is-invalid':submitted && autoliq.controls.autoliqUtilizzato.errors || submitted && erroreSommaParziale[i] ==true}"
                        (keyup)="setResiduoAutoliquidante(i,j)">
                    </div>
                    <div class="col-2">
                      <label>Residuo</label>
                      <input type="text" class="form-control" formControlName="autoliqRimanenze" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }">
                    </div>
                    <div class="col-1 d-flex justify-content-center align-items-center" *ngIf="j>0">
                      <fa-icon class="pointer " style="font-size: 20px" [icon]="faTrashAlt" (click)="eliminaAutoliq(i,j)">
                      </fa-icon>
                    </div>
                  </div>
                </div>
                <div class="row   margin-top-20">
                  <div class="col-10 d-flex justify-content-end">
                    <button class="btn btn-primary mr-1" type="button" (click)="aggiungiAutoliq(i)">Aggiungi</button>
                  </div>
                </div>
                <hr>
              </div>
  
              <!-- a revoca -->
              <div *ngIf="this.tipoDebitoList[i] == 4"
                formArrayName="suddivisioneRevoca">
                <div class="row d-flex justify-content-between margin-top-10">
                  <div class="col-8">
                    <h3 class="nopadding">Fidi a Revoca</h3>
                  </div>
                </div>
                <div *ngFor="let revoca of debito.controls.suddivisioneRevoca.controls;let j=index" [formGroupName]="j">
                  <div class="row margin-top-10">
                    <div class="col-2">
                      <label>Descrizione</label>
                      <input type="text" class="form-control" formControlName="revocaDescrizione"
                        [ngClass]="{ 'is-invalid':submitted && revoca.controls.revocaDescrizione.errors}">
                    </div>
                    <div class="col-2">
                      <label>Accordato</label>
                      <input type="text" class="form-control" formControlName="revocaAccordato" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                        [ngClass]="{ 'is-invalid':submitted && revoca.controls.revocaAccordato.errors}"
                        (keyup)="setResiduoRevoca(i,j)">
                    </div>
                    <div class="col-2">
                      <label>Utilizzato</label>
                      <input type="text" class="form-control" formControlName="revocaUtilizzato" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                        [ngClass]="{ 'is-invalid':submitted && revoca.controls.revocaUtilizzato.errors || submitted && erroreSommaParziale[i] ==true}"
                        (keyup)="setResiduoRevoca(i,j)">
                    </div>
                    <div class="col-2">
                      <label>Residuo</label>
                      <input type="text" class="form-control" formControlName="revocaRimanenze" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }">
                    </div>
                    <div class="col-1 d-flex justify-content-center align-items-center" *ngIf="j>0">
                      <fa-icon class="pointer" style="font-size: 20px" [icon]="faTrashAlt" (click)="eliminaRevoca(i,j)">
                      </fa-icon>
                    </div>
                  </div>
                </div>
                <div class="row   margin-top-20">
                  <div class="col-10 d-flex justify-content-end">
                    <button class="btn btn-primary mr-1" type="button" (click)="aggiungiRevoca(i)">Aggiungi</button>
                  </div>
                </div>
                <hr>
              </div>
  
              <!-- debiti -->
              <div formArrayName="suddivisioneDebiti">
                <div class="row d-flex justify-content-between margin-top-10">
                  <div class="col-8" *ngIf="this.tipoDebitoList[i] == 4">
                    <h3 class="nopadding">Fidi a scadenza</h3>
                  </div>
                </div>
                <div *ngFor="let suddivisione of debito.controls.suddivisioneDebiti.controls;let j=index"
                  [formGroupName]="j">
  
                  <div class="row margin-top-10 d-flex justify-content-start align-items-end">
                    <div class="col-2">
                      <label>Descrizione</label>
                      <input type="text" class="form-control" formControlName="descrizione"
                        [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.descrizione.errors}">
                    </div>
                    <div [ngClass]="this.tipoDebitoList[i] == 4 && !crisiImpresa? 'col-2':'col-1'">
                      <label>Importo</label>
                      <input type="text" class="form-control" formControlName="importo" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                        [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.importo.errorssubmitted || submitted && erroreSommaParziale[i] ==true}"
                        (keyup)="setValoreRinegoziatoDebito(i,j)">
                    </div>
                    <div class="col-2" *ngIf="this.tipoDebitoList[i] != 4 || crisiImpresa">
                      <label>Importo Rinegoziato</label>
                      <input type="text" class="form-control" formControlName="importoRinegoziato" currencyMask
                        [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                        [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.importoRinegoziato.errors}"
                        (keyup)="valoreRinegoziatoMax(i,j)">
                    </div>
                    <div class="col-1">
                      <label>Anno</label>
                      <input type="number" class="form-control" formControlName="annoInizioRimborso"
                      (change)="onChangeAnno(i,j)"
                        [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.annoInizioRimborso.errors}">
                    </div>
                    <div class="col-2">
                      <label>Mese inizio rimborso</label>
                      <select class="form-select form-control" formControlName="meseInizioRimborso"
                        [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.meseInizioRimborso.errors}">
                        <option [ngValue]="k" *ngFor="let mese of listaMesi;let k=index">{{mese |date:'MMMM' : 'it'}}
                        </option>
                      </select>
                    </div>
                    <div class="col-1">
                      <label>Rate residue</label>
                      <select class="form-select form-control" formControlName="numeroRate"
                        [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.numeroRate.errors}">
                        <option [ngValue]="numero" *ngFor="let numero of listaNumeroRate">{{numero}}</option>
                      </select>
                    </div>
                    <div class="col-2">
                      <label>Tipologia rata</label>
                      <select class="form-select form-control" formControlName="tipologiaRata"
                        [ngClass]="{ 'is-invalid':submitted && suddivisione.controls.tipologiaRata.errors}">
                        <option [ngValue]="tipologiaRata" *ngFor="let tipologiaRata of tipoRateList">
                          {{tipologiaRata.descrizione}}</option>
                      </select>
                    </div>
  
                    <div class="col-1 d-flex justify-content-center align-items-center" *ngIf="j>0" style="margin-bottom: 0.2em">
                      <fa-icon class="pointer" style="font-size: 20px" [icon]="faTrashAlt" (click)="eliminaDebito(i,j)">
                      </fa-icon>
                    </div>
                  </div>
                </div>
                <div class="row   margin-top-20">
                  <div class="col-12 d-flex justify-content-end">
                    <button class="btn btn-primary mr-1" type="button" (click)="aggiungiDebito(i)">Aggiungi</button>
                  </div>
                </div>
              </div>
  
  
            </ng-template>
  
          </div>
        </div>
      </div>
    </ng-template>
  </div>