<form [formGroup]="formAteco">
<div class="row margin-bottom-10">
    <h3 class="font-bold">Codice ATECO</h3>
    <div class="col-3 col-lg-3 col-xl-3 ">
        <label>Divisione</label>
        <select formControlName="divisione" class="form-control form-select" (change)="onChangeDivisione()">
            <option [ngValue]="divisione"
                *ngFor="let divisione of listaAtecoDivisione">
                {{divisione.descrizioneCompleta}}
            </option>
        </select>
    </div>
    <div class="col-3 col-lg-3 col-xl-3">
        <label>Gruppo</label>
        <select formControlName="gruppo" class="form-control form-select" (change)="onChangeGruppo()">
            <option [ngValue]="gruppo"
                *ngFor="let gruppo of listaAtecoGruppo">
                {{gruppo.descrizioneCompleta}}
            </option>
        </select>
    </div>
    <div class="col-3 col-lg-3 col-xl-3">
        <label>Classe</label>
        <select formControlName="classe" class="form-control form-select">
            <option [ngValue]="classe"
                *ngFor="let classe of listaAtecoClasse">
                {{classe.descrizioneCompleta}}
            </option>
        </select>
    </div>
</div>
<div class="row">
  <div class="col-12 d-flex align-items-center justify-content-end">
    <button class="btn btn-success" type="submit" (click)="onSubmit()" [disabled]="formAteco.invalid">Elabora</button>
  </div>
</div>
</form>
<div class="table-container" *ngIf="this.data.length > 0">
    <table>
      <thead>
        <tr>
          <th></th>
          <th>{{annoInizio}}</th>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <th>Trend</th>
            <th>{{ year }}</th>
        </ng-container>

        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ricavi storici (K€)</td>
          <td>{{ getData(annoInizio,0).fatturato | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffFatt | percent:'1.0-2':'it' }}</td>
            <td>{{ getData(year, i + 1).fatturato | number:'1.0-0':'it'}}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Ricavi previsionali (K€)</td>
          <td style="color: red">{{ getData(annoInizio,0).fatturatoPrev | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffFattPrev | percent:'1.0-2':'it' }}</td>
            <td style="color: red">{{ getData(year, i + 1).fatturatoPrev | number:'1.0-0':'it'}}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Ricavi previsionali Pil (K€)</td>
          <td style="color: red">{{ getData(annoInizio,0).fatturatoPil | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffFattPil | percent:'1.0-2':'it' }}</td>
            <td style="color: red">{{ getData(year, i + 1).fatturatoPil | number:'1.0-0':'it'}}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Costi storici (K€)</td>
          <td>{{ getData(annoInizio, 0).acquistoBeniEServizi + getData(annoInizio, 0).costiPersonale | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffCosti | percent:'1.0-2':'it' }}</td>
            <td>{{ getData(year, i + 1).acquistoBeniEServizi + getData(year, i + 1).costiPersonale | number:'1.0-0':'it' }}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Costi previsionali (K€)</td>
          <td style="color: red">{{ getData(annoInizio, 0).acquistoBeniEServiziPrev + getData(annoInizio, 0).costiPersonalePrev | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffCostiPrev | percent:'1.0-2':'it' }}</td>
            <td style="color: red">{{ getData(year, i + 1).acquistoBeniEServiziPrev + getData(year, i + 1).costiPersonalePrev | number:'1.0-0':'it' }}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Ebitda storico (K€)</td>
          <td> {{ getData(annoInizio, 0).fatturato - getData(annoInizio, 0).acquistoBeniEServizi - getData(annoInizio, 0).costiPersonale | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffEbitda | percent:'1.0-2':'it' }}</td>
            <td>{{ getData(year, i + 1).fatturato - getData(year, i + 1).acquistoBeniEServizi - getData(year, i + 1).costiPersonale | number:'1.0-0':'it'}}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Ebitda previsionale (K€)</td>
          <td style="color: red"> {{ getData(annoInizio, 0).fatturatoPrev - getData(annoInizio, 0).acquistoBeniEServiziPrev - getData(annoInizio, 0).costiPersonalePrev | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffEbitdaPrev | percent:'1.0-2':'it' }}</td>
            <td style="color: red">{{ getData(year, i + 1).fatturatoPrev - getData(year, i + 1).acquistoBeniEServiziPrev - getData(year, i + 1).costiPersonalePrev | number:'1.0-0':'it'}}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Numero aziende storico</td>
          <td>{{ getData(annoInizio,0).numeroAziende | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffNumeroAziende | percent:'1.0-2':'it' }}</td>
            <td>{{ getData(year, i + 1).numeroAziende | number:'1.0-0':'it'}}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Numero aziende previsonale</td>
          <td style="color: red">{{ getData(annoInizio,0).numeroAziendePrev | number:'1.0-0':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffNumeroAziendePrev | percent:'1.0-2':'it' }}</td>
            <td style="color: red">{{ getData(year, i + 1).numeroAziendePrev | number:'1.0-0':'it'}}</td>
          </ng-container>
        </tr>
        <tr>
          <td>Indice dei prezzi</td>
          <td style="color: red">{{ getData(annoInizio,0).indicePrezzi | number:'1.0-1':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffIndicePrezzi | percent:'1.0-2':'it' }}</td>
            <td>{{ getData(year, i + 1).indicePrezzi | number:'1.0-1':'it'}}</td>
          </ng-container>
        </tr>
        <tr>
          <td>PIL (K€)</td>
          <td style="color: red">{{ getData(annoInizio,0).pil | number:'1.0-1':'it'}}</td>
          <ng-container *ngFor="let year of displayedYears; let i = index">
            <td>{{ getData(year, i + 1).diffPil | percent:'1.0-2':'it' }}</td>
            <td>{{ getData(year, i + 1).pil | number:'1.0-1':'it'}}</td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    <div class="navigation">
      <button class="btn shadow-none" (click)="previousPage()">
        <i class="fa fa-arrow-circle-left fa-2x"  aria-hidden="true" placement="top" ngbTooltip="Anno più uno" ></i>
        </button>
        <button  class="btn shadow-none" (click)="nextPage()">
            <i class="fa fa-arrow-circle-right fa-2x"  aria-hidden="true" placement="top" ngbTooltip="Anno meno uno"></i>
          </button>
    </div>
  </div>
  