import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BilancioService } from 'src/app/_services/bilancio.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { CrisiService } from 'src/app/_services/crisi.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { SituazioneCreditiziaSmartComponent } from 'src/app/configurazione-smart-bp/situazione-finanziaria/situazione-creditizia-smart/situazione-creditizia-smart.component';
import { SituazioneDebitoriaSmartComponent } from 'src/app/configurazione-smart-bp/situazione-finanziaria/situazione-debitoria-smart/situazione-debitoria-smart.component';

@Component({
  selector: 'app-conf-crisi-impresa',
  templateUrl: './conf-crisi-impresa.component.html',
  styleUrls: ['./conf-crisi-impresa.component.css']
})
export class ConfCrisiImpresaComponent implements OnInit {

  @ViewChild(SituazioneDebitoriaSmartComponent, { static: false }) situazioneDebitoriaComponent: SituazioneDebitoriaSmartComponent;
  @ViewChild(SituazioneCreditiziaSmartComponent, { static: false }) situazioneCreditiziaComponent: SituazioneCreditiziaSmartComponent;

  selectedIndex = 0;
  selectedIndexFin = 0;
  codiceAtecoPrimario: string;
  
  confCrisiForm: UntypedFormGroup;
  risultatiVolatileSubscription: Subscription;
  currentCrisiSubscription: Subscription;
  checkBpSalvatoSubscription: Subscription;
  //calcolaStickySub: Subscription;
  //setPaddingTopRicaviCostiSub: Subscription;
  //setPaddingTopDebitoriaSub: Subscription;

  listaNomiAzienda: string[] = [];
  aziendaRichiedente: any = null;
 
  bpSalvato: boolean = false;

  situazioneDebitoriaValid: boolean = true;
  stagionalitaValid: boolean = true;
  warningValid: boolean = true;
  situazioneCreditiziaValid: boolean = true;


  situazioneDebitoriaDirty: boolean = false;
  stagionalitaDirty: boolean = false;
  warningDirty: boolean = false;
  situazioneCreditiziaDirty: boolean = false;

  situazioneDebitoriaSaved: boolean = false;
  situazioneCreditiziaSaved: boolean = false;
  crisiImpresa: any = null;
  data = new Date();
  profiloBP = 1;
  sommaColonneCrisi : any[] = [];
  sommaCentoPagamenti = true;
  sommaCentoCrediti = true;
  sommaCentoVenduto = true;
  sommaCentoMagaz = true;
  submitted = false;
  bilancioAbbreviato = false;
annoOut = 0;
profilo = 1;
listaProfiliProspettici = ['Worst case','Expected case','Best case'];
annoInizio = new Date().getFullYear();
boolCredDeb = false;

  inizioDurata = {
    anniSviluppoBp: 0,
    annoIniziale: new Date().getFullYear()
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private crisiService: CrisiService,
    private tokeStorageService: TokenStorageService,
    private router: Router,
    private bilancioService: BilancioService,) {

    this.confCrisiForm = this.formBuilder.group({
      stagionalita: this.formBuilder.group({
        percTrimVenduto: this.formBuilder.array([[25],[25],[25],[25]]),
        percTrimMagaz: this.formBuilder.array([[25],[25],[25],[25]]),
        giorniIncassoCrediti: this.formBuilder.array([
          [{ value: ''}], /* 30 gg */
          [{ value: ''}], /* 60 gg */
          [{ value: ''}],/* 90 gg */
          [{ value: ''}],/* 120 gg */
          [{ value: ''}],/* 150 gg */
          [{ value: ''}], /* >150 gg */
        ]),
        giorniPagamentoFornitori: this.formBuilder.array([
          [{ value: ''}], /* 30 gg */
          [{ value: ''}], /* 60 gg */
          [{ value: ''}],/* 90 gg */
          [{ value: ''}],/* 120 gg */
          [{ value: ''}],/* 150 gg */
          [{ value: ''}], /* >150 gg */
        ]),
      }),
      situazioneDebitoria: this.formBuilder.group({}),
      situazioneCreditizia: this.formBuilder.group({})
    })
  }

  get currentUser() { return this.tokeStorageService.getUser() }
  get f() { return this.confCrisiForm.controls }
  get stagionalitaForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.stagionalita }
  get creditiForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.situazioneCreditizia }
  get debitiForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.situazioneDebitoria }
  get percTrimVendutoArray(): UntypedFormArray { return <UntypedFormArray>this.stagionalitaForm.controls.percTrimVenduto; }
  get percTrimVendutoFormArray(): UntypedFormControl[] { return <UntypedFormControl[]>this.percTrimVendutoArray.controls; };
 get percTrimMagazArray(): UntypedFormArray { return <UntypedFormArray>this.stagionalitaForm.controls.percTrimMagaz; }
  get percTrimMagazFormArray(): UntypedFormControl[] { return <UntypedFormControl[]>this.percTrimMagazArray.controls; };
  get giorniIncassoArray(): UntypedFormArray { return <UntypedFormArray>this.stagionalitaForm.controls.giorniIncassoCrediti; }
  get giorniIncassoFormArray(): UntypedFormControl[] { return <UntypedFormControl[]>this.giorniIncassoArray.controls; };
 get giorniPagamentoArray(): UntypedFormArray { return <UntypedFormArray>this.stagionalitaForm.controls.giorniPagamentoFornitori; }
  get giorniPagamentoFormArray(): UntypedFormControl[] { return <UntypedFormControl[]>this.giorniPagamentoArray.controls; };


  /* quando accedo da un BP specifico (no nuovo) passiamo l'id BP */
  ngOnInit(): void {
    /* crea una copia della funzione  checkBpDirty() vhe viene messa nel service. richiamando la funzione nel service la attivo*/
    //this.headerService.checkFormDirty(this.checkBpDirty.bind(this))

    this.currentCrisiSubscription = this.crisiService.currentAziendaCrisi.subscribe(azienda => {
      if (azienda != null && azienda.id) {
        this.bilancioService.getDataLastBilancio(azienda.id).subscribe(checkBil => {
          if (checkBil != null) {
            this.bilancioAbbreviato = checkBil.abbreviato;
            this.aziendaRichiedente = azienda;
      
          }
        })
      } else {
       this.router.navigate(['/listaCrisi']);
      }
    })



  }

  sommaColonne(arrayDaSommare, keys: string[]) {
    let somma = {};
    for (let i = 0; i < keys.length; i++) {
      somma[keys[i]] = arrayDaSommare.reduce((acc, val) => { return acc + val[keys[i]] }, 0);
    }
    return somma;
  }

  elaboraBpCrisi(){
    this.submitted = true;
    
    let sommaR = 0;
    let sommaC = 0;
    let arrayPercVenduto = [];
    let arrayPercMagaz = [];

    for (let i = 0; i < this.percTrimVendutoFormArray.length; i++) {
      arrayPercVenduto.push(this.percTrimVendutoFormArray[i].value && this.percTrimVendutoFormArray[i].value >= 0? this.percTrimVendutoFormArray[i].value : 0);
      arrayPercMagaz.push(this.percTrimMagazFormArray[i].value && this.percTrimVendutoFormArray[i].value >= 0 ? this.percTrimMagazFormArray[i].value : 0);
      sommaR += arrayPercVenduto[i];
      sommaC += arrayPercMagaz[i];
    }
    this.sommaCentoVenduto = sommaR == 100;
    this.sommaCentoMagaz = sommaC == 100;

    sommaR = 0;
    sommaC = 0;
    let arrayPercCrediti = [];
    let arrayPercPagamenti = [];
    for (let i = 0; i < this.giorniIncassoFormArray.length; i++) {
      arrayPercCrediti.push(this.giorniIncassoFormArray[i].value && this.giorniIncassoFormArray[i].value >= 0? this.giorniIncassoFormArray[i].value : 0);
      arrayPercPagamenti.push(this.giorniPagamentoFormArray[i].value && this.giorniPagamentoFormArray[i].value >= 0? this.giorniPagamentoFormArray[i].value : 0);
      sommaR += arrayPercCrediti[i];
      sommaC += arrayPercPagamenti[i];
    }
    this.sommaCentoCrediti = sommaR == 100;
    this.sommaCentoPagamenti = sommaC == 100;
    let debitiValid = true;
    let creditiValid = true;
    if(this.crisiImpresa){
debitiValid = this.situazioneDebitoriaComponent.isDebitiValid();
creditiValid = this.situazioneCreditiziaComponent.isCreditiValid();
    }
    if(this.stagionalitaForm.valid && this.sommaCentoCrediti && this.sommaCentoPagamenti && this.sommaCentoVenduto && this.sommaCentoMagaz && creditiValid && debitiValid){
   
    const request = {
      idRichiedente: this.aziendaRichiedente.id,
      annoInizio: this.annoInizio,
      percTrimVenduto: arrayPercVenduto,
      percTrimMagaz: arrayPercMagaz,
      percGiorniCrediti: arrayPercCrediti,
      percGiorniDebiti: arrayPercPagamenti,
      situazioneCreditizia: this.crisiImpresa ? this.situazioneCreditiziaComponent.elaboraSituazioneCreditizia() : null,
      situazioneDebitoria: this.crisiImpresa ? this.situazioneDebitoriaComponent.elaboraSituazioneDebitoria() : null,
    };
    this.crisiService.elaboraBpCrisi(request).subscribe(result => {
      if(this.crisiImpresa) this.boolCredDeb = true;
      this.crisiImpresa = result;
      this.sommaColonneCrisi = [];
      for(let i = 0; i < this.crisiImpresa.length; i++){
        this.sommaColonneCrisi.push(this.sommaColonne(this.crisiImpresa[i].colonne.slice(0,12), Object.keys(this.crisiImpresa[i].colonne[0])));
      }
    })
  }
  }

  setAnno(index: number){
this.annoOut = index;
this.sommaColonneCrisi = [];
for(let i = 0; i < this.crisiImpresa.length; i++){
  this.sommaColonneCrisi.push(this.sommaColonne(this.crisiImpresa[i].colonne.slice((this.annoOut)*12,(this.annoOut + 1)*12), Object.keys(this.crisiImpresa[i].colonne[0])));
}

  }

  aggiornaTabella(){

  }
  ngOnDestroy(): void {
    this.currentCrisiSubscription.unsubscribe();
  }

}
