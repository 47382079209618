<div class="container-fluid" style="padding-top: 10px;">
    <!--sticky-bp conf-bp-container id="container-bpconfiguation" -->
    <div class="header">
      <div class="row margin-right-20">
  
        <div class="col-12 d-flex justify-content-center">
          <h2 class="text-center margin-bottom-10" style="font-family: var(--UI-font);margin: 0;">Centro Studi

          </h2>
        </div>
      </div>
    </div>
    <!-- selectedIndex proprietà delle mat tab app-dossier-amministrativi-->
    <div id="container-mat-tab-bp" >
      <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex" class="d-flex justify-content-around">
        <mat-tab label="Analisi di settore">
          <app-analisi-settore></app-analisi-settore>
        </mat-tab>
        <mat-tab label="Smart BP"
          id="Smart BP" [formGroup]="backtestMassivoForm"> 
          <div class="row">
            <div class="col-3"> 
                <label>Codice ATECO</label>
                <select formControlName="codiceAteco" class="form-control form-select"
                    (change)="getListaAziende()">
                    <option [ngValue]="codice"
                        *ngFor="let codice of listaCodiciAteco">
                        {{codice.descrizione}}
                    </option>
                </select>
                </div>
            <div class="col-6"> 
                <label>Aziende osservate</label>
                <ng-select
                [items]="listaAziende"
                [multiple]="true"
                [selectableGroupAsModel]="false"
                bindLabel="ragioneSociale"
                [closeOnSelect]="false"
                bindValue="id"
                formControlName="aziendeSelezionate"> 
                  
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/> {{item.ragioneSociale}}
                          </ng-template>
                         
              </ng-select>
            </div>
            <div class="col-3"> 
                <label>Anno</label>
                <select formControlName="anno" class="form-control form-select">
                    <option [ngValue]="anno"
                        *ngFor="let anno of listaAnni">
                        {{anno}}
                    </option>
                </select>
            </div>
            </div>
            <div class="row margin-top-20">
              <div class="col-12 flex-center-right">
                <button button type="button" [disabled]="!(f.anno.value && f.aziendeSelezionate.value && f.aziendeSelezionate.value.length > 0)"
                class="btn btn-dark margin-right-10"
                (click)="calcolaSmartBpMassivo()">Elabora
              </button>
              </div>
            </div>
                </mat-tab>

        <mat-tab label="Sensitivity analysis">
        </mat-tab>
        
  
  
      </mat-tab-group>
    </div>
  </div>
  <div class="footer">
  
  </div>