import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { DettaglioRisultatiService } from 'src/app/_services/dettaglio-risultati.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { LineaBusinessBpCompleto } from 'src/app/interface/LineaBusinessBpCompleto';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';
import { IndicatoriGuida } from 'src/app/interface/indicatori-guida-bp';
import { Magazzino } from 'src/app/interface/magazzino';
import { SituazioneCreditizia, SituazioneDebitoria } from 'src/app/interface/situazione-debitoria';

@Component({
  selector: 'app-assunzioni',
  templateUrl: './assunzioni.component.html',
  styleUrls: ['./assunzioni.component.css']
})
export class AssunzioniComponent implements OnInit {
  @Input() assunzioniSmart: any;
  @Input() smartBp: boolean;
  @Input() currentBp: PraticheNuova;
  @Input() ambitoAssunzioni: number;

  risultatiVolatileSubscription: Subscription;
  questionarioBpSubscription: Subscription;
  volatileData: boolean = false;
  nomiLinee: any[] = [];
  formAssunzioni: FormGroup;
  idRuoloUtente = 0;
  sommaCentoVenduto = true;
  sommaCentoCrediti: boolean = true;
  sommaCentoPagamenti: boolean = true;
  valoreMax = {};
  totaleCrediti = 0;
  totaleDebiti = 0;
  submitted = false;
  annoCreditiDebiti = 0;
  totaleCredito = [];
  totaleDebito = [];
  nomeCredito = [];
  nomeDebito = [];
  tipoDebitoList = [];
  tipoCreditoList = [];
  tipoRateList = [];
  listaMesi = [];
  listaNumeroRate = [];

  constructor(private formBuilder: UntypedFormBuilder, private serviceBp: ConfigurazioneBusinessPlanService, private tokenStorageService: TokenStorageService,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService, private risultatiService: DettaglioRisultatiService,) {
    this.formAssunzioni = formBuilder.group({
      lineeBusiness: this.formBuilder.array([]),
      giorniIncassoCrediti: this.formBuilder.group({
        a30Giorni: { value: '', disabled: true },
        a60Giorni: { value: '', disabled: true },
        a90Giorni: { value: '', disabled: true },
        a120Giorni: { value: '', disabled: true },
        a150Giorni: { value: '', disabled: true },
        oltre150Giorni: { value: '', disabled: true },
      }),
      giorniPagamentoFornitori: this.formBuilder.group({
        a30Giorni: { value: '', disabled: true },
        a60Giorni: { value: '', disabled: true },
        a90Giorni: { value: '', disabled: true },
        a120Giorni: { value: '', disabled: true },
        a150Giorni: { value: '', disabled: true },
        oltre150Giorni: { value: '', disabled: true },
      }),
      creditiFormGroup: this.formBuilder.group({
        arrayCreditizia: this.formBuilder.array([]),
      }),
      debitiFormGroup: this.formBuilder.group({
        arrayDebitoria: this.formBuilder.array([]),
      }),
    });
  }

  ngOnInit(): void {
    this.idRuoloUtente = this.tokenStorageService.getUser().roles.id;
    this.configurazioneAmbienteService.getValoriMax('magazzino').subscribe(
      valMax => {
        valMax.forEach(element => {
          if (element.error == null) {
            this.valoreMax[element.nomeCampo] = element.valoreMax;
          }
        });
      }
    );

    this.listaNumeroRate = []
    for (let i = 0; i < 500; i++) {
      this.listaNumeroRate.push(i + 1)
    }

    this.listaMesi = []
    for (let i = 0; i < 12; i++) {
      //Conta solo il mese
      this.listaMesi.push(new Date(1, i))
    }

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.volatileData = isVolatile;
      this.configurazioneAmbienteService.getListaTipoRate().subscribe(list => {
        this.tipoRateList = list
      if (isVolatile) {
        this.questionarioBpSubscription = this.serviceBp.currentQuestionarioBp.subscribe(response => {
          if (response != null) {
            this.popolaForm(response.ricaviCosti, response.magazzino, response.indicatoriGuidaBp.indicatoriGuida, response.situazioneCreditizia, response.situazioneDebitoria);
          }
        })
      } else {
        this.risultatiService.assunzioniTab(this.currentBp.id, this.currentBp.idAziendaRichiedente).subscribe(
          assunzioni => {
            if (assunzioni != null) {
              this.popolaForm(assunzioni.ricaviCosti, assunzioni.magazzino, assunzioni.indicatoriGuidaBp.indicatoriGuida, assunzioni.situazioneCreditizia, assunzioni.situazioneDebitoria);
            }
          }
        )
      }
    })
  })





  }

  popolaForm(ricaviCosti: LineaBusinessBpCompleto[], magazzino: Magazzino[], indicatoriGuida: IndicatoriGuida, crediti: SituazioneCreditizia, debiti: SituazioneDebitoria) {
    this.lineeBusinessArray.clear();
    this.nomiLinee = [];
    ricaviCosti.forEach(ricaviCostiForm => {
      this.nomiLinee.push(ricaviCostiForm.linea.nome);
      let ricavi = 0, costiVar = 0;
      if (ricaviCostiForm.linea.idRicaviCosti == 1) {
        ricavi = ricaviCostiForm.linea.ricaviComplessivo.valoreIniziale;
        costiVar = ricaviCostiForm.linea.costiComplessivo.valoreIniziale + ricaviCostiForm.linea.costiComplessivo.valoreInizialeAlt;
      } else if (ricaviCostiForm.linea.idRicaviCosti == 2) {
        for (let i = 0; i < ricaviCostiForm.prodotti.length; i++) {
          ricavi += ricaviCostiForm.prodotti[i].prezzoIniz * ricaviCostiForm.prodotti[i].quantitaIniz;
          costiVar += (ricaviCostiForm.prodotti[i].costoServiziIniz + ricaviCostiForm.prodotti[i].costoMaterieIniz) * ricaviCostiForm.prodotti[i].quantitaIniz;
        }

      }
      let magazzinoForm = null;
      if (ricaviCostiForm.linea.presenzaMagazzino) {
        magazzinoForm = magazzino.find(x => x.idLineaBusiness === ricaviCostiForm.linea.idLineaBusinessAmbiente);
      }
      this.lineeBusinessFormArray.push(this.formBuilder.group({
        ricavi: { value: ricavi, disabled: true },
        costiVar: { value: costiVar, disabled: true },
        costiFissi: { value: ricaviCostiForm.linea.costiFissiAzienda * ricaviCostiForm.linea.percCostiFissi / 100., disabled: true },
        ribassoRicavi: { value: ricaviCostiForm.linea.idRicaviCosti == 1 ? ricaviCostiForm.linea.ricaviComplessivo.ribasso : ricaviCostiForm.linea.prezzoUnitarioIniziale.ribasso, disabled: true },
        rialzoRicavi: { value: ricaviCostiForm.linea.idRicaviCosti == 1 ? ricaviCostiForm.linea.ricaviComplessivo.rialzo : ricaviCostiForm.linea.prezzoUnitarioIniziale.rialzo, disabled: true },
        driftRicavi: { value: ricaviCostiForm.linea.idRicaviCosti == 1 ? ricaviCostiForm.linea.ricaviComplessivo.drift : ricaviCostiForm.linea.prezzoUnitarioIniziale.drift, disabled: true },
        ribassoCosti: { value: ricaviCostiForm.linea.idRicaviCosti == 1 ? ricaviCostiForm.linea.costiComplessivo.ribasso : ricaviCostiForm.linea.costoUnitarioIniziale.ribasso, disabled: true },
        rialzoCosti: { value: ricaviCostiForm.linea.idRicaviCosti == 1 ? ricaviCostiForm.linea.costiComplessivo.rialzo : ricaviCostiForm.linea.costoUnitarioIniziale.rialzo, disabled: true },
        driftCosti: { value: ricaviCostiForm.linea.idRicaviCosti == 1 ? ricaviCostiForm.linea.costiComplessivo.drift : ricaviCostiForm.linea.costoUnitarioIniziale.drift, disabled: true },
        percTrimVenduto: this.formBuilder.array([{ value: ricaviCostiForm.linea.percTrimVenduto[0], disabled: true }, { value: ricaviCostiForm.linea.percTrimVenduto[1], disabled: true }, { value: ricaviCostiForm.linea.percTrimVenduto[2], disabled: true }, { value: ricaviCostiForm.linea.percTrimVenduto[3], disabled: true }]),
        indiceRotazione: { value: magazzino != null ? magazzinoForm.indRotMagaz : '', disabled: true },
        sogliaMagaz: { value: magazzino != null ? magazzinoForm.sogliaMagaz : '', disabled: true },
        ribassoMagaz: { value: magazzino != null ? magazzinoForm.magazRibasso : '', disabled: true },
        rialzoMagaz: { value: magazzino != null ? magazzinoForm.magazRialzo : '', disabled: true },
        driftMagaz: { value: magazzino != null ? magazzinoForm.magazDrift : '', disabled: true },
      }));

    });

    this.giorniIncassoCrediti.controls.a30Giorni.setValue(indicatoriGuida.giorniIncassoCrediti.a30Giorni)
    this.giorniIncassoCrediti.controls.a60Giorni.setValue(indicatoriGuida.giorniIncassoCrediti.a60Giorni)
    this.giorniIncassoCrediti.controls.a90Giorni.setValue(indicatoriGuida.giorniIncassoCrediti.a90Giorni)
    this.giorniIncassoCrediti.controls.a120Giorni.setValue(indicatoriGuida.giorniIncassoCrediti.a120Giorni)
    this.giorniIncassoCrediti.controls.a150Giorni.setValue(indicatoriGuida.giorniIncassoCrediti.a150Giorni)
    this.giorniIncassoCrediti.controls.oltre150Giorni.setValue(indicatoriGuida.giorniIncassoCrediti.oltre150Giorni)

    this.giorniPagamentoFornitori.controls.a30Giorni.setValue(indicatoriGuida.giorniPagamentoFornitori.a30Giorni)
    this.giorniPagamentoFornitori.controls.a60Giorni.setValue(indicatoriGuida.giorniPagamentoFornitori.a60Giorni)
    this.giorniPagamentoFornitori.controls.a90Giorni.setValue(indicatoriGuida.giorniPagamentoFornitori.a90Giorni)
    this.giorniPagamentoFornitori.controls.a120Giorni.setValue(indicatoriGuida.giorniPagamentoFornitori.a120Giorni)
    this.giorniPagamentoFornitori.controls.a150Giorni.setValue(indicatoriGuida.giorniPagamentoFornitori.a150Giorni)
    this.giorniPagamentoFornitori.controls.oltre150Giorni.setValue(indicatoriGuida.giorniPagamentoFornitori.oltre150Giorni)

    this.totaleCrediti = crediti.creditoTot;
    this.annoCreditiDebiti = crediti.anno
    this.totaleCredito = [];
    this.nomeCredito = [];
    this.tipoCreditoList = [];
    this.creditiArray.clear();
    if (this.totaleCrediti > 0) {
      if (crediti.credito.length > 0) {
        crediti.credito.forEach(credito => {
          this.nomeCredito.push(credito.nome)
          this.tipoCreditoList.push(credito.idTipoCredito)
          this.totaleCredito.push(credito.totale)
          let suddivisioneCreditoForm: UntypedFormGroup[] = [];
          if (credito.creditiBpList && credito.creditiBpList.length > 0) {
            credito.creditiBpList.forEach(dettaglio => {
              //gestire tutte le select
              let tipoRata: BeanStatico;
              this.tipoRateList.forEach(tipo => {
                if (tipo.id == dettaglio.idTipoRata) {
                  tipoRata = tipo
                }
              })
              suddivisioneCreditoForm.push(this.formBuilder.group({
                descrizione: {value: dettaglio.descrizione,disabled: true},
                importo: {value: dettaglio.importo,disabled: true},
                importoRinegoziato: {value: dettaglio.importoRinegoziato,disabled: true},
                numeroRate: {value: dettaglio.numeroRate,disabled: true},
                annoInizioRimborso: {value: dettaglio.annoInizioRimborso,disabled: true},
                meseInizioRimborso: {value: dettaglio.idMeseInizioRimborso,disabled: true},
                tipologiaRata: {value: tipoRata,disabled: true},
                //banche
              }))
            })
          }
          this.creditiArray.push(this.formBuilder.group({
            totale: { value: credito.totale, disabled: true },
            suddivisioneCrediti: this.formBuilder.array(suddivisioneCreditoForm),

          }))
        });
      }
    }

    this.totaleDebiti = debiti.debitoTot;
 this.totaleDebito = [];
    this.nomeDebito = [];
    this.tipoDebitoList = []
    this.debitiArray.clear();
    if (this.totaleDebiti > 0) {
      if (debiti.debito.length > 0) {
        debiti.debito.forEach((debito,j) => {
          this.nomeDebito.push(debito.nome)
          this.tipoDebitoList.push(debito.idTipoDebito)
          this.totaleDebito.push(debito.totale)
          let suddivisioneDebitoForm: UntypedFormGroup[] = [];
          let suddivisioneAutoliqForm: UntypedFormGroup[] = [];
          let suddivisioneRevocaForm: UntypedFormGroup[] = [];
          if (debito.debitiBpList && debito.debitiBpList.length > 0) {
            debito.debitiBpList.forEach(dettaglio => {
              //gestire tutte le select
              let tipoRata: BeanStatico;
              this.tipoRateList.forEach(tipo => {
                if (tipo.id == dettaglio.idTipoRata) {
                  tipoRata = tipo
                }
              })
              suddivisioneDebitoForm.push(this.formBuilder.group({
                descrizione: {value: dettaglio.descrizione,disabled: true},
                importo: {value: dettaglio.importo,disabled: true},
                importoRinegoziato: {value: dettaglio.importoRinegoziato,disabled: true},
                numeroRate: {value: dettaglio.numeroRate,disabled: true},
                annoInizioRimborso: {value: dettaglio.annoInizioRimborso,disabled: true},
                meseInizioRimborso: {value: dettaglio.idMeseInizioRimborso,disabled: true},
                tipologiaRata: {value: tipoRata,disabled: true},
                //banche
              }))
            })
          }
          if (this.tipoDebitoList[j] == 4) {
            if (debito.affidamentiBpList && debito.affidamentiBpList.length > 0) {
              debito.affidamentiBpList.forEach(affidamenti => {
                if (affidamenti.idTipoAffidamento == 1) {
                  let residuo = affidamenti.accordato - affidamenti.utilizzato
                  suddivisioneAutoliqForm.push(this.formBuilder.group({
                    autoliqDescrizione: {value: affidamenti.descrizione,disabled: true},
                    autoliqUtilizzato: {value: affidamenti.utilizzato,disabled: true},
                    autoliqAccordato: {value: affidamenti.accordato,disabled: true},
                    autoliqRimanenze: { value: residuo, disabled: true },
                  }))
                } else {
                  let residuo = affidamenti.accordato - affidamenti.utilizzato
                  suddivisioneRevocaForm.push(this.formBuilder.group({
                    revocaDescrizione: {value: affidamenti.descrizione,disabled: true},
                    revocaUtilizzato: {value: affidamenti.utilizzato,disabled: true},
                    revocaAccordato: {value: affidamenti.accordato,disabled: true},
                    revocaRimanenze: { value: residuo, disabled: true },
                  }))
                }

              })
            }
          }
          this.debitiArray.push(this.formBuilder.group({
            totale: { value: debito.totale, disabled: true },
            suddivisioneAutoliq: this.formBuilder.array(suddivisioneAutoliqForm),
            suddivisioneRevoca: this.formBuilder.array(suddivisioneRevocaForm),
            suddivisioneDebiti: this.formBuilder.array(suddivisioneDebitoForm),

          }))
        });
      }
    }

  }

  get f() { return this.formAssunzioni.controls }
  get giorniIncassoCrediti(): UntypedFormGroup { return <UntypedFormGroup>this.f.giorniIncassoCrediti };
  get giorniPagamentoFornitori(): UntypedFormGroup { return <UntypedFormGroup>this.f.giorniPagamentoFornitori };
  get creditiForm(): UntypedFormGroup { return <UntypedFormGroup>this.f.creditiFormGroup };
  get creditiArray(): UntypedFormArray { return <UntypedFormArray> this.creditiForm.controls.arrayCreditizia; }
  get creditiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.creditiArray.controls; }
  get debitiForm(): UntypedFormGroup { return <UntypedFormGroup>this.f.debitiFormGroup };
  get debitiArray(): UntypedFormArray { return <UntypedFormArray> this.debitiForm.controls.arrayDebitoria; }
  get debitiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.debitiArray.controls; }
  get lineeBusinessArray(): UntypedFormArray { return <UntypedFormArray>this.f.lineeBusiness }
  get lineeBusinessFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.lineeBusinessArray.controls }
  percTrimVendutoArray(i: number): UntypedFormArray { return <UntypedFormArray>this.lineeBusinessFormArray[i].controls.percTrimVenduto }
  percTrimVendutoFormArray(i: number): UntypedFormGroup[] { return <UntypedFormGroup[]>this.percTrimVendutoArray(i).controls }
  /*
      //modifica importo rinegoziato
      setValoreRinegoziatoDebito(i: number, j: number) {
        let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
        if (formgroup[j].controls.importo.value != null) {
          formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
        } else {
          formgroup[j].controls.importoRinegoziato.setValue('0')
        }
      }
      // modifica il residuo in base a cosa cambio in accordato e utilizzato
      setResiduoAutoliquidante(i: number, j: number) {
        let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneAutoliq).controls
        if (formgroup[j].controls.autoliqAccordato.value != null && formgroup[j].controls.autoliqUtilizzato.value != null) {
          formgroup[j].controls.autoliqRimanenze.setValue(parseFloat(formgroup[j].controls.autoliqAccordato.value) - parseFloat(formgroup[j].controls.autoliqUtilizzato.value))
        }
      }
      setResiduoRevoca(i: number, j: number) {
        let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneRevoca).controls
        if (formgroup[j].controls.revocaAccordato.value != null && formgroup[j].controls.revocaUtilizzato.value != null) {
          formgroup[j].controls.revocaRimanenze.setValue(parseFloat(formgroup[j].controls.revocaAccordato.value) - parseFloat(formgroup[j].controls.revocaUtilizzato.value))
        }
      }
    
      onChangeAnnoDebiti(i: number, j: number){
        let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
        if (formgroup[j].controls.annoInizioRimborso.value != null && formgroup[j].controls.annoInizioRimborso.value != "" && formgroup[j].controls.annoInizioRimborso.value <= this.currentAnno) {
          formgroup[j].controls.annoInizioRimborso.setValue(this.currentAnno)
        }
      }
  
    valoreRinegoziatoMaxDeb(i: number, j: number) {
      let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
      if (formgroup[j].controls.importo.value != null) {
        if (formgroup[j].controls.importoRinegoziato.value > formgroup[j].controls.importo.value)
          formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
      } else {
        formgroup[j].controls.importoRinegoziato.setValue('0')
      }
    }
  
    valoreRinegoziatoMaxCred(i: number, j: number) {
      let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[i].controls.suddivisioneCrediti).controls
      if (formgroup[j].controls.importo.value != null) {
        if (formgroup[j].controls.importoRinegoziato.value > formgroup[j].controls.importo.value)
          formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
      } else {
        formgroup[j].controls.importoRinegoziato.setValue('0')
      }
  
    }
  
      //modifica importo rinegoziato
      setValoreRinegoziatoCredito(i: number, j: number) {
        let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[i].controls.suddivisioneCrediti).controls
        if (formgroup[j].controls.importo.value != null) {
          formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
        } else {
          formgroup[j].controls.importoRinegoziato.setValue('0')
        }
      }
    
      onChangeAnnoCrediti(i: number, j: number){
        let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[i].controls.suddivisioneCrediti).controls
        if (formgroup[j].controls.annoInizioRimborso.value != null && formgroup[j].controls.annoInizioRimborso.value != "" && formgroup[j].controls.annoInizioRimborso.value <= this.currentAnno) {
          formgroup[j].controls.annoInizioRimborso.setValue(this.currentAnno)
        }
      }
  */
  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    //Finire
  }

}
