<div style="padding-top: 10px;">
  <!--sticky-bp conf-bp-container id="container-bpconfiguation" -->
  <div class="header">
    <div class="row margin-right-20">

      <div class="col-12 d-flex justify-content-center">
        <h2 class="text-center margin-bottom-10" style="font-family: var(--UI-font);margin: 0;">Configurazione
          Crisi Impresa per:
          <strong>{{aziendaRichiedente?
            aziendaRichiedente.ragioneSociale : ''}}</strong>
        </h2>
      </div>
      <div class="col-2"></div>
      <div class="col-8 d-flex justify-content-center">
      </div>
      <div class="col-2 d-flex align-items-end justify-content-end">
        <button class="btn btn-dark" style="margin-left: 20px" (click)="elaboraBpCrisi()">Elabora</button>
      </div>
    </div>
  </div>
  <!-- selectedIndex proprietà delle mat tab app-dossier-amministrativi-->
  <div id="container-mat-tab-bp" [formGroup]="confCrisiForm">
        <div class="container-fluid container-fluid-sez" [formGroup]="stagionalitaForm">
          <div class="row nopadding margin-0">
            <div class="col-12 nopadding margin-top-20">
              <div class="row margin-0">
                <div class="col-6">

                  <h3>Distribuzione trimestrale vendite (%):</h3>

              <table class="table table-borderless" formArrayName="percTrimVenduto">
                <thead>
                  <tr>
                    <th scope="col font-tabella">1° trimestre</th>
                    <th scope="col font-tabella">2° trimestre</th>
                    <th scope="col font-tabella">3° trimestre</th>
                    <th scope="col font-tabella">4° trimestre</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>

                    <td *ngFor="let trim of [].constructor(4);let i =index">
                      <input type="number" class="form-control" [formControlName]="i"
                      [ngClass]="{ 'is-invalid':submitted && percTrimVendutoFormArray[i].errors }"
                        onkeyup="if(this.value<0){this.value= this.value *-1}" />
                        <div *ngIf="submitted && percTrimVendutoFormArray[i].errors" class="invalid-feedback">
                            <div *ngIf="percTrimVendutoFormArray[i].errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </td>

                  </tr>
                </tbody>
              </table>
              <div class="row">

                <div *ngIf="!sommaCentoVenduto" class="errorePerc">
                    La somma delle percentuali trimestrali deve essere pari al 100%
                </div>
            </div>
            </div>
                <div class="col-6">

                  <h3>Distribuzione trimestrale reintegro scorte (%):</h3>

              <table class="table table-borderless" formArrayName="percTrimMagaz">
                <thead>
                  <tr>
                    <th scope="col font-tabella">1° trimestre</th>
                    <th scope="col font-tabella">2° trimestre</th>
                    <th scope="col font-tabella">3° trimestre</th>
                    <th scope="col font-tabella">4° trimestre</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>

                    <td *ngFor="let trim of [].constructor(4);let i =index">
                      <input type="number" class="form-control"  [formControlName]="i"
                      [ngClass]="{ 'is-invalid':submitted && percTrimMagazFormArray[i].errors }"
                        onkeyup="if(this.value<0){this.value= this.value *-1}" />
                        <div *ngIf="submitted && percTrimMagazFormArray[i].errors" class="invalid-feedback">
                            <div *ngIf="percTrimMagazFormArray[i].errors.required">Campo obbligatorio
                            </div>
                        </div>
                    </td>

                  </tr>
                </tbody>
              </table>
              <div class="row">

                <div *ngIf="!sommaCentoMagaz" class="errorePerc">
                    La somma delle percentuali trimestrali deve essere pari al 100%
                </div>
            </div>
            </div>

          </div>
          <div class="row d-flex flex-column margin-top-20">
                <div class="col-12 d-flex align-items-center">
    
                    <h2>Pianificazione incassi/pagamenti per arco temporale:</h2>
                </div>
            </div>
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">% a meno di 30 giorni</th>
                        <th scope="col">% tra 30 e 60 giorni</th>
                        <th scope="col">% tra 60 e 90 giorni</th>
                        <th scope="col">% tra 90 e 120 giorni</th>
                        <th scope="col">% tra 120 e 150 giorni</th>
                        <th scope="col">% a più di 150 giorni</th>
    
                    </tr>
                </thead>
                <tbody>
                    <tr formArrayName="giorniIncassoCrediti"
                        [ngClass]="{'border': !sommaCentoCrediti,'border-danger': !sommaCentoCrediti}">
                        <th scope="row" class="align-middle text-center" style="width: 15%">Giorni incasso
                            crediti
                        </th>
                        <td *ngFor="let trim of giorniIncassoFormArray; let i =index"><input [formControlName]="i" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"/>
                        </td>
    
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div *ngIf="!sommaCentoCrediti" class="errorePerc">
                    La somma delle percentuali dei crediti deve essere 100%
                </div>
            </div>
            <table class="table table-borderless">
                <tbody>
                    <tr formArrayName="giorniPagamentoFornitori"
                        [ngClass]="{'border': !sommaCentoPagamenti,'border-danger': !sommaCentoPagamenti}">
                        <th scope="row" class="align-middle text-center" style="width: 15%">Giorni
                            pagamento
                            fornitori
                        </th>
                        <td *ngFor="let trim of giorniPagamentoFormArray; let i =index">
                            <input [formControlName]="i" type="number" class="form-control" min=0
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                 />
                        </td>
   
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div *ngIf="!sommaCentoPagamenti" class="errorePerc">
                    La somma delle percentuali dei pagamenti deve essere 100%
                </div>
            </div>

            </div>
          </div>
        </div>
  </div>
  <div class="row sezione-vetrina-tabella larg-max-91-lg " *ngIf="crisiImpresa">
    <div class="margin-top-10 d-flex justify-content-center">
        <button *ngFor="let anno of [].constructor(5); let i = index" class="btn" style="background-color: white;margin-right: 10px" (click)="setAnno(i)">
          {{annoInizio + i}}
          </button>
      </div>
<div class="row d-flex justify-content-center margin-top-10">
    <div class="col-lg-2 col-3">
    <label>Profilo prospettico</label>
    <select id="profilo" [ngModelOptions]="{standalone: true}" [(ngModel)]="profilo"
        class="form-control">
        <option *ngFor="let profilo of listaProfiliProspettici;index as i" [ngValue]="i">
            {{profilo}}
        </option>

    </select>
</div>
</div>
                    <div class="d-flex">
                        <div id="crisi-impresa-table" class="col-12 padding-top-bottom-10 padding-left-right-15"
                            style="justify-content: center">

                            <div class="row intestazione text-center ">
                                <div class="col-2 alignLeft border-horizontal font-bold padding-left-right-5">
                                    CASH PLAN PROSPETTICO (K€)
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col font-bold alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{this.crisiImpresa[profilo].colonne[annoOut*12 + i].dataRiferimento
                                            | date:'MM/yyyy'
                                            : 'it'}}
                                        </div>
                                        <div
                                            class="col  font-bold alignRight border-horizontal padding-left-right-5">
                                            Totale
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga  text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    SALDO CASSA INIZIALE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profilo].colonne[annoOut*12 + i].cassaOperIniz
                                            | number: '1.0-0':'it')}}
                                        </div>
                                        <div class="col  alignRight border-horizontal padding-left-right-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    INCASSI CORRENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profilo].colonne[annoOut*12 + i].ricaviCassa
                                            |number: '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profilo].ricaviCassa| number: '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    COSTI CORRENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">

                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profilo].colonne[annoOut*12 + i].costiProduzione
                                            +
                                            this.crisiImpresa[profilo].colonne[annoOut*12 + i].costiPersonale +
                                            this.crisiImpresa[profilo].colonne[annoOut*12 + i].oneriGestione +
                                            this.crisiImpresa[profilo].colonne[annoOut*12 + i].tasse |
                                            number: '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profilo].costiProduzione +
                                            this.sommaColonneCrisi[profilo].costiPersonale +
                                            this.sommaColonneCrisi[profilo].oneriGestione +
                                            this.sommaColonneCrisi[profilo].tasse |
                                            number: '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                              <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                  COSTI VARIABILI
                              </div>
                              <div class="col-10">

                                  <div class="row row-cols-13-custom text-center ">

                                      <div class="col alignRight padding-left-right-5"
                                          *ngFor="let item of [].constructor(12); let i = index">
                                          {{
                                          (this.crisiImpresa[profilo].colonne[annoOut*12 + i].costiProduzione |
                                          number: '1.0-0':'it')}}
                                      </div>
                                      <div class="col   alignRight border-horizontal padding-left-right-5">
                                          {{ (this.sommaColonneCrisi[profilo].costiProduzione |
                                          number: '1.0-0':'it')}}
                                      </div>
                                  </div>
                              </div>
                          </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI PERSONALE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">

                                        <div class="col alignRight padding-left-right-5 "
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profilo].colonne[annoOut*12 + i].costiPersonale |
                                            number: '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profilo].costiPersonale |
                                            number: '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    ONERI GESTIONE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">

                                        <div class="col alignRight padding-left-right-5 "
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profilo].colonne[annoOut*12 + i].oneriGestione |
                                            number: '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profilo].oneriGestione |
                                            number: '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    IMPOSTE ORDINARIE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">

                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profilo].colonne[annoOut*12 + i].tasse | number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profilo].tasse |
                                            number: '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center  border-horizontal font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    CASSA OPERATIVA
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">

                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profilo].colonne[annoOut*12 + i].cassaOperativa | number: '1.0-0':'it')}}
                                        </div>
                                        <div class="col alignRight border-horizontal padding-left-right-5">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row riga text-center font-bold margin-top-20" *ngIf="boolCredDeb">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    INCASSI NON CORRENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profiloBP].colonne[i].incasClientStraord
                                            | number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].incasClientStraord |
                                            number: '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row riga text-center font-bold " *ngIf="boolCredDeb">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    COSTI NON CORRENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profiloBP].colonne[i].ripagDebForn +
                                                this.crisiImpresa[profiloBP].colonne[i].cfDebFinanz +
                                                this.crisiImpresa[profiloBP].colonne[i].oneriFinanz +
                                            this.crisiImpresa[profiloBP].colonne[i].ripagDebTribut
                                            + this.crisiImpresa[profiloBP].colonne[i].ripagDebInps +
                                            this.crisiImpresa[profiloBP].colonne[i].ripagDebAltri
                                            | number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebForn +
                                                this.sommaColonneCrisi[profiloBP].cfDebFinanz +
                                                this.sommaColonneCrisi[profiloBP].oneriFinanz +
                                            this.sommaColonneCrisi[profiloBP].ripagDebTribut
                                            + this.sommaColonneCrisi[profiloBP].ripagDebInps +
                                            this.sommaColonneCrisi[profiloBP].ripagDebAltri
                                            | number:
                                            '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;" *ngIf="boolCredDeb">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI FORNITORI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profiloBP].colonne[i].ripagDebForn |
                                            number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebForn | number:
                                            '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;" *ngIf="boolCredDeb">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI BANCHE
                                </div>
                                <div class="col-10">
        
                                    <div class="row row-cols-13-custom text-center ">
        
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profiloBP].colonne[i].cfDebFinanz |
                                            number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].cfDebFinanz |
                                            number: '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;" *ngIf="boolCredDeb">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    ONERI FINANZIARI
                                </div>
                                <div class="col-10">
        
                                    <div class="row row-cols-13-custom text-center ">
        
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profiloBP].colonne[i].oneriFinanz |
                                            number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].oneriFinanz | number: '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;" *ngIf="boolCredDeb">

                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI ERARIO
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profiloBP].colonne[i].ripagDebTribut
                                            | number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebTribut | number:
                                            '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;" *ngIf="boolCredDeb">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI INPS
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profiloBP].colonne[i].ripagDebInps |
                                            number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebInps | number:
                                            '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;" *ngIf="boolCredDeb">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    ALTRI COSTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profiloBP].colonne[i].ripagDebAltri |
                                            number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebAltri | number:
                                            '1.0-0':'it')}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="row riga text-center font-bold" *ngIf="boolCredDeb">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    CASSA FINALE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">
                                        <div class="col alignRight padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.crisiImpresa[profilo].colonne[annoOut*12 + i].cassaFin |
                                            number:
                                            '1.0-0':'it')}}
                                        </div>
                                        <div class="col   alignRight border-horizontal padding-left-right-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid" style="background-color: #FFFFFF">
                        <div class="card-header">
                          <h2 class="intestazioneFin" style="margin-bottom:0;">Situazione finanziaria</h2>
                    
                        </div>
                        <!-- selectedIndex proprietà delle mat tab -->
                        <div><!-- class="card-body" -->
                    
                              <mat-tab-group mat-stretch-tabs (selectedIndex)="0" class="d-flex justify-content-around">
                                <mat-tab label="Situazione creditizia">
                                  <app-situazione-creditizia-smart [creditiformGroup]="creditiForm" [bilancioAbbreviato]="bilancioAbbreviato"
                                    [idRichiedenteTemp]="aziendaRichiedente.id" [crisiImpresa]="true">
                                  </app-situazione-creditizia-smart>
                                </mat-tab>
                                <mat-tab label="Situazione debitoria">
                                  <app-situazione-debitoria-smart [debitiformGroup]="debitiForm" [bilancioAbbreviato]="bilancioAbbreviato"
                                    [idRichiedenteTemp]="aziendaRichiedente.id" [crisiImpresa]="true">
                                  </app-situazione-debitoria-smart>
                                </mat-tab>
                              </mat-tab-group>
                      
                    
                        </div>
                      </div>
                  </div>
                
</div>
<div class="footer">

</div>