<div class="container-fluid background margin-top-10">
    <h2 class="text-center  margin-bottom-10" style="font-family: var(--UI-font);margin: 0;">Monitoring andamentale</h2>
    <div class="row margin-left-right-0 nopadding margin-top-10">
        <div class="col-2">Variabile</div>
        <div class="col-2">Valore consuntivo</div>
        <div class="col-2">Valore atteso minimo</div>
        <div class="col-2">Valore atteso medio</div>
        <div class="col-2">Valore atteso massimo</div>
        <div class="col-2">Range percentili</div>
        
        
    </div>
    <div class="row margin-left-right-0 nopadding margin-top-10" *ngFor="let variabile of listaBacktesting">
        <div class="col-2">{{variabile.descrizione}}</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'euro'">{{variabile.valConsuntivo| number:'1.0-0':'it' }} €</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'euro'">{{variabile.minimo| number:'1.0-0':'it' }} €</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'euro'">{{variabile.mediana| number:'1.0-0':'it' }} €</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'euro'">{{variabile.massimo| number:'1.0-0':'it' }} €</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'indice'">{{variabile.valConsuntivo | number:'1.0-1':'it' }}</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'indice'">{{variabile.minimo | number:'1.0-1':'it' }}</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'indice'">{{variabile.mediana | number:'1.0-1':'it' }}</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'indice'">{{variabile.massimo | number:'1.0-1':'it' }}</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'perc'">{{variabile.valConsuntivo * 100. | number:'1.0-1':'it' }} %</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'perc'">{{variabile.minimo * 100. | number:'1.0-1':'it' }} %</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'perc'">{{variabile.mediana * 100. | number:'1.0-1':'it' }} %</div>
        <div class="col-2" *ngIf="variabile.tipoVar == 'perc'">{{variabile.massimo * 100. | number:'1.0-1':'it' }} %</div>
        <div class="col-2">{{variabile.percentileInferiore <= 0 ? '<' + (variabile.percentileSuperiore/10 | number:'1.0-1':'it') + '°' : variabile.percentileSuperiore >= 1000 ? '>' + (variabile.percentileInferiore/10 | number:'1.0-1':'it') + '°' : (variabile.percentileInferiore/10 | number:'1.0-1':'it') + '°-' + (variabile.percentileSuperiore/10 | number:'1.0-1':'it') + '°'}}</div>        
        
    </div>
    </div>
