import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BacktestingService } from 'src/app/_services/backtesting.service';
import { DataBilancioBean } from 'src/app/condivisi/data-bilancio-bean';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';

@Component({
  selector: 'app-confronto-backtesting',
  templateUrl: './confronto-backtesting.component.html',
  styleUrls: ['./confronto-backtesting.component.css']
})
export class ConfrontoBacktestingComponent implements OnInit {

  bpSubscription: Subscription = null;
  bilancioSubscription: Subscription = null;
  businessPlan: PraticheNuova = null;
  dataBilancio: DataBilancioBean = null;
  listaBacktesting: any[] = null;
  constructor(private backtestingService: BacktestingService,
              private router: Router) { }

  ngOnInit(): void {
    this.bpSubscription = this.backtestingService.currentBusinessPlan.subscribe(bp => {
      if(bp != null){
        this.businessPlan = bp;

    }else{
      this.router.navigate(["/listaBplanNuova"])
    }
    });
    this.bilancioSubscription = this.backtestingService.currentBilancioBacktest.subscribe(bil => {
if(bil != null){
this.dataBilancio = bil;
}else{
  this.router.navigate(["/listaBplanNuova"])

}
    })
    if(this.businessPlan != null && this.dataBilancio != null){
      this.getConfronto();
    }
  }

  getConfronto(){
    this.backtestingService.getConfronto(this.businessPlan.id,this.businessPlan.idAziendaRichiedente,this.dataBilancio.anno,this.dataBilancio.mese).subscribe(resp => {
this.listaBacktesting = resp;
    })
  }

}
