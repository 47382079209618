<div class="container-fluid">
    <div class="row margin-top-10" id="save-Investimenti">
        <div class="col-9">Inseririsci gli investimenti e disinvestimenti</div>
        <div class="col-3 d-flex justify-content-end align-items-center">
            <!--<button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class=" btn btn-success" type="submit" (click)="onSubmit()">Salva</button>-->
            <button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-secondary" type="button" style="margin-left: 20px" (click)="Annulla()">
                Annulla modifiche
            </button>
        </div>
    </div>
    <hr>
    <div>
        <!-- id="container-situazione-investimenti-body" -->

        <div [formGroup]="investimentiForm">
            <div class="row">
                <!--  id="addPaddingInSticky" -->
                <h2 class="align-middle text-left margin-0"> Investimenti&nbsp;<i style="margin-bottom:0px" id="icon"
                        class="fa fa-1x fa-info-circle margin-right-10" aria-hidden=" true" placement="top"
                        container="body" ngbTooltip={{listaTips[22]?.descrizione}}></i>

                    <fa-icon class="pointer margin-left-20" style="font-size: 25px" [icon]="faPlus"
                        *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                        (click)="aggiungiInvestimento()">
                    </fa-icon>
                </h2>
            </div>

            <div formArrayName="investimenti">
                <ul class="list-group list-unstyled  margin-top-10"
                    *ngFor="let investimento of investimentiFormArray;let i = index" [formGroupName]="i">
                    <li>
                        <hr class="hrBold">
                        <div class="row">
                            <div class="col-11 nopadding  margin-top-10">
                                <div class="row">
                                    <div class="col-4 align-items-center nopadding">
                                        <div class="row d-flex justify-content-between" style="margin:0; padding:0">
                                            <div [ngClass]="{'col-lg-5':investimento.controls.tipoInvestimento.value!= null && investimento.controls.tipoInvestimento.value.id==8,
                                        'col-lg-12':investimento.controls.tipoInvestimento.value!= null && investimento.controls.tipoInvestimento.value.id!=8}"
                                                style="margin:0; padding:0">
                                                <label>Tipologia investimento</label>
                                                <select type="text" formControlName="tipoInvestimento"
                                                    class="form-control form-select" (change)="onChangeInvestimento(i)"
                                                    [ngClass]="{ 'is-invalid':submitted && investimento.controls.tipoInvestimento.errors }">
                                                    <option [ngValue]="elem" *ngFor="let elem of tipiInvestimento">
                                                        {{elem.descrizione}}
                                                    </option>
                                                </select>
                                                <div *ngIf=" submitted && investimento.controls.tipoInvestimento.errors"
                                                    class="invalid-feedback">
                                                    <div class="col-12  nopadding"
                                                        *ngIf="investimento.controls.tipoInvestimento.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 align-items-center nopadding"
                                                *ngIf="investimento.controls.tipoInvestimento.value && investimento.controls.tipoInvestimento.value.id==8">
                                                <label>Descrizione</label>
                                                <input type="text" class="form-control"
                                                    formControlName="descrizioneInvestimento"
                                                    [ngClass]="{ 'is-invalid':submitted && investimento.controls.descrizioneInvestimento.errors }" />
                                                <div *ngIf=" submitted && investimento.controls.descrizioneInvestimento.errors"
                                                    class="invalid-feedback">
                                                    <div class="col-12  nopadding"
                                                        *ngIf="investimento.controls.descrizioneInvestimento.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Importo (€)</label>
                                        <input type="text" class="form-control" formControlName="importoInvestimento"
                                            (keyup)="onchangeImportoInvestimento(i)" currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.importoInvestimento.errors || 
                                        submitted && arrayErroriInv[i] ||submitted && arrayErroriLiquidita[i]  }" />
                                        <div *ngIf=" submitted && investimento.controls.importoInvestimento.errors "
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="investimento.controls.importoInvestimento.errors.required">Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && arrayErroriLiquidita[i]" class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0" *ngIf=" arrayErroriLiquidita[i]">
                                                L'importo della liquidità di cassa inserita supera il valore a bilancio
                                                ({{liquiditaCassaBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 align-items-center justify-content-start nopadding">
                                        <div class="row" style="margin:0; padding:0">
                                            <div class="col-7 col-xl-6" style="margin:0; padding:0">
                                                <label>Anno investimento&nbsp;&nbsp;<i style="margin-top:-10px"
                                                        id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                                                        placement="top" container="body"
                                                        [ngbTooltip]="listaTips[4]?.descrizione"></i>
                                                </label>
                                                <input type="number" class="form-control"
                                                    formControlName="annoInvestimento" min="0"
                                                    [ngClass]="{ 'is-invalid':submitted && investimento.controls.annoInvestimento.errors }"
                                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    (change)="onChangeAnnoInvestimento(i)" />
                                                <div *ngIf=" submitted && investimento.controls.annoInvestimento.errors"
                                                    class="invalid-feedback">
                                                    <div class="col-12  nopadding"
                                                        *ngIf="investimento.controls.annoInvestimento.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-5 col-xl-6" style="margin:0; padding:0">
                                                <label>Trimestre&nbsp;&nbsp;<i style="margin-top:-10px" id="icon"
                                                        class="fa fa-1x fa-info-circle" aria-hidden="true"
                                                        placement="top" container="body"
                                                        [ngbTooltip]="listaTips[3]?.descrizione"></i>
                                                </label>
                                                <select type="text" formControlName="trimestreInvestimento"
                                                    class="form-control form-select"
                                                    [ngClass]="{ 'is-invalid':submitted && investimento.controls.trimestreInvestimento.errors }">
                                                    <option [ngValue]="trimestre"
                                                        *ngFor="let trimestre of trimestreList;let j=index">
                                                        {{trimestre.descrizione}}
                                                    </option>
                                                </select>
                                                <div *ngIf=" submitted && investimento.controls.trimestreInvestimento.errors"
                                                    class="invalid-feedback">
                                                    <div class="col-12  nopadding"
                                                        *ngIf="investimento.controls.trimestreInvestimento.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 align-items-center nopadding">
                                        <label>Modalità finanziamento
                                        </label>
                                        <select type="text" formControlName="modalitaFinanziamento"
                                            class="form-control form-select" (change)="onChangeModalitaFinanziamento(i)"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.modalitaFinanziamento.errors }">
                                            <option [ngValue]="modalita"
                                                *ngFor="let modalita of listaModalitaFinanziamento"
                                                [disabled]="investimento.controls.modalitaFinanziamento.disabled ?false: checkCurrentAnno(i,modalita.presenzaCassa)"
                                                >       
                                                {{modalita.descrizione}}
                                            </option>
                                        </select>
                                        <div *ngIf=" submitted && investimento.controls.modalitaFinanziamento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12 "
                                                *ngIf="investimento.controls.modalitaFinanziamento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr *ngIf="investimento.controls.modalitaFinanziamento.value!=null && (investimento.controls.modalitaFinanziamento.value.id!=1 &&
                            investimento.controls.modalitaFinanziamento.value.id!=2 && investimento.controls.modalitaFinanziamento.value.id!=3 &&
                            investimento.controls.modalitaFinanziamento.value.id!=4)">
                                <div class="row" *ngIf=" submitted && arrayErroriInv[i] && (investimento.controls.modalitaFinanziamento.value!=null && investimento.controls.modalitaFinanziamento.value.id!=1 &&
                            investimento.controls.modalitaFinanziamento.value.id!=2 && investimento.controls.modalitaFinanziamento.value.id!=3 &&
                            investimento.controls.modalitaFinanziamento.value.id!=4)">
                                    <div class="col-8 erroreImporto">
                                        La somma degli importi delle modalità di finanziamento non corrisponde
                                        all'importo
                                        totale
                                        dell'investimento.
                                    </div>
                                </div>
                                <!-- finanziamento banca -->
                                <div class="row margin-top-10" *ngIf="investimento.controls.modalitaFinanziamento.value!=null && (investimento.controls.modalitaFinanziamento.value.id==1 ||
                            investimento.controls.modalitaFinanziamento.value.id==5 ||investimento.controls.modalitaFinanziamento.value.id==6 ||
                            investimento.controls.modalitaFinanziamento.value.id==10 ||investimento.controls.modalitaFinanziamento.value.id==12 || 
                            investimento.controls.modalitaFinanziamento.value.id==13 ||investimento.controls.modalitaFinanziamento.value.id==14 ||
                            investimento.controls.modalitaFinanziamento.value.id==15)">
                                    <div class="col-2 d-flex align-items-center nopadding">
                                        <h3 class="align-middle">{{listaModalitaFinanziamento[0].descrizione}}</h3>
                                    </div>

                                    <div *ngIf="investimento.controls.modalitaFinanziamento.value.id!=1; else onlyCol2"
                                        class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Importo (€)</label>
                                        <input type="text" class="form-control" formControlName="importoFinanziamento"
                                            currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.importoFinanziamento.errors || submitted && arrayErroriInv[i] }" />
                                        <div *ngIf=" submitted && investimento.controls.importoFinanziamento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="investimento.controls.importoFinanziamento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #onlyCol2>
                                        <div class="col-2 nopadding"></div>
                                    </ng-template>
                                    <div
                                        class="col-1 align-items-center justify-content-start nopadding margin-right-10">
                                        <label class="text-center">Mesi rimborso</label>
                                        <input type="number" class="form-control" style="width:100%"
                                            formControlName="mesiRimborsoFinanziamento"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.mesiRimborsoFinanziamento.errors}" />
                                        <div *ngIf=" submitted && investimento.controls.mesiRimborsoFinanziamento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="investimento.controls.mesiRimborsoFinanziamento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Preammortamento</label>
                                        <input type="number" class="form-control" style="width:100%"
                                            formControlName="preammortamentoFinanziamento"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.preammortamentoFinanziamento.errors}" />
                                        <div *ngIf=" submitted && investimento.controls.preammortamentoFinanziamento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="investimento.controls.preammortamentoFinanziamento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 align-items-center nopadding">
                                        <label>Garanzie
                                        </label>
                                        <select type="text" formControlName="descrizioneGaranziaFinanziamento"
                                            class="form-control form-select"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.descrizioneGaranziaFinanziamento.errors }">
                                            <option [ngValue]="garanzia" *ngFor="let garanzia of listaGaranzie">
                                                {{garanzia.descrizione}}
                                            </option>
                                        </select>
                                        <div *ngIf=" submitted && investimento.controls.descrizioneGaranziaFinanziamento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12 "
                                                *ngIf="investimento.controls.descrizioneGaranziaFinanziamento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Importo garanzie(€)</label>
                                        <input type="text" class="form-control"
                                            formControlName="importoGaranziaFinanziamento" currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.importoGaranziaFinanziamento.errors}" />
                                        <div *ngIf=" submitted && investimento.controls.importoGaranziaFinanziamento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="investimento.controls.importoGaranziaFinanziamento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- liquidita -->
                                <div class="row margin-top-10" *ngIf="investimento.controls.modalitaFinanziamento.value!=null && (
                            investimento.controls.modalitaFinanziamento.value.id==5 ||investimento.controls.modalitaFinanziamento.value.id==7 ||
                            investimento.controls.modalitaFinanziamento.value.id==8 ||investimento.controls.modalitaFinanziamento.value.id==10 ||
                            investimento.controls.modalitaFinanziamento.value.id==11|| investimento.controls.modalitaFinanziamento.value.id==13 ||
                            investimento.controls.modalitaFinanziamento.value.id==15)">
                                    <div class="col-2 d-flex align-items-center nopadding">
                                        <h3 class="align-middle">{{listaModalitaFinanziamento[1].descrizione}}</h3>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Importo (€)</label>
                                        <input type="text" class="form-control" formControlName="importoLiquiditaCassa"
                                            currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (investimento.controls.importoLiquiditaCassa.errors || arrayErroriInv[i] || arrayErroriLiquidita[i])}" />
                                        <div *ngIf=" submitted && arrayErroriLiquidita[i]" class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0" *ngIf=" arrayErroriLiquidita[i]">
                                                La liquidità di cassa inserita supera il valore a bilancio
                                                ({{liquiditaCassaBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && investimento.controls.importoLiquiditaCassa.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="investimento.controls.importoLiquiditaCassa.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- mezzi prpri -->
                                <div class="row margin-top-10" *ngIf="investimento.controls.modalitaFinanziamento.value!=null && (
                            investimento.controls.modalitaFinanziamento.value.id==6 ||investimento.controls.modalitaFinanziamento.value.id==7 ||
                            investimento.controls.modalitaFinanziamento.value.id==9 ||investimento.controls.modalitaFinanziamento.value.id==10 ||
                            investimento.controls.modalitaFinanziamento.value.id==11 || investimento.controls.modalitaFinanziamento.value.id==14 ||
                            investimento.controls.modalitaFinanziamento.value.id==15)">
                                    <div class="col-2 d-flex align-items-center nopadding">
                                        <h3 class="align-middle">{{listaModalitaFinanziamento[2].descrizione}}</h3>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Importo (€)</label>
                                        <input type="text" class="form-control" formControlName="importoMezziPropri"
                                            currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.importoMezziPropri.errors || submitted && arrayErroriInv[i] }" />
                                        <div *ngIf=" submitted && investimento.controls.importoMezziPropri.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="investimento.controls.importoMezziPropri.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--nuovo finanziatore -->
                                <div class="row margin-top-10" *ngIf="investimento.controls.modalitaFinanziamento.value!=null && (
                                investimento.controls.modalitaFinanziamento.value.id==8 || investimento.controls.modalitaFinanziamento.value.id==9 ||
                                investimento.controls.modalitaFinanziamento.value.id==11 || investimento.controls.modalitaFinanziamento.value.id==12 || 
                            investimento.controls.modalitaFinanziamento.value.id==13 || investimento.controls.modalitaFinanziamento.value.id==14 ||
                            investimento.controls.modalitaFinanziamento.value.id==15)">
                                    <div class="col-2 d-flex align-items-center nopadding">
                                        <h3 class="align-middle">{{listaModalitaFinanziamento[3].descrizione}}</h3>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Importo (€)</label>
                                        <input type="text" class="form-control"
                                            formControlName="importoNuovoInvestitore" currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && investimento.controls.importoNuovoInvestitore.errors || submitted && arrayErroriInv[i] }" />
                                        <div *ngIf=" submitted && investimento.controls.importoNuovoInvestitore.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="investimento.controls.importoNuovoInvestitore.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-1 nopadding d-flex justify-content-center  margin-top-10">
                                <fa-icon class="pointer margin-top-20" *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)" style="font-size: 25px" [icon]="faTrashAlt"
                                    (click)="eliminaInvestimento(i)"></fa-icon>
                            </div>
                        </div>
                        <div class="row margin-top-10  margin-bottom-10" *ngIf="investimento.controls.tipoInvestimento.value.id == 8 || investimento.controls.tipoInvestimento.value.id == 5 || investimento.controls.tipoInvestimento.value.id == 6">
                            <h3 class="align-left nopadding">Benefici investimento</h3>
                            <div  class="col-4 align-items-center justify-content-start nopadding">                           
                        <label class="text-center">Linee di business associate all'investimento</label>
                                <ng-select
                                [items]="listaLdb"
                                [multiple]="true"
                                [selectableGroupAsModel]="false"
                                bindLabel="descrizione"
                                [closeOnSelect]="false"
                                [appendTo]="'body'"
                                bindValue="id"
                                formControlName="ldbAssociate"
                                (change)="onChangeLdb(i)"> 
                                  
                                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                              <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected"/> {{item.descrizione}}
                                          </ng-template>
                                         
                              </ng-select>
                               
                            </div>
<div class="col-1"></div>
                            <div class="col-2 align-items-center justify-content-start nopadding" *ngIf="investimento.controls.ldbAssociate.value.length > 0">
                                <label>Anno inizio beneficio&nbsp;&nbsp;<i style="margin-top:-10px"
                                    id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                                    placement="top" container="body"
                                    [ngbTooltip]="listaTips[4]?.descrizione"></i>
                            </label>
                            <input type="number" class="form-control"
                                formControlName="annoBeneficio" min="0"
                                [ngClass]="{ 'is-invalid':submitted && investimento.controls.annoBeneficio.errors }"
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (change)="onChangeAnnoBeneficio(i)" />
                                <div *ngIf=" submitted && investimento.controls.annoBeneficio.errors"
                                    class="invalid-feedback">
                                    <div class="col-12  nopadding"
                                        *ngIf="investimento.controls.annoBeneficio.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </div>

                            <div class="col-2 align-items-center justify-content-start nopadding" *ngIf="investimento.controls.ldbAssociate.value.length > 0">
                                <label>Trimestre inizio beneficio&nbsp;&nbsp;<i style="margin-top:-10px" id="icon"
                                    class="fa fa-1x fa-info-circle" aria-hidden="true"
                                    placement="top" container="body"
                                    [ngbTooltip]="listaTips[3]?.descrizione"></i>
                            </label>
                            <select type="text" formControlName="trimestreBeneficio"
                                class="form-control form-select"
                                [ngClass]="{ 'is-invalid':submitted && investimento.controls.trimestreBeneficio.errors }">
                                <option [ngValue]="trimestre"
                                    *ngFor="let trimestre of trimestreList;let j=index">
                                    {{trimestre.descrizione}}
                                </option>
                            </select>
                            <div *ngIf=" submitted && investimento.controls.trimestreBeneficio.errors"
                                    class="invalid-feedback">
                                    <div class="col-12  nopadding"
                                        *ngIf="investimento.controls.trimestreBeneficio.errors.required">
                                        Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </div>
                            <div formArrayName="effettiLdb">

                            <div class="row margin-top-10" *ngFor="let linea of investimento.controls.effettiLdb.controls;let j = index" [formGroupName]="j">
                                <h3 class="align-left nopadding">{{getNomeLdb(linea.controls.idLdb.value)}}</h3>
                                
                                <div class="col-2 align-items-center justify-content-start nopadding">
                                    <label class="text-center">Variazione ricavi (%)</label>
                                    <input type="number" class="form-control"
                                        formControlName="varRicavi" 
                                        [ngClass]="{ 'is-invalid':submitted && linea.controls.varRicavi.errors }" />
                                    <div *ngIf=" submitted && linea.controls.varRicavi.errors"
                                        class="invalid-feedback">
                                        <div class="col-12  nopadding"
                                            *ngIf="linea.controls.varRicavi.errors.required">
                                            Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 align-items-center justify-content-start nopadding">
                                    <label class="text-center">Variazione costi materie (%)</label>
                                    <input type="number" class="form-control"
                                        formControlName="varCostiMaterie" 
                                        [ngClass]="{ 'is-invalid':submitted && linea.controls.varCostiMaterie.errors }" />
                                    <div *ngIf=" submitted && linea.controls.varCostiMaterie.errors"
                                        class="invalid-feedback">
                                        <div class="col-12  nopadding"
                                            *ngIf="linea.controls.varCostiMaterie.errors.required">
                                            Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 align-items-center justify-content-start nopadding">
                                    <label class="text-center">Variazione costi servizi (%)</label>
                                    <input type="number" class="form-control"
                                        formControlName="varCostiServizi" 
                                        [ngClass]="{ 'is-invalid':submitted && linea.controls.varCostiServizi.errors }" />
                                    <div *ngIf=" submitted && linea.controls.varCostiServizi.errors"
                                        class="invalid-feedback">
                                        <div class="col-12  nopadding"
                                            *ngIf="linea.controls.varCostiServizi.errors.required">
                                            Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 align-items-center justify-content-start nopadding">
                                    <label class="text-center">Variazione costi fissi (%)</label>
                                    <input type="number" class="form-control"
                                        formControlName="varCostiFissi" 
                                        [ngClass]="{ 'is-invalid':submitted && linea.controls.varCostiFissi.errors }" />
                                    <div *ngIf=" submitted && linea.controls.varCostiFissi.errors"
                                        class="invalid-feedback">
                                        <div class="col-12  nopadding"
                                            *ngIf="linea.controls.varCostiFissi.errors.required">
                                            Campo
                                            obbligatorio
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            
                        </div>
                    </li>

                </ul>
            </div>

            <hr class="margin-top-20" style="height: 2px;border-top: 2px solid black;" *ngIf="bilancioPresente">
            <ng-container *ngIf="bilancioPresente">
                <div class="row">
                    <h2 class="align-middle text-left margin-0"> Disinvestimenti&nbsp;&nbsp;<i style="margin-bottom:0px"
                            id="icon" class="fa fa-1x fa-info-circle margin-right-10" aria-hidden=" true"
                            placement="top" container="body" ngbTooltip="{{listaTips[24]?.descrizione}}"></i>
                        <fa-icon class="pointer margin-left-20" style="font-size: 25px" [icon]="faPlus"
                            *ngIf="businessPlanTemp.idStato == 1 || businessPlanTemp.idStato == 2"
                            (click)="aggiungiDisinvestimento()">
                        </fa-icon>
                    </h2>
                </div>
                <div formArrayName="disinvestimenti">
                    <div *ngFor="let disinvestimento of disinvestimentiFormArray;let i = index" [formGroupName]="i"
                        class=" margin-top-10">
                        <hr class="hrBold">
                        <div class="row">
                            <div class="col-11 nopadding  margin-top-10">
                                <div class="row ">
                                    <div class="col-4 align-items-center nopadding">
                                        <div class="row d-flex justify-content-between" style="margin:0; padding:0">
                                            <div [ngClass]="{'col-5':disinvestimento.controls.tipoDisinvestimento.value!= null && disinvestimento.controls.tipoDisinvestimento.value.id==6,
                                        'col-12':disinvestimento.controls.tipoDisinvestimento.value!= null && disinvestimento.controls.tipoDisinvestimento.value.id!=6}"
                                                style="margin:0; padding:0">
                                                <label>Tipologia disinvestimento&nbsp;&nbsp;</label>
                                                <select type="text" formControlName="tipoDisinvestimento"
                                                    class="form-control form-select"
                                                    (change)="onChangeDisinvestimento(i)"
                                                    [ngClass]="{ 'is-invalid':submitted && disinvestimento.controls.tipoDisinvestimento.errors }">
                                                    <option [ngValue]="elem" *ngFor="let elem of tipiDisinvestimento"
                                                        [disabled]="elem.id==4 && beneImmobilBilancio==0">
                                                        {{elem.descrizione}}
                                                    </option>
                                                </select>
                                                <div *ngIf=" submitted && disinvestimento.controls.tipoDisinvestimento.errors"
                                                    class="invalid-feedback">
                                                    <div class="col-12  nopadding"
                                                        *ngIf="disinvestimento.controls.tipoDisinvestimento.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-7 align-items-center nopadding"
                                                *ngIf="disinvestimento.controls.tipoDisinvestimento.value && disinvestimento.controls.tipoDisinvestimento.value.id==6">
                                                <label>Descrizione</label>
                                                <input type="text" class="form-control"
                                                    formControlName="descrizioneDisinvestimento"
                                                    [ngClass]="{ 'is-invalid':submitted && disinvestimento.controls.descrizioneDisinvestimento.errors }" />
                                                <div *ngIf=" submitted && disinvestimento.controls.descrizioneDisinvestimento.errors"
                                                    class="invalid-feedback">
                                                    <div class="col-12  nopadding"
                                                        *ngIf="disinvestimento.controls.descrizioneDisinvestimento.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding" *ngIf="!(disinvestimento.controls.tipoDisinvestimento.value!=null && disinvestimento.controls.tipoDisinvestimento.value.id==5)">
                                        <label class="text-center">Importo contabile (€)</label>
                                        <input type="text" class="form-control" formControlName="importoDisinvestimento"
                                            (keyup)="onchangeImportoDisinvestimento(i)" currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoDisinvestimento.errors ||  arrayErroriImportoDis[i]) }" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoDisinvestimento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.importoDisinvestimento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && disinvestimento.controls.tipoDisinvestimento.value.id==4 && arrayErroriImportoDis[i]"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.tipoDisinvestimento.value.id==4 && arrayErroriImportoDis[i]">
                                                L'importo dei beni immobili superano il valore a bilancio
                                                ({{beneImmobilBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding" *ngIf="disinvestimento.controls.tipoDisinvestimento.value!=null && disinvestimento.controls.tipoDisinvestimento.value.id==4">
                                        <label class="text-center">Importo monetario (€)</label>
                                        <input type="text" class="form-control" formControlName="importoCassaDisinvestimento"
                                            currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoCassaDisinvestimento.errors) }" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoDisinvestimento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.importoDisinvestimento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 align-items-center justify-content-start nopadding">
                                        <div class="row" style="margin:0; padding:0">
                                            <div class="col-7 col-xl-6" style="margin:0; padding:0">
                                                <label>Anno disinvestimento&nbsp;&nbsp;<i style="margin-top:-10px"
                                                        id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                                                        placement="top" container="body"
                                                        [ngbTooltip]="listaTips[3]?.descrizione"></i>
                                                </label>
                                                <input type="number" class="form-control"
                                                    formControlName="annoDisinvestimento" min="0"
                                                    [ngClass]="{ 'is-invalid':submitted && disinvestimento.controls.annoDisinvestimento.errors }"
                                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    (change)="onChangeAnnoDisinvestimento(i)" />
                                                <div *ngIf=" submitted && disinvestimento.controls.annoDisinvestimento.errors"
                                                    class="invalid-feedback">
                                                    <div class="col-12  nopadding"
                                                        *ngIf="disinvestimento.controls.annoDisinvestimento.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-5 col-xl-6" style="margin:0; padding:0">
                                                <label>Mese&nbsp;&nbsp;<i style="margin-top:-10px" id="icon"
                                                        class="fa fa-1x fa-info-circle" aria-hidden="true"
                                                        placement="top" container="body"
                                                        [ngbTooltip]="listaTips[3]?.descrizione"></i>
                                                </label>
                                                <select class="form-select form-control" formControlName="meseDisinvestimento"
                                                [ngClass]="{ 'is-invalid':submitted && disinvestimento.controls.meseDisinvestimento.errors}">
                                                <option [ngValue]="k" *ngFor="let mese of listaMesi;let k=index">{{mese |date:'MMMM' : 'it'}}
                                                </option>
                                              </select>
                                                <div *ngIf=" submitted && disinvestimento.controls.meseDisinvestimento.errors"
                                                    class="invalid-feedback">
                                                    <div class="col-12  nopadding"
                                                        *ngIf="disinvestimento.controls.meseDisinvestimento.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row margin-top-10"
                                    *ngIf=" submitted && arrayErroriImportoDis[i] && disinvestimento.controls.tipoDisinvestimento.value.id==5">
                                    <!--disinvestimento.controls.tipoDisinvestimento.value.id!=  &&  id della vendita ramo d'azienda -->
                                    <div class="col-8 erroreImporto">
                                        La somma degli importi delle voci di ricavo della vendita ramo d'azienda non
                                        corrisponde all'importo
                                        totale.
                                    </div>
                                </div>
                                <div class="row margin-top-20" *ngIf="false">
                                    <div class="col-1 d-flex align-items-center justify-content-start">
                                        <label>Anni
                                            rimborso
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 nopadding d-flex justify-content-center  margin-top-10">
                                <fa-icon class="pointer margin-top-20" style="font-size: 25px"  *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"  [icon]="faTrashAlt"
                                    (click)="eliminaDisinvestimento(i)"></fa-icon>
                            </div>
                            <!-- vendita ramo -->
                            <ng-container
                                *ngIf="disinvestimento.controls.tipoDisinvestimento.value!=null && disinvestimento.controls.tipoDisinvestimento.value.id==5">
                                <!-- costi dipendenti -->
                                <div class="row margin-top-10"
                                    *ngIf="costiPersonaleBilancio!=null ||costiTfrBilancio!=null">
                                    <div class="col-2 d-flex align-items-center nopadding">
                                        <h3 class="align-middle">Costi dipendenti</h3>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding"
                                        *ngIf="costiPersonaleBilancio!=null">
                                        <label class="text-center">costi personale ceduto (€)</label>
                                        <input type="text" class="form-control"
                                            formControlName="importoCostiRetribuzioniPersonale" currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoCostiRetribuzioniPersonale.errors || arrayErroriImportoDis[i] ||arrayErroriCostiPersonale[i]) }" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoCostiRetribuzioniPersonale.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.importoCostiRetribuzioniPersonale.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && arrayErroriCostiPersonale[i]"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0"
                                                *ngIf=" arrayErroriCostiPersonale[i]">
                                                I costi del personale inseriti superano il valore a bilancio
                                                ({{costiPersonaleBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding"
                                        *ngIf="costiTfrBilancio!=null">
                                        <label class="text-center">Valore TFR ceduto (€)</label>
                                        <input type="text" class="form-control"
                                            formControlName="importoCostiTfrPersonale" currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoCostiTfrPersonale.errors || arrayErroriImportoDis[i] ||arrayErroriCostiTfr[i]) }" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoCostiTfrPersonale.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.importoCostiTfrPersonale.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && arrayErroriCostiTfr[i]" class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0" *ngIf=" arrayErroriCostiTfr[i]">
                                                L'importo associato al TFR supera il valore a bilancio
                                                ({{costiTfrBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- immobil -->
                                <div class="row margin-top-10">
                                    <div class="col-2 d-flex align-items-center nopadding"
                                        *ngIf="immobImmatBilancio!=null ||immobMatBilancio!=null">
                                        <h3 class="align-middle">Immobilizzazioni</h3>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding"
                                        *ngIf="immobMatBilancio!=null">
                                        <label class="text-center">Immob. materiali cedute (€)</label>
                                        <input type="text" class="form-control"
                                            formControlName="importoImmobilizzazioniMateriali" currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoImmobilizzazioniMateriali.errors || arrayErroriImportoDis[i] ||arrayErroriImmobilMat[i])}" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoImmobilizzazioniMateriali.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.importoImmobilizzazioniMateriali.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && arrayErroriImmobilMat[i]" class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0" *ngIf=" arrayErroriImmobilMat[i]">
                                                L'importo delle immobilizzazioni materiali superano il valore a bilancio
                                                ({{immobMatBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding"
                                        *ngIf="immobImmatBilancio!=null">
                                        <label class="text-center">Immob. immateriali cedute (€)</label>
                                        <input type="text" class="form-control"
                                            formControlName="importoImmobilizzazioniImmateriali" currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoImmobilizzazioniImmateriali.errors || arrayErroriImmobilImmat[i]|| arrayErroriImportoDis[i]) }" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoImmobilizzazioniImmateriali.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.importoImmobilizzazioniImmateriali.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && arrayErroriImmobilImmat[i]" class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0" *ngIf=" arrayErroriImmobilImmat[i]">
                                                L'importo delle immobilizzazioni immateriali superano il valore a
                                                bilancio
                                                ({{immobImmatBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- costiFornitoriBilancio!=null vuol dire bilancio normale e costi fornitori non presenti, costiFornitoriBilancio!=0 vuol dire bilancio  -->
                                <div class="row margin-top-10"
                                    *ngIf="costiFornitoriBilancio!=null ||businessPlanTemp!=null && businessPlanTemp.bilancioAbbreviato && costiFornitoriBilancio!=0">
                                    <div class="col-2 d-flex align-items-center nopadding">
                                        <h3 class="align-middle">Debito verso fornitori</h3>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Valore ceduto (€)</label>
                                        <input type="text" class="form-control" formControlName="importoDebitoFornitori"
                                            currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoDebitoFornitori.errors ||arrayErroriImportoDis[i] ||arrayErroriDebitoFornitori[i]) }" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoDebitoFornitori.errors"
                                            class="invalid-feedback">
                                            <div class="col-12 nopadding"
                                                *ngIf="disinvestimento.controls.importoDebitoFornitori.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && arrayErroriDebitoFornitori[i]"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0"
                                                *ngIf=" !businessPlanTemp.bilancioAbbreviato">
                                                I debiti verso i fornitori superano il valore a bilancio
                                                ({{costiFornitoriBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                            <div class="col-12  nopadding margin-0" style="min-width: 200px;"
                                                *ngIf=" businessPlanTemp.bilancioAbbreviato">
                                                L'importo inserito supera il valore dei debiti verso i fornitori
                                                ({{costiFornitoriBilancio | number: '1.0-0':'it'}} €)
                                                inserito nella sezione situazione finanziaria (situazione debitoria)
                                                .
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- magazzino -->
                                <div class="row margin-top-10" *ngIf="valoreMagazBilancio!=null">
                                    <div class="col-2 d-flex align-items-center nopadding">
                                        <h3 class="align-middle">Valore Magazzino</h3>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Valore ceduto (€)</label>
                                        <input type="text" class="form-control" formControlName="importoValoreMagazzino"
                                            currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoValoreMagazzino.errors ||  arrayErroriImportoDis[i] ||arrayErroriValoreMagaz[i])}" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoValoreMagazzino.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.importoValoreMagazzino.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && arrayErroriValoreMagaz[i]" class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0" *ngIf=" arrayErroriValoreMagaz[i]">
                                                L'importo delle immobilizzazioni immateriali superano il valore a
                                                bilancio
                                                ({{valoreMagazBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--ammmortamento *ngIf="AmmortamentoBilancio!=null" -->
                                <div class="row margin-top-10">
                                    <div class="col-2 d-flex align-items-center nopadding">
                                        <h3 class="align-middle">Ammortamento</h3>
                                    </div>
                                    <div class="col-2 align-items-center justify-content-start nopadding">
                                        <label class="text-center">Valore ceduto (€)</label>
                                        <input type="text" class="form-control" formControlName="importoAmmortamento"
                                            currencyMask
                                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                            [ngClass]="{ 'is-invalid':submitted && (disinvestimento.controls.importoAmmortamento.errors || arrayErroriAmmortamento[i] || arrayErroriImportoDis[i])}" />
                                        <div *ngIf=" submitted && disinvestimento.controls.importoAmmortamento.errors"
                                            class="invalid-feedback">
                                            <div class="col-12  nopadding"
                                                *ngIf="disinvestimento.controls.importoAmmortamento.errors.required">
                                                Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                        <div *ngIf=" submitted && arrayErroriAmmortamento[i]" class="invalid-feedback">
                                            <div class="col-12  nopadding margin-0" *ngIf=" arrayErroriAmmortamento[i]">
                                                L'ammortamento superano il valore a
                                                bilancio
                                                ({{ammortamentoBilancio | number: '1.0-0':'it'}} €).
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- beni immobili -->
                            <ng-container
                                *ngIf="disinvestimento.controls.tipoDisinvestimento.value!=null && disinvestimento.controls.tipoDisinvestimento.value.id==4">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
    <hr class="hrBold">
</div>