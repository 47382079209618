<div class="container-fluid background margin-top-10" [formGroup]="backtestingForm">
    <h2 class="text-center  margin-bottom-10" style="font-family: var(--UI-font);margin: 0;">Monitoring andamentale</h2>
<div class="row margin-left-right-0 nopadding margin-top-10 margin-bottom-10">
        
        
        <div class="margin-bottom-20 col-3 col-xl-3">
            <h3>Seleziona il quarter di riferimento:</h3> 
        </div>
        <div class="col-1 margin-bottom-20">
            <select formControlName="quarterBilancio" class="form-control"
                (change)="onChangeQuarter()"
                [ngClass]="{ 'is-invalid': submitted && f.quarterBilancio.errors }">
                <option *ngFor="let quarter of listaQuarter" [ngValue]="quarter"> {{quarter.descrizione}} </option>
                <div *ngIf="submitted && f.quarterBilancio.errors" class="invalid-feedback">
                    <div *ngIf="f.quarterBilancio.errors.required">Campo obbligatorio</div>
                </div>
            </select>
    </div>
</div>
<div class="row d-flex flex-column margin-left-right-0 nopadding" *ngIf="f.quarterBilancio.value">
    <div class=" col-12 col-lg-12">
        <h3 *ngIf="this.bilancioPresente">
            L'ultimo bilancio di confronto registrato negli archivi relativo al {{f.quarterBilancio.value.descrizione}} &egrave; stato 
            caricato in data:
            <strong>{{dataInserimentoBilancio}}</strong>

        </h3>
        <h3 *ngIf="!this.bilancioPresente">
            Nessun bilancio di confronto presente negli archivi

        </h3>
    </div>
</div>
<hr  *ngIf="f.quarterBilancio.value">
    <div class="row margin-left-right-0 margin-bottom-10 margin-top-10"  *ngIf="f.quarterBilancio.value">

        <div class=" col-lg-3">
            <label>Inserisci bilancio:</label>
        </div>
        <div class=" form-check col-3">
            <input class="form-check-input" formControlName="caricaBilancio" type="radio"
                name="caricaBilancio" id="caricaBilancioXbrl" checked [value]="1"
                (click)="onChangeCaricaBilancio(1)">
            <label class="form-check-label" for="caricaBilancioXbrl">
                Carica file.xbrl
            </label>
        </div>
        <div class=" form-check col-3">
            <input class="form-check-input" formControlName="caricaBilancio" type="radio"
                name="caricaBilancio" id="caricaBilancioManuale" checked [value]="2"
                (click)="onChangeCaricaBilancio(2)">
            <label class="form-check-label" for="caricaBilancioManuale">
                Inserimento manuale
            </label>
        </div>
        <div class="row margin-top-20" *ngIf="f.caricaBilancio.value==1">
            <div class="col-6 div-upload">
                <div class="d-flex justify-content-between">
                    <input type="file" class="form-control upload-file" formControlName="bilancio"
                        accept=".xbrl" (change)="getFileBilancio($event)">
                    <button class="btn btn-primary" [disabled]="buttonDisabledBilancio"
                        (click)="CaricaBilancio()">Carica</button>
                </div>
                <p id="p-error" *ngIf="errorBilancio!=null"><strong>{{errorBilancio}}</strong></p>
            </div>
            <div class="col-6  d-flex flex-column justify-content-center align-items-start">
                <div id="uploadcontainer"
                    class="file-list d-flex flex-row justify-content-around  align-items-center"
                    *ngIf="bilancioCaricato!=null">
                    <p style="margin-bottom: 0px;">{{bilancioCaricato.name}}</p>
                    <fa-icon class="pointer margin-left-20"
                        style="font-size: 25px; color: rgb(138, 43, 43);" [icon]="faTrashAlt"
                        (click)="deleteFileBilancio()">
                    </fa-icon>
                </div>
                <div class="margin-left-20" *ngIf="messaggioerroreBilancio !=''"><label
                        id="p-error">{{messaggioerroreBilancio}}</label></div>
            </div>
        </div>
        <div class="row margin-top-20" *ngIf="f.caricaBilancio.value==2">
            <div class="col-11 d-flex justify-content-end">
                <button class="btn btn-primary mr-1" type="button"
                    (click)="goToBilancio()">Inserisci
                    nuovo bilancio</button>
            </div>
        </div>

        <div class="row margin-top-20"  *ngIf="f.quarterBilancio.value">
            <div class="col-11 d-flex justify-content-end">
                <button class="btn btn-primary mr-1" type="button" [disabled]="!bilancioPresente" (click)="confronta()">Confronta</button>
            </div>
        </div>
    </div>
    </div>
