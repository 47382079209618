import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { LineaBusiness } from 'src/app/condivisi/linea-business';
import { ToolTip } from 'src/app/interface/toolTip';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Richiedente } from './../../interface/richiedente';
import { regione } from 'src/app/interfaces/user-key';
import { BeanStaticoEsteso } from 'src/app/condivisi/beanStaticoEsteso';

@Component({
  selector: 'app-linee-business',
  templateUrl: './linee-business.component.html',
  styleUrls: ['./linee-business.component.css']
})
export class LineeBusinessComponent implements OnInit, OnDestroy {



  @Input() submitted: boolean;
  listaAtecoSezione: any[];
  listaAtecoDivisione: any[];
  tempIdDivisione: number;
  listaAtecoClasse: any[];
  tempIdClasse: number;
  listaAtecoSottocategoria: any[];
  tempIdSottocategoria: number;
  annoInizialeSubscription: Subscription;
  /* prendo in input  idRichiedenteTemp*/
  @Input() set richiedenteTemp(richiedenteTemp: Richiedente) {
    this.richiedenteTemp1 = richiedenteTemp
    this.saved = false;
    this.lineeBusinessForm.markAsPristine();
    if (richiedenteTemp != undefined) {
      this.idRichiedenteTemp = richiedenteTemp.id;
      this.lineeBusinessArray.clear();
      this.idLineeBusinessTempArray = [];
      this.sommaCentoVenduto = [];
      this.sommaCentoMagaz = [];
      this.costiFissiLinee =[];
      this.loadListaCodiciAteco();
      this.getCostiFissi();
      this.submitted = false;
      this.addLineaDisabled = false;
      this.disabledSalva = false;
      this.agricoltura = richiedenteTemp.codiceAtecoPrimario.slice(0, 2) == '01' ||
        (richiedenteTemp.codiceAtecoSecondario1 != null && richiedenteTemp.codiceAtecoSecondario1 == '01') ||
        (richiedenteTemp.codiceAtecoSecondario2 != null && richiedenteTemp.codiceAtecoSecondario2 == '01');

      /* console.log(this.idLineeBusinessTempArray)
      console.log(this.lineeBusinessArray) */
    } else {
      this.disabledSalva = true;
    }
  }
  idRichiedenteTemp: number
  richiedenteTemp1: Richiedente;
  agricoltura: boolean = false;
  disabledSalva: boolean = true;
  addLineaDisabled: boolean = false;
  saved: boolean = false;
  valoreMax: any = {};
  idLineeBusinessTempArray: number[] = [];
  lineeBusinessForm: UntypedFormGroup;
  listaTips: ToolTip[] = [];
  listaEvoluzioneLinea: BeanStatico[];
  tipsSubscription: Subscription

  tipologieAttivita: BeanStatico[] = [];
  categorieProd: any[] = [];
  regioni: regione[] = [];
  listaCodiciAteco: BeanStaticoEsteso[] = [];
  sommaCentoVenduto: boolean[] = [];
  sommaCentoMagaz: boolean[] = [];
  idRuolo: number;
  costiFissi: number = null;
  percTotCostiFissi: number = null;
  costiFissiAssegnare: number = null;
  costiFissiLinee: number[] = [];
  annoIniziale = new Date().getFullYear();
  trimestreList: any[] = [{ id: 1, descrizione: "1° trimestre" }, { id: 2, descrizione: "2° trimestre" }, { id: 3, descrizione: "3° trimestre" }, { id: 4, descrizione: "4° trimestre" }]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tokenStorageService: TokenStorageService,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private toolTipService: ToolTipService,
    private httpClient: HttpClient,
    private modalService: NgbModal,
  ) {
    this.lineeBusinessForm = this.formBuilder.group({
      lineeBusiness: this.formBuilder.array([])
    })
  }
  ngOnDestroy(): void {
    this.tipsSubscription.unsubscribe()
    this.annoInizialeSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.idRuolo = this.tokenStorageService.getUser().roles.id;

    this.configurazioneAmbienteService.getTipologiaAttivita().subscribe(list => {
      this.tipologieAttivita = list
    })

    this.configurazioneAmbienteService.getCategorie().subscribe(list => {
      this.categorieProd = list
    })

    this.configurazioneAmbienteService.getRegioni().subscribe(list => {
      this.regioni = list
    })


    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/parametriGenerali/getModalitaEvoluzione').subscribe(response => {
      this.listaEvoluzioneLinea = response
    })

    this.configurazioneAmbienteService.getValoriMax('linee business').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMax[element.nomeCampo] = element.valoreMax;
        }
      });
      //console.log(this.valoreMax);
    })
    /* this.initialValues = this.lineeBusinessForm.value; */

    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })

    this.annoInizialeSubscription = this.configurazioneAmbienteService.currentAnnoIniziale.subscribe(annoIniziale => {
      this.annoIniziale = annoIniziale;
    })
    /* ateco Sezione*/
    this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoSezioni').subscribe(data => {

      this.listaAtecoSezione = [];
      data.forEach(element => {
        let atecoSezione = { id: element.id, descrizione:element.codiceAteco, descrizioneCompleta: element.codiceAteco + " - " + element.descrizione };
        this.listaAtecoSezione.push(atecoSezione)
      });
    });

    
  }
  loadListaCodiciAteco(){
    this.configurazioneAmbienteService.getListaCodiciAteco(this.idRichiedenteTemp, this.tokenStorageService.getUser().cliente.idCliente).subscribe(list => {
      this.listaCodiciAteco = list;
      this.onLoadLineeBusiness();
    })
  }

  getCostiFissi(){
    this.configurazioneAmbienteService.getCostiFissi(this.idRichiedenteTemp).subscribe(costiFissi => {
      if(costiFissi != null && costiFissi > 0){
        this.costiFissi = costiFissi;
      }else{
        this.costiFissi = null;
      }
    })
  }

  onLoadLineeBusiness() {
    this.configurazioneAmbienteService.getLineeBusiness(this.idRichiedenteTemp, this.tokenStorageService.getUser().cliente.idCliente).subscribe(response => {
      /* console.log(response) */
      this.lineeBusinessArray.clear();
      this.idLineeBusinessTempArray = []  //serve per azienda singola
      this.sommaCentoVenduto = [];
      this.sommaCentoMagaz = [];
      this.costiFissiLinee = []
      if (response.error == null) {
        let value: BeanStatico = null;
        for (let i = 0; i < response.lineeBusiness.length; i++) {
          this.addLineaDisabled = response.lineeBusiness.length >= 10;
          //pusho l'id
          this.listaEvoluzioneLinea.forEach(element => {
            if (element.id == response.lineeBusiness[i].evoluzioneLineaDiBusiness.id) {
              value = element;
            }
          })
          let tipologiaAttTemp: BeanStatico = null
          this.tipologieAttivita.forEach(element => {
            if (element.id == response.lineeBusiness[i].idTipologiaAtt) {
              tipologiaAttTemp = element
            }
          })
          let categoriaProdTemp: BeanStatico = null
          this.categorieProd.forEach(element => {
            if (element.id == response.lineeBusiness[i].idCategoriaProd) {
              categoriaProdTemp = element
            }
          })
          let regioneTemp: regione = null
          this.regioni.forEach(element => {
            if (element.id == response.lineeBusiness[i].idRegione) {
              regioneTemp = element
            }
          })

          let atecoTemp: BeanStaticoEsteso = null
          this.listaCodiciAteco.forEach(element => {
            if (element.descrizione == response.lineeBusiness[i].codiceAteco) {
              atecoTemp = element
            }
          })

          let percTrimVendutoTemp: UntypedFormControl[] = [];
          let percTrimMagazTemp: UntypedFormControl[] = [];
//In aggiungi linea business il numero di trimestri è scolpito a 4
          for(let j = 0; j < response.lineeBusiness[i].percTrimVenduto.length;j++){
            percTrimVendutoTemp.push(new UntypedFormControl( response.lineeBusiness[i].percTrimVenduto[j], Validators.required))
            percTrimMagazTemp.push(new UntypedFormControl(response.lineeBusiness[i].percTrimMagaz[j], Validators.required))
  
          }

          let latenzaTemp: UntypedFormControl[] = [];
          for(let j = 0; j < 8;j++){
            latenzaTemp.push(new UntypedFormControl(response.lineeBusiness[i].latenza[j], response.lineeBusiness[i].lineaEsistente ? null : Validators.required))
  
          }
            
          this.idLineeBusinessTempArray.push(response.lineeBusiness[i].id);
          this.sommaCentoVenduto.push(true);
          this.sommaCentoMagaz.push(true);
          this.costiFissiLinee.push(null);
          this.lineeBusinessFormArray.push(this.formBuilder.group({
            nomeLineaBusiness: [response.lineeBusiness[i].nomeLineaBusiness, Validators.required],
            tipologiaAtt: [tipologiaAttTemp, this.agricoltura ? Validators.required : null],
            categoriaProd: [categoriaProdTemp, this.agricoltura ? Validators.required : null],
            sitoProduttivo: [regioneTemp, this.agricoltura ? Validators.required : null],
            presenzaMagazzino: [this.agricoltura ? false : response.lineeBusiness[i].presenzaMagazzino, this.agricoltura ? Validators.required : null],
            codiceAteco: [atecoTemp, this.agricoltura ? null : Validators.required],
            evoluzioneLineaDiBusiness: [value, Validators.required],/* MODIFICATO DA ANDREA.C 26/09/22 */
            quantitaVenduta: this.formBuilder.group({
              ribasso: [response.lineeBusiness[i].quantitaVenduta.ribasso, [Validators.required, Validators.max(this.valoreMax.ribasso)]],/* Validators.required */
              rialzo: [response.lineeBusiness[i].quantitaVenduta.rialzo, [Validators.required, Validators.max(this.valoreMax.rialzo)]],
              scarto: [response.lineeBusiness[i].quantitaVenduta.scarto, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],
              drift: [response.lineeBusiness[i].quantitaVenduta.drift, [Validators.required, Validators.max(this.valoreMax.drift)]]
            }),
            prezzoIniziale: this.formBuilder.group({
              ribasso: [response.lineeBusiness[i].prezzoIniziale.ribasso, [Validators.required, Validators.max(this.valoreMax.ribasso)]],
              rialzo: [response.lineeBusiness[i].prezzoIniziale.rialzo, [Validators.required, Validators.max(this.valoreMax.rialzo)]],
              scarto: [response.lineeBusiness[i].prezzoIniziale.scarto, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],
              drift: [response.lineeBusiness[i].prezzoIniziale.drift, [Validators.required, Validators.max(this.valoreMax.drift)]],
            }),
            costoIniziale: this.formBuilder.group({
              ribasso: [response.lineeBusiness[i].costoIniziale.ribasso, [Validators.required, Validators.max(this.valoreMax.ribasso)]],
              rialzo: [response.lineeBusiness[i].costoIniziale.rialzo, [Validators.required, Validators.max(this.valoreMax.rialzo)]],
              scarto: [response.lineeBusiness[i].costoIniziale.scarto, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],
              drift: [response.lineeBusiness[i].costoIniziale.drift, [Validators.required, Validators.max(this.valoreMax.drift)]],
            }),
            percTrimVenduto: this.formBuilder.array(percTrimVendutoTemp),
            percTrimMagaz: this.formBuilder.array(percTrimMagazTemp),
            percCostiFissi: [response.lineeBusiness[i].percCostiFissi, this.richiedenteTemp1.isStartUp ? '' : Validators.required],
            atecoSezione: [''],
            atecoDivisione: [{ value: '', disabled: true }],
            atecoClasse: [{ value: '', disabled: true }],
            atecoSottocategoria: [{ value: '', disabled: true }],
            lineaEsistente: [response.lineeBusiness[i].lineaEsistente, Validators.required],
            annoInizio: [response.lineeBusiness[i].annoInizio, response.lineeBusiness[i].lineaEsistente ? '': Validators.required],
            trimestreInizio: [response.lineeBusiness[i].trimestreInizio, response.lineeBusiness[i].lineaEsistente ? '': Validators.required],
            latenza: this.formBuilder.array(latenzaTemp),
          }));
          this.lineeBusinessFormArray[i].controls.nomeLineaBusiness.setValue(response.lineeBusiness[i].nomeLineaBusiness);

          this.lineeBusinessFormArray[i].controls.nomeLineaBusiness.updateValueAndValidity();
          this.quantitaVendutaForm(i).controls.ribasso.updateValueAndValidity();
          this.quantitaVendutaForm(i).controls.rialzo.updateValueAndValidity();
          this.quantitaVendutaForm(i).controls.drift.updateValueAndValidity();
          this.quantitaVendutaForm(i).controls.scarto.updateValueAndValidity();

          this.prezzoInizialeForm(i).controls.ribasso.updateValueAndValidity();
          this.prezzoInizialeForm(i).controls.rialzo.updateValueAndValidity();
          this.prezzoInizialeForm(i).controls.drift.updateValueAndValidity();
          this.prezzoInizialeForm(i).controls.scarto.updateValueAndValidity();

          this.costoInizialeForm(i).controls.ribasso.updateValueAndValidity();
          this.costoInizialeForm(i).controls.rialzo.updateValueAndValidity();
          this.costoInizialeForm(i).controls.drift.updateValueAndValidity();
          this.costoInizialeForm(i).controls.scarto.updateValueAndValidity();

          for(let j = 0; j < this.percTrimVendutoFormArray(i).length; j++){
            this.percTrimVendutoFormArray(i)[j].updateValueAndValidity(); 
            this.percTrimMagazFormArray(i)[j].updateValueAndValidity(); 
          }
          
          for(let j = 0; j < this.latenzaFormArray(i).length; j++){
            this.latenzaFormArray(i)[j].updateValueAndValidity(); 
          }

          this.lineeBusinessFormArray[i].updateValueAndValidity();

          this.onChangeCostiFissi(i);

        /* ateco divisione */
        
     this.lineeBusinessFormArray[i].controls.atecoSezione.valueChanges.subscribe(selectedValue => {
      this.listaAtecoDivisione = [];
      if (selectedValue) {
        this.lineeBusinessFormArray[i].controls.atecoDivisione.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoDivisioniByIdSezione', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const atecoDivisione = { id: element.id, descrizione:element.codiceAteco, descrizioneCompleta: element.codiceAteco + " - " + element.descrizione };
            this.listaAtecoDivisione.push(atecoDivisione);
          });
        });
      }
      else {
        this.lineeBusinessFormArray[i].controls.atecoDivisione.disable();
      }
      this.lineeBusinessFormArray[i].controls.atecoDivisione.updateValueAndValidity();
    })
    
    /* ateco classe */
    
    this.lineeBusinessFormArray[i].controls.atecoDivisione.valueChanges.subscribe(selectedValue => {
      this.listaAtecoClasse = [];
      if (selectedValue) {
        this.lineeBusinessFormArray[i].controls.atecoClasse.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoClassiByIdDivisione', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const atecoClasse = { id: element.id,  descrizione:element.codiceAteco, descrizioneCompleta: element.codiceAteco + " - " + element.descrizione };
            this.listaAtecoClasse.push(atecoClasse)

          });
        });
      }
      else {
        this.lineeBusinessFormArray[i].controls.atecoClasse.disable();
      }
      this.lineeBusinessFormArray[i].controls.atecoClasse.updateValueAndValidity();
    })
    
    /* ateco sottocategoria */
    
    this.lineeBusinessFormArray[i].controls.atecoClasse.valueChanges.subscribe(selectedValue => {
      this.listaAtecoSottocategoria = [];

      if (selectedValue) {
        this.lineeBusinessFormArray[i].controls.atecoSottocategoria.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoSottocategorieByIdClasse', selectedValue.id).subscribe(data => {
          if (data.length == 0) {
            this.listaAtecoSottocategoria.push({ id: 0,  descrizione:'N/A', descrizioneCompleta: 'N/A' })
          }
          data.forEach(element => {
            const atecoSottocategoria = { id: element.id,  descrizione:element.codiceAteco, descrizioneCompleta: element.codiceAteco + " - " + element.descrizione };
            this.listaAtecoSottocategoria.push(atecoSottocategoria)
          });
        });
      }
      else {
        this.lineeBusinessFormArray[i].controls.atecoSottocategoria.disable();
      }
      this.lineeBusinessFormArray[i].controls.atecoSottocategoria.updateValueAndValidity();
    })
    
    this.lineeBusinessFormArray[i].controls.atecoSottocategoria.valueChanges.subscribe(selectedValue => {
      if (selectedValue) {
        if(selectedValue.descrizione == 'N/A'){
          let codiceAggiunto = {id: 0, descrizione: this.lineeBusinessFormArray[i].controls.atecoClasse.value.descrizione,descrizioneCompleta: this.lineeBusinessFormArray[i].controls.atecoClasse.value.descrizioneCompleta};
          this.listaCodiciAteco.splice(this.listaCodiciAteco.length - 1,0,codiceAggiunto);
          this.lineeBusinessFormArray[i].controls.codiceAteco.setValue(codiceAggiunto);
        }else{
          let codiceAggiunto = {id: 0, descrizione: this.lineeBusinessFormArray[i].controls.atecoSottocategoria.value.descrizione,descrizioneCompleta: this.lineeBusinessFormArray[i].controls.atecoSottocategoria.value.descrizioneCompleta};
          this.listaCodiciAteco.splice(this.listaCodiciAteco.length - 1,0,codiceAggiunto);
          this.lineeBusinessFormArray[i].controls.codiceAteco.setValue(codiceAggiunto);
        }
        this.onChangeCodiceAteco(i);
      }
    })
  }
  this.f.lineeBusiness.updateValueAndValidity();
      } else {
      }
      /* console.log(this.idLineeBusinessTempArray)
      console.log(this.lineeBusinessArray) */
    })

  }


  get f() { return this.lineeBusinessForm.controls; }
  get lineeBusinessArray(): UntypedFormArray { return <UntypedFormArray> this.f.lineeBusiness; }
  get lineeBusinessFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.lineeBusinessArray.controls; };
  /* casting valido solo per il tutti i sottoform form. la funzione ritorna il control come un formGroup*/
  quantitaVendutaForm(i: number): UntypedFormGroup { return <UntypedFormGroup> this.lineeBusinessFormArray[i].controls.quantitaVenduta; }
  prezzoInizialeForm(i: number): UntypedFormGroup { return <UntypedFormGroup> this.lineeBusinessFormArray[i].controls.prezzoIniziale; }
  costoInizialeForm(i: number): UntypedFormGroup { return <UntypedFormGroup> this.lineeBusinessFormArray[i].controls.costoIniziale; }
  percTrimVendutoArray(i: number): UntypedFormArray { return <UntypedFormArray> this.lineeBusinessFormArray[i].controls.percTrimVenduto; }
  percTrimMagazArray(i: number): UntypedFormArray { return <UntypedFormArray> this.lineeBusinessFormArray[i].controls.percTrimMagaz; }
  percTrimVendutoFormArray(i: number): UntypedFormControl[] { return <UntypedFormControl[]> this.percTrimVendutoArray(i).controls; }
  percTrimMagazFormArray(i: number): UntypedFormControl[] { return <UntypedFormControl[]> this.percTrimMagazArray(i).controls; }
  latenzaArray(i: number): UntypedFormArray { return <UntypedFormArray> this.lineeBusinessFormArray[i].controls.latenza; }
  latenzaFormArray(i: number): UntypedFormControl[] { return <UntypedFormControl[]> this.latenzaArray(i).controls; }

  addLineaBusiness() {
    if (this.lineeBusinessArray.length <= 10) {
      this.lineeBusinessForm.markAsDirty();
      let percTrimVendutoTemp: UntypedFormControl[] = [];
      let percTrimMagazTemp: UntypedFormControl[] = [];
//Lunghezza scolpita a 4 e valore 25
      for(let j = 0; j < 4;j++){
        percTrimVendutoTemp.push(new UntypedFormControl(25, Validators.required))
        percTrimMagazTemp.push(new UntypedFormControl(25, Validators.required))

      }

      let latenzaTemp: UntypedFormControl[] = [];
      for(let j = 0; j < 8;j++){
        latenzaTemp.push(new UntypedFormControl('', this.richiedenteTemp1.isStartUp && !this.agricoltura ? Validators.required : null));

      }

      let value: BeanStatico = null;

      this.listaEvoluzioneLinea.forEach(element => {
        if (element.id == 2) {
          value = element;
        }
      })

      this.lineeBusinessFormArray.push(this.formBuilder.group({
        nomeLineaBusiness: ['', Validators.required],
        tipologiaAtt: ['', this.agricoltura ? Validators.required : null],
        categoriaProd: ['', this.agricoltura ? Validators.required : null],
        sitoProduttivo: ['', this.agricoltura ? Validators.required : null],
        codiceAteco: ['', this.agricoltura ? null : Validators.required],
        evoluzioneLineaDiBusiness: [value, Validators.required],/* MODIFICATO DA ANDREA.C 26/09/22 */
        presenzaMagazzino: [this.agricoltura ? false : '', Validators.required],
        quantitaVenduta: this.formBuilder.group({
          ribasso: [0, [Validators.required, Validators.max(this.valoreMax.ribasso)]],/* Validators.required */
          rialzo: [0, [Validators.required, Validators.max(this.valoreMax.rialzo)]],/* Validators.required */
          scarto: [5, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],/* [Validators.min(0.1),Validators.required] */
          drift: [0, [Validators.required, Validators.max(this.valoreMax.drift)]],/* Validators.required */
        }),
        prezzoIniziale: this.formBuilder.group({
          ribasso: [0, [Validators.required, Validators.max(this.valoreMax.ribasso)]],/* Validators.required */
          rialzo: [0, [Validators.required, Validators.max(this.valoreMax.rialzo)]],/* Validators.required */
          scarto: [5, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],/* [Validators.min(0.1),Validators.required] */
          drift: [0, [Validators.required, Validators.max(this.valoreMax.drift)]],/* Validators.required */
        }),
        costoIniziale: this.formBuilder.group({
          ribasso: [0, [Validators.required, Validators.max(this.valoreMax.ribasso)]],/* Validators.required */
          rialzo: [0, [Validators.required, Validators.max(this.valoreMax.rialzo)]],/* Validators.required */
          scarto: [5, [Validators.min(0.1), Validators.required, Validators.max(this.valoreMax.scarto)]],/* [Validators.min(0.1),Validators.required] */
          drift: [0, [Validators.required, Validators.max(this.valoreMax.drift)]],/* Validators.required */
        }),
        percTrimVenduto: this.formBuilder.array(percTrimVendutoTemp),
        percTrimMagaz: this.formBuilder.array(percTrimMagazTemp),
        percCostiFissi: ['', this.richiedenteTemp1.isStartUp ? '' : Validators.required],
        atecoSezione: [''],
        atecoDivisione: [{ value: '', disabled: true }],
        atecoClasse: [{ value: '', disabled: true }],
        atecoSottocategoria: [{ value: '', disabled: true }],
        lineaEsistente: [!this.richiedenteTemp1.isStartUp || this.agricoltura, Validators.required],
        annoInizio: ['',this.richiedenteTemp1.isStartUp && !this.agricoltura? Validators.required : ''],
        trimestreInizio: ['',this.richiedenteTemp1.isStartUp && !this.agricoltura? Validators.required : ''],
        latenza: this.formBuilder.array(latenzaTemp),
      }));
      /*se aggiungo un form aggiungo un id= 0 all'array degli id delle linee di business */
      this.idLineeBusinessTempArray.push(0);
      this.sommaCentoVenduto.push(true);
      this.sommaCentoMagaz.push(true);
      this.costiFissiLinee.push(null)
      /* update value and validity dei campi dell'ultimo form pushato */
      this.lineeBusinessFormArray[this.lineeBusinessFormArray.length - 1].controls.nomeLineaBusiness.updateValueAndValidity();
      this.lineeBusinessFormArray[this.lineeBusinessFormArray.length - 1].controls.presenzaMagazzino.updateValueAndValidity();
      this.quantitaVendutaForm(this.lineeBusinessFormArray.length - 1).controls.ribasso.updateValueAndValidity();
      this.quantitaVendutaForm(this.lineeBusinessFormArray.length - 1).controls.rialzo.updateValueAndValidity();
      this.quantitaVendutaForm(this.lineeBusinessFormArray.length - 1).controls.drift.updateValueAndValidity();
      this.quantitaVendutaForm(this.lineeBusinessFormArray.length - 1).controls.scarto.updateValueAndValidity();

      this.prezzoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.ribasso.updateValueAndValidity();
      this.prezzoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.rialzo.updateValueAndValidity();
      this.prezzoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.drift.updateValueAndValidity();
      this.prezzoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.scarto.updateValueAndValidity();

      this.costoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.ribasso.updateValueAndValidity();
      this.costoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.rialzo.updateValueAndValidity();
      this.costoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.drift.updateValueAndValidity();
      this.costoInizialeForm(this.lineeBusinessFormArray.length - 1).controls.scarto.updateValueAndValidity();

      for(let j = 0; j < this.percTrimVendutoFormArray(this.lineeBusinessFormArray.length - 1).length; j++){
        this.percTrimVendutoFormArray(this.lineeBusinessFormArray.length - 1)[j].updateValueAndValidity(); 
        this.percTrimMagazFormArray(this.lineeBusinessFormArray.length - 1)[j].updateValueAndValidity(); 
      }

      for(let j = 0; j < this.latenzaFormArray(this.lineeBusinessFormArray.length - 1).length; j++){
        this.latenzaFormArray(this.lineeBusinessFormArray.length - 1)[j].updateValueAndValidity(); 
      }
      this.lineeBusinessArray.updateValueAndValidity();
    } else {
      this.addLineaDisabled = true;
    }
    /*     console.log(this.idLineeBusinessTempArray)
        console.log(this.lineeBusinessArray) */
  }

  onChangeTipologiaAtt(index: number) {
    if (this.lineeBusinessFormArray[index].controls.tipologiaAtt.value) {
      if (this.lineeBusinessFormArray[index].controls.tipologiaAtt.value.id == 1) {
        this.lineeBusinessFormArray[index].controls.categoriaProd.setValidators(Validators.required)
        this.lineeBusinessFormArray[index].controls.sitoProduttivo.setValidators(Validators.required)
      } else {
        this.lineeBusinessFormArray[index].controls.categoriaProd.clearValidators()
        this.lineeBusinessFormArray[index].controls.sitoProduttivo.clearValidators()
      }
      this.lineeBusinessFormArray[index].controls.categoriaProd.updateValueAndValidity()
      this.lineeBusinessFormArray[index].controls.sitoProduttivo.updateValueAndValidity()
    }
  }

  onChangeCodiceAteco(index: number) {
if(this.lineeBusinessFormArray[index].controls.codiceAteco.value && this.lineeBusinessFormArray[index].controls.codiceAteco.value.descrizione == 'Altro'){
  
  this.lineeBusinessFormArray[index].controls.atecoSottocategoria.setValue('');
  this.lineeBusinessFormArray[index].controls.atecoClasse.setValue('');
  this.lineeBusinessFormArray[index].controls.atecoDivisione.setValue('');
  this.lineeBusinessFormArray[index].controls.atecoSezione.setValue('');
}else if(this.lineeBusinessFormArray[index].controls.codiceAteco.value && this.lineeBusinessFormArray[index].controls.codiceAteco.value.descrizione != '' && this.lineeBusinessFormArray[index].controls.codiceAteco.value.descrizione != 'Altro'){
  this.configurazioneAmbienteService.oscillazioniByAteco(this.lineeBusinessFormArray[index].controls.codiceAteco.value.descrizione).subscribe(response => {
    this.prezzoInizialeForm(index).controls.ribasso.setValue(Math.round(response.prezzoIniziale.ribasso*10.)/10.);
    this.prezzoInizialeForm(index).controls.rialzo.setValue(Math.round(response.prezzoIniziale.rialzo*10.)/10.);
    this.prezzoInizialeForm(index).controls.drift.setValue(Math.round(response.prezzoIniziale.drift*10.)/10.);

    this.costoInizialeForm(index).controls.ribasso.setValue(Math.round(response.costoIniziale.ribasso*10.)/10.);
    this.costoInizialeForm(index).controls.rialzo.setValue(Math.round(response.costoIniziale.rialzo*10.)/10.);
    this.costoInizialeForm(index).controls.drift.setValue(Math.round(response.costoIniziale.drift*10.)/10.);

    this.prezzoInizialeForm(index).controls.ribasso.updateValueAndValidity();
    this.prezzoInizialeForm(index).controls.rialzo.updateValueAndValidity();
    this.prezzoInizialeForm(index).controls.drift.updateValueAndValidity();

    this.costoInizialeForm(index).controls.ribasso.updateValueAndValidity();
    this.costoInizialeForm(index).controls.rialzo.updateValueAndValidity();
    this.costoInizialeForm(index).controls.drift.updateValueAndValidity();
  });
}
    
  }
  

  EliminaLineaDiBusiness(index: number) {
    /* inserisce data fine validità della linea */
    if (this.idLineeBusinessTempArray[index] != 0) {
      if (this.lineeBusinessArray.length > 1) {
        this.lineeBusinessForm.markAsDirty();
        if (this.modalService.hasOpenModals()) {
          this.modalService.dismissAll();
        }
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "elimina",
          title: "Eliminare la linea di Business " + this.lineeBusinessFormArray[index].controls.nomeLineaBusiness.value + " ?",
          description: "NOTA BENE: L'eliminazione della linea di business cancellerà anche tutti i PRODOTTI relativi a quest'ultima e sarà necessario ricompilare i Business Plan associati.",
          undoButtonText: "Annulla",
          actionButtonText: "Elimina",
        }
        modalRef.result.then((r) => {
          if (r) {
            this.configurazioneAmbienteService.deleteLineabusiness(this.idLineeBusinessTempArray[index], this.tokenStorageService.getUser().idUtente, this.idRichiedenteTemp, this.idLineeBusinessTempArray.length).subscribe(response => {
              this.idLineeBusinessTempArray.splice(index, 1);
              this.lineeBusinessArray.removeAt(index);
              this.sommaCentoVenduto.splice(index,1);
              this.sommaCentoMagaz.splice(index,1);
              this.costiFissiLinee.splice(index,1);

              const modalRef = this.modalService.open(ModalcontentComponent, {
                backdrop: 'static',
                backdropClass: 'customBackdrop',
              });
              modalRef.componentInstance.modalPar = {
                name: "messaggioelimina",
                description: response ? "Linea di Business eliminata con successo" : "Errore",
                actionButtonText: "",
                undoButtonText: "Ok",
              }
              modalRef.result.then((r) => {
                if (!r) {
                  this.onLoadLineeBusiness();
                  this.configurazioneAmbienteService.lineeBpChanged(false)
                }
              })
            })
          }


        })
      } else {
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "messaggio",
          description: "Mantenere almeno una linea di business.",
          actionButtonText: "",
          undoButtonText: "Ok",
        }
        modalRef.result.then((r) => {
          if (!r) {

          }
        })
      }
    } else {
      /* eliminazione righe senza dati da db */
      this.idLineeBusinessTempArray.splice(index, 1);
      this.lineeBusinessArray.removeAt(index);
      this.sommaCentoVenduto.splice(index,1);
      this.sommaCentoMagaz.splice(index,1);      
      this.costiFissiLinee.splice(index,1);      

    }

  }
  isFormDirty() {
    this.lineeBusinessArray.updateValueAndValidity();
    for (let i = 0; i < this.lineeBusinessFormArray.length; i++) {
      if (this.lineeBusinessFormArray[i].dirty) {
        this.lineeBusinessForm.markAsDirty();
      }
    }
    this.submitted = this.lineeBusinessForm.dirty
    return this.submitted
  }

  annulla() {
    if (this.isFormDirty()) {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Così facendo, tutte le modifiche non salvate in Linee Business andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.lineeBusinessArray.clear();
          this.idLineeBusinessTempArray = [];
          this.sommaCentoVenduto = [];
          this.sommaCentoMagaz = [];
          this.costiFissiLinee = [];
          this.onLoadLineeBusiness();
          this.submitted = false;
          this.addLineaDisabled = false;
          this.lineeBusinessForm.markAsPristine();

        }
      })
    }
  }

  /*onchange*/
  onChangeLinee(index: number) {

    if (this.lineeBusinessFormArray[index].controls['evoluzioneLineaDiBusiness'].value.id == 2) {
      if (this.lineeBusinessFormArray[index].controls.drift != null)
        this.lineeBusinessFormArray[index].controls.drift.setValidators([Validators.required, Validators.max(this.valoreMax.drift)]);
    } else {
      if (this.lineeBusinessFormArray[index].controls.drift != null)
        this.lineeBusinessFormArray[index].controls.drift.clearValidators();
    }
    if (this.lineeBusinessFormArray[index].controls.drift != null)
      this.lineeBusinessFormArray[index].controls.drift.updateValueAndValidity();
  }

  onChangeCostiFissi(index: number) {

    if (!this.agricoltura && this.lineeBusinessFormArray[index].controls.percCostiFissi.value && this.lineeBusinessFormArray[index].controls.lineaEsistente.value) {
this.costiFissiLinee[index] = this.costiFissi*this.lineeBusinessFormArray[index].controls.percCostiFissi.value/100.;

    }else{
      this.costiFissiLinee[index] = null;
    }
let sommaPerc = 0;
    for(let i = 0; i < this.lineeBusinessFormArray.length; i++){
    sommaPerc += !this.agricoltura && this.lineeBusinessFormArray[i].controls.percCostiFissi.value && this.lineeBusinessFormArray[index].controls.lineaEsistente.value? this.lineeBusinessFormArray[i].controls.percCostiFissi.value : 0.;
    }
    this.percTotCostiFissi = sommaPerc;
    this.costiFissiAssegnare = 100 - sommaPerc > 0 ? 100 - sommaPerc : 0;
  }


  /* salva */
  onSubmit() {
    if (this.isFormDirty()) {
      this.lineeBusinessForm.updateValueAndValidity();
      this.lineeBusinessArray.updateValueAndValidity();
      let sommaVendutoTot = true;
      let sommaMagazTot = true;
      let boolLineaEsistente = false;
      for(let i=0; i < this.lineeBusinessFormArray.length;i++){

      let sommaVenduto = 0;
      let sommaMagaz = 0;
      for(let j=0; j < this.percTrimVendutoFormArray(i).length;j++){
        sommaVenduto += this.percTrimVendutoFormArray(i)[j].value;
        sommaMagaz += this.percTrimMagazFormArray(i)[j].value;
      }
      this.sommaCentoVenduto[i] = sommaVenduto == 100;
      this.sommaCentoMagaz[i] = sommaMagaz == 100;
      sommaVendutoTot &&= this.sommaCentoVenduto[i];
      sommaMagazTot &&= this.sommaCentoMagaz[i];
      boolLineaEsistente ||= this.lineeBusinessFormArray[i].controls.lineaEsistente.value;
    }
      if (this.lineeBusinessForm.valid && sommaVendutoTot && sommaMagazTot ) {
        let descrizione ="";
        if(this.percTotCostiFissi != 100 && !this.richiedenteTemp1.isStartUp && boolLineaEsistente && !this.agricoltura){
          descrizione = "La percentuale di costi aziendali assegnati \u00e8 pari a " + this.percTotCostiFissi + "% del totale, salvare comunque le linee di business inserite?";

        }else{
 descrizione = "Salvare le linee di business inserite?";
        }
        /* modale salva */
        const modalRef = this.modalService.open(ModalcontentComponent);
        modalRef.componentInstance.modalPar = {
          name: "salvamodifiche",
          description: descrizione,
          actionButtonText: "Salva",
          undoButtonText: "Indietro",
        }
        modalRef.result.then((r) => {
          if (r) {
            let lineeBusiness: LineaBusiness[] = [];
            for (let i = 0; i < this.lineeBusinessFormArray.length; i++) {
              let percTrimVenduto = [];
              let percTrimMagaz = [];
              for (let j = 0; j < this.percTrimVendutoFormArray(i).length; j++) {
                percTrimVenduto.push(this.percTrimVendutoFormArray(i)[j].value);
                percTrimMagaz.push(this.percTrimMagazFormArray(i)[j].value);
        
              }

              let latenza = [];
              if((!this.lineeBusinessFormArray[i].controls.lineaEsistente.value || this.richiedenteTemp1.isStartUp) && !this.agricoltura){
                for (let j = 0; j < this.latenzaFormArray(i).length; j++) {
                  latenza.push(this.latenzaFormArray(i)[j].value);
          
                }
              }

              const lineaBusiness: LineaBusiness = {
                id: this.idLineeBusinessTempArray[i],
                nomeLineaBusiness: this.lineeBusinessFormArray[i].controls.nomeLineaBusiness.value,
                idTipologiaAtt: this.lineeBusinessFormArray[i].controls.tipologiaAtt.value ?
                  this.lineeBusinessFormArray[i].controls.tipologiaAtt.value.id : 0,
                idCategoriaProd: this.lineeBusinessFormArray[i].controls.categoriaProd.value ?
                  this.lineeBusinessFormArray[i].controls.categoriaProd.value.id : 0,
                idRegione: this.lineeBusinessFormArray[i].controls.sitoProduttivo.value ?
                  this.lineeBusinessFormArray[i].controls.sitoProduttivo.value.id : 0,
                costoIniziale: {
                  ribasso: this.costoInizialeForm(i).controls.ribasso.value,
                  rialzo: this.costoInizialeForm(i).controls.rialzo.value,
                  scarto: this.costoInizialeForm(i).controls.scarto.value, /* non possiamo inizializzare lo scarto a 0 */
                  drift: this.costoInizialeForm(i).controls.drift.value,
                },
                prezzoIniziale: {
                  ribasso: this.prezzoInizialeForm(i).controls.ribasso.value,
                  rialzo: this.prezzoInizialeForm(i).controls.rialzo.value,
                  scarto: this.prezzoInizialeForm(i).controls.scarto.value,
                  drift: this.prezzoInizialeForm(i).controls.drift.value,
                },
                quantitaVenduta: {
                  ribasso: this.quantitaVendutaForm(i).controls.ribasso.value,
                  rialzo: this.quantitaVendutaForm(i).controls.rialzo.value,
                  scarto: this.quantitaVendutaForm(i).controls.scarto.value,
                  drift: this.quantitaVendutaForm(i).controls.drift.value,
                },
                presenzaMagazzino: this.lineeBusinessFormArray[i].controls.presenzaMagazzino.value,
                evoluzioneLineaDiBusiness: this.lineeBusinessFormArray[i].controls.evoluzioneLineaDiBusiness.value,
                percTrimVenduto: percTrimVenduto,
                percTrimMagaz: percTrimMagaz,
                codiceAteco: this.lineeBusinessFormArray[i].controls.codiceAteco.value ? this.lineeBusinessFormArray[i].controls.codiceAteco.value.descrizione : '',
                percCostiFissi: !this.agricoltura && (this.richiedenteTemp1.isStartUp || !this.lineeBusinessFormArray[i].controls.lineaEsistente.value) ? 100 : this.lineeBusinessFormArray[i].controls.percCostiFissi.value,
                lineaEsistente: this.lineeBusinessFormArray[i].controls.lineaEsistente.value,
                annoInizio: this.lineeBusinessFormArray[i].controls.annoInizio.value ? this.lineeBusinessFormArray[i].controls.annoInizio.value : null,
                trimestreInizio: this.lineeBusinessFormArray[i].controls.trimestreInizio.value ? this.lineeBusinessFormArray[i].controls.trimestreInizio.value : null,
                latenza: latenza
              }
              lineeBusiness.push(lineaBusiness)
            }

            let request = {
              idRichiedente: this.idRichiedenteTemp,
              idUtente: this.tokenStorageService.getUser().idUtente,
              lineeBusiness: lineeBusiness,
            }
            this.configurazioneAmbienteService.saveLineeBusiness(request).subscribe(
              response => {
                if (response) {
                  const modalRef = this.modalService.open(ModalcontentComponent);
                  modalRef.componentInstance.modalPar = {
                    name: "messaggiosalva",
                    description: "Linee Business salvate con successo",
                    actionButtonText: "",
                    undoButtonText: "Ok",
                  }
                  modalRef.result.then(r => {
                    if (!r) {
                      //this.configurazioneAmbienteService.Loadsubmitted(false)
                      this.onLoadLineeBusiness();
                      this.saved = true;
                      this.submitted = false;
                      this.configurazioneAmbienteService.lineeBpChanged(true)
                      /* condizione bool per andare alla prossima scheda */
                    }
                  })
                } else {
                  const modalRef = this.modalService.open(ModalcontentComponent);
                  modalRef.componentInstance.modalPar = {
                    name: "messaggiosalva",
                    description: "Errore",
                    actionButtonText: "",
                    undoButtonText: "Ok",
                  }
                  modalRef.result.then(r => {
                  })
                }



              }
            );
          } else {
            this.submitted = false;
          }
        })

      }
    }
  }

  onChangeAnnoInizio(i: number){
    this.lineeBusinessFormArray[i].controls.annoInizio.updateValueAndValidity()
    if (this.lineeBusinessFormArray[i].controls.annoInizio.value != '' && this.lineeBusinessFormArray[i].controls.annoInizio.value < this.annoIniziale) {
        this.lineeBusinessFormArray[i].controls.annoInizio.setValue(this.annoIniziale);
      }

  }

  onChangeEsistente(i: number){
    if(this.lineeBusinessFormArray[i].controls.lineaEsistente.value){
      this.lineeBusinessFormArray[i].controls.annoInizio.clearValidators();
      this.lineeBusinessFormArray[i].controls.trimestreInizio.clearValidators();
      for(let j = 0; j < this.latenzaFormArray(i).length; j++){
        this.latenzaFormArray(i)[j].clearValidators();
      }
      this.lineeBusinessFormArray[i].controls.percCostiFissi.setValidators(Validators.required);
    }else{
      this.lineeBusinessFormArray[i].controls.annoInizio.setValidators(Validators.required);
      this.lineeBusinessFormArray[i].controls.trimestreInizio.setValidators(Validators.required);
      for(let j = 0; j < this.latenzaFormArray(i).length; j++){
        this.latenzaFormArray(i)[j].setValidators(Validators.required);
      }
      this.lineeBusinessFormArray[i].controls.percCostiFissi.clearValidators();
    }
    this.lineeBusinessFormArray[i].controls.annoInizio.updateValueAndValidity();
    this.lineeBusinessFormArray[i].controls.trimestreInizio.updateValueAndValidity();
    this.lineeBusinessFormArray[i].controls.percCostiFissi.updateValueAndValidity();
   
    for(let j = 0; j < this.latenzaFormArray(i).length; j++){
      this.latenzaFormArray(i)[j].updateValueAndValidity();
    }

  }

  onChangeLatenza(i: number,j: number){
    if(this.latenzaFormArray(i)[j].value){
      if(this.latenzaFormArray(i)[j].value < 0) this.latenzaFormArray(i)[j].setValue(-this.latenzaFormArray(i)[j].value)
      if(this.latenzaFormArray(i)[j].value > 100) this.latenzaFormArray(i)[j].setValue(100)

      if(this.latenzaFormArray(i)[j].value == 100){
for(let k = j+1;k < this.latenzaFormArray(i).length; k++){
  this.latenzaFormArray(i)[k].setValue(100);
}
      }
    }
    
  }
}
