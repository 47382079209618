<div class="container-fluid">
    <ng-container *ngIf="richiedenteTemp1==undefined">
        <div class="row nopadding">
            <div class="col-8 nopadding">
                <h2>Gestione linee di business</h2>
            </div>
        </div>
        <div class="row nopadding">
            <div class="col-12 nopadding d-flex align-items-center margin-top-10">
                <h3> Inserisci l'azienda di cui vuoi configurare l'ambiente.</h3>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="richiedenteTemp1!=undefined">
        <div class="row d-flex justify-content-between" id="saveLineeBusinessAmbiente">
            <div class="col-8 nopadding">
                <h2 class="margin-top-10">Gestione linee di business: </h2>
                <p>Premi "<strong>Aggiungi</strong>" per inserire una nuova linea di business - &Egrave;
                    necessario
                    inserire almeno una linea di Business per lo sviluppo del Business Plan.</p>
            </div>

            <div class="col-4 text-center d-flex align-items-center justify-content-end">
                <button class="btn btn-success" aria-hidden="true" type="button" (click)="addLineaBusiness()"
                    placement="top" container="body"
                    ngbTooltip="Aggiungi Linea di Business. Sono disponibili fino 10 linee di business per azienda."
                    [disabled]="addLineaDisabled">Aggiungi
                </button>
                <button class="btn btn-success" type="submit" (click)="onSubmit()" [disabled]="disabledSalva"
                    style="margin-left: 20px">Salva</button>
                <button class="btn btn-secondary" style="margin-left: 20px" type="button" (click)="annulla()">
                    Annulla
                </button>
            </div>
        </div>
        <hr>
        <div id="container-linee-bp-configurazione-ambiente">
            <div class="row" [formGroup]=" lineeBusinessForm">
                <div formArrayName="lineeBusiness" class="nopadding">

                    <div *ngFor="let lineaBusiness of lineeBusinessFormArray;let i = index" [formGroupName]="i"
                        class=" border-top">
                        <div class="row margin-bottom-10 margin-top-10">
                            <div class="col-11 nopadding">
                                <h2>Linea di Business {{i+1}}</h2>
                            </div>
                            <div class="col-1">
                                <button class="btn fa fa-trash fa-lg pointer" (click)="EliminaLineaDiBusiness(i)"
                                    aria-hidden="true" type="button" placement="top"
                                    ngbTooltip="Elimina Linea di Business"></button>
                            </div>
                        </div>
                        <div class="row align-items-center" style="margin: 0px;">
                            <div class="nopadding"
                                [ngClass]="[agricoltura ? 'col-12': idRuolo == 0 ? 'col-4' : 'col-6']">
                                <div class="row">

                                    <div class="margin-bottom-10 margin-top-10"
                                        [ngClass]="[agricoltura ? 'col-2':'col-6']">
                                        <!--<label>Linea di Business</label>-->
                                        <label>Descrizione</label>
                                        <input type="text" formControlName="nomeLineaBusiness" class="form-control"
                                            style="max-width: 100%;"
                                            [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.nomeLineaBusiness.errors }" />
                                        <div *ngIf=" submitted && lineaBusiness.controls.nomeLineaBusiness.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="lineaBusiness.controls.nomeLineaBusiness.errors.required">Campo
                                                obbligatorio
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="agricoltura">
                                        <div class="col-3 align-items-center margin-bottom-10 margin-top-10">
                                            <label>Tipologia attività</label>
                                            <select formControlName="tipologiaAtt" class="form-control form-select"
                                                [ngClass]="{'is-invalid': submitted &&
                                        lineaBusiness.controls.tipologiaAtt.errors}"
                                                (change)="onChangeTipologiaAtt(i)">
                                                <option [ngValue]="tipologiaAtt"
                                                    *ngFor="let tipologiaAtt of tipologieAttivita">
                                                    {{tipologiaAtt.descrizione}}
                                                </option>
                                            </select>
                                            <div *ngIf=" submitted && lineaBusiness.controls.tipologiaAtt.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="lineaBusiness.controls.tipologiaAtt.errors.required">Campo
                                                    obbligatorio
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.lineeBusinessFormArray[i].controls.tipologiaAtt.value!=null && 
                                this.lineeBusinessFormArray[i].controls.tipologiaAtt.value.id==1"
                                            class="col-3 align-items-center margin-bottom-10 margin-top-10">
                                            <label>Categoria produzione</label>
                                            <select class="form-control form-select" formControlName="categoriaProd"
                                                [ngClass]="{'is-invalid': submitted && lineaBusiness.controls.categoriaProd.errors}">
                                                <option [ngValue]="categoriaProd"
                                                    *ngFor="let categoriaProd of categorieProd">
                                                    {{categoriaProd.descrizione}}
                                                </option>
                                            </select>
                                            <div *ngIf=" submitted && lineaBusiness.controls.categoriaProd.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="lineaBusiness.controls.categoriaProd.errors.required">Campo
                                                    obbligatorio
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.lineeBusinessFormArray[i].controls.tipologiaAtt.value!=null && 
                                this.lineeBusinessFormArray[i].controls.tipologiaAtt.value.id==1"
                                            class="col-3 align-items-center margin-bottom-10 margin-top-10">
                                            <label>Sito produttivo</label>
                                            <select class="form-control form-select" formControlName="sitoProduttivo"
                                                [ngClass]="{'is-invalid': submitted && lineaBusiness.controls.sitoProduttivo.errors}">
                                                <option [ngValue]="regione" *ngFor="let regione of regioni">
                                                    {{regione.regione}}
                                                </option>
                                            </select>
                                            <div *ngIf=" submitted && lineaBusiness.controls.sitoProduttivo.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="lineaBusiness.controls.sitoProduttivo.errors.required">Campo
                                                    obbligatorio
                                                </div>
                                            </div>
                                        </div>

                                    </ng-container>

                                    <!-- ngif == ateco!= '01' -->
                                    <ng-container *ngIf="!agricoltura">
                                        <div class="col-6 align-items-center margin-bottom-10 margin-top-10">
                                            <label>Codice ATECO</label>
                                            <select class="form-control form-select" formControlName="codiceAteco"
                                                style="max-width: 100%;" (change)="onChangeCodiceAteco(i)"
                                                [ngClass]="{'is-invalid': submitted && lineaBusiness.controls.codiceAteco.errors}">
                                                <option [ngValue]="codice" *ngFor="let codice of listaCodiciAteco">
                                                    {{codice.descrizioneCompleta}}
                                                </option>
                                            </select>
                                            <div *ngIf=" submitted && lineaBusiness.controls.codiceAteco.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="lineaBusiness.controls.codiceAteco.errors.required">Campo
                                                    obbligatorio
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="lineaBusiness.controls.codiceAteco.value && lineaBusiness.controls.codiceAteco.value.descrizione == 'Altro'">


                                            <div class=" col-6 margin-bottom-10">
                                                <label>Sezione</label>
                                                <select formControlName="atecoSezione" class="form-control form-select"
                                                    [ngClass]="{ 'is-invalid': submitted && f.atecoSezione.errors }">
                                                    <option [ngValue]="atecoSezione" style="width: 200px"
                                                        *ngFor="let atecoSezione of listaAtecoSezione">
                                                        {{atecoSezione.descrizioneCompleta}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && f.atecoSezione.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.atecoSezione.errors.required">Campo obbligatorio</div>
                                                </div>
                                            </div>
                                            <div class="col-6 margin-bottom-10">
                                                <label>Divisione</label>
                                                <select formControlName="atecoDivisione"
                                                    class="form-control form-select"
                                                    [ngClass]="{ 'is-invalid': submitted && f.atecoDivisione.errors}">
                                                    <option [ngValue]="atecoDivisione"
                                                        *ngFor="let atecoDivisione of listaAtecoDivisione">
                                                        {{atecoDivisione.descrizioneCompleta}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && f.atecoDivisione.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.atecoDivisione.errors.required">Campo obbligatorio
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 margin-bottom-10">
                                                <label>Classe</label>
                                                <select formControlName="atecoClasse" class="form-control form-select"
                                                    [ngClass]="{ 'is-invalid': submitted && f.atecoClasse.errors }">
                                                    <option [ngValue]="atecoClasse"
                                                        *ngFor="let atecoClasse of listaAtecoClasse">
                                                        {{atecoClasse.descrizioneCompleta}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && f.atecoClasse.errors" class="invalid-feedback">
                                                    <div *ngIf="f.atecoClasse.errors.required">Campo obbligatorio</div>
                                                </div>
                                            </div>
                                            <div class="col-6 margin-bottom-10">
                                                <label>Sottocategoria</label>
                                                <select formControlName="atecoSottocategoria"
                                                    class="form-control form-select"
                                                    [ngClass]="{ 'is-invalid': submitted && f.atecoSottocategoria.errors }">
                                                    <option [ngValue]="atecoSottocategoria"
                                                        *ngFor="let atecoSottocategoria of listaAtecoSottocategoria">
                                                        {{atecoSottocategoria.descrizioneCompleta}}
                                                    </option>
                                                </select>

                                            </div>

                                        </ng-container>
                                    </ng-container>

                                </div>

                                <!--  -->
                            </div>
                            <div class="col-3" *ngIf="idRuolo == 0">
                                <div class="row">
                                    <div class="col-12 d-flex align-items-center">
                                        <div>
                                            <label>Evoluzione</label>
                                            <select formControlName="evoluzioneLineaDiBusiness" value=""
                                                class="form-control form-select"
                                                [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.evoluzioneLineaDiBusiness.errors }"
                                                style="max-width:100%;" (change)="onChangeLinee(i)">
                                                <option [ngValue]="evoluzioneLineaDiBusiness"
                                                    *ngFor="let evoluzioneLineaDiBusiness of listaEvoluzioneLinea">
                                                    {{evoluzioneLineaDiBusiness.descrizione}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && lineaBusiness.controls.evoluzioneLineaDiBusiness.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="lineaBusiness.controls.evoluzioneLineaDiBusiness.errors.required">
                                                    Campo obbligatorio
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="idRuolo < 3 && !agricoltura"
                                [ngClass]="[idRuolo == 0 ? 'col-5' : agricoltura? 'col-6 offset-3 margin-top-10' :'col-6']">
                                <h3 class="text" style="text-align: center;">Volatilit&agrave; prospettiche settore
                                    merceologico &nbsp;&nbsp;
                                    <i id=" icon" class="fa fa-1x fa-info-circle" aria-hidden="true" container="body"
                                        placement="top" ngbTooltip="{{listaTips[10]?.descrizione}}"></i>
                                </h3>
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col" class="text-left"> % Ribasso
                                            </th>
                                            <th scope="col" class="text-left"> % Rialzo
                                            </th>
                                            <th scope="col" class="text-left" *ngIf="idRuolo == 0"> %
                                                Scarto&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                                                    aria-hidden="true" container="body" placement="top"
                                                    [ngbTooltip]="listaTips[5]?.descrizione"></i>
                                            </th>
                                            <th *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2"
                                                scope="col" class="text-left"> % Drift&nbsp;<i id="icon"
                                                    class="fa fa-1x fa-info-circle" aria-hidden="true" container="body"
                                                    placement="top" [ngbTooltip]="listaTips[6]?.descrizione"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr formGroupName="quantitaVenduta"
                                            *ngIf="idRuolo == 0 ||(this.lineeBusinessFormArray[i].controls.tipologiaAtt.value != null && lineaBusiness.controls.tipologiaAtt.value.id == 1)">
                                            <th scope="row" class="align-middle text-right"
                                                style="width: 25%;text-align: right;"
                                                *ngIf="!(this.lineeBusinessFormArray[i].controls.tipologiaAtt.value != null && lineaBusiness.controls.tipologiaAtt.value.id == 1)">
                                                Q.tà
                                                venduta:
                                            </th>
                                            <th scope="row" class="align-middle text-right"
                                                style="width: 25%;text-align: right;"
                                                *ngIf="(this.lineeBusinessFormArray[i].controls.tipologiaAtt.value != null && lineaBusiness.controls.tipologiaAtt.value.id == 1)">
                                                Resa:
                                            </th>
                                            <td><input formControlName="ribasso" type="number" class="form-control"
                                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.quantitaVenduta.controls.ribasso.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.quantitaVenduta.controls.ribasso.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.quantitaVenduta.controls.ribasso.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.quantitaVenduta.controls.ribasso.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.ribasso}}%
                                                    </div>
                                                </div>
                                            </td>
                                            <td><input formControlName="rialzo" type="number" class="form-control"
                                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.quantitaVenduta.controls.rialzo.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.quantitaVenduta.controls.rialzo.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.quantitaVenduta.controls.rialzo.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.quantitaVenduta.controls.rialzo.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.rialzo}}%
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="idRuolo == 0"><input formControlName="scarto" type="number"
                                                    class="form-control" min="0"
                                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.quantitaVenduta.controls.scarto.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.quantitaVenduta.controls.scarto.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.quantitaVenduta.controls.scarto.errors.required">
                                                        Campo obbligatorio</div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.quantitaVenduta.controls.scarto.errors.min || lineaBusiness.controls.quantitaVenduta.controls.scarto.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.scarto}}%</div>
                                                </div>
                                            </td>
                                            <td
                                                *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2">
                                                <input formControlName="drift" type="number" class="form-control"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.quantitaVenduta.controls.drift.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.quantitaVenduta.controls.drift.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.quantitaVenduta.controls.drift.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.quantitaVenduta.controls.drift.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.drift}}%
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr formGroupName="prezzoIniziale">
                                            <th scope="row" class="align-middle text-right"
                                                style="width: 25%;text-align: right;">Ricavi:
                                            </th>
                                            <td><input formControlName="ribasso" type="number" class="form-control"
                                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.prezzoIniziale.controls.ribasso.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.prezzoIniziale.controls.ribasso.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.prezzoIniziale.controls.ribasso.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.prezzoIniziale.controls.ribasso.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.ribasso}}%
                                                    </div>
                                                </div>
                                            </td>
                                            <td><input formControlName="rialzo" type="number" class="form-control"
                                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.prezzoIniziale.controls.rialzo.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.prezzoIniziale.controls.rialzo.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.prezzoIniziale.controls.rialzo.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.prezzoIniziale.controls.rialzo.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.rialzo}}%
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="idRuolo == 0"><input formControlName="scarto" type="number"
                                                    class="form-control" min="0.1"
                                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.prezzoIniziale.controls.scarto.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.prezzoIniziale.controls.scarto.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.prezzoIniziale.controls.scarto.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.prezzoIniziale.controls.scarto.errors.min || lineaBusiness.controls.prezzoIniziale.controls.scarto.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.scarto}}%</div>
                                                </div>
                                            </td>
                                            <td
                                                *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2">
                                                <input formControlName="drift" type="number" class="form-control"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.prezzoIniziale.controls.drift.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.prezzoIniziale.controls.drift.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.prezzoIniziale.controls.drift.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.prezzoIniziale.controls.drift.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.drift}}%
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr formGroupName="costoIniziale">
                                            <th scope="row" class="align-middle text-right"
                                                style="width: 25%;text-align: right;">Costi
                                            </th>
                                            <td><input formControlName="ribasso" type="number" class="form-control"
                                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.costoIniziale.controls.ribasso.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.costoIniziale.controls.ribasso.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.costoIniziale.controls.ribasso.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.costoIniziale.controls.ribasso.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.ribasso}}%
                                                    </div>
                                                </div>
                                            </td>
                                            <td><input formControlName="rialzo" type="number" class="form-control"
                                                    min="0" onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.costoIniziale.controls.rialzo.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.costoIniziale.controls.rialzo.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.costoIniziale.controls.rialzo.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.costoIniziale.controls.rialzo.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.rialzo}}%
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="idRuolo == 0"><input formControlName="scarto" type="number"
                                                    class="form-control" min="0"
                                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.costoIniziale.controls.scarto.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.costoIniziale.controls.scarto.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.costoIniziale.controls.scarto.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.costoIniziale.controls.scarto.errors.min || lineaBusiness.controls.costoIniziale.controls.scarto.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.scarto}}%</div>
                                                </div>
                                            </td>
                                            <td
                                                *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 2">
                                                <input formControlName="drift" type="number" class="form-control"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.costoIniziale.controls.drift.errors }" />
                                                <div *ngIf=" submitted && lineaBusiness.controls.costoIniziale.controls.drift.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.costoIniziale.controls.drift.errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                    <div
                                                        *ngIf="lineaBusiness.controls.costoIniziale.controls.drift.errors.max">
                                                        Parametro fuori
                                                        scala. Inserire
                                                        un valore compreso tra 0 e {{valoreMax.drift}}%
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="col-1 margin-bottom-10 d-flex justify-content-center align-items-center"
                                style="padding: 0px;">
                                <button class="btn fa fa-trash fa-lg pointer" (click)="EliminaLineaDiBusiness(i)"
                                    aria-hidden="true" type="button" placement="top"
                                    ngbTooltip="Elimina Linea di Business"></button>
                            </div> -->
                            <div class="col-5"
                                *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 1">
                            </div>
                            <div class="col-7 d-flex justify-content-center"
                                *ngIf="this.lineeBusinessFormArray[i].controls['evoluzioneLineaDiBusiness'].value.id == 1">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-8  warning-label" style="text-align:center;">
                                        <p>
                                            <strong>Attenzione : </strong>sarà necessario configurare il
                                            valore di <strong>Drift</strong> nella Configurazione del Business
                                            Plan
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row margin-top-10" style="margin-left: 0px;margin-right: 0px;">
                            <div class="col-3 flex-center-left" *ngIf="!richiedenteTemp1.isStartUp && !agricoltura">
                                <input type="checkbox" class="checkbox-m"
                                    formControlName="lineaEsistente" (change)="onChangeEsistente(i)"/>&nbsp;&nbsp;Linea di business in attivit&agrave;
                            </div>
                            <div class="col-2" *ngIf="!agricoltura && (richiedenteTemp1.isStartUp || !lineaBusiness.controls.lineaEsistente.value)">
                                <label>Anno inizio attivit&agrave;</label>
                                <input formControlName="annoInizio" type="number" class="form-control"
                                [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.annoInizio.errors }"
                                (change)="onChangeAnnoInizio(i)"/>
                                <div *ngIf="submitted && lineaBusiness.controls.annoInizio.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="lineaBusiness.controls.annoInizio.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            </div>
                            <div class="col-2" *ngIf="!agricoltura && (richiedenteTemp1.isStartUp || !lineaBusiness.controls.lineaEsistente.value)">
                                <label>Trimestre inizio attivit&agrave;</label>
                                <select formControlName="trimestreInizio"
                                    class="form-control form-select"
                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.trimestreInizio.errors }">
                                    <option [ngValue]="trimestre.id"
                                    *ngFor="let trimestre of trimestreList;let j=index">
                                    {{trimestre.descrizione}}
                                </option>
                                </select>
                                <div *ngIf="submitted && lineaBusiness.controls.trimestreInizio.errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="lineaBusiness.controls.trimestreInizio.errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row margin-top-10" style="margin-left: 0px;margin-right: 0px">
                            <div class="col-5 col-xl-4">


                                <h3>Distribuzione delle vendite (%) &nbsp;&nbsp;<i id="icon"
                                        class="fa fa-1x fa-info-circle margin-right-10" aria-hidden="true"
                                        placement="top" container="body"
                                        [ngbTooltip]="listaTips[9]?.descrizione"></i>&nbsp;&nbsp;</h3>

                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">1° Quarter</th>
                                            <th scope="col">2° Quarter</th>
                                            <th scope="col">3° Quarter</th>
                                            <th scope="col">4° Quarter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr formArrayName="percTrimVenduto">

                                            <td
                                                *ngFor="let trim of lineaBusiness.controls.percTrimVenduto.controls;let j =index">
                                                <input [formControlName]="j" type="number" class="form-control"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.percTrimVenduto.controls[j].errors }"
                                                    onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                                <div *ngIf="submitted && lineaBusiness.controls.percTrimVenduto.controls[j].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.percTrimVenduto.controls[j].errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>

                                <div class="row">
                                    <div *ngIf="!sommaCentoVenduto[i]" class="errorePerc">
                                        La somma delle percentuali trimestrali deve essere pari al 100%
                                    </div>
                                </div>

                            </div>
                            <div class="col-7 col-xl-8" *ngIf="!agricoltura && !richiedenteTemp1.isStartUp && lineaBusiness.controls.lineaEsistente.value">
                                <div class="row" style="margin-left: 0px; margin-right: 0px">
                                    <div class="col-4 col-xl-6">
                                        <h3 class="pull-right">Distribuzione costi fissi aziendali &nbsp;&nbsp;<i
                                                id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                                                placement="top" container="body"
                                                [ngbTooltip]="listaTips[9]?.descrizione"></i>&nbsp;&nbsp;</h3>
                                    </div>
                                    <div class="col-8 col-xl-6 nopadding">
                                        <div class="row font-tabella" style="margin: 0px">
                                            <div class="col-4"> Complessivo</div>
                                            <div class="col-4"> Assegnato (%)</div>
                                            <div class="col-4"> Da assegnare (%)</div>

                                        </div>
                                    </div>

                                </div>
                                <div class="row margin-top-10 align-items-center"
                                    style="margin-left: 0px; margin-right: 0px">
                                    <div class="col-4 col-xl-6 font-tabella"><span class="pull-right">Valore complessivo
                                            (da ultimo bilancio)</span></div>
                                    <div class="col-8 col-xl-6 nopadding">
                                        <div class="row" style="margin: 0px">
                                            <div class="col-4">
                                                <input type="text" class="form-control" currencyMask min="0"
                                                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                                    [(ngModel)]="costiFissi" [ngModelOptions]="{standalone: true}"
                                                    [disabled]="true" />
                                            </div>
                                            <div class="col-4">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="percTotCostiFissi"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="true" />
                                            </div>
                                            <div class="col-4">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="costiFissiAssegnare"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="true" />

                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div class="row margin-top-10 align-items-center"
                                    style="margin-left: 0px; margin-right: 0px">
                                    <div class="col-4 col-xl-6 font-tabella"><span class="pull-right">% assegnata alla
                                            linea di business</span></div>
                                    <div class="col-8 col-xl-6 nopadding">
                                        <div class="row" style="margin: 0px">
                                            <div class="col-4">
                                                <input type="number" class="form-control"
                                                    (change)="onChangeCostiFissi(i)" formControlName="percCostiFissi"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.percCostiFissi.errors }"
                                                    onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                            </div>
                                            <div class="col-4"></div>
                                            <div class="col-4"></div>

                                        </div>
                                    </div>

                                </div>
                                <div class="row margin-top-10  margin-bottom-10 align-items-center"
                                    style="margin-left: 0px; margin-right: 0px">
                                    <div class="col-4 col-xl-6 font-tabella"><span class="pull-right">Costi assegnati
                                            alla LDB</span></div>
                                    <div class="col-8 col-xl-6 nopadding">
                                        <div class="row" style="margin: 0px">
                                            <div class="col-4">
                                                <input type="text" class="form-control" currencyMask min="0"
                                                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                                                    [(ngModel)]="costiFissiLinee[i]"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="true" />
                                            </div>
                                            <div class="col-4"></div>
                                            <div class="col-4"></div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-7 col-xl-8" *ngIf="!agricoltura && (richiedenteTemp1.isStartUp || !lineaBusiness.controls.lineaEsistente.value)">
                                <div class="row" style="margin-left: 0px; margin-right: 0px">
                                    <h3>Latenza produttiva &nbsp;&nbsp;<i id="icon"
                                        class="fa fa-1x fa-info-circle margin-right-10" aria-hidden="true"
                                        placement="top" container="body"
                                        [ngbTooltip]="listaTips[9]?.descrizione"></i>&nbsp;&nbsp;</h3>

                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">1° Quarter</th>
                                            <th scope="col">2° Quarter</th>
                                            <th scope="col">3° Quarter</th>
                                            <th scope="col">4° Quarter</th>
                                            <th scope="col">5° Quarter</th>
                                            <th scope="col">6° Quarter</th>
                                            <th scope="col">7° Quarter</th>
                                            <th scope="col">8° Quarter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr formArrayName="latenza">

                                            <td
                                                *ngFor="let lat of lineaBusiness.controls.latenza.controls;let j =index">
                                                <input [formControlName]="j" type="number" class="form-control"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.latenza.controls[j].errors }"
                                                    (change)="onChangeLatenza(i,j)"/>
                                                <div *ngIf="submitted && lineaBusiness.controls.latenza.controls[j].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.latenza.controls[j].errors.required">
                                                        Campo
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
</div>
                            </div>

                        </div>

                        <!--  -->
                        <div class="row margin-top-20" *ngIf="!agricoltura">

                            <div
                                class="col-5 col-xl-3  d-flex align-items-center justify-content-start margin-bottom-20">
                                <label class="text-left margin-top-10">Si prevede
                                    la gestione delle scorte?&nbsp;&nbsp;<i id="icon"
                                        class="fa fa-1x fa-info-circle margin-right-10" aria-hidden="true"
                                        placement="top" container="body"
                                        [ngbTooltip]="listaTips[9]?.descrizione"></i>&nbsp;&nbsp;
                                </label>
                                <select formControlName="presenzaMagazzino" type="boolean"
                                    class="form-control form-select" style="max-width: fit-content;"
                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.presenzaMagazzino.errors }">
                                    <option [ngValue]="true">Si</option>
                                    <option [ngValue]="false">No</option>
                                </select>
                            </div>

                            <div class="col-5 col-xl-4"
                                *ngIf="this.lineeBusinessFormArray[i].controls.presenzaMagazzino.value">

                                <h3>Modalit&agrave; di reintegro delle scorte (%) &nbsp;&nbsp;<i id="icon"
                                        class="fa fa-1x fa-info-circle margin-right-10" aria-hidden="true"
                                        placement="top" container="body"
                                        [ngbTooltip]="listaTips[9]?.descrizione"></i>&nbsp;&nbsp;</h3>

                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">1° Quarter</th>
                                            <th scope="col">2° Quarter</th>
                                            <th scope="col">3° Quarter</th>
                                            <th scope="col">4° Quarter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr formArrayName="percTrimMagaz">

                                            <td
                                                *ngFor="let trim of lineaBusiness.controls.percTrimMagaz.controls;let j =index">
                                                <input [formControlName]="j" type="number" class="form-control"
                                                    [ngClass]="{ 'is-invalid':submitted && lineaBusiness.controls.percTrimMagaz.controls[j].errors }"
                                                    onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                                <div *ngIf="submitted && lineaBusiness.controls.percTrimMagaz.controls[j].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="lineaBusiness.controls.percTrimMagaz.controls[j].errors.required">
                                                        Campo obbligatorio
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                                <div class="row">
                                    <div *ngIf="!sommaCentoMagaz[i]" class="errorePerc">
                                        La somma delle percentuali trimestrali deve essere pari al 100%
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

</div>