import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCaretUp, faCaretDown, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BilancioService } from 'src/app/_services/bilancio.service';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { DebitoBp, SituazioneDebitoria, suddivisioneAffidamenti, suddivisioneDebito } from 'src/app/interface/situazione-debitoria';
import { ToolTip } from 'src/app/interface/toolTip';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';

@Component({
  selector: 'app-situazione-debitoria-ambiente',
  templateUrl: './situazione-debitoria-ambiente.component.html',
  styleUrls: ['./situazione-debitoria-ambiente.component.css']
})
export class SituazioneDebitoriaAmbienteComponent implements OnInit, OnDestroy {

  @Input() debitiformGroup: FormGroup
  @Input() get idRichiedenteTemp(): number {
    return this._idRichiedenteTemp;
  }
  set idRichiedenteTemp(idRichiedenteTemp: number) {
    if (idRichiedenteTemp != null) {
      this._idRichiedenteTemp = idRichiedenteTemp;
      this.ngOnInit()
    }
  }
  @Input() bilancioAbbreviato: boolean

  private _idRichiedenteTemp: number;

  submitted: boolean = false

  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;

  debitoSuddiviso: boolean[]

  tipsSubscription: Subscription

  erroreSommaTotale: boolean = false //per bilancio abbreviato dove non ho i campi già impostati
  erroreSommaParziale: boolean[];
  situazioneDebitoriaIsValid: boolean = true

  tipoDebitoList: number[] = [];
  situazioneDebitoria: SituazioneDebitoria
  totaleDebiti = 0;
  anniBilancio = 0;
  debitiDirty: boolean = false
  openCheckBoxComponent = false
  listaMesi: Date[] = [];
  listaTipoDebitoBean: BeanStatico[] = []
  currentAnno = new Date().getFullYear()
  listaTips: ToolTip[] = [];
  nomeDebito: string[] = []

  valoreMin: any = {};

  mesiList: Date[]
  listaNumeroRate: number[]
  tipoRateList: BeanStatico[]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private confService: ConfigurazioneBusinessPlanService,
    private modalService: NgbModal,
    private toolTipService: ToolTipService,
    private bilancioService: BilancioService,
    private tokenService: TokenStorageService
  ) {

  }

  get f() { return this.debitiformGroup.controls }
  get debitiArray(): UntypedFormArray { return <UntypedFormArray> this.f.arrayDebitoria; }
  get debitiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.debitiArray.controls; }

  ngOnInit(): void {
    this.listaMesi = []
    this.listaNumeroRate = []
    this.listaNumeroRate = []
    for (let i = 0; i < 12; i++) {
      this.listaMesi.push(new Date(this.currentAnno, i))
    }
    for (let i = 0; i < 500; i++) {
      this.listaNumeroRate.push(i + 1)
    }

    this.debitiformGroup = this.formBuilder.group({
      arrayDebitoria: this.formBuilder.array([])
    })
    if (this.bilancioAbbreviato) {
      //importo la lista debiti vergine dal service
      this.confService.getListaDebiti().subscribe(list => {
        this.listaTipoDebitoBean = [];
        list.forEach(item => {
          this.listaTipoDebitoBean.push({ id: item.id, descrizione: item.descrizione, checked: false })
        })
      })
    }
    this.configurazioneAmbienteService.getValoriMax('situazione debitoria').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMin[element.nomeCampo] = element.valoreMax;
        }

      })
    })
    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })
    this.configurazioneAmbienteService.getListaTipoRate().subscribe(list => {
      this.tipoRateList = list
      this.getSituazioneDebitoria();
    })

  }


  getSituazioneDebitoria() {
    this.configurazioneAmbienteService.getSituazioneDebitoria(this.idRichiedenteTemp).subscribe(response => {
      this.debitoSuddiviso = [];
      this.nomeDebito = [];
      this.debitiArray.clear()
      this.tipoDebitoList = []
      this.erroreSommaParziale = []
      if (response.error == null) {
        this.situazioneDebitoria = response;
        this.anniBilancio = this.situazioneDebitoria.anno
        this.totaleDebiti = this.situazioneDebitoria.debitoTot;
        if (this.totaleDebiti != 0) {
          if (this.situazioneDebitoria.debito.length > 0) {
            this.situazioneDebitoria.debito.forEach((debito, j) => {
              this.nomeDebito.push(debito.nome)
              this.tipoDebitoList.push(debito.idTipoDebito)
              let suddivisioneDebitoForm: UntypedFormGroup[] = [];
              let suddivisioneAutoliqForm: UntypedFormGroup[] = [];
              let suddivisioneRevocaForm: UntypedFormGroup[] = [];
              this.debitoSuddiviso.push(false)
              this.erroreSommaParziale.push(false)
              if (debito.debitiList && debito.debitiList.length > 0) {
                this.debitoSuddiviso.splice(j, 1, true)
                debito.debitiList.forEach(dettaglio => {
                  //gestire tutte le select
                  let tipoRata: BeanStatico;
                  this.tipoRateList.forEach(tipo => {
                    if (tipo.id == dettaglio.idTipoRata) {
                      tipoRata = tipo
                    }
                  })
                  suddivisioneDebitoForm.push(this.formBuilder.group({
                    descrizione: [dettaglio.descrizione, Validators.required], //anche a scandenza
                    conversEquity:[dettaglio.conversEquity],
                    importo: [dettaglio.importo, Validators.required], //anche a scandenza
                    importoRinegoziato: [dettaglio.importoRinegoziato, this.tipoDebitoList[j] != 4 && !dettaglio.conversEquity ? Validators.required : null],
                    numeroRate: [dettaglio.numeroRate, !dettaglio.conversEquity ? Validators.required : null], //anche a scandenza
                    annoInizioRimborso: [dettaglio.annoInizioRimborso, Validators.required],
                    meseInizioRimborso: [dettaglio.idMeseInizioRimborso, Validators.required], //anche a scandenza
                    tipologiaRata: [tipoRata, !dettaglio.conversEquity ?  Validators.required : null], //anche a scandenza
                    //banche
                  }))
                })
              }
              if (this.tipoDebitoList[j] == 4) {
                if (debito.affidamentiList && debito.affidamentiList.length > 0) {
                  this.debitoSuddiviso.splice(j, 1, true)
                  debito.affidamentiList.forEach(affidamenti => {
                    if (affidamenti.idTipoAffidamento == 1) {
                      let residuo = affidamenti.accordato - affidamenti.utilizzato
                      suddivisioneAutoliqForm.push(this.formBuilder.group({
                        autoliqDescrizione: [affidamenti.descrizione, Validators.required],
                        autoliqUtilizzato: [affidamenti.utilizzato, Validators.required],
                        autoliqAccordato: [affidamenti.accordato, Validators.required],
                        autoliqRimanenze: [{ value: residuo, disabled: true }, Validators.required],
                      }))
                    } else {
                      let residuo = affidamenti.accordato - affidamenti.utilizzato
                      suddivisioneRevocaForm.push(this.formBuilder.group({
                        revocaDescrizione: [affidamenti.descrizione, Validators.required],
                        revocaUtilizzato: [affidamenti.utilizzato, Validators.required],
                        revocaAccordato: [affidamenti.accordato, Validators.required],
                        revocaRimanenze: [{ value: residuo, disabled: true }, Validators.required],
                      }))
                    }

                  })
                }
              }
              this.debitiArray.push(this.formBuilder.group({
                totale: [{ value: debito.totale, disabled: !this.bilancioAbbreviato }, Validators.required],
                entroEsercizio: [{ value: debito.entroEsercizio, disabled: true }, Validators.required],
                oltreEsercizio: [{ value: debito.oltreEsercizio, disabled: true }, Validators.required],
                suddivisioneAutoliq: this.formBuilder.array(suddivisioneAutoliqForm),
                suddivisioneRevoca: this.formBuilder.array(suddivisioneRevocaForm),
                suddivisioneDebiti: this.formBuilder.array(suddivisioneDebitoForm),

              }))
            })

          }
        }
      }
    })
  }


  aggiornaListaCheckBox() {
    //serve a tenere sempre aggiornata la lista dei debiti che l'utente inserisce

    if (this.openCheckBoxComponent == false) {
      for (let i = 0; i < this.listaTipoDebitoBean.length; i++) {
        this.listaTipoDebitoBean[i].checked = false;// setto sempre il checked della lista a false all 'inizio poi porto a true solo quelli che
        //soddisfano la cond (mi evita di mettere l'else a riga 393 che rompe la logica)
        if (this.tipoDebitoList.length > 0) {
          for (let j = 0; j < this.tipoDebitoList.length; j++) {
            if (this.listaTipoDebitoBean[i].id == this.tipoDebitoList[j]) {
              this.listaTipoDebitoBean[i].checked = true;
            }
          }
        }
      }
    }
    this.openCheckBoxComponent = !this.openCheckBoxComponent
  }
  ModificaListaDebiti(listaDebitiNew: BeanStatico[]) {// se cambio il numero di debiti devo aggiornare l'array
    this.debitiformGroup.markAsDirty()
    let suddivisioneDebitoForm: UntypedFormGroup[] = [];
    let suddivisioneAutoliqForm: UntypedFormGroup[] = [];
    let suddivisioneRevocaForm: UntypedFormGroup[] = [];

    if (this.debitiArray.length > 0) {
      for (let i = 0; i < listaDebitiNew.length; i++) {
        if (listaDebitiNew[i].checked) {

          //includes controlla se è presente un elemento di listaDebitiNew[i] senza ciclare su tipoDebitoList
          if (!this.tipoDebitoList.includes(listaDebitiNew[i].id)) {
            suddivisioneDebitoForm = [];
            suddivisioneAutoliqForm = [];
            suddivisioneRevocaForm = [];
            suddivisioneDebitoForm.push(this.formBuilder.group({
              descrizione: ['', Validators.required], //anche a scandenza
              conversEquity: [false],
              importo: ['0', Validators.required], //anche a scandenza
              importoRinegoziato: ['0', Validators.required],
              numeroRate: [1, Validators.required], //anche a scandenza
              annoInizioRimborso: ['', Validators.required], 
              meseInizioRimborso: ['', Validators.required], //anche a scandenza
              tipologiaRata: ['', Validators.required], //anche a scandenza
            }));
            if(listaDebitiNew[i].id == 4){
              suddivisioneDebitoForm[0].controls.importoRinegoziato.clearValidators();
              suddivisioneAutoliqForm.push(this.formBuilder.group({
                autoliqDescrizione: ['', Validators.required],
                autoliqUtilizzato: ['', Validators.required],
                autoliqAccordato: ['', Validators.required],
                autoliqRimanenze: [{ value: '', disabled: true }, Validators.required],
              }))
              suddivisioneRevocaForm.push(this.formBuilder.group({
                revocaDescrizione: ['', Validators.required],
                revocaUtilizzato: ['', Validators.required],
                revocaAccordato: ['', Validators.required],
                revocaRimanenze: [{ value: '', disabled: true }, Validators.required],
              }))
            }
            this.nomeDebito.push(listaDebitiNew[i].descrizione)
            this.erroreSommaParziale.push(false);
            this.debitoSuddiviso.push(true);
            this.debitiArray.push(this.formBuilder.group({
              totale: ['0', Validators.required],
              entroEsercizio: ['0'],
              oltreEsercizio: ['0'],
              suddivisioneAutoliq: this.formBuilder.array(suddivisioneAutoliqForm),
              suddivisioneRevoca: this.formBuilder.array(suddivisioneRevocaForm),
              suddivisioneDebiti: this.formBuilder.array(suddivisioneDebitoForm),
            }))
            this.tipoDebitoList.push(listaDebitiNew[i].id)

          }
          //eliminare pezzi
        } else {
          let arr = []
          //arr contiene gli elementi dell'Array che soddisfano la condizione del filter
          //in questo caso avrà sempre solo un elmento perchè la condizione è su un id
          arr = this.tipoDebitoList.filter(obj => { return obj == listaDebitiNew[i].id })
          if (arr.length > 0) {
            this.nomeDebito.splice(this.tipoDebitoList.indexOf(arr[0]), 1);
            this.erroreSommaParziale.splice(this.tipoDebitoList.indexOf(arr[0]), 1);
            this.debitoSuddiviso.splice(this.tipoDebitoList.indexOf(arr[0]), 1);
            /*  this.arrayErroriDebiti.sommaParziale.splice(this.tipoDebitoList.indexOf(arr[0]), 1)
             this.abilitaModificaDebitoOltre.splice(this.tipoDebitoList.indexOf(arr[0]), 1)
             this.abilitaModificaDebitoEntro.splice(this.tipoDebitoList.indexOf(arr[0]), 1)
             this.suddivisioneDebitiEntro.splice(this.tipoDebitoList.indexOf(arr[0]), 1);
             this.isConfermatoDebitoEntro.splice(this.tipoDebitoList.indexOf(arr[0]), 1)
             this.suddivisioneDebitiOltre.splice(this.tipoDebitoList.indexOf(arr[0]), 1);
             this.isConfermatoDebitoOltre.splice(this.tipoDebitoList.indexOf(arr[0]), 1) */
            this.debitiArray.removeAt(this.tipoDebitoList.indexOf(arr[0]));
            this.debitiArray.updateValueAndValidity()
            this.tipoDebitoList.splice(this.tipoDebitoList.indexOf(arr[0]), 1);
          }
        }
      }
    } else {
      //caso t=0  form vuoto (devo pushare tutto da 0)
      listaDebitiNew.forEach(debito => {
        if (debito.checked) {
          suddivisioneDebitoForm = [];
          suddivisioneAutoliqForm = [];
          suddivisioneRevocaForm = [];
          suddivisioneDebitoForm.push(this.formBuilder.group({
            descrizione: ['', Validators.required], //anche a scandenza
            conversEquity: [false],
            importo: ['0', Validators.required], //anche a scandenza
            importoRinegoziato: ['0', Validators.required],
            numeroRate: [1, Validators.required], //anche a scandenza
            annoInizioRimborso: ['', Validators.required], 
            meseInizioRimborso: ['', Validators.required], //anche a scandenza
            tipologiaRata: ['', Validators.required], //anche a scandenza
          }));
          if(debito.id == 4){
            suddivisioneDebitoForm[0].controls.importoRinegoziato.clearValidators();
            suddivisioneAutoliqForm.push(this.formBuilder.group({
              autoliqDescrizione: ['', Validators.required],
              autoliqUtilizzato: ['', Validators.required],
              autoliqAccordato: ['', Validators.required],
              autoliqRimanenze: [{ value: '', disabled: true }, Validators.required],
            }));
            suddivisioneRevocaForm.push(this.formBuilder.group({
              revocaDescrizione: ['', Validators.required],
              revocaUtilizzato: ['', Validators.required],
              revocaAccordato: ['', Validators.required],
              revocaRimanenze: [{ value: '', disabled: true }, Validators.required],
            }));
          }
          this.nomeDebito.push(debito.descrizione)
          this.tipoDebitoList.push(debito.id)
          this.erroreSommaParziale.push(false);
          this.debitoSuddiviso.push(true);
          //errori liste
          this.debitiArray.push(this.formBuilder.group({
            totale: ['0', Validators.required],
            entroEsercizio: ['0'],
            oltreEsercizio: ['0'],
            suddivisioneAutoliq: this.formBuilder.array(suddivisioneAutoliqForm),
            suddivisioneRevoca: this.formBuilder.array(suddivisioneRevocaForm),
            suddivisioneDebiti: this.formBuilder.array(suddivisioneDebitoForm),
          })
          )
        }
      })
    }

    this.openCheckBoxComponent = false;
    //alla fine bisogna ri aggiornare la lista delle checkbox (forse anche l'idTipoDebito) e tutti gli array di errore

  }

  isDebitiValid(): boolean {
    let sommaTot = 0
    let sommaParzialeIsValid = true;
    this.situazioneDebitoriaIsValid = true
    let debitiFormGroupValid = true;
    for (let i = 0; i < this.debitiFormArray.length; i++) {
      sommaTot += +this.debitiFormArray[i].controls.totale.value
      let suddivisioneDebitiGroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
      let sommaParziale = 0
      //controllo somma parziale
      suddivisioneDebitiGroup.forEach(debitoForm => {
        sommaParziale += +debitoForm.controls.importo.value
        debitoForm.updateValueAndValidity()

        debitiFormGroupValid = debitiFormGroupValid && debitoForm.valid
      })
      if (this.tipoDebitoList[i] == 4) {

        let suddivisioneAutoliqGroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneAutoliq).controls
        let suddivisioneRevocaGroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneRevoca).controls
        suddivisioneAutoliqGroup.forEach(autoliqForm => {
          sommaParziale += +autoliqForm.controls.autoliqUtilizzato.value
          debitiFormGroupValid = debitiFormGroupValid && autoliqForm.valid
        })
        suddivisioneRevocaGroup.forEach(revocaForm => {
          sommaParziale += +revocaForm.controls.revocaUtilizzato.value
          debitiFormGroupValid = debitiFormGroupValid && debitiFormGroupValid
        })

      }
      if (this.debitoSuddiviso[i] && sommaParziale != +this.debitiFormArray[i].controls.totale.value) {
        this.erroreSommaParziale.splice(i, 1, true)
      } else {
        this.erroreSommaParziale.splice(i, 1, false)
      }

      sommaParzialeIsValid = sommaParzialeIsValid && !this.erroreSommaParziale[i]

    }
    //somma importo debit==totale (per debiti bilancio abbreviato dove li inserisce lui manualmente)
    if (sommaTot != this.totaleDebiti) {
      this.erroreSommaTotale = true
    } else {
      this.erroreSommaTotale = false
    }

    return this.situazioneDebitoriaIsValid = !this.erroreSommaTotale && sommaParzialeIsValid && debitiFormGroupValid
  }
  annullaDebiti() {
    if (this.debitiformGroup.dirty) {
      this.submitted = false;
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Cliccando su 'Annulla' tutte le modifiche non salvate su questa pagina andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.situazioneDebitoriaIsValid = true
          this.openCheckBoxComponent = false;
          this.debitiformGroup.reset();
          this.getSituazioneDebitoria();
          this.markFormDebitoPristine()
        } else { this.submitted = true }
      })
    }
  }

  markFormDebitoPristine() {
    this.debitiformGroup.markAsPristine()
    this.debitiDirty = false
  }

  suddividiDebito(index: number) {
    this.debitiformGroup.markAsDirty()
    this.debitoSuddiviso.splice(index, 1, true)
    this.debitiFormArray[index].controls.entroEsercizio.clearValidators()
    this.debitiFormArray[index].controls.oltreEsercizio.clearValidators()
    if (this.tipoDebitoList[index] == 4) {
      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).controls.push(this.formBuilder.group({
        autoliqDescrizione: ['', Validators.required],
        autoliqUtilizzato: ['0', Validators.required],
        autoliqAccordato: ['0', Validators.required],
        autoliqRimanenze: [{ value: '0', disabled: true }, Validators.required],
      }));

      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).controls.push(this.formBuilder.group({
        revocaDescrizione: ['', Validators.required],
        revocaUtilizzato: ['0', Validators.required],
        revocaAccordato: ['0', Validators.required],
        revocaRimanenze: [{ value: '0', disabled: true }, Validators.required],
      }))
    }
    (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).controls.push(this.formBuilder.group({
      descrizione: ['', Validators.required], //anche a scandenza
      conversEquity: [false],
      importo: ['0', Validators.required], //anche a scandenza
      importoRinegoziato: ['0', this.tipoDebitoList[index] == 4 ? null : Validators.required],
      numeroRate: [1, Validators.required], //anche a scandenza
      annoInizioRimborso: ['', Validators.required],
      meseInizioRimborso: ['', Validators.required], //anche a scandenza
      tipologiaRata: ['', Validators.required],
    }))
    this.debitiFormArray[index].controls.entroEsercizio.updateValueAndValidity()
    this.debitiFormArray[index].controls.oltreEsercizio.updateValueAndValidity()
  }

  //modifica importo rinegoziato
  setValoreRinegoziatoDebito(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
    if (formgroup[j].controls.importo.value != null) {
      formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
    } else {
      formgroup[j].controls.importoRinegoziato.setValue('0')
    }
  }
  // modifica il residuo in base a cosa cambio in accordato e utilizzato
  setResiduoAutoliquidante(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneAutoliq).controls
    if (formgroup[j].controls.autoliqAccordato.value != null && formgroup[j].controls.autoliqUtilizzato.value != null) {
      formgroup[j].controls.autoliqRimanenze.setValue(parseFloat(formgroup[j].controls.autoliqAccordato.value) - parseFloat(formgroup[j].controls.autoliqUtilizzato.value))
    }
  }
  setResiduoRevoca(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneRevoca).controls
    if (formgroup[j].controls.revocaAccordato.value != null && formgroup[j].controls.revocaUtilizzato.value != null) {
      formgroup[j].controls.revocaRimanenze.setValue(parseFloat(formgroup[j].controls.revocaAccordato.value) - parseFloat(formgroup[j].controls.revocaUtilizzato.value))
    }
  }

  onChangeAnno(i: number, j: number){
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
    if (formgroup[j].controls.annoInizioRimborso.value != null && formgroup[j].controls.annoInizioRimborso.value != "" && formgroup[j].controls.annoInizioRimborso.value <= this.anniBilancio) {
      formgroup[j].controls.annoInizioRimborso.setValue(this.anniBilancio +1)
    }
  }

  aggiungiAutoliq(index: number) {
    this.debitiformGroup.markAsDirty();
    (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).controls.push(this.formBuilder.group({
      autoliqDescrizione: ['', Validators.required],
      autoliqUtilizzato: ['0', Validators.required],
      autoliqAccordato: ['0', Validators.required],
      autoliqRimanenze: [{ value: '0', disabled: true }, Validators.required],
    }));
  }
  aggiungiRevoca(index: number) {
    this.debitiformGroup.markAsDirty();
    (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).controls.push(this.formBuilder.group({
      revocaDescrizione: ['', Validators.required],
      revocaUtilizzato: ['0', Validators.required],
      revocaAccordato: ['0', Validators.required],
      revocaRimanenze: [{ value: '0', disabled: true }, Validators.required],
    }))
  }
  aggiungiDebito(index: number) {
    this.debitiformGroup.markAsDirty();
    (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).controls.push(this.formBuilder.group({
      descrizione: ['', Validators.required], //anche a scandenza
      conversEquity: [false],
      importo: ['0', Validators.required], //anche a scandenza
      importoRinegoziato: ['0', this.tipoDebitoList[index] == 4 ? null : Validators.required],
      numeroRate: [1, Validators.required], //anche a scandenza
      annoInizioRimborso: ['', Validators.required],
      meseInizioRimborso: ['', Validators.required], //anche a scandenza
      tipologiaRata: ['', Validators.required],
    }))
  }



  eliminaDebito(index: number, index2: number) {
    this.debitiformGroup.markAsDirty();
    if ((<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).length > 1) {
      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).removeAt(index2)
    }
  }
  eliminaRevoca(index: number, index2: number) {
    this.debitiformGroup.markAsDirty();
    if ((<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).length > 1) {
      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).removeAt(index2)
    }
  }
  eliminaAutoliq(index: number, index2: number) {
    this.debitiformGroup.markAsDirty();
    if ((<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).length > 1) {
      (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).removeAt(index2)
    }
  }



  valoreRinegoziatoMax(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[i].controls.suddivisioneDebiti).controls
    if (formgroup[j].controls.importo.value != null) {
      if (formgroup[j].controls.importoRinegoziato.value > formgroup[j].controls.importo.value)
        formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
    } else {
      formgroup[j].controls.importoRinegoziato.setValue('0')
    }
  }


  onSubmitDebiti() {
    this.submitted = true
    if (this.isDebitiValid()) {
      if (this.debitoSuddiviso.includes(false)) {
        const modalRef = this.modalService.open(ModalcontentComponent, {
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
          name: "salvamodifiche",
          description: "Alcuni Debiti non sono stati suddivisi. Si ricorda senza una configurazione" +
            " ambiente completa non sarà possibile generare Business Plan per l'azienda in esame. Salvare lo stesso?",
          actionButtonText: "Salva",
          undoButtonText: "Annulla",
        }
        modalRef.result.then(r => {
          if (r) {
            this.salvaDebiti()
          }
          /* intestazione verde */
        })
      } else {
        this.salvaDebiti()
      }
    } else {
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "errore",
        description: "Ricontrollare la scomposizione dei debiti.",
        undoButtonText: "Ok",
      }
    }


  }

  salvaDebiti() {
    let request: SituazioneDebitoria;
    let listDebiti: DebitoBp[] = []
    for (let index = 0; index < this.debitiFormArray.length; index++) {
      let suddivisioneDebitoForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).controls;
      let listaDettaglioDebito: suddivisioneDebito[] = []
      let listaAffidamenti: suddivisioneAffidamenti[] = []
      for (let j = 0; j < suddivisioneDebitoForm.length; j++) {
        listaDettaglioDebito.push({
          id: 0,
          idRichiedente: this.idRichiedenteTemp,
          idUtente: this.tokenService.getUser().idUtente,
          idTipoDebito: this.tipoDebitoList[index],
          idTipoRata: suddivisioneDebitoForm[j].controls.tipologiaRata.value.id,
          idPassivo: this.situazioneDebitoria.idPassivo,
          descrizione: suddivisioneDebitoForm[j].controls.descrizione.value,
          conversEquity: suddivisioneDebitoForm[j].controls.conversEquity.value,
          importo: suddivisioneDebitoForm[j].controls.importo.value,
          importoRinegoziato: suddivisioneDebitoForm[j].controls.importoRinegoziato.value,
          numeroRate: suddivisioneDebitoForm[j].controls.numeroRate.value,
          idMeseInizioRimborso: suddivisioneDebitoForm[j].controls.meseInizioRimborso.value,
          annoInizioRimborso: suddivisioneDebitoForm[j].controls.annoInizioRimborso.value,
        })
      }

      if (this.tipoDebitoList[index] == 4) {
        let suddivisionRevocaForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneRevoca).controls;
        let suddivisioneAutoliqForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneAutoliq).controls;
        for (let j = 0; j < suddivisionRevocaForm.length; j++) {
          listaAffidamenti.push({
            id: 0,
            idRichiedente: this.idRichiedenteTemp,
            idUtente: this.tokenService.getUser().idUtente,
            idTipoAffidamento: 2,
            idPassivo: this.situazioneDebitoria.idPassivo,
            descrizione: suddivisionRevocaForm[j].controls.revocaDescrizione.value,
            accordato: suddivisionRevocaForm[j].controls.revocaAccordato.value,
            utilizzato: suddivisionRevocaForm[j].controls.revocaUtilizzato.value,

          })
        }
        for (let j = 0; j < suddivisioneAutoliqForm.length; j++) {
          listaAffidamenti.push({
            id: 0,
            idRichiedente: this.idRichiedenteTemp,
            idUtente: this.tokenService.getUser().idUtente,
            idTipoAffidamento: 1,
            idPassivo: this.situazioneDebitoria.idPassivo,
            descrizione: suddivisioneAutoliqForm[j].controls.autoliqDescrizione.value,
            accordato: suddivisioneAutoliqForm[j].controls.autoliqAccordato.value,
            utilizzato: suddivisioneAutoliqForm[j].controls.autoliqUtilizzato.value,
          })
        }

      }

      listDebiti.push({
        id: 0,
        idTipoDebito: this.tipoDebitoList[index],
        nome: this.nomeDebito[index],
        entroEsercizio: this.debitiFormArray[index].controls.entroEsercizio.value,
        oltreEsercizio: this.debitiFormArray[index].controls.oltreEsercizio.value,
        totale: this.debitiFormArray[index].controls.totale.value,
        debitiList: listaDettaglioDebito,
        affidamentiList: listaAffidamenti
      })
    }
    request = {
      id: 0,
      idRichiedente: this.idRichiedenteTemp,
      debito: listDebiti,
      debitoTot: this.situazioneDebitoria.debitoTot,
      anno: this.situazioneDebitoria.anno,
      idBusinessPlan: 0,

      error: null,
    }
    this.configurazioneAmbienteService.saveSituazioneDebitoria(request).subscribe(resp => {
      const modalRef = this.modalService.open(ModalcontentComponent);
      modalRef.componentInstance.modalPar = {
        name: resp ? "messaggiosalva" : "errore",
        description: resp ? "Salvato con successo" : "Abbiamo riscontrato un errore nel salvataggio",
        actionButtonText: "",
        undoButtonText: "Ok",
      }
      modalRef.result.then(r => {
        if (resp) {
          this.getSituazioneDebitoria()
        }

      })
    })
  }

  onChangeConversEquity(index: number, subIndex: number){
    let suddivisioneDebitoForm = <UntypedFormGroup[]> (<UntypedFormArray> this.debitiFormArray[index].controls.suddivisioneDebiti).controls
if(suddivisioneDebitoForm[subIndex].controls.conversEquity.value){
  suddivisioneDebitoForm[subIndex].controls.importoRinegoziato.clearValidators();
  suddivisioneDebitoForm[subIndex].controls.tipologiaRata.clearValidators();
  suddivisioneDebitoForm[subIndex].controls.numeroRate.clearValidators();

}else{
  suddivisioneDebitoForm[subIndex].controls.importoRinegoziato.setValidators(Validators.required);
  suddivisioneDebitoForm[subIndex].controls.tipologiaRata.setValidators(Validators.required);
  suddivisioneDebitoForm[subIndex].controls.numeroRate.setValidators(Validators.required);
}
  }

  ngOnDestroy(): void {
    this.tipsSubscription.unsubscribe()
  }



}
