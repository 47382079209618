import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import { InterazioneRisultatiQuestionarioService } from '../interazione-risultati-questionario.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-side-right-navmenu',
  templateUrl: './side-right-navmenu.component.html',
  styleUrls: ['./side-right-navmenu.component.css']
})
export class SideRightNavmenuComponent implements OnInit {

  @Output() table_show = new EventEmitter<string>();
  @Input() ancore: string[];

  utenteAdminView = ['Riepilogo', 'Indicatori Economici e Finanziari', 'Situazione Debitoria',
    //  'Analisi MCC e Centrale dei Rischi',
    'Conto Economico', 'Cash Flow',
    'Stato Patrimoniale', 'Debito Finanziario', 'Valutazione MCC Prospettica',
    'Garanzie', 'KPI','Valutazione Crisi di Impresa']

  close: boolean = false;
  left_side_menu_close: boolean = false;
  table_select = 'Riepilogo';
  count = 0;

  constructor(private scroller: ViewportScroller, private router: Router,
    private _interazioneRisultatiQuestionarioService: InterazioneRisultatiQuestionarioService) { }

  ngOnInit(): void {
    this._interazioneRisultatiQuestionarioService.dilatateContainer(['74%', '13%', '13%', '100%']);
    this._interazioneRisultatiQuestionarioService.observeLeftBehaviour$
      .subscribe((left_menu) => {
        this.left_side_menu_close = left_menu;
      })

      this._interazioneRisultatiQuestionarioService.currentListaMenu
      .subscribe((lista) => {
        this.utenteAdminView = lista;
      })

  }

  ngAfterViewInit(): void {
    this.clickDscr();
  }

  navRight(): void {
    if (this.close) {
      this.openNavRight();
      this.close = false;
      console.log('chiudi');
    }
    else {
      this.closeNavRight();
      this.close = true;
      console.log('opens');
    }
  }

  clickDscr() {
    console.log("CLICCO DSCR");
    setTimeout(() => {
      if (document.getElementById("ancora-sezione-dscr")) {
        document.getElementById("ancora-sezione-dscr").click();
      }
    }, 500);
  }



  openNavRight(): void {
    document.getElementById("mySidenav-right").style.width = "15%";
    document.getElementById("close-right").style.paddingRight = "32px";
    this._interazioneRisultatiQuestionarioService.checkRightSide(false);
    if (!this.left_side_menu_close) {
      this._interazioneRisultatiQuestionarioService.dilatateContainer(['74%', '13%', '13%', '100%']);
    }
    if (this.left_side_menu_close) {
      this._interazioneRisultatiQuestionarioService.dilatateContainer(['82%', '5%', '13%', '100%']);
    }
  }

  closeNavRight(): void {
    document.getElementById("mySidenav-right").style.width = "0";
    document.getElementById("close-right").style.paddingRight = "0";
    this._interazioneRisultatiQuestionarioService.checkRightSide(true);
    if (!this.left_side_menu_close) {
      this._interazioneRisultatiQuestionarioService.dilatateContainer(['82%', '13%', '5%', '100%']);
    }
    if (this.left_side_menu_close) {
      this._interazioneRisultatiQuestionarioService.dilatateContainer(['95%', '2.5%', '2.5%', '100%']);
    }
  }

  sendShowTable(tab_show: string, secondoClick: boolean) {
    console.log('esci tab' + this.table_select);
    console.log('/n');
    this.table_select = tab_show;
    console.log('entra tab' + this.table_select)
    this.table_show.emit(this.table_select);


    if (tab_show != "Riepilogo") {
      this.count = 0;
    }

    if (secondoClick && this.count == 0) {
      this.clickDscr();
    }

    if (tab_show == "Riepilogo") {
      this.count++;
    }


  }
}
