import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

interface AnnualData {
  anno: number;
  fatturato: number;
  fatturatoPil: number;
  acquistoBeniEServizi: number;
  costiPersonale: number;
  numeroAziende: number;
  indicePrezzi: number;
  fatturatoPrev: number;
  acquistoBeniEServiziPrev: number;
  costiPersonalePrev: number;
  numeroAziendePrev: number;
  pil: number;
  previsionale: boolean;
  diffFatt?: number;
  diffFattPil?: number;
  diffCosti?: number;
  diffEbitda?: number;
  diffNumeroAziende?: number;
  diffFattPrev?: number;
  diffCostiPrev?: number;
  diffNumeroAziendePrev?: number;
  diffEbitdaPrev?: number;
  diffIndicePrezzi?: number;
  diffPil?: number;
}

@Component({
  selector: 'app-analisi-settore',
  templateUrl: './analisi-settore.component.html',
  styleUrls: ['./analisi-settore.component.css']
})
export class AnalisiSettoreComponent implements OnInit {
  data: AnnualData[] = [];

  displayedYears: number[] = [];
  startIndex: number = 0;
  annoInizio: number = (new Date()).getFullYear();
listaAtecoDivisione: any[] = [];
listaAtecoGruppo: any[] = [];
listaAtecoClasse: any[] = [];
formAteco: UntypedFormGroup;
constructor(private httpClient: HttpClient, private formBuilder: FormBuilder){}

  ngOnInit() {
    this.formAteco = this.formBuilder.group({
      divisione: ['', Validators.required],
      gruppo: [{value: '', disabled: true}, Validators.required],
      classe: [{value: '', disabled: true}, Validators.required]
    });

      /* ateco Sezione*/
      
        this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoDivisioni').subscribe(data => {
          this.listaAtecoDivisione = [];
          data.forEach(element => {
            const atecoDivisione = { id: element.id, descrizione:element.codiceAteco, descrizioneCompleta: element.codiceAteco + " - " + element.descrizione };
            this.listaAtecoDivisione.push(atecoDivisione)
          });
        });
     
      
      
  }

  updateDisplayedYears() {
    this.displayedYears = this.data.slice(this.startIndex + 1, this.startIndex + 5).map(item => item.anno);
  }

  getData(year: number, displayIndex: number): AnnualData {
    const index = this.data.findIndex(item => item.anno === year);
    if (index === -1) return {} as AnnualData;

    const item = this.data[index];

    //next è il prossimo nel tempo ma quello precedente nell'array
    const nextItem = displayIndex > 1 ? this.data[index - 1] : displayIndex == 1 ? this.data[0] : null;

    return {
      ...item,
      diffFatt: item.fatturato && nextItem ? (nextItem.fatturato - item.fatturato) / Math.abs(item.fatturato) : undefined,
      diffFattPil: item.fatturatoPil && nextItem ? (nextItem.fatturatoPil - item.fatturatoPil) / Math.abs(item.fatturatoPil) : undefined,
      diffCosti: item.costiPersonale && item.acquistoBeniEServizi && nextItem ? ((nextItem.costiPersonale + nextItem.acquistoBeniEServizi) - (item.costiPersonale + item.acquistoBeniEServizi)) / Math.abs(item.costiPersonale + item.acquistoBeniEServizi) : undefined,
      diffEbitda: item.fatturato && item.costiPersonale && item.acquistoBeniEServizi && nextItem ? ((nextItem.fatturato - nextItem.costiPersonale - nextItem.acquistoBeniEServizi) - (item.fatturato - item.costiPersonale - item.acquistoBeniEServizi)) / Math.abs(item.fatturato - item.costiPersonale - item.acquistoBeniEServizi) : undefined,
      diffNumeroAziende: item.numeroAziende && nextItem ? (nextItem.numeroAziende - item.numeroAziende) / Math.abs(item.numeroAziende) : undefined,
      diffFattPrev: item.fatturatoPrev && nextItem ? (nextItem.fatturatoPrev - item.fatturatoPrev) / Math.abs(item.fatturatoPrev) : undefined,
      diffCostiPrev: item.costiPersonalePrev && item.acquistoBeniEServiziPrev && nextItem ? ((nextItem.costiPersonalePrev + nextItem.acquistoBeniEServiziPrev) - (item.costiPersonalePrev + item.acquistoBeniEServiziPrev)) / Math.abs(item.costiPersonalePrev + item.acquistoBeniEServiziPrev) : undefined,
      diffNumeroAziendePrev: item.numeroAziendePrev && nextItem ? (nextItem.numeroAziendePrev - item.numeroAziendePrev) / Math.abs(item.numeroAziendePrev) : undefined,
      diffEbitdaPrev: item.fatturatoPrev && item.costiPersonalePrev && item.acquistoBeniEServiziPrev && nextItem ? ((nextItem.fatturatoPrev - nextItem.costiPersonalePrev - nextItem.acquistoBeniEServiziPrev) - (item.fatturatoPrev - item.costiPersonalePrev - item.acquistoBeniEServiziPrev)) / Math.abs(item.fatturatoPrev - item.costiPersonalePrev - item.acquistoBeniEServiziPrev) : undefined,
      diffIndicePrezzi: nextItem ? (nextItem.indicePrezzi - item.indicePrezzi) / Math.abs(item.indicePrezzi) : undefined,
      diffPil:  nextItem ? (nextItem.pil - item.pil) / Math.abs(item.pil) : undefined,
    };
  }

  previousPage() {
    if (this.startIndex > 0) {
      this.startIndex--;
      this.updateDisplayedYears();
    }
  }

  nextPage() {
    if (this.startIndex + 5 < this.data.length) {
      this.startIndex++;
      this.updateDisplayedYears();
    }
  }

  onChangeDivisione(){
    this.formAteco.controls['gruppo'].setValue('');
    this.formAteco.controls['classe'].setValue('');
    this.formAteco.controls['classe'].disable();
    this.listaAtecoGruppo = [];
    if(this.formAteco.controls['divisione'].value && this.formAteco.controls['divisione'].value.id){
      this.formAteco.controls['gruppo'].enable();
      this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoGruppiByIdDivisione',this.formAteco.controls['divisione'].value.id).subscribe(data => {
      data.forEach(element => {
        const atecoGruppo = { id: element.id, descrizione:element.codiceAteco, descrizioneCompleta: element.codiceAteco + " - " + element.descrizione };
        this.listaAtecoGruppo.push(atecoGruppo);
      });
      if(this.listaAtecoGruppo.length == 1){
        this.formAteco.controls['gruppo'].setValue(this.listaAtecoGruppo[0]);
        this.onChangeGruppo();
      }

    });
  }else{
    this.formAteco.controls['gruppo'].disable();

  }
  }

  onChangeGruppo(){
    this.formAteco.controls['classe'].setValue('');
    this.listaAtecoClasse = [];
    if(this.formAteco.controls['gruppo'].value && this.formAteco.controls['gruppo'].value.id){
      this.formAteco.controls['classe'].enable();
    this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoClassiByIdGruppo',this.formAteco.controls['gruppo'].value.id).subscribe(data => {
      data.forEach(element => {
        const atecoClasse = { id: element.id, descrizione:element.codiceAteco, descrizioneCompleta: element.codiceAteco + " - " + element.descrizione };
        this.listaAtecoClasse.push(atecoClasse);
      });
      if(this.listaAtecoClasse.length == 1) this.formAteco.controls['classe'].setValue(this.listaAtecoClasse[0]);
    });
  }else{
    this.formAteco.controls['classe'].disable();

  }
  }

  onSubmit(){
    this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/analisiSettore/getDatiSettore',{anno: this.annoInizio, idDivisione:this.formAteco.controls['divisione'].value.id, idGruppo:this.formAteco.controls['gruppo'].value.id, idClasse:this.formAteco.controls['classe'].value.id}).subscribe(data => {
if(data) this.data = data;
this.updateDisplayedYears();

    })    
  }
}
