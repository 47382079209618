import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CentroStudiService } from '../_services/centro-studi.service';

@Component({
  selector: 'app-centro-studi',
  templateUrl: './centro-studi.component.html',
  styleUrls: ['./centro-studi.component.css']
})
export class CentroStudiComponent implements OnInit {
  selectedIndex = 0;
  listaAnni = [];
  annoCorrente = new Date().getFullYear();
  listaCodiciAteco = [];
  backtestMassivoForm: UntypedFormGroup;
  listaAziende = [];

  constructor(private formBuilder: UntypedFormBuilder, private centroStudiService: CentroStudiService) {
    this.backtestMassivoForm = this.formBuilder.group({
      codiceAteco: ['',Validators.required],
      aziendeSelezionate: [{value:[],disabled: true},Validators.required],
      anno: ['',Validators.required],
    })
   }

  ngOnInit(): void {
    this.listaAnni = [];
    for(let i = 1; i < 5; i ++){
      this.listaAnni.push(this.annoCorrente - i);
    }

    this.centroStudiService.getListaCodiciAteco().subscribe(response => this.listaCodiciAteco = response);


  }

  get f() { return this.backtestMassivoForm.controls; }

  getListaAziende(){
    this.backtestMassivoForm.controls.aziendeSelezionate.enable();
    this.backtestMassivoForm.controls.aziendeSelezionate.setValue([]);
    this.centroStudiService.getAziendeByCodiceAteco(this.backtestMassivoForm.controls.codiceAteco.value.id).subscribe(response => 
      this.listaAziende = response);

  }

  calcolaSmartBpMassivo(){

  }



}
