import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { CrisiService } from 'src/app/_services/crisi.service';
import { DettaglioRisultatiService } from 'src/app/_services/dettaglio-risultati.service';
import { SmartBpService } from 'src/app/_services/smart-bp.service';
import { SplashpageServiceService } from 'src/app/_services/splashpage-service.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { MenuPulsantieraService } from 'src/app/header/services/menu-pulsantiera.service';
import { Richiedente } from 'src/app/interface/richiedente';

export interface SmartBpElement {
  idAziendaRichiedente: number,
  nomeAzienda: string,
  codiceAteco: string,
  azioni: string[]
}

export interface SmartBpList {
  rows: SmartBpElement[];
}

@Component({
  selector: 'app-lista-crisi-impresa',
  templateUrl: './lista-crisi-impresa.component.html',
  styleUrls: ['./lista-crisi-impresa.component.css']
})
export class ListaCrisiImpresaComponent implements OnInit {

  displayedColumns: string[] = ['nomeAzienda', 'codiceAteco','azioni'];
  @ViewChild(MatSort) sort: MatSort;//per sortare la tabella
  @ViewChild(MatPaginator) paginator!: MatPaginator; /* definizione del paginator della tabella*/
  listaRichiedenti: any[] = [];
  listaCrisi: SmartBpList = { rows: [] }; //serve solo per tab material
  dataSource: MatTableDataSource<SmartBpElement>;
  annoSmartBp = new Date().getFullYear();

  currentUser: any;
  constructor(
    //Vedere se serve service per passare i dati a risultati
    private tokenService: TokenStorageService,
    private router: Router,
    private crisiService: CrisiService,
    private smartBpService: SmartBpService,
  ) {
    this.dataSource = new MatTableDataSource(this.listaCrisi.rows);
  }


  ngOnInit(): void {

    this.currentUser = this.tokenService.getUser();
    this.listaCrisi.rows = [];


      /* restituisce la lista di tutti i richiedenti per cui si può fare lo smart bp se ce ne sono */
      this.smartBpService.getListaRichiedenti(this.currentUser.idUtente).subscribe(response => {
        this.listaRichiedenti = response;
        if ( this.listaRichiedenti.length > 0) {
          this.listaRichiedenti.forEach((richiedente) => {
            let record: SmartBpElement = {
              idAziendaRichiedente: richiedente.id,
              nomeAzienda: richiedente.descrizione,
              codiceAteco: richiedente.descrizioneCompleta,
              azioni: ['Configura']
            }
            this.listaCrisi.rows.push(record)
            this.dataSource = new MatTableDataSource(this.listaCrisi.rows);

            this.sort.sort(({ id: 'nomeAzienda', start: 'asc', disableClear: true }) as MatSortable); // sorting by nome azienda, comincia asc e non sparisce la
            this.dataSource.sort = this.sort
            this.paginator.pageSize = 10;
            this.dataSource.paginator = this.paginator;
          })
        } else {
          this.router.navigate(['/funzioni']);
        }
      })
  }

  configuraCrisiImpresa(idRichiedente: number, nomeAzienda: string){
    let richiedente: Richiedente = {id: idRichiedente, ragioneSociale: nomeAzienda }
//Serve per il nome in alto
this.crisiService.LoadAziendaCrisi(richiedente);
this.router.navigate(['/confCrisi']);

  }




}
