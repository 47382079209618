<div class="container-fluid first-conteiner-top-section first-conteiner-top-section">
    <div class="row" style="justify-content: center;
    align-items: center;
    align-content: center;">
        <div class="col-8 mon-h">
            <div class="container hdt">
                <div class="row contex-data" style="justify-content: center;
                align-items: center;
                align-content: center;">
                    <div class="col-5 min-h expert-section">
                        <div class="row">
                            <div class="col-6 mat-subheading-2" id="nome-azienda" style="margin: 0;">
                            </div>
                            <div class="col-6  mat-subheading-2"
                                style="font-family: var(--data-font); font-size: 10px; margin: 0; text-align: end;">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <section>
                                    <div class="form-group form-group-textarea">
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"
                                            cols="8" style="resize: none;min-width: 180px; max-width:300px;"
                                            [(ngModel)]="commentoExp" (change)="onChangeCommento()">
                                    </textarea>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 d-flex min-h emoticon-section" style="justify-content: center;
                    align-items: center;
                    align-content: center;">
                        <div class="row">
                            <div class="col-12 d-flex" style="justify-content: center;
                    align-items: center;
                    align-content: center;">
                                <div class="mat-subheading-2" id="label-as-is" style="font-family: var(--UI-font);">
                                    AS IS
                                </div>
                            </div>
                            <div class="col-12 d-flex" style="justify-content: center;
                    align-items: center;
                    align-content: center;">
                                <mat-icon class="md-80" aria-hidden="false" aria-label="Example home icon">
                                    sentiment_satisfied_alt
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-5 min-h">
                        <div class="mat-subheading-2" id="label-valutazione" style="font-family: var(--UI-font);">
                            Valutazione generale affidabilita'
                        </div>
                        <table mat-table [dataSource]="dataSourcePerditaSortino"
                            class="mat-elevation-z8 md-tab mx-auto last-table-margin top-table">

                            <!--- Note that these columns can be defined in any order.
                                                                    The actual rendered columns are set as a property on the row definition" -->

                            <!-- Position Column -->
                            <ng-container matColumnDef="Quadro sintetico">
                                <th mat-header-cell *matHeaderCellDef> Quadro Sintetico</th>
                                <td mat-cell *matCellDef="let element"> {{element.tipoQuadroSintetico}} </td>
                            </ng-container>
                            <!-- Name Column -->
                            <ng-container matColumnDef="YTD">
                                <th mat-header-cell *matHeaderCellDef>{{this.annoInizioBilancio > 0 ? 'YTD' : ''}} </th>
                                <td mat-cell *matCellDef="let element"> {{this.annoInizioBilancio > 0 ? element.ytd !=
                                    'NaN' ? element.ytd : 'N/A' :
                                    ''}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="+1YD">
                                <th mat-header-cell *matHeaderCellDef>+1YD </th>
                                <td mat-cell *matCellDef="let element"> {{element.plusone_yd != 'NaN' ?
                                    element.plusone_yd : 'N/A'}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="MT">
                                <th mat-header-cell *matHeaderCellDef>MT </th>
                                <td mat-cell *matCellDef="let element"> {{element.mt != 'NaN' ? element.mt : 'N/A'}}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="LT">
                                <th mat-header-cell *matHeaderCellDef>LT </th>
                                <td mat-cell *matCellDef="let element"> {{element.lt != 'NaN' ? element.lt : 'N/A'}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<app-timeline-with-buttons>
</app-timeline-with-buttons>-->
<div class="container-fluid contenitore-tabelle-grafici">
    <div class="container-fluid" style="padding: 0;">
        <div class="loading-container" *ngIf="isLoading">
            <div class="spinner-border text-primary" style="width: 50px; height: 50px" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="row">
            <div class="row distanza-terna-potenziometri larghezza-area-risultati">
                <!-- <app-side-left-menu-potenziometri class="desktop-show"
                        [name_potenziometro_one]="name_potenziometro[0]"
                        [name_potenziometro_two]="name_potenziometro[1]"
                        [name_potenziometro_three]="name_potenziometro[2]"
                        [name_potenziometro_four]="name_potenziometro[3]"
                        [name_potenziometro_five]="name_potenziometro[0]"
                        [name_potenziometro_six]="name_potenziometro[0]" [lancetta_one]="lancetta[0]"
                        [lancetta_two]="lancetta[1]" [lancetta_three]="lancetta[2]" [lancetta_four]="lancetta[3]"
                        [lancetta_five]="lancetta[0]" [lancetta_six]="lancetta[0]"
                        [rotate_dscr]="(risultatiQuestionario.valDscrFrontEnd[profiloBP] * (1.8))"
                        [rotate_debitoria]="valDeb*(1.8)" [rotate_mcc]="semaforoMcc*(1.8)"
                        [rotate_garanzia]="risultatiQuestionario.valGaranzia*(1.8)"
                        [rotate_dscr_mock1]="(risultatiQuestionario.valDscrFrontEnd[profiloBP] * (1.8))"
                        [rotate_dscr_mock2]="(risultatiQuestionario.valDscrFrontEnd[profiloBP] * (1.8))"
                        [valore_tip_dscr]="risultatiQuestionario.dscrFrontEndTot[profiloBP]*100"
                        [valore_tip_debitoria]="this.mediaIndicatoreDebito"
                        [valore_tip_mcc]="valutazioneMcc.valutazioneMcc"
                        [valore_tip_garanzia]="this.risultatiQuestionario.perditaAttesaAnnuale">
                    </app-side-left-menu-potenziometri> -->
                <!--da selezionare il giusto valore per tip garanzia-->

                <!--START ACCORDION-->
                <div class="col-12 area-tabelle desktop-show" style="margin-bottom: 20px;">

                    <!--Dove Inserire Potenziometri tiemline-->
                    <div class="row potenziometri-time">
                        <!--Ritrovalo easy class="ipad-show"-->
                        <!--<app-top-bar-potenziometri [name_potenziometro_one]="name_potenziometro[0]"
                                [name_potenziometro_two]="name_potenziometro[1]"
                                [name_potenziometro_three]="name_potenziometro[2]"
                                [name_potenziometro_four]="name_potenziometro[3]"
                                [name_potenziometro_five]="name_potenziometro[0]"
                                [name_potenziometro_six]="name_potenziometro[0]" [lancetta_one]="lancetta[0]"
                                [lancetta_two]="lancetta[1]" [lancetta_three]="lancetta[2]"
                                [lancetta_four]="lancetta[3]" [lancetta_five]="lancetta[0]" [lancetta_six]="lancetta[0]"
                                [rotate_dscr]="(risultatiQuestionario.valDscrFrontEnd[profiloBP] * (1.8))"
                                [rotate_debitoria]="valDeb*(1.8)" [rotate_mcc]="semaforoMcc*(1.8)"
                                [rotate_garanzia]="risultatiQuestionario.valGaranzia*(1.8)"
                                [rotate_dscr_mock1]="(risultatiQuestionario.valDscrFrontEnd[profiloBP] * (1.8))"
                                [rotate_dscr_mock2]="(risultatiQuestionario.valDscrFrontEnd[profiloBP] * (1.8))"
                                [valore_tip_dscr]="risultatiQuestionario.dscrFrontEndTot[profiloBP]*100"
                                [valore_tip_debitoria]="this.mediaIndicatoreDebito"
                                [valore_tip_mcc]="valutazioneMcc.valutazioneMcc"
                                [valore_tip_garanzia]="this.risultatiQuestionario.perditaAttesaAnnuale">
                            </app-top-bar-potenziometri>-->
                        <div class="row timeline-row" *ngIf="!(this.show_table == 'KPI' && !boolSeconSectionIndiciBilancio)">
                            <div class="col-12" style="width: 100%; height: 100%;">
                                <!--DOVE INSERIRE TIME LINE VERTICALES-->
                                <app-timeline-risultati-questionario></app-timeline-risultati-questionario>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.caricato">
                        <div *ngIf="this.show_table != 'Indicatori Economici e Finanziari' 
                            &&  this.show_table != 'Situazione Debitoria' 
                            && this.show_table != 'Conto Economico'
                            && this.show_table != 'Cash Flow'
                            && this.show_table != 'Stato Patrimoniale'
                            && this.show_table != 'Riepilogo'
                            && this.show_table != 'Valutazione MCC Prospettica'
                            && this.show_table != 'KPI'
                            && this.show_table != 'Valutazione Centrale Rischi'"
                            class="col-5 padding-left-right-15  padding-top-bottom-10 font-big margin-top-20 header-tabella">
                            <!--class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20" (click)="boolMccProsp=!boolMccProsp" [ngStyle]="{'background-color': coloreProgetto}"-->
                            {{this.show_table}}
                            <!--<span class="pull-right pointer" style="margin-top: -15px" ><fa-icon style="font-size: 45px" [icon]="boolMccProsp? faCaretUp : faCaretDown"></fa-icon></span>-->
                        </div>

                        <div *ngIf="this.show_table == 'Riepilogo'"
                            class="col-3 padding-left-right-15  padding-top-bottom-10 font-big margin-top-20 header-tabella">
                            {{this.show_table}}
                        </div>

                        <div *ngIf=" this.show_table=='Indicatori Economici e Finanziari' || this.show_table == 'Valutazione MCC Prospettica' ||  this.show_table == 'Valutazione Centrale Rischi'"
                            class=" col-6 padding-left-right-15 padding-top-bottom-10 font-big margin-top-20
                                header-tabella">
                            <!--class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20" (click)="boolMccProsp=!boolMccProsp" [ngStyle]="{'background-color': coloreProgetto}"-->
                            {{this.show_table}}
                            <!--<span class="pull-right pointer" style="margin-top: -15px" ><fa-icon style="font-size: 45px" [icon]="boolMccProsp? faCaretUp : faCaretDown"></fa-icon></span>-->
                        </div>

                        <div *ngIf="this.show_table == 'Stato Patrimoniale' || this.show_table == 'Cash Flow' || this.show_table == 'Situazione Debitoria' || this.show_table == 'Conto Economico' || this.show_table == 'KPI'"
                            class="col-4 padding-left-right-15  padding-top-bottom-10 font-big margin-top-20 header-tabella">
                            {{this.show_table}}
                        </div>

                        <div *ngIf="this.show_table == 'Valutazione MCC Prospettica'"
                            class="col-3 margin-bottom-10 container-profili-dati-statistici">
                            <label>Valutazione</label>
                            <select id="valutazioneMccProsp" [(ngModel)]="valutazioneMccProsp"
                                (change)="onChangeValutazioneMccProsp()" class="form-control">
                                <option [ngValue]="8" selected>Integrata</option>
                                <option [ngValue]="9">Modulo economico finanziario</option>
                                <option [ngValue]="10">Modulo andamentale</option>
                            </select>
                        </div>

                        <div *ngIf="this.show_table == 'Stato Patrimoniale' || this.show_table == 'Cash Flow' 
                            || this.show_table == 'Debito Finanziario' || (this.show_table == 'KPI' && !boolSeconSectionIndiciBilancio)
                            || this.show_table == 'Conto Economico' || (this.show_table == 'Valutazione Crisi di Impresa' && !this.boolCrisiOld) "
                            class="col-2 container-profili-dati-statistici">
                            <label>Profilo prospettico</label>
                            <select id="profiloBp" [ngModelOptions]="{standalone: true}" [(ngModel)]="profiloBP"
                                class="form-control">
                                <option *ngFor="let profilo of listaProfiliProspettici;index as i" [ngValue]="i">
                                    {{profilo}}
                                </option>

                            </select>
                        </div>

                        <div *ngIf="this.show_table == 'Assunzioni' && !smartBp"
                            class="col-2 container-profili-dati-statistici">
                            <label>Ambito</label>
                            <select id="ambitoAssunzioni" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="ambitoAssunzioni" class="form-control">
                                <option value="1">Linee di business</option>
                                <option value="2">Crediti e debiti</option>

                            </select>
                        </div>

                        <div *ngIf="this.show_table == 'Situazione Debitoria'"
                            class="col-2 container-profili-dati-statistici">
                            <label>DSCR per anno</label>
                            <select id="indiceDscrAnnuo" [(ngModel)]="indiceDscrAnnuo"
                                (change)="onChangeIndiceDscrAnnuo()" class="form-control">
                                <option [ngValue]="6">Dscr annuo totale
                                </option>
                                <option [ngValue]="7" selected>Dscr
                                    cumulato totale</option>
                            </select>
                        </div>

                        <div *ngIf="this.show_table == 'KPI' && boolSeconSectionIndiciBilancio"
                            class="col-3 container-profili-dati-statistici">
                            <label>Indice</label>
                            <select id="indiceBil" [(ngModel)]="indiceBil" (change)="onChangeIndiceBil()"
                                class="form-control">
                                <option [ngValue]="11" selected>Capitale circolante
                                    netto</option>
                                <option [ngValue]="12">Net profit</option>
                                <option [ngValue]="13">Ricavi</option>

                                <option [ngValue]="14">Ratio patrimonio netto /
                                    debiti</option>
                                <option [ngValue]="15">Ratio patrimonio netto /
                                    attivo immobilizzato</option>

                            </select>
                        </div>


                        <div *ngIf="this.show_table == 'Riepilogo'" class="col-9 container-profili-dati-statistici">
                            <div class="row">
                                <div class="col-4">
                                    <label>Profilo prospettico</label>
                                    <select id="profiloBp" [(ngModel)]="profiloBP" class="form-control"
                                        (change)="RiempiTabellaProfiloProspettico()">
                                        <option *ngFor="let profilo of listaProfiliProspettici;index as i"
                                            [ngValue]="i">
                                            {{profilo}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="this.show_table == 'Indicatori Economici e Finanziari'"
                            class="col-6 container-profili-dati-statistici">
                            <div class="row">
                                <div class="col-6">
                                    <label>Indicatore</label>
                                    <select id="indicatore" [(ngModel)]="indicatoreEconometrico"
                                        (change)="onChangeIndicatore()" class="form-control">
                                        <option [ngValue]="1" selected>IRR Equity
                                        </option>
                                         
                                        <option [ngValue]="2" *ngIf="this.dettaglioVolatile.complessiva[profiloBP].rowEquity.irrPr != null">IRR Progetto
                                        </option>
                                        <!--
                                        <option [ngValue]="3">Anno di break even
                                        </option>
                                    -->
                                        <option [ngValue]="4">Somma surplus
                                            cash flow equity</option>
                                        <option [ngValue]="5">Valore azienda
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>



                <!--START tabella principale-->
                <div id='prima_sezione' class="row sezione-vetrina-tabella "
                    *ngIf="this.show_table == 'Riepilogo' && this.caricato">
                    <!--se cambi tabella di atterraggio a dettagli tu del futuro cambia anche dove setti a true caricato mi raccomando -->

                    <div class="d-flex">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                                <a ngbNavLink (click)="clickFrecciaEnsioneRighe()">Top</a>
                                <ng-template ngbNavContent>
                                    <div class="container">
                                        <div class="row" style="justify-content: center; border-radius: 20px;">


                                            <div class="col-3"
                                                style="background-color: rgba(172,175,184,0.1);border-radius: 8px;">
                                                <app-tabella-semplice id="tab-dscr" [vertical]="true"
                                                    [number_pipe]="true" [ngClass]="styleTabellaProfiloProspettico"
                                                    [valsTab]="valoriTabellaProfiloProspettico">
                                                </app-tabella-semplice>
                                            </div>
                                            <div class="col-9 colonna-dettagli-tab">
                                                <div class="row">

                                                    <div class="row margin-0 text-center container-dettagli">
                                                        <div class="col-12" style="padding: 0;">
                                                            <div class="row margin-0 first-row-table">
                                                                <div class="col-2 borderRBlack min-height-25">
                                                                </div>
                                                                <div class="col-2 borderTBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                                    *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                                    [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }">
                                                                    {{annoCorrente + i + anniBP}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12" style="padding: 0;">
                                                            <div class="row margin-0">
                                                                <div class="row row-cell">

                                                                    <div
                                                                        class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold white cella-row">
                                                                        Ricavi
                                                                        <span class="pull-right pointer"
                                                                            (click)="boolRicavi = !boolRicavi;boolRicaviProgetto=false;this.setBackGroundDSCRtabTimeout();"
                                                                            *ngIf="boolAziendaEsistente">
                                                                            <fa-icon style="font-size: 30px"
                                                                                [icon]="boolRicavi? faCaretUp : faCaretDown">
                                                                            </fa-icon>
                                                                        </span>
                                                                        <!--
                                                                            <span class="pull-right pointer"
                                                                                (click)="boolRicaviProgetto = !boolRicaviProgetto;this.setBackGroundDSCRtabTimeout();"
                                                                                *ngIf="!boolAziendaEsistente">
                                                                                <fa-icon style="font-size: 30px"
                                                                                    [icon]="boolRicaviProgetto? faCaretUp : faCaretDown">
                                                                                </fa-icon>
                                                                            </span>
                                                                            -->
                                                                    </div>
                                                                    <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center white cella-row"
                                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                        {{i + anniBP >=
                                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                        &&
                                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                        > 0 ? (0 | number:
                                                                        '1.0-0':'it')
                                                                        :
                                                                        (this.dettaglioVolatile.complessiva[this.profiloBP].colonneComplessiva[i
                                                                        + this.anniBP].ricavi | number:
                                                                        '1.0-0':'it')}}
                                                                       
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 nopadding" *ngIf="boolRicavi">
                                                                    <div class="row margin-0">
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 min-height-25 flex-justify-space-between white cella-row">
                                                                                Attivit&agrave; preesistente
                                                                            </div>

                                                                            <div class="col-2 min-height-25 flex-justify-center white cella-row"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                                                {{i + anniBP >=
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                &&
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                > 0 ? (0 | number:
                                                                                '1.0-0':'it')
                                                                                :
                                                                                (this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].ricaviPreesistente |
                                                                                number:
                                                                                '1.0-0':'it')}}
                                                                               
                                                                            </div>
                                                                        </div>

                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2  min-height-25 flex-justify-space-between cella-row">
                                                                                Nuovo progetto
                                                                            </div>

                                                                            <div class="col-2  min-height-25 flex-justify-center cella-row"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                                                {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].ricaviProgetto | number:
                                                                                '1.0-0':'it'}}
                                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div class="col-12" style="padding: 0;">
                                                            <div class="row margin-0">
                                                                <div class="row row-cell">
                                                                    <div
                                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold cella-row">
                                                                        Costi
                                                                        <span class="pull-right pointer"
                                                                            (click)="boolCosti = !boolCosti; boolCostiProgetto=false;this.setBackGroundDSCRtabTimeout();"
                                                                            *ngIf="boolAziendaEsistente">
                                                                            <fa-icon style="font-size: 30px"
                                                                                [icon]="boolCosti? faCaretUp : faCaretDown">
                                                                            </fa-icon>
                                                                        </span>
                                                                        <!--
                                                                            <span class="pull-right pointer"
                                                                                (click)="boolCostiProgetto = !boolCostiProgetto;this.setBackGroundDSCRtabTimeout();"
                                                                                *ngIf="!boolAziendaEsistente">
                                                                                <fa-icon style="font-size: 30px"
                                                                                    [icon]="boolCostiProgetto? faCaretUp : faCaretDown">
                                                                                </fa-icon>
                                                                            </span>
                                                                            -->
                                                                    </div>
                                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                        *ngFor="let item of [].constructor(anniOutput); 
                                                                                                                                                    let i = index">
                                                                        {{i + anniBP >=
                                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                        &&
                                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                        > 0 ? (0 | number: '1.0-0':'it') :
                                                                        (this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                        + anniBP].costi | number: '1.0-0':'it')}}
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 nopadding" *ngIf="boolCosti">
                                                                    <div class="row margin-0">
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Attivit&agrave; preesistente
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{i + anniBP >=
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                &&
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                > 0 ? (0 | number: '1.0-0':'it')
                                                                                :
                                                                                (this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].costiPreesitente
                                                                                |
                                                                                number: '1.0-0':'it')}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Nuovo progetto

                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{i + anniBP >=
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                &&
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                > 0 ? (0 | number: '1.0-0':'it')
                                                                                :
                                                                                (this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                +
                                                                                anniBP].costiProgetto | number:
                                                                                '1.0-0':'it')}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>


                                                        <div class="col-12" style="padding: 0;">
                                                            <div class="row margin-0">
                                                                <div class="row row-cell">
                                                                    <div
                                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold cella-row">
                                                                        EBITDA
                                                                        <span class="pull-right pointer"
                                                                            (click)="boolEbitda = !boolEbitda;this.setBackGroundDSCRtabTimeout();"
                                                                            *ngIf="boolAziendaEsistente">
                                                                            <fa-icon style="font-size: 30px"
                                                                                [icon]="boolEbitda? faCaretUp : faCaretDown">
                                                                            </fa-icon>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                        {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                        + anniBP].ebitda | number: '1.0-0':'it'}}
                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 nopadding" *ngIf="boolEbitda">
                                                                    <div class="row margin-0">
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Attivit&agrave; preesistente
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{i + anniBP >=
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                &&
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                > 0 ? (0 | number: '1.0-0':'it')
                                                                                :
                                                                                (this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].ebitdaPreesitente |
                                                                                number: '1.0-0':'it')}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Nuovo progetto
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].ebitdaProgetto | number:
                                                                                '1.0-0':'it'}}
                                                                               
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="col-12" style="padding: 0">
                                                            <div class="row margin-0">
                                                                <div class="row row-cell">
                                                                    <div
                                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold cella-row">
                                                                        EBIT
                                                                        <span class="pull-right pointer"
                                                                            (click)="boolEbit = !boolEbit;this.setBackGroundDSCRtabTimeout();">
                                                                            <fa-icon style="font-size: 30px"
                                                                                [icon]="boolEbit? faCaretUp : faCaretDown">
                                                                            </fa-icon>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                        *ngFor="let item of [].constructor(anniOutput); 
                                                                            let i = index">
                                                                        {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                        + anniBP].ebit | number: '1.0-0':'it'}}
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 nopadding" *ngIf="boolEbit">
                                                                    <div class="row margin-0">
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Ammortamenti
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); 
                                                                                  let i = index">
                                                                                {{i + anniBP >=
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                &&
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                > 0 ? (0 | number: '1.0-0':'it')
                                                                                :
                                                                                (this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].ebitAmmortamenti| number:
                                                                                '1.0-0':'it')}}
                                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>




                                                        <div class="col-12" style="padding: 0;">
                                                            <div class="row margin-0">
                                                                <div class="row row-cell">
                                                                    <div
                                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold cella-row">
                                                                        Net profit
                                                                        <span class="pull-right pointer"
                                                                            (click)="boolNetProfitProgetto = !boolNetProfitProgetto;this.setBackGroundDSCRtabTimeout();">
                                                                            <fa-icon style="font-size: 30px"
                                                                                [icon]="boolNetProfitProgetto? faCaretUp : faCaretDown">
                                                                            </fa-icon>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                        {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                        + anniBP].netProfit | number:
                                                                        '1.0-0':'it'}}
                                                                    </div>

                                                                </div>
                                                                <div class="col-12 nopadding"
                                                                    *ngIf="boolNetProfitProgetto">
                                                                    <div class="row margin-0">
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Interessi passivi
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput);
                                                                                let i = index">
                                                                                {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].netProfitInteressiPassivi
                                                                                |
                                                                                number:
                                                                                '1.0-0':'it'}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Tasse
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].netProfitTasse | number:
                                                                                '1.0-0':'it'}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="col-12" style="padding:0">
                                                            <div class="row margin-0">
                                                                <div class="row row-cell">
                                                                    <div
                                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold cella-row">
                                                                        Cash flow equity
                                                                        <span class="pull-right pointer"
                                                                            (click)="boolEquityProgetto = !boolEquityProgetto;this.setBackGroundDSCRtabTimeout();">
                                                                            <fa-icon style="font-size: 30px"
                                                                                [icon]="boolEquityProgetto? faCaretUp : faCaretDown">
                                                                            </fa-icon>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                        *ngFor="let item of [].constructor(anniOutput); 
                                                                            let i = index">
                                                                        {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                        + anniBP].cashflowEquity | number:
                                                                        '1.0-0':'it'}}
                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 nopadding"
                                                                    *ngIf="boolEquityProgetto">
                                                                    <div class="row margin-0">
                                                                        <div class="row row-cell" *ngIf="!smartBp">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Debito residuo
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{i + anniBP >=
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                &&
                                                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                > 0 ?
                                                                                (this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                                                -
                                                                                1].finanziamentoRateizzato | number:
                                                                                '1.0-0':'it')
                                                                                :
                                                                                (this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].finanziamentoRateizzato |
                                                                                number:
                                                                                '1.0-0':'it')}}
                                                                               
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 nopadding">
                                                                            <div class="row margin-0">
                                                                                <div class="row row-cell">
                                                                                    <div
                                                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                        Costi debito
                                                                                    </div>

                                                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                        {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                        + anniBP].debitoPregresso |
                                                                                        number:
                                                                                        '1.0-0':'it'}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Finanziamento a revoca
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput);
                                                                                let i = index">
                                                                                {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].finanziamentoCassa |
                                                                                number:
                                                                                '1.0-0':'it'}}
                                                                               
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Finanziamento autoliquidante
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].finanziamentoCastelletto |
                                                                                number:
                                                                                '1.0-0':'it'}}
                                                                               
                                                                            </div>

                                                                        </div>
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Crediti e debiti a breve termine
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].cashflowCreditiDebiti|
                                                                                number:
                                                                                '1.0-0':'it'}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cell">
                                                                            <div
                                                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between cella-row">
                                                                                Cassa
                                                                            </div>

                                                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center cella-row"
                                                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                                {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].cashflowCassa| number:
                                                                                '1.0-0':'it'}}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>




                                                            <div class="col-12" style="padding: 0;">
                                                                <div class="row margin-0">
                                                                    <div class="row row-cell">
                                                                        <div
                                                                            class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                                            Cash flow progetto
                                                                        </div>
                                                                        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                                            [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                                            *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                            {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                            + anniBP].cashflowProgetto | number:
                                                                            '1.0-0':'it'}}
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12" style="padding: 0;">
                                                                <div class="row margin-0">
                                                                    <div class="row row-cell">
                                                                        <div
                                                                            class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                                            DSCR annuo
                                                                        </div>
                                                                        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                                            [ngClass]="{'focus-cell-left-right-bottom': i == this.foscusCol }"
                                                                            *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                            {{this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                            + anniBP].dscr > 3 ? '> ' + 3 :
                                                                            this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                            + anniBP].dscr < -3 ? '< ' + 3*(-1) :
                                                                                this.dettaglioVolatile.complessiva[profiloBP].colonneComplessiva[i
                                                                                + anniBP].dscr| number: '1.0-2' :'it'}}
                                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                </ng-template>
                            </li>
                            <!--
                            <li ngbNavItem="midle">
                                <a ngbNavLink>Midle</a>
                                <ng-template ngbNavContent>
                                        <app-line-chart [valueOrdinataNegativo]="this.risultatiQuestionario.ricavi[1]"
                                            [valueOrdinataMedia]="this.risultatiQuestionario.ricavi[2]"
                                            [valueOrdinataPositivo]="this.risultatiQuestionario.ricavi[3]"
                                            [labelAscissa]="">
                                        </app-line-chart>
                                    </ng-template>
                            </li>
                            -->
                        </ul>
                        <div [ngbNavOutlet]="nav" class="ms-4"></div>
                    </div>

                </div>
                <!--END tabella principale-->
                <!--START tabella Indicatori economici-finanziari-->
                <div id='seconda_sezione' class="row sezione-vetrina-tabella"
                    *ngIf="this.show_table == 'Indicatori Economici e Finanziari'">
                    <div class="d-flex">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                                <a ngbNavLink>Top</a>
                                <ng-template ngbNavContent>
                                    <div class="container">
                                        <div class="row" style="justify-content: center;  border-radius: 20px;">
                                            <div class="col-3"
                                                style="background-color: rgba(172,175,184,0.1);border-radius: 8px;">
                                                <!--qui-->
                                                <div class="col-12 padding-top-bottom-10">
                                                    <div class="row margin-0">
                                                        <div class="col-12 margin-bottom-20">
                                                            <div class="row margin-0 text-center">
                                                                <div
                                                                    class="col-12 custom-simple-tab-header min-height-25 margin-top-10 font-bold">
                                                                    Minimo
                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 1 || indicatoreEconometrico == 2">
                                                                        <div class="row margin-0">
                                                                            <div
                                                                                class="col-12 custom-simple-tab-content min-height-25">
                                                                                {{minimoIndicatore >= 0 ?
                                                                                (minimoIndicatore | number:
                                                                                '1.0-2':'it') :
                                                                                'Negativo'}}
                                                                                {{minimoIndicatore >=0 ? '%'
                                                                                :
                                                                                ''}}</div>

                                                                        </div>
                                                                    </div>


                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 3">
                                                                        <div class="row margin-0">
                                                                            <div
                                                                                class="col-12 custom-simple-tab-content  min-height-25">
                                                                                {{minimoIndicatore >= 0 ?
                                                                                (minimoIndicatore | number:
                                                                                '1.0-0':'it') :
                                                                                'Negativo'}}
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 4 || indicatoreEconometrico == 5">
                                                                        <div class="row margin-0">
                                                                            <div
                                                                                class="col-4 custom-simple-tab-content  min-height-25">
                                                                                {{minimoIndicatore >= 0 ?
                                                                                (minimoIndicatore | number:
                                                                                '1.0-0':'it') :
                                                                                'Negativo'}}
                                                                                {{minimoIndicatore >=0 ? '€'
                                                                                :
                                                                                ''}}</div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div
                                                                    class="col-12  custom-simple-tab-header min-height-25 margin-top-10 font-bold">
                                                                    Massimo
                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 1 || indicatoreEconometrico == 2">
                                                                        <div class="row margin-0">

                                                                            <div
                                                                                class="col-12 custom-simple-tab-content  min-height-25">
                                                                                {{massimoIndicatore
                                                                                >= 0 ?
                                                                                (massimoIndicatore | number:
                                                                                '1.0-2':'it') : 'Negativo'}}
                                                                                {{massimoIndicatore >=0 ?
                                                                                '%' : ''}}</div>

                                                                        </div>
                                                                    </div>


                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 3">
                                                                        <div class="row margin-0">

                                                                            <div
                                                                                class="col-12 custom-simple-tab-content min-height-25">
                                                                                {{massimoIndicatore
                                                                                >= 0 ?
                                                                                (massimoIndicatore | number:
                                                                                '1.0-0':'it') : 'Negativo'}}
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 4 || indicatoreEconometrico == 5">
                                                                        <div class="row margin-0">

                                                                            <div
                                                                                class="col-4 custom-simple-tab-content min-height-25">
                                                                                {{massimoIndicatore
                                                                                >= 0 ?
                                                                                (massimoIndicatore | number:
                                                                                '1.0-0':'it') : 'Negativo'}}
                                                                                {{massimoIndicatore >=0 ?
                                                                                '€' : ''}}</div>

                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div id="media"
                                                                    class="col-12  custom-simple-tab-headermin-height-25 margin-top-10 font-bold">
                                                                    Media
                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 1 || indicatoreEconometrico == 2">
                                                                        <div class="row margin-0">
                                                                            <div
                                                                                class="col-12 custom-simple-tab-content min-height-25">
                                                                                {{mediaIndicatore
                                                                                >=0 ?
                                                                                (mediaIndicatore | number:
                                                                                '1.0-2':'it') : 'Negativo'}}
                                                                                {{mediaIndicatore
                                                                                >=0 ? '%'
                                                                                : ''}}</div>
                                                                        </div>
                                                                    </div>


                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 3">
                                                                        <div class="row margin-0">
                                                                            <div
                                                                                class="col-12 custom-simple-tab-content min-height-25">
                                                                                {{mediaIndicatore
                                                                                >=0 ?
                                                                                (mediaIndicatore | number:
                                                                                '1.0-0':'it') : 'Negativo'}}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 nopadding"
                                                                        *ngIf="indicatoreEconometrico == 4 || indicatoreEconometrico == 5">
                                                                        <div class="row margin-0">
                                                                            <div class="col-12 min-height-25">
                                                                                {{mediaIndicatore
                                                                                >=0 ?
                                                                                (mediaIndicatore | number:
                                                                                '1.0-0':'it') : 'Negativo'}}
                                                                                {{mediaIndicatore
                                                                                >=0 ? '€'
                                                                                : ''}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!--fino qui-->
                                                        <!--fini 2-->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-9 colonna-dettagli-tab">
                                                <div class="col-3">

                                                </div>
                                                <div class="col-12" style="margin:auto">
                                                    <div class="row margin-0 text-center">

                                                        <app-tabella-with-options class="col-12 nopadding"
                                                            [number_pipe]="true"
                                                            [ngClass]="styleTabellaIndiicatoriEconomici"
                                                            [valsTab]="valoriTabellaDSRC"
                                                            [coloreDebito]="coloreIndicatore" [frequenza]="frequenze"
                                                            [maxFrequenzeDebito]="maxFrequenze"
                                                            [indicatore_eventBreake]="true"
                                                            [indicatoreEconometrico]="indicatoreEconometrico">
                                                        </app-tabella-with-options>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <!--
                            <li ngbNavItem="midle">
                                <a ngbNavLink>Midle</a>
                                <ng-template ngbNavContent>
                                    <app-bar-chart-indicatori-economici [AscissaLabel]="this.range"
                                        [OrdinataLabel]="this.frequenze"
                                        [numSimulazioni]="this.risultatiQuestionario.numSimulazioni">
                                    </app-bar-chart-indicatori-economici>
                                </ng-template>
                            </li>
                            -->
                        </ul>

                        <div [ngbNavOutlet]="nav" class="ms-4"></div>
                    </div>
                </div>
                <!--END tabella Indicatori economici-finanziari-->
                <div class="row sezione-vetrina-tabella" *ngIf="this.show_table == 'Conto Economico'">
                    <div class="d-flex">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                                <a ngbNavLink>Top</a>
                                <ng-template ngbNavContent>
                                    <!--START Conto economico-->
                                    <div id="conto-economico-table"
                                        class="col-12  margin-bottom-20 padding-top-bottom-10 padding-left-right-15 borderL borderR borderB"
                                        style="justify-content: center;  background-color: var(--soft-grey); border-radius: 20px;">



                                        <div class="row margin-0 text-center">
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center">
                                                Importi in €
                                            </div>
                                            <div class="col-2 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center"
                                                [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{annoCorrente + i + anniBP}}
                                            </div>
                                        </div>


                                        <div class="row margin-0" *ngIf="boolAziendaEsistente">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Ricavi progetto
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ricaviProduzioneProgetto
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>

                                        </div>
                                        <div class="row margin-0" *ngIf="boolAziendaEsistente">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Ricavi attivit&agrave; preesistente
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right "
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ricaviPreesistente
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>

                                        </div>

                                        <div class="row margin-0">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Ricavi da vendite 
                                                <span class="pull-right pointer" *ngIf="!smartBp && dettaglioVolatile.contoeconomico[0].rowContoEconomico[0].ricaviLdb.length > 0"
                                                (click)="boolLdbConto = !boolLdbConto;">
                                                <fa-icon style="font-size: 20px"
                                                    [icon]="boolLdbConto? faCaretUp : faCaretDown">
                                                </fa-icon>
                                            </span>
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ricavi
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>

                                        </div>

                                        <ng-container *ngIf="boolLdbConto">
                                        <div class="row margin-0" *ngFor="let ldb of this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].ricaviLdb; let j = index">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-center-right">
                                                {{dettaglioVolatile.nomiLdb[j]}}
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ricaviLdb[j] | number: '1.0-0':'it')}}
                                            </div>

                                        </div>
                                    </ng-container>
                                        <div class="row margin-0">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Variazione rimanenze prodotti
                                                <span class="pull-right pointer" *ngIf="!smartBp && dettaglioVolatile.contoeconomico[0].rowContoEconomico[0].ricaviLdb.length > 0"
                                                (click)="boolLdbConto = !boolLdbConto;">
                                                <fa-icon style="font-size: 20px"
                                                    [icon]="boolLdbConto? faCaretUp : faCaretDown">
                                                </fa-icon>
                                            </span>
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].rimanenzeProdotti
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>

                                        </div>
                                        <ng-container *ngIf="boolLdbConto">

                                        <div class="row margin-0" *ngFor="let ldb of this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].rimanenzeProdLdb; let j = index">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-center-right">
                                                {{dettaglioVolatile.nomiLdb[j]}}
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].rimanenzeProdLdb[j] | number: '1.0-0':'it')}}
                                            </div>

                                        </div>
</ng-container>
                                        <div class="row margin-0 text-center">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Valore della produzione
                                            </div>
                                            <div [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ricavi
                                                +
                                                this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].rimanenzeProdotti
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>
                                        </div>


                                        <div class="row margin-0" *ngIf="boolAziendaEsistente">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Costi Progetto
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0 ?
                                                (0 |
                                                number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiProduzioneProgetto
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>

                                        </div>

                                        <div class="row margin-0" *ngIf="boolAziendaEsistente">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Costi Attivit&agrave; preesistente
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0 ?
                                                (0 |
                                                number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiPreesitente
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>

                                        </div>

                                        <div class="row margin-0">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Costi di produzione
                                                <span class="pull-right pointer" *ngIf="!smartBp && dettaglioVolatile.contoeconomico[0].rowContoEconomico[0].ricaviLdb.length > 0"
                                                (click)="boolLdbConto = !boolLdbConto;">
                                                <fa-icon style="font-size: 20px"
                                                    [icon]="boolLdbConto? faCaretUp : faCaretDown">
                                                </fa-icon>
                                            </span>
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costi
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>

                                        </div>
                                        <ng-container *ngIf="boolLdbConto">

                                        <div class="row margin-0" *ngFor="let ldb of this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].costiLdb; let j = index">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-center-right">
                                                {{dettaglioVolatile.nomiLdb[j]}}
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiLdb[j] | number: '1.0-0':'it')}}
                                            </div>

                                        </div>
                                        </ng-container>

                                        <!-- bool azienda esistente è fisso a false-->
                                        <div class="row margin-0" *ngIf="boolAziendaEsistente">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Variazione rimanenze materie
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].rimanenzeMaterie
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>

                                        </div>

                                        <div class="row margin-0 text-center" *ngIf="false">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Margine delle vendite
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">{{i +
                                                anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].margineVendite
                                                -
                                                this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiFatturato
                                                -
                                                this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiFissi
                                                | number:
                                                '1.0-0':'it')}}
                                            </div>
                                        </div>

                                        <div class="row margin-0"
                                            *ngIf="this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].costiFissi != 0 || (anniOutput >= 5 && this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[4].costiFissi != 0)">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Costi fissi
                                                <span class="pull-right pointer" *ngIf="!smartBp && dettaglioVolatile.contoeconomico[0].rowContoEconomico[0].ricaviLdb.length > 0"
                                                (click)="boolLdbConto = !boolLdbConto;">
                                                <fa-icon style="font-size: 20px"
                                                    [icon]="boolLdbConto? faCaretUp : faCaretDown">
                                                </fa-icon>
                                            </span>
                                            </div>




                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiFissi
                                                | number:
                                                '1.0-0':'it')}}
                                            </div>
                                        </div>
                                        <ng-container *ngIf="boolLdbConto && (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].costiFissi != 0 || (anniOutput >= 5 && this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[4].costiFissi != 0))">

                                        <div class="row margin-0" *ngFor="let ldb of this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].costiFissiLdb; let j = index">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-center-right">
                                                {{dettaglioVolatile.nomiLdb[j]}}
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiFissiLdb[j] | number: '1.0-0':'it')}}
                                            </div>

                                        </div>
                                        </ng-container>

                                        <div class="row margin-0"
                                            *ngIf="this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].costiFatturato != 0 || (anniOutput >= 5 && this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[4].costiFatturato != 0)">

                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Costi di fatturato
                                                <span class="pull-right pointer" *ngIf="!smartBp && dettaglioVolatile.contoeconomico[0].rowContoEconomico[0].ricaviLdb.length > 0"
                                                (click)="boolLdbConto = !boolLdbConto;">
                                                <fa-icon style="font-size: 20px"
                                                    [icon]="boolLdbConto? faCaretUp : faCaretDown">
                                                </fa-icon>
                                            </span>
                                            </div>



                                            <div class="col-2 borderRBlack bordeCash Flow rBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0 ? (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiFatturato
                                                | number:'1.0-0':'it')}}
                                            </div>

                                        </div>
                                        <ng-container *ngIf="boolLdbConto  && (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].costiFatturato != 0 || this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].ricavi == 0)">

                                        <div class="row margin-0" *ngFor="let ldb of this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].costiFatturatoLdb; let j = index">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-center-right">
                                                {{dettaglioVolatile.nomiLdb[j]}}
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiFatturatoLdb[j] | number: '1.0-0':'it')}}
                                            </div>

                                        </div>
                                        </ng-container>

                                        <div class="row margin-0"
                                            *ngIf="this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].costiAffito> 0">

                                            <div style='background-color:var(--main-white) !important'
                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                Costi affitto
                                            </div>




                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                style='background-color:var(--main-white) !important'
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0 | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiAffito
                                                | number:
                                                '1.0-0':'it')}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Totale costi
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">{{i +
                                                anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costi
                                                +
                                                this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].rimanenzeMaterie
                                                +
                                                this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiFatturato
                                                +
                                                this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].costiFissi
                                                | number:
                                                '1.0-0':'it')}}
                                            </div>
                                        </div>


                                        <div class="row margin-0 text-center">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Ebitda
                                                <span class="pull-right pointer" *ngIf="!smartBp && dettaglioVolatile.contoeconomico[0].rowContoEconomico[0].ricaviLdb.length > 0"
                                                (click)="boolLdbConto = !boolLdbConto;">
                                                <fa-icon style="font-size: 20px"
                                                    [icon]="boolLdbConto? faCaretUp : faCaretDown">
                                                </fa-icon>
                                            </span>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it')
                                                :(this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ebitda
                                                |
                                                number: '1.0-0':'it')}}
                                            </div>
                                        </div>
                                        <ng-container *ngIf="boolLdbConto">

                                        <div class="row margin-0" *ngFor="let ldb of this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[0].ebitdaLdb; let j = index">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-center-right">
                                                {{dettaglioVolatile.nomiLdb[j]}}
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ? (0
                                                | number: '1.0-0':'it')
                                                :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ebitdaLdb[j] | number: '1.0-0':'it')}}
                                            </div>

                                        </div>
                                        </ng-container>

                                        <div class="row margin-0 text-center">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Ammortamenti
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">{{i +
                                                anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0 ? (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ammortamenti
                                                | number:
                                                '1.0-0':'it')}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center" *ngIf="!smartBp">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Perdite e sopravvenienze attive
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">{{i +
                                                anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0 ? (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].perdite
                                                | number:'1.0-0':'it')}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center" *ngIf="!smartBp && (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[anniBP].valenzeDisinvest != 0 ||
                                            (anniOutput >= 2 && this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[1+anniBP].valenzeDisinvest != 0) || (anniOutput >= 3 && this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[2+anniBP].valenzeDisinvest != 0) ||
                                            (anniOutput >= 4 && this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[3+anniBP].valenzeDisinvest != 0) || (anniOutput >= 5 && this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[4+anniBP].valenzeDisinvest != 0) )">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Plus/minusvalenze disinvestimenti
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">{{i +
                                                anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0 ? (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].valenzeDisinvest
                                                | number:'1.0-0':'it')}}
                                            </div>
                                        </div>


                                        <div class="row margin-0 text-center">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                EBIT

                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].ebit
                                                |
                                                number:
                                                '1.0-0':'it'}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Interessi passivi
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].interessiPassivi
                                                |
                                                number: '1.0-0':'it'}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Tasse
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].tasse
                                                |
                                                number: '1.0-0':'it'}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div
                                                class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                Net profit

                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-center-right"
                                                [ngClass]="{'focus-cell-left-right-bottom': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[i+anniBP].netProfit
                                                |
                                                number: '1.0-0':'it'}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <!--
                            <li ngbNavItem="midle">
                                <a ngbNavLink>Midle</a>
                                <ng-template ngbNavContent>

                                    <div
                                        class="conto-economico col-12 padding-left-right-15 pointer padding-top-bottom-10  font-big margin-top-20 header-tabella">
                                        Conto economico
                                    </div>
                                    <ul ngbNav #nav="ngbNav" class="nav-tabs">

                                        <li ngbNavItem>
                                            <a ngbNavLink>Totali Ricavi</a>

                                            <ng-template ngbNavContent>

                                                <app-line-chart
                                                    [valueOrdinataNegativo]="this.risultatiQuestionario.ricaviTot[1]"
                                                    [valueOrdinataMedia]="this.risultatiQuestionario.ricaviTot[2]"
                                                    [valueOrdinataPositivo]="this.risultatiQuestionario.ricaviTot[3]"
                                                    [labelAscissa]="">
                                                </app-line-chart>
                                            </ng-template>
                                        </li>
                                        <li ngbNavItem>
                                            <a ngbNavLink>Totale Costi</a>
                                            <ng-template ngbNavContent>
                                                <app-line-chart
                                                    [valueOrdinataNegativo]="this.risultatiQuestionario.costiComplessiviTot[1]"
                                                    [valueOrdinataMedia]="this.risultatiQuestionario.costiComplessiviTot[2]"
                                                    [valueOrdinataPositivo]="this.risultatiQuestionario.costiComplessiviTot[3]"
                                                    [labelAscissa]="">
                                                </app-line-chart>
                                            </ng-template>
                                        </li>
                                        <li ngbNavItem>
                                            <a ngbNavLink>EBITDA</a>
                                            <ng-template ngbNavContent>
                                                <app-line-chart
                                                    [valueOrdinataNegativo]="this.risultatiQuestionario.ebitdaTot[1]"
                                                    [valueOrdinataMedia]="this.risultatiQuestionario.ebitdaTot[2]"
                                                    [valueOrdinataPositivo]="this.risultatiQuestionario.ebitdaTot[3]"
                                                    [labelAscissa]="">
                                                </app-line-chart>
                                            </ng-template>
                                        </li>
                                        <li ngbNavItem>
                                            <a ngbNavLink>EBIT</a>
                                            <ng-template ngbNavContent>
                                                <app-line-chart
                                                    [valueOrdinataNegativo]="this.risultatiQuestionario.ebitTot[1]"
                                                    [valueOrdinataMedia]="this.risultatiQuestionario.ebitTot[2]"
                                                    [valueOrdinataPositivo]="this.risultatiQuestionario.ebitTot[3]"
                                                    [labelAscissa]="">
                                                </app-line-chart>
                                            </ng-template>
                                        </li>
                                        <li ngbNavItem>
                                            <a ngbNavLink>Interessi Passivi</a>
                                            <ng-template ngbNavContent>
                                                <app-line-chart
                                                    [valueOrdinataNegativo]="this.risultatiQuestionario.interessiTot[1]"
                                                    [valueOrdinataMedia]="this.risultatiQuestionario.interessiTot[2]"
                                                    [valueOrdinataPositivo]="this.risultatiQuestionario.interessiTot[3]"
                                                    [labelAscissa]="">
                                                </app-line-chart>
                                            </ng-template>
                                        </li>
                                        <li ngbNavItem>
                                            <a ngbNavLink>NET Profit</a>
                                            <ng-template ngbNavContent>
                                                <app-line-chart
                                                    [valueOrdinataNegativo]="this.risultatiQuestionario.netProfitTot[1]"
                                                    [valueOrdinataMedia]="this.risultatiQuestionario.netProfitTot[2]"
                                                    [valueOrdinataPositivo]="this.risultatiQuestionario.netProfitTot[3]"
                                                    [labelAscissa]="">
                                                </app-line-chart>
                                            </ng-template>
                                        </li>
                                    </ul>
                                    <div [ngbNavOutlet]="nav"></div>
                                </ng-template>
                            </li>
                            -->
                        </ul>
                        <div [ngbNavOutlet]="nav" class="ms-4"></div>
                    </div>
                </div>
                <!--END tabella conto economico-->
                <!--START tabella Cash flow-->
                <div class="row sezione-vetrina-tabella" *ngIf="this.show_table == 'Cash Flow'">
                    <div class="col-12 padding-left-right-15 margin-bottom-20 padding-top-bottom-10">
                        <div class="row margin-0 " style="justify-content: center; border-radius: 20px;">
                            <div class="d-flex">
                                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                                    <!--
                                    <li ngbNavItem="top">
                                        <a ngbNavLink>Top</a>
                                        <ng-template ngbNavContent>
                                            <div class="col-2 margin-bottom-10 year-input-hide"
                                                style="padding-left: 0px">
                                                <label>Anni previsione</label>
                                                <select id="anniCF" [(ngModel)]="anniCF"
                                                    [ngModelOptions]="{standalone: true}" class="form-control">
                                                    <option [ngValue]="0">1 - 5</option>
                                                    <option [ngValue]="5">6 - 10</option>
                                                </select>
                                            </div>
                                            <div class="col-2 margin-bottom-10" style="padding-left: 0px">

                                            </div>
                                            <div class="container-dettagli" id="cashflowIndiretto">
                                                <div class="col-12 padding-top-bottom-10 text-center">
                                                    <span class="font-bold label-cashFlow">Metodo indiretto</span>
                                                </div>


                                                <div class="row margin-0 text-center "
                                                    style="background-color: #FFFFFF !important; border: none !important;">
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25">
                                                    </div>
                                                    <div class="col-2 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                        [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }">
                                                        {{annoCorrente + i + anniBP}}
                                                    </div>
                                                </div>


                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Net profit

                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].netProfit | number: '1.0-0':'it'}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Ammortamenti
                                                    </div>

                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        >
                                                        0 ? (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].ammortamenti |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center" *ngIf="!smartBp">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Anticipi

                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].anticipi | number: '1.0-0':'it'}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center"
                                                    *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].finPregressi > 0">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        <div *ngIf="smartBp">Cash flow debiti a scadenza</div> <div *ngIf="!smartBp">Cash flow debiti banche pregressi</div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (-this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].debitoPregresso|
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center" *ngIf="!smartBp">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Cash flow finanziamento rateizzato
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (-this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].finanziamentoRateizzato| number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>


                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Cash flow finanziamento per cassa
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].finanziamentoCassa | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Cash flow finanziamento autoliquidante
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].finanziamentoCastelletto | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center"
                                                    *ngIf="this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[0].cfLiquidInvest > 0 ||
                                                    this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[1].cfLiquidInvest > 0 ||
                                                    this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[2].cfLiquidInvest > 0 ||
                                                    this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[3].cfLiquidInvest > 0 ||
                                                    this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[4].cfLiquidInvest > 0">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        <div *ngIf="!this.smartBp">Apporto liqudit&agrave;</div>
                                                        <div *ngIf="this.smartBp">Variazione immobilizzazioni</div>

                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].cfLiquidInvest | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">

                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Variazione cassa
                                                    </div>


                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') : (-
                                                        this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        + anniBP].varCassa
                                                        |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">

                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Variazione rimanenze
                                                    </div>


                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') : (-
                                                        this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].varRimanenze | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">

                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Variazione crediti e debiti
                                                    </div>


                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].varCreditiDebiti
                                                        | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Cash flow equity
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right-bottom': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].cashflowEquity
                                                        | number: '1.0-0':'it'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    -->
                                    <li ngbNavItem="top">
                                        <a ngbNavLink>Top</a>
                                        <ng-template ngbNavContent>

                                            <div class="col-2 margin-bottom-10 year-input-hide"
                                                style="padding-left: 0px">
                                                <label>Anni previsione</label>
                                                <select id="anniCF" [(ngModel)]="anniCF"
                                                    [ngModelOptions]="{standalone: true}" class="form-control">
                                                    <option [ngValue]="0">1 - 5</option>
                                                    <option [ngValue]="5">6 - 10</option>
                                                </select>
                                            </div>

                                            <div class="container-dettagli" id="cashflowDiretto">
                                                <div class="col-12 padding-top-bottom-10 text-center">
                                                    <span class="font-bold label-cashFlow">Metodo diretto</span>
                                                </div>


                                                <div class="row margin-0 text-center"
                                                    style="background-color: #FFFFFF !important; border: none !important;">
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25">
                                                    </div>
                                                    <div class="col-2 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{annoCorrente + i + anniBP}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Ricavi da vendite
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].ricaviTotCassa | number: '1.0-0' :'it')}} </div>
                                                </div>

                                                <div class="row margin-0 text-center" *ngIf="false">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Anticipi

                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        + anniBP].anticipi
                                                        | number: '1.0-0':'it'}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Costi di produzione
                                                    </div>

                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0 ? (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].costiProduzione | number: '1.0-0':'it')}}
                                                       
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center"
                                                    *ngIf="this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[anniBP].costiFissi != 0 || (anniOutput >= 2 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[1 + anniBP].costiFissi != 0)
                                                    || (anniOutput >= 3 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[2 + anniBP].costiFissi != 0) || (anniOutput >= 4 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[3 + anniBP].costiFissi != 0)
                                                    || (anniOutput >= 5 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[4 + anniBP].costiFissi != 0)">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Margine delle vendite
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].margineVendite | number: '1.0-0':'it')}}
                                                       
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[anniBP].costiFissi != 0 || (anniOutput >= 2 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[1 + anniBP].costiFissi != 0)
                                                || (anniOutput >= 3 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[2 + anniBP].costiFissi != 0) || (anniOutput >= 4 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[3 + anniBP].costiFissi != 0)
                                                || (anniOutput >= 5 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[4 + anniBP].costiFissi != 0)">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Costi fissi
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].costiFissi | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center"
                                                    *ngIf="this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[0].costiFatturato != 0 || (anniOutput >= 5 && this.dettaglioVolatile.contoeconomico[profiloBP].rowContoEconomico[4].costiFatturato != 0)">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Costi sul fatturato
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].costiFatturato |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Margine operativo lordo
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].margineLordo
                                                        | number:'1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center" *ngIf="!smartBp">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Perdite e sopravvenienze attive
                                                    </div>

                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                        {{this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].perdite | number: '1.0-0':'it'}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Interessi passivi
                                                    </div>

                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                        {{this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].interessiPassivi | number: '1.0-0':'it'}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Tasse
                                                    </div>

                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                        {{this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        + anniBP].tasse
                                                        | number: '1.0-0':'it'}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center"
                                                    *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].finPregressi > 0 || this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[0].debitoPregresso">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        <div *ngIf="smartBp">Cash flow debiti a scadenza</div>
                                                        <div *ngIf="!smartBp">Cash flow debiti banche pregressi</div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (-this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].debitoPregresso
                                                        |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center" *ngIf="!smartBp">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Cash flow finanziamento rateizzato
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (-this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].finanziamentoRateizzato|
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Cash flow finanziamento a revoca
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].finanziamentoCassa
                                                        |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Variazione fabbisogno cassa
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].varFabbisognoCassa
                                                        |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Cash flow finanziamento autoliquidante
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].finanziamentoCastelletto | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center"
                                                    *ngIf="this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[anniBP].cfLiquidInvest > 0 ||
                                                    (anniOutput >= 2 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[1 + anniBP].cfLiquidInvest > 0) ||
                                                    (anniOutput >= 3 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[2 + anniBP].cfLiquidInvest > 0) ||
                                                    (anniOutput >= 4 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[3 + anniBP].cfLiquidInvest > 0) ||
                                                    (anniOutput >= 5 && this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[4 + anniBP].cfLiquidInvest > 0)">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        <div *ngIf="!this.smartBp">Cash flow investimenti/disinvestimenti</div>
                                                        <div *ngIf="this.smartBp">Variazione immobilizzazioni</div>

                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].cfLiquidInvest | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                                <div class="row margin-0 text-center">

                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Variazione crediti e debiti
                                                    </div>


                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].varCreditiDebiti
                                                        | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Variazione cassa
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{i + anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (-this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].varCassa |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>

                                                <div class="row margin-0 text-center">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Cash flow equity
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right-bottom': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{this.dettaglioVolatile.cashflow[profiloBP].colonneCashflow[i
                                                        +
                                                        anniBP].cashflowEquity
                                                        | number: '1.0-0':'it'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <!--
                                    <li ngbNavItem="bottom">
                                        <a ngbNavLink>Bottom</a>
                                        <ng-template ngbNavContent>
                                            <ul ngbNav #nav="ngbNav" class="nav-tabs">

                                                <li ngbNavItem>
                                                    <a ngbNavLink>Metodo Diretto</a>

                                                    <ng-template ngbNavContent>

                                                        <app-line-chart
                                                            [valueOrdinataNegativo]="this.risultatiQuestionario.ricaviTot[1]"
                                                            [valueOrdinataMedia]="this.risultatiQuestionario.ricaviTot[2]"
                                                            [valueOrdinataPositivo]="this.risultatiQuestionario.ricaviTot[3]"
                                                            [labelAscissa]="">
                                                        </app-line-chart>
                                                    </ng-template>
                                                </li>
                                                <li ngbNavItem>
                                                    <a ngbNavLink>Metodo Indiretto</a>
                                                    <ng-template ngbNavContent>
                                                        <app-line-chart
                                                            [valueOrdinataNegativo]="this.risultatiQuestionario.ricaviTot[1]"
                                                            [valueOrdinataMedia]="this.risultatiQuestionario.ricaviTot[2]"
                                                            [valueOrdinataPositivo]="this.risultatiQuestionario.ricaviTot[3]"
                                                            [labelAscissa]="">
                                                        </app-line-chart>
                                                    </ng-template>
                                                </li>
                                            </ul>
                                            <div [ngbNavOutlet]="nav"></div>
                                        </ng-template>
                                    </li>
-->
                                </ul>

                                <div [ngbNavOutlet]="nav" class="ms-4"></div>
                            </div>
                        </div>

                    </div>
                </div>
                <!--END tabella Cash flow-->
                <!--START tabella Stato patrimoniale-->
                <div class="row sezione-vetrina-tabella" *ngIf="this.show_table == 'Stato Patrimoniale'">
                    <div class="d-flex">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                                <a ngbNavLink>Top</a>
                                <ng-template ngbNavContent>
                                    <div id="container-attivo"
                                        class="col-12  margin-bottom-20 padding-top-bottom-10 padding-left-right-15"
                                        style="justify-content: center;" *ngIf="true">

                                        <div class="row margin-0">
                                            <div class="col-2 margin-bottom-10 year-input-hide"
                                                style="padding-left: 0px">
                                                <label>Anni previsione</label>
                                                <select id="anniSP" [(ngModel)]="anniSP"
                                                    [ngModelOptions]="{standalone: true}" class="form-control">
                                                    <option [ngValue]="0">1 - 5</option>
                                                    <option [ngValue]="5">6 - 10</option>
                                                </select>
                                            </div>

                                            <div class="col-2 margin-bottom-10" style="padding-left: 0px">

                                            </div>

                                        </div>

                                        <div class="col-12 padding-top-bottom-10 text-center">
                                            <span id="labelAttivo" class="font-bold">Attivo</span>
                                        </div>
                                        <div id="containerAttivoStatoPatrimoniale" class="container-dettagli">
                                            <div class="row margin-0 text-center">
                                                <div class="col-2 borderRBlack min-height-25"></div>
                                                <div class="col-2 borderTBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                    [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }"
                                                    *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                    {{annoCorrente + i + anniBP}}
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].creditiSoci && this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].creditiSoci != 0">
                                                <div class="row row-cell">
                                                    <div
                                                        class=" col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Crediti verso soci per versamenti ancora dovuti
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{i +
                                                        anniBP >=
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        &&
                                                        this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                        > 0
                                                        ?
                                                        (0 | number: '1.0-0':'it') :
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].creditiSoci |
                                                        number: '1.0-0':'it')}}
                                                       
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Attivo immobilizzato
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].attivoImmobilizzato |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Attivo circolante



                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{(this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i
                                                        +
                                                        anniBP].attivoCircolante | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">
                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Cassa
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].cassa
                                                        | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">

                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Rimanenze
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].rimanenze |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">
                                                            <div class="col-2 borderBBlack"></div>
                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Crediti verso clienti
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].creditiBt |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].altriCrediti && this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].altriCrediti != 0">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">
                                                            <div class="col-2 borderBBlack"></div>
                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Altri crediti
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].altriCrediti |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].attivitaFin && this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].attivitaFin != 0">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">
                                                            <div class="col-2 borderBBlack"></div>
                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Attivit&agrave; finanziarie che non costituiscono
                                                                immobilizzazioni
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].attivitaFin |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].rateiRiscontiAttivo && this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].rateiRiscontiAttivo != 0">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack font-bold min-height-25 flex-justify-space-between">
                                                        Totale ratei e risconti
                                                    </div>

                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].rateiRiscontiAttivo | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Totale attivo
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right-bottom': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].totAttivo |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="container-passivo"
                                        class="col-12  margin-bottom-20 padding-top-bottom-10 padding-left-right-15"
                                        style="justify-content: center;">

                                        <div class="col-12 padding-top-bottom-10 margin-top-20 text-center">
                                            <span id="labelPassivo" class="font-bold">Passivo</span>
                                        </div>
                                        <div id="containerPassivoStatoPatrimoniale" class="container-dettagli">
                                            <div class="row margin-0 text-center">

                                                <div class="col-2 borderRBlack min-height-25"></div>
                                                <div class="col-2 borderTBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                    *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                    [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }">
                                                    {{annoCorrente + i + anniBP}}
                                                </div>

                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Patrimonio netto
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].patrimonioNetto
                                                        |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].appAzionista > 0">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">

                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Apporto azionista
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].appAzionista |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].patrAzEs > 0">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">


                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-start">
                                                                Patrimonio azienda esistente
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{(this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i
                                                        +
                                                        anniBP].patrAzEs |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">

                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">

                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-start">
                                                                Utili e perdite anni precedenti
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{(this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i
                                                        +
                                                        anniBP].netProfitAnniPrec | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">

                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-start">
                                                                Utili e perdite anno in corso
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{(this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i
                                                        +
                                                        anniBP].netProfit |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">
                                                            <div class="col-2 borderBBlack"></div>
                                                            <div style="line-height: 20px;"
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-start">
                                                                Dividendi cumulati
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{ (-
                                                        this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].dividendiCumulati | number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
                                                        Totale debiti bancari

                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].totDebitiBancari
                                                        |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center" *ngIf="!smartBp">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">

                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Debiti per finanziamento rateizzato
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].finRateizzato |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">

                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Debiti per finanziamento a revoca
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].finCassa |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].finPregressi > 0">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">

                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                <div *ngIf="smartBp">Debiti a scadenza</div>
                                                                <div *ngIf="!smartBp">Debiti verso banche pregressi
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].finPregressi |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">
                                                            <div class="col-2 borderBBlack"></div>
                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Debiti per finanziamento autoliquidante
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].finCastelletto |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div class="col-2 nopadding">
                                                        <div class="row margin-0">
                                                            <div class="col-2 borderBBlack"></div>
                                                            <div
                                                                class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                                Debiti per fabbisogno cassa
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].fabbisognoCassa |
                                                        number: '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Debiti verso fornitori
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].debitiBt
                                                        | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].debitiPrevidenz > 0">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Debito previdenziale
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].debitiPrevidenz
                                                        | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].debitiTributari > 0">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Debiti tributari
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].debitiTributari
                                                        | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>




                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].altriDebiti && this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].altriDebiti != 0">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Altri debiti
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].altriDebiti
                                                        | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].fondoRischiOneri && this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].fondoRischiOneri != 0">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Totale fondi per rischi e oneri
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].fondoRischiOneri
                                                        | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].tfr && this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].tfr != 0">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        TFR lavoro subordinato
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].tfr | number:
                                                        '1.0-0':'it')}}
                                                       
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center"
                                                *ngIf="this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].rateiRiscontiPassivo && this.dettaglioVolatile.patrimoniale[profiloBP].colonne[0].rateiRiscontiPassivo != 0">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Totale ratei e risconti
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].rateiRiscontiPassivo | number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row margin-0 text-center">
                                                <div class="row row-cell">
                                                    <div
                                                        class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
                                                        Totale passivo e netto
                                                    </div>
                                                    <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right-bottom': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">{{
                                                        (this.dettaglioVolatile.patrimoniale[profiloBP].colonne[i +
                                                        anniBP].totPassivo |
                                                        number:
                                                        '1.0-0':'it')}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            
                        </ul>
                        <div [ngbNavOutlet]="nav" class="ms-4"></div>
                    </div>
                </div>

                <!--END tabella Stato patrimoniale-->

                <!--START tabella Debito finanziario-->
                <div class="row sezione-vetrina-tabella" *ngIf="this.show_table == 'Debito Finanziario'">
                    <div class="d-flex">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                                <a ngbNavLink>Top</a>
                                <ng-template ngbNavContent>

                                    <div id="container-debito-finanziario"
                                        class="col-12  margin-bottom-20 padding-top-bottom-10 padding-left-right-15 container-dettagli">




                                        <div class="row margin-0 text-center" *ngIf="!smartBp">
                                            <div class="col-2  min-height-25"></div>
                                            <div class="col-2 borderTBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{annoCorrente + i + anniBP}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center" *ngIf="!smartBp">
                                            <div class="col-2 fix-no-delete min-height-25 flex-justify-space-between font-bold"
                                                style="background-color: var(--accessory-grey) !important;">
                                                Finanziamento rateizzato
                                            </div>
                                            <div class="col-2 borderBBlack borderTBlack min-height-25 flex-justify-center"
                                                [ngClass]="{'focus-cell-left-right-only-border': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{ 'borderRBlack': i == 4 }"
                                                [ngStyle]="{'background-color: rgba(0,0,0,0)': i == 5}">
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center" *ngIf="!smartBp">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderBBlack borderRBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Debito residuo
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-2 borderBBlack borderRBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i +
                                                anniBP].ratDebito |
                                                number:
                                                '1.0-0':'it')}} €
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center" *ngIf="!smartBp">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Interessi
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i +
                                                anniBP].ratInteressi
                                                | number:
                                                '1.0-0':'it')}} €
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center" *ngIf="!smartBp">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">
                                                    <div class="col-2 borderBBlack"></div>
                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Tasso d'interesse
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i == 0 ?
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i].ratTasso*100.
                                                |
                                                number:'1.0-2':'it') + ' %' : ''}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 fix-no-delete min-height-25 flex-justify-space-between font-bold"
                                                style="background-color: var(--accessory-grey) !important;">
                                                <div *ngIf="smartBp">Debiti a scadenza</div>
                                                <div *ngIf="!smartBp">Debiti verso banche pregressi</div>
                                            </div>
                                            <div class="col-2 borderBBlack borderTBlack min-height-25 flex-justify-center"
                                                [ngClass]="{'focus-cell-left-right-only-border': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{ 'borderRBlack': i == 4 }"
                                                [ngStyle]="{'background-color: rgba(0,0,0,0)': i == 5}">
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderBBlack borderRBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Debito residuo
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-2 borderBBlack borderRBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i +
                                                anniBP].pregressiDebito | number:
                                                '1.0-0':'it')}} €
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Interessi
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i +
                                                anniBP].pregressiInteressi | number:
                                                '1.0-0':'it')}} €
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">
                                                    <div class="col-2 borderBBlack"></div>
                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Tasso d'interesse
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i == 0 ?
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i].pregressiTasso*100.
                                                |
                                                number:'1.0-2':'it') + ' %' : ''}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 fix-no-delete min-height-25 flex-justify-space-between font-bold"
                                                style="background-color: var(--accessory-grey) !important;">
                                                Finanziamento a revoca
                                            </div>
                                            <div class="col-2 borderBBlack min-height-25 flex-justify-center"
                                                [ngClass]="{'focus-cell-left-right-only-border': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{ 'borderRBlack': i == 5 -1 }">
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Stock finanziamento
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i +
                                                anniBP].cassaDebito
                                                | number: '1.0-0':'it')}} €
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Interessi
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i +
                                                anniBP].cassaInteressi
                                                | number: '1.0-0':'it')}} €
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">
                                                    <div class="col-2 borderBBlack"></div>
                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Tasso d'interesse
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i == 0 ?
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i].cassaTasso*100.
                                                |
                                                number:'1.0-2':'it') + ' %' : ''}}
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 fix-no-delete min-height-25 flex-justify-space-between font-bold"
                                                style="background-color: var(--accessory-grey) !important;">
                                                Finanziamento autoliquidante
                                            </div>
                                            <div class="col-2 borderBBlack min-height-25 flex-justify-center"
                                                [ngClass]="{'focus-cell-left-right-only-border': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{ 'borderRBlack': i == 4 }">
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Stock finanziamento
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }">
                                                {{i + anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i +
                                                anniBP].castellettoDebito
                                                | number: '1.0-0':'it')}} €
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Interessi
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                [ngClass]="{'focus-cell-left-right': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">{{i +
                                                anniBP >=
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                &&
                                                this.dettaglioVolatile.complessiva[profiloBP].rowEquity.annoDefault
                                                > 0
                                                ?
                                                (0 | number: '1.0-0':'it') :
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i +
                                                anniBP].castellettoInteressi | number: '1.0-0':'it')}} €
                                            </div>
                                        </div>

                                        <div class="row margin-0 text-center">
                                            <div class="col-2 nopadding">
                                                <div class="row margin-0">

                                                    <div
                                                        class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                                                        Tasso d'interesse
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
                                                [ngClass]="{'focus-cell-left-right-bottom': i == this.foscusCol }"
                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                {{i == 0 ?
                                                (this.dettaglioVolatile.debitoFinanziario[profiloBP].colonne[i].castellettoTasso*100.
                                                |
                                                number:'1.0-2':'it') + ' %' : ''}}
                                            </div>
                                        </div>

                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="ms-4"></div>
                    </div>
                </div>
                <!--END tabella Debito finanziario-->
                <!--START tabella Garanzie-->
                <div class="row sezione-vetrina-tabella" *ngIf="this.show_table == 'Garanzie'">

                    <div class="d-flex justify-content-center">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                                <a ngbNavLink>Top</a>
                                <ng-template ngbNavContent>
                                    <div class="col-12 padding-top-bottom-10">

                                        <div class="row margin-0">
                                            <div class="col-12 container-tabella-gazanzie"
                                                style=" background-color: rgba(172,175,184,0.1); border-radius: 8px;">


                                                <app-tabella-semplice class="garanzia-tabbella-semplice"
                                                    [number_pipe]="true" [ngClass]="styleTabellaGaranzie"
                                                    [valsTab]="valoriTabellaGaranzie">
                                                </app-tabella-semplice>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="ms-4"></div>
                    </div>
                </div>
                <!--END tabella Garanzie-->
                <!--START tabella Indici di bilancio-->
                <div class="row sezione-vetrina-tabella" style="margin-top: 0px !important" *ngIf="this.show_table == 'KPI'">
                    <div class="d-flex">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                                <a ngbNavLink (click)="boolSeconSectionIndiciBilancio=false">Top</a>
                                <ng-template ngbNavContent>
                                    <div id="indiciDiBilancio"
                                        class="col-12 padding-top-bottom-10 padding-left-right-15">

                                        <div class="row margin-0">
                                            <div class="col-12 margin-bottom-20">
                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-12 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Settore merceologico di riferimento: {{this.datiMercato.ateco}}    
                                                    </div>
                                                </div>
                                                
                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-2 borderBBlack borderRBlack min-height-25 font-bold flex-justify-center">
                                                        
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col flex-center-right borderBBlack borderRBlack borderTBlack min-height-25 font-bold"
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">
                                                                {{annoCorrente + i + anniIndice - 2}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Ricavi (M€)</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{this.datiMercato.ricavi[i + 1]/1000 |
                                                                number: '1.0-0':'it'}} €
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Costi (M€)</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{this.datiMercato.costi[i + 1]/1000 |
                                                                    number: '1.0-0':'it'}} €
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Ebitda (M€)</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{this.datiMercato.ebitda[i + 1]/1000 |
                                                                    number: '1.0-0':'it'}} €
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Ebitda margin </div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{this.datiMercato.ricavi[i + 1] > 0 ? (this.datiMercato.ebitda[i + 1]/this.datiMercato.ricavi[i + 1]*100 |
                                                                    number: '1.0-2':'it') + ' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                </div>
                                                </div>

                                                <div class="row margin-0"  *ngIf="!this.smartBp">


                                                    <div class="col-12 margin-bottom-20">
                                                        <div class="row margin-0  text-center" >
                                                            <div
                                                                class="col-12 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                                Risultati azienda    
                                                            </div>
                                                        </div>
                                                        <div class="row margin-0 tab-indicatori">
                                                            <div
                                                                class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">
                                                                IRR Equity</div>
                                                            <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-center-right">
                                                                {{this.dettaglioVolatile.complessiva[profiloBP].rowEquity.irrEquity*100 | number: '1.0-1':'it'}} %
                                                            </div>
                                                        </div>

                                                        <div class="row margin-0 tab-indicatori">
                                                            <div
                                                                class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">
                                                                Equity Risk premium</div>
                                                            <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-center-right">
                                                                {{this.dettaglioVolatile.complessiva[profiloBP].rowEquity.irrEquity*100 - datiMercato.tassoRiskFree| number: '1.0-1':'it'}} %
                                                            </div>
                                                        </div>

                                                        <div class="row margin-0 tab-indicatori">
                                                            <div
                                                                class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">
                                                                Indice rendimento rischio</div>
                                                            <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-center-right">
                                                                {{isFiniteNumber(this.dettaglioVolatile.garanzie.indiceSortino) && this.dettaglioVolatile.garanzie.indiceSortino > 0 ? (this.dettaglioVolatile.garanzie.indiceSortino | number:'1.0-3':'it') : 'N/A'}}
                                                            </div>
                                                        </div>

                                                        <div class="row margin-0 tab-indicatori">
                                                            <div
                                                                class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">
                                                                Perdita attesa</div>
                                                            <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-center-right">
                                                                <!--Rivedere, non funziona il controllo sull'infinito-->
                                                                {{isFiniteNumber(this.dettaglioVolatile.garanzie.perditaAttesa) ?  (this.dettaglioVolatile.garanzie.perditaAttesa |
                                                                number:
                                                                '1.0-3':'it') : "N/A"}}
                                                            </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                       
                                        <div class="row margin-0">


                                            <div class="col-12 margin-bottom-10">
                                                <div class="row margin-0  text-center" *ngIf="this.smartBp">
                                                    <div
                                                        class="col-12 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Risultati azienda    
                                                    </div>
                                                </div>
                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-2 borderBBlack borderRBlack min-height-25 font-bold flex-justify-center">
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col flex-center-right borderBBlack borderRBlack borderTBlack min-height-25 font-bold"
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">
                                                                {{annoCorrente + i + anniIndice - 2}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Trend</div>
                                                    <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="'col-'+5*2"></div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Variazione ricavi</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].trendRicavi) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].trendRicavi*100 |
                                                                number: '1.0-1':'it') + ' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Variazione attivo</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].trendAttivo) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].trendAttivo*100 |
                                                                number: '1.0-1':'it') + ' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Variazione patrimonio</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].trendPatrimonio) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].trendPatrimonio*100 |
                                                                number: '1.0-1':'it') +' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Indicatori redditivit&agrave;</div>
                                                    <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="'col-'+5*2"></div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Return on
                                                        equity</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].returnEquity) ?(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].returnEquity*100 |
                                                                number: '1.0-1':'it') + ' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Return on
                                                        investment</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].returnInvestment) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].returnInvestment*100 |
                                                                number: '1.0-1':'it') + ' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Return on
                                                        sales</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].returnSales) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].returnSales*100 |
                                                                number: '1.0-1':'it') + ' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Return on
                                                        assets</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].indiceRoa) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].indiceRoa*100 |
                                                                number: '1.0-1':'it') + ' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Ebitda margin</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ebitdaMargin) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ebitdaMargin*100 |
                                                                number: '1.0-2':'it') + ' %' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Ebitda (K€)</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ebitda) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ebitda/1000. |
                                                                number: '1.0-0':'it') + ' €' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Rotazione
                                                        capitale investito</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].rotazioneCapitale |
                                                                number: '1.0-2':'it'}}
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                -->
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Ebitda/Oneri finanziari</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{ isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                    +
                                                                    anniIndice + 1].ebitdaOneriFin) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ebitdaOneriFin |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Indicatori di solidit&agrave;</div>
                                                    <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="'col-'+5*2"></div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Net profit (K€)</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].netProfit) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].netProfit/1000. |
                                                                number: '1.0-0':'it') + ' €' : 'N/A'}}                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Leverage</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].leverage) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].leverage |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Indicatori liquidit&agrave;</div>
                                                    <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="'col-'+5*2"></div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        D/E ratio</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ratioDeTot) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ratioDeTot |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        PFN (K€)</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].pfn) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].pfn/1000 |
                                                                number: '1.0-0':'it') + ' €' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Current ratio</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].currentRatio) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].currentRatio |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Quick ratio</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].quickRatio) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].quickRatio |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Margine di tesoreria (K€)</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].margineTes) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].margineTes/1000. |
                                                                number: '1.0-0':'it') + ' €' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Capitale circolante netto (K€)</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].capCircNett) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].capCircNett/1000. |
                                                                number: '1.0-0':'it') + ' €' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Indicatori di bancabilit&agrave;</div>
                                                    <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="'col-'+5*2"></div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        DSCR
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                    +
                                                                    anniIndice + 1].dscrAnnuale) && this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].dscrAnnuale != -100.1 ?
                                                                (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].dscrAnnuale |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        PFN/EBITDA
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].pfnEbitda) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].pfnEbitda |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">
                                                        LLCR</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25"
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].llcr) ?
                                                                (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].llcr |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="row margin-0  text-center">
                                                    <div
                                                        class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Indicatori di efficienza</div>
                                                    <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="'col-'+5*2"></div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Rotazione dell'attivo</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].rotazioneCapitale) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].rotazioneCapitale |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Rotazione circolante</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].rotCapCircNett) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].rotCapCircNett |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Giorni incasso crediti</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].giorniCrediti) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].giorniCrediti |
                                                                number: '1.0-0':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Giorni pagamento debiti</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].giorniDebiti) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].giorniDebiti |
                                                                number: '1.0-0':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Rotazione del magazzino</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].indiceMagaz) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].indiceMagaz |
                                                                number: '1.0-2':'it') : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0  text-center"  *ngIf="this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].dcfInvest  != null">
                                                    <div
                                                        class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold nopadding">
                                                        Indicatori performance investimento</div>
                                                    <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="'col-'+5*2"></div>
                                                </div>
                                                <div class="row margin-0 strisce"  *ngIf="this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].dcfInvest  != null">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Cashflow investimento</div>
                                                    <div class="col-10">
                                                        <div class="row margin-0">
                                                            <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                
                                                                *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                {{isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ebitdaInvest) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[i
                                                                +
                                                                anniIndice + 1].ebitdaInvest |
                                                                number: '1.0-0':'it') + ' €' : 'N/A'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-0 strisce"  *ngIf="this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].dcfInvest  != null">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        Terminal value investimento</div>
                                                        <div class="col-10">
                                                            <div class="row margin-0">
                                                                <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                    
                                                                    *ngFor="let item of [].constructor(2 + anniOutput); let i = index">
    
                                                                    {{i == 6? isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].termValInvest) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].termValInvest |
                                                                    number: '1.0-0':'it') + ' €' : 'N/A' : ''}}
                                                                </div>
                                                            </div>
                                                        </div>

                                                </div>
                                                <div class="row margin-0 strisce"  *ngIf="this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].dcfInvest  != null">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        IRR investimento</div>

                                                        <div class="col-10">
                                                            <div class="row margin-0">
                                                                <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                    
                                                                    *ngFor="let item of [].constructor(2 + anniOutput); let i = index">

                                                                    {{i == 6? (this.dettaglioVolatile.complessiva[profiloBP].rowEquity.irrPr*100 |
                                                    number: '1.0-1':'it') + ' %' : ''}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="row margin-0 strisce"  *ngIf="this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].dcfInvest  != null">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center nopadding">
                                                        DCF investimento</div>
                                                        <div class="col-10">
                                                            <div class="row margin-0">
                                                                <div class="col font-numeri flex-center-right borderRBlack borderBBlack  min-height-25 "
                                                                    
                                                                    *ngFor="let item of [].constructor(2 + anniOutput); let i = index">
    
                                                                    {{i == 6? isFiniteNumber(this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].dcfInvest) ? (this.dettaglioVolatile.indiciBilancio[profiloBP].colonna[0].dcfInvest |
                                                        number: '1.0-0':'it') + ' €' : 'N/A' : ''}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>

                                                <!--
                                                                                                    <div class="row margin-0 strisce">
                                                                                                      <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center" >IRR progetto</div>
                                                                                                      <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center" >{{this.dettaglioVolatile.complessiva[profiloBP].rowEquity.irrPr*100 | number: '1.0-1':'it'}} %</div>
                                                                                                    </div>
                                                                                          -->
                                            </div>
                                        </div>

                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem="midle">
                                <a ngbNavLink (click)="boolSeconSectionIndiciBilancio=true">Midle</a>
                                <ng-template ngbNavContent>
                                    <div class="row margin-0">

                                        <div id="containerProbIndiciBilancio" class="col-12 margin-bottom-10">
                                            <div class="row margin-0  text-center">
                                                <div class="col-2 borderBBlack borderRBlack">
                                                </div>
                                                <div class="lableProbIndiciBilancio  borderRBlack borderTBlack x font-bold flex-justify-center"
                                                    [ngClass]="'col-'+5*2">Probabilit&agrave;
                                                </div>
                                            </div>
                                            <div class="row margin-0  text-center">
                                                <div
                                                    class="col-2 borderBBlack  borderRBlack  font-bold flex-justify-center">
                                                    Range
                                                </div>
                                                <div class="col-2 borderBBlack  font-bold flex-justify-center"
                                                    *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                    {{annoCorrente + i + anniBP}}
                                                </div>
                                            </div>
                                            <div class="row margin-0 text-center"
                                                *ngFor="let item of [].constructor(freqIndiceBil[0].valori.length); let i = index">
                                                <div class="col-2 borderLBlack borderRBlack  flex-justify-center">
                                                    {{this.bordiIndiceBil[i]}}
                                                </div>
                                                <div class="col-2 borderRBlack    flex-justify-center"
                                                    [ngClass]="[( j == this.foscusCol && i!=9 ) ? 'focus-cell-left-right' : '', ( j == this.foscusCol && i==9 ) ? 'focus-cell-left-right-bottom' : '' ]"
                                                    *ngFor="let item of [].constructor(anniOutput); let j = index">
                                                    {{(freqIndiceBil[j +
                                                    anniBP].valori[i]|
                                                    number:
                                                    '1.0-2':'it')}} %
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--
                                                                            <app-bar-chart-indicatori-bilancio [AscissaLabel]="bordiIndiceBil[anniFE]"
                                                                                [OrdinataLabel]="freqIndiceBil[anniFE]"
                                                                                [numSimulazioni]="this.risultatiQuestionario.numSimulazioni">
                                                                            </app-bar-chart-indicatori-bilancio>
                                    										-->
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="ms-4"> </div>

                    </div>

                </div>
                <!--END tabella Indici di bilancio-->
                <!--START tabella Situazione debitoria-->
                <div class="row sezione-vetrina-tabella" *ngIf="this.show_table == 'Situazione Debitoria'">

                    <div id="situazioneDebitoria" class="col-12 padding-top-bottom-10">
                        <div class="row margin-0">


                            <div class="col-12 margin-bottom-20">
                                <div class="row margin-0 text-center">
                                    <div class="d-flex">
                                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills"
                                            orientation="vertical">
                                            <li ngbNavItem="top">
                                                <a ngbNavLink>Top</a>
                                                <ng-template ngbNavContent>


                                                    <div class="col-12 margin-bottom-10">
                                                        <div class="row margin-0  text-center">
                                                            <div class="col-2 borderBBlack borderRBlack min-height-25">
                                                            </div>
                                                            <div
                                                                class="label-tabella-probabilità col-10 borderBBlack borderRBlack borderTBlack min-height-25 font-bold flex-justify-center">
                                                                Probabilità</div>
                                                        </div>
                                                        <div class="row margin-0  text-center interno">
                                                            <div
                                                                class="col-2 borderBBlack borderLBlack borderRBlack min-height-25 font-bold flex-justify-center">
                                                                Range</div>
                                                            <div class="col-2 borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                                [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }"
                                                                *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                                {{annoCorrente + i + anniBP}}
                                                                <!-- segnalino -->
                                                            </div>
                                                        </div>
                                                        <div class="row margin-0 text-center rowColor"
                                                            *ngFor="let item of [].constructor(freqDscrAnnuo[0].valori.length);
                                                                                                                    let i = index">
                                                            <div
                                                                class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">
                                                                {{this.bordiDscr[i]}}
                                                            </div>
                                                            <ng-container
                                                                *ngIf=' i == this.freqDscrAnnuo[0].valori.length - 1; else bottom_focus'>
                                                                <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                                                                    [ngClass]="{'focus-cell-left-right-bottom': j == this.foscusCol}"
                                                                    *ngFor="let item of [].constructor(anniOutput); 
                                                                                                                        let j = index">
                                                                    {{(freqDscrAnnuo[j +
                                                                    anniBP].valori[i] |
                                                                    number: '1.0-2':'it')}} %
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #bottom_focus>
                                                                <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                                                                    [ngClass]="{'focus-cell-left-right': j == this.foscusCol}"
                                                                    *ngFor="let item of [].constructor(anniOutput); 
                                                                                                                        let j = index">
                                                                    {{(freqDscrAnnuo[j +
                                                                    anniBP].valori[i] |
                                                                    number: '1.0-2':'it')}} %
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>

                                                </ng-template>
                                            </li>
                                            <!-- 
                                            <li ngbNavItem="midle">
                                                <a ngbNavLink>Midle</a>
                                                <ng-template ngbNavContent>
                                                    DA TOGLIERE; VEDERE SE C'E' QUALCHE COSA DI UTILE
                                                                                <div class="col-12 margin-bottom-20"
                                                                                    >
                                                                                    <div class="container">
                                                                                        <div class="row"
                                                                                            style="justify-content: center;  background-color: var(--soft-grey); border-radius: 20px;">
                                                                                            
                                                                                            <div class="col-9 colonna-dettagli-tab">
                                                                                                
                                                                                                <app-tabella-with-options
                                                                                                    class="col-12 nopadding"
                                                                                                    [number_pipe]="true"
                                                                                                    [ngClass]="styleTabellaDSRC"
                                                                                                    [valsTab]="valoriTabellaDSRC"
                                                                                                    [coloreDebito]="coloreDebito"
                                                                                                    [frequenza]="frequenzeDebito"
                                                                                                    [maxFrequenzeDebito]="maxFrequenzeDebito"
                                                                                                    [indicatore_eventBreake]="false">
                                                                                                </app-tabella-with-options>
                                                                                            </div>
                                                                                        </div>
                    
                    
                    
                                                                                    </div>
                                                                                </div>
                                                                           


                                                </ng-template>
                                            </li>
                                             -->
                                        </ul>

                                        <div [ngbNavOutlet]="nav" class="ms-4"></div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <!--END tabella Situazione debitoria-->
                <!--START tabella Valutazione MCC Prospettica-->
                <div class="row sezione-vetrina-tabella" *ngIf="this.show_table == 'Valutazione MCC Prospettica'">

                    <div class="d-flex">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                                <a ngbNavLink>Top</a>
                                <ng-template ngbNavContent>
                                    <div class="col-12 padding-top-bottom-10 padding-left-right-15" *ngIf="true">
                                        <div id="valutazioneMcc" class="row margin-0">
                                            <div class="col-12 margin-bottom-10 container-dettagli">
                                                <div class="row margin-0  text-center">
                                                    <div class="col-2 borderRBlack min-height-25"></div>
                                                    <div id="label-probabilità-vslitszioneMCC"
                                                        class="col-10 min-height-25 font-bold flex-justify-center"
                                                        style="background-color: var(--main-white);">
                                                        Probabilità</div>
                                                </div>
                                                <div class="row margin-0  text-center" style="background-color:#FFFFFF">
                                                    <div
                                                        class="col-2 borderBBlack borderLBlack borderRBlack min-height-25 font-bold flex-justify-center">
                                                        Range</div>
                                                    <div class="col-2 borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                                                        [ngClass]="{'focus-cell-left-right-top': i == this.foscusCol }"
                                                        *ngFor="let item of [].constructor(anniOutput); let i = index">
                                                        {{annoCorrente + i + anniBP}}
                                                    </div>
                                                </div>
                                                <div class="row margin-0 text-center"
                                                    *ngFor="let item of [].constructor(frequenzeMcc[0].valori.length); let i = index">
                                                    <div
                                                        class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">
                                                        {{this.bordiMcc[i]}}</div>
                                                    <ng-container
                                                        *ngIf="i == frequenzeMcc[0].valori.length - 1; else lateral_focus">
                                                        <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                                                            [ngClass]="{'focus-cell-left-right-bottom': j == this.foscusCol }"
                                                            *ngFor="let item of [].constructor(anniOutput); let j = index">
                                                            {{(frequenzeMcc[j +
                                                            anniBP].valori[i] |
                                                            number:
                                                            '1.0-2':'it')}} %
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #lateral_focus>
                                                        <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                                                            [ngClass]="{'focus-cell-left-right': j == this.foscusCol }"
                                                            *ngFor="let item of [].constructor(anniOutput); let j = index">
                                                            {{(frequenzeMcc[j +
                                                            anniBP].valori[i]|
                                                            number:
                                                            '1.0-2':'it')}} %
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="ms-4"></div>
                    </div>
                </div>
                <!--END tabella Valutazione MCC Prospettica-->
                <!--START tabella Valutazione Centrale Rischi-->
                <div class="row sezione-vetrina-tabella" *ngIf="this.show_table == 'Valutazione Centrale Rischi'">

                    <div class="d-flex">

                        <div class="col-12 padding-top-bottom-10 padding-left-right-15">
                            <div id="valutazioneCr" class="row margin-0">
                                <div class="row margin-0 text-center" style="background-color: var(--soft-grey);">
                                    <div class="col-3 min-height-25 margin-top-10 font-bold">Mesi sconfino rischi
                                        autoliquidanti</div>
                                    <div class="col-3 min-height-25 margin-top-10 font-bold">Mesi sconfino rischi a
                                        scadenza </div>
                                    <div class="col-3 min-height-25 margin-top-10 font-bold">Mesi sconfino rischi a
                                        revoca </div>
                                    <div class="col-3 min-height-25 margin-top-10 font-bold">Valutazione MCC </div>
                                    <div class="col-3 min-height-25">{{mesiSconfAutoLiq}}</div>
                                    <div class="col-3 min-height-25">{{mesiSconfScad}}</div>
                                    <div class="col-3 min-height-25">{{mesiSconfRev}}</div>
                                    <div class="col-3 min-height-25">{{valutazioneCr.fasciaValutazione}}</div>

                                </div>
                                <div class="col-2 margin-bottom-20">
                                    <label>Mese</label>
                                    <select id="meseCr" [(ngModel)]="meseCr" class="form-control">
                                        <option [ngValue]="i" *ngFor="let data of arrayDateCr; let i = index">
                                            {{data | date:
                                            'MMMM yyyy' : 'it'}}</option>
                                    </select>
                                </div>
                                <div class="d-flex">
                                    <div class="col-12 col-xl-8" id="cr-table" style="margin:auto">
                                        <!-- colonna-dettagli-tab -->
                                        <div class="row row-cols-6 margin-0 text-center intestazione">

                                            <div class="col  flex-justify-center font-bold"></div>
                                            <div class="col  flex-justify-center font-bold">
                                                Accordato</div>
                                            <div class="col  flex-justify-center font-bold">
                                                Accordato operativo</div>
                                            <div class="col  flex-justify-center font-bold">
                                                Utilizzato</div>
                                            <div class="col  flex-justify-center font-bold">
                                                Percentuale utilizzato</div>
                                            <div class="col  flex-justify-center font-bold">
                                                Sconfino</div>
                                        </div>
                                        <!-- colonna (1) -->
                                        <div class="row row-cols-6 margin-0 text-center riga">
                                            <div class="col font-bold alignLeft">
                                                Rischi autoliquidanti
                                                <span class="pull-right pointer" (click)="boolAutoLiq = !boolAutoLiq">
                                                    <fa-icon style="font-size: 24px"
                                                        [icon]="boolAutoLiq? faCaretUp : faCaretDown">
                                                    </fa-icon>
                                                </span>
                                            </div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.accordatoAutoLiq[meseCr] | number: '1.0-0':'it'}} €
                                            </div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.accOpAutoLiq[meseCr] | number: '1.0-0':'it'}} €
                                            </div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.utiAutoLiq[meseCr] | number: '1.0-0':'it'}} €</div>
                                            <div class="col min-height-25 flex-justify-center "
                                                [ngStyle]="{'color': valutazioneCr.utiAutoLiq[meseCr]/valutazioneCr.accOpAutoLiq[meseCr] > 0.85 ? dangerColor : 'black'}">
                                                {{valutazioneCr.accOpAutoLiq[meseCr] != 0 ?
                                                ((valutazioneCr.utiAutoLiq[meseCr]/valutazioneCr.accOpAutoLiq[meseCr])*100
                                                | number:
                                                '1.0-1':'it') : 0}} %</div>
                                            <div class="col min-height-25 flex-justify-center"
                                                [ngStyle]="{'color': valutazioneCr.sconfAutoLiq[meseCr] > 0 ? dangerColor : 'black'}">
                                                {{valutazioneCr.sconfAutoLiq[meseCr] | number: '1.0-0':'it'}} €
                                            </div>
                                        </div>
                                        <!-- colonne a scomparsa 1  -->
                                        <ng-container *ngIf="boolAutoLiq">
                                            <div class="row margin-0 row-cols-6 riga text-center "
                                                *ngFor="let elemento of filterByMonth(valutazioneCr.autoLiqPerBanca); let i = index">
                                                <div class="col alignLeft" style="font-size: 12px;">
                                                    {{elemento.banca}}
                                                </div>
                                                <div class="col flex-justify-center">
                                                    {{elemento.accordato | number: '1.0-0':'it'}} €</div>
                                                <div class="col flex-justify-center">
                                                    {{elemento.accOperativo | number: '1.0-0':'it'}} €</div>
                                                <div class="col flex-justify-center">
                                                    {{elemento.utilizzato | number: '1.0-0':'it'}} €</div>
                                                <div class="col flex-justify-center"
                                                    [ngStyle]="{'color': elemento.utilizzato/elemento.accOperativo > 0.85 ? dangerColor : 'black'}">
                                                    {{elemento.accOperativo != 0 ?
                                                    ((elemento.utilizzato/elemento.accOperativo)*100
                                                    | number: '1.0-1':'it') : 0}} %</div>
                                                <div class="col flex-justify-center"
                                                    [ngStyle]="{'color': elemento.sconfino  > 0 ? dangerColor : 'black'}">
                                                    {{elemento.sconfino | number: '1.0-0':'it'}} €</div>
                                            </div>
                                        </ng-container>



                                        <!-- riga 2 -->
                                        <div class="row row-cols-6 margin-0 text-center riga">
                                            <div class="col font-bold alignLeft">
                                                Rischi a scadenza
                                                <span class="pull-right pointer" (click)="boolScad = !boolScad">
                                                    <fa-icon style="font-size: 24px"
                                                        [icon]="boolScad? faCaretUp : faCaretDown">
                                                    </fa-icon>
                                                </span>
                                            </div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.accordatoScad[meseCr] | number: '1.0-0':'it'}} €
                                            </div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.accOpScad[meseCr] | number: '1.0-0':'it'}} €</div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.utiScad[meseCr] | number: '1.0-0':'it'}} €</div>
                                            <div class="col min-height-25 flex-justify-center "
                                                [ngStyle]="{'color': valutazioneCr.utiScad[meseCr]/valutazioneCr.accOpScad[meseCr] > 0.75 ? dangerColor : 'black'}">
                                                {{valutazioneCr.accOpScad[meseCr] != 0 ?
                                                ((valutazioneCr.utiScad[meseCr]/valutazioneCr.accOpScad[meseCr])*100
                                                | number:
                                                '1.0-1':'it') : 0}} %</div>
                                            <div class="col min-height-25 flex-justify-center"
                                                [ngStyle]="{'color': valutazioneCr.sconfScad[meseCr] > 0 ? dangerColor : 'black'}">
                                                {{valutazioneCr.sconfScad[meseCr] | number: '1.0-0':'it'}} €</div>
                                        </div>
                                        <!-- colonne a scomparsa (2) -->
                                        <ng-container *ngIf="boolScad">
                                            <div class="row margin-0 row-cols-6 riga text-center "
                                                *ngFor="let elemento of filterByMonth(valutazioneCr.scadPerBanca); let i = index">
                                                <div class="col alignLeft " style="font-size: 12px;">
                                                    {{elemento.banca}}
                                                </div>
                                                <div class="col flex-justify-center">
                                                    {{elemento.accordato | number: '1.0-0':'it'}} €</div>
                                                <div class="col flex-justify-center">
                                                    {{elemento.accOperativo | number: '1.0-0':'it'}} €</div>
                                                <div class="col flex-justify-center">
                                                    {{elemento.utilizzato | number: '1.0-0':'it'}} €</div>
                                                <div class="col flex-justify-center"
                                                    [ngStyle]="{'color': elemento.utilizzato/elemento.accOperativo > 0.85 ? dangerColor : 'black'}">
                                                    {{elemento.accOperativo != 0 ?
                                                    ((elemento.utilizzato/elemento.accOperativo)*100
                                                    | number: '1.0-1':'it') : 0}} %</div>
                                                <div class="col flex-justify-center"
                                                    [ngStyle]="{'color': elemento.sconfino  > 0 ? dangerColor : 'black'}">
                                                    {{elemento.sconfino | number: '1.0-0':'it'}} €</div>
                                            </div>
                                        </ng-container>

                                        <!-- colonna 3 -->
                                        <div class="row row-cols-6 margin-0 text-center riga">
                                            <div class="col font-bold alignLeft">
                                                Rischi a revoca
                                                <span class="pull-right pointer" (click)="boolRev = !boolRev">
                                                    <fa-icon style="font-size: 24px"
                                                        [icon]="boolRev? faCaretUp : faCaretDown">
                                                    </fa-icon>
                                                </span>
                                            </div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.accordatoRev[meseCr] | number: '1.0-0':'it'}} €
                                            </div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.accOpRev[meseCr] | number: '1.0-0':'it'}} €</div>
                                            <div class="col min-height-25 flex-justify-center">
                                                {{valutazioneCr.utiRev[meseCr] | number: '1.0-0':'it'}} €</div>
                                            <div class="col min-height-25 flex-justify-center"
                                                [ngStyle]="{'color': valutazioneCr.utiRev[meseCr]/valutazioneCr.accOpRev[meseCr] > 1 ? dangerColor : 'black'}">
                                                {{valutazioneCr.accOpRev[meseCr] != 0 ?
                                                ((valutazioneCr.utiRev[meseCr]/valutazioneCr.accOpRev[meseCr])*100 |
                                                number:
                                                '1.0-1':'it') : 0}} %</div>
                                            <div class="col min-height-25 flex-justify-center"
                                                [ngStyle]="{'color': valutazioneCr.sconfRev[meseCr] > 0 ? dangerColor : 'black'}">
                                                {{valutazioneCr.sconfRev[meseCr] | number: '1.0-0':'it'}} €</div>
                                        </div>
                                        <!-- colonne a scomparsa 3 -->
                                        <ng-container *ngIf="boolRev">
                                            <div class="row margin-0 row-cols-6 riga text-center"
                                                *ngFor="let elemento of filterByMonth(valutazioneCr.revPerBanca); let i = index">
                                                <div class="col  min-height-25 alignLeft text-center"
                                                    style="font-size: 12px;">
                                                    {{elemento.banca}}
                                                </div>
                                                <div class="col min-height-25 flex-justify-center">
                                                    {{elemento.accordato| number: '1.0-0':'it'}} €</div>
                                                <div class="col min-height-25 flex-justify-center">
                                                    {{elemento.accOperativo | number: '1.0-0':'it'}} €</div>
                                                <div class="col min-height-25 flex-justify-center">
                                                    {{elemento.utilizzato | number: '1.0-0':'it'}} €</div>
                                                <div class="col min-height-25 flex-justify-center"
                                                    [ngStyle]="{'color': elemento.utilizzato/elemento.accOperativo > 1 ? dangerColor : 'black'}">
                                                    {{elemento.accOperativo != 0 ?
                                                    ((elemento.utilizzato/elemento.accOperativo)*100
                                                    | number: '1.0-1':'it') : 0}} %</div>
                                                <div class="col min-height-25 flex-justify-center"
                                                    [ngStyle]="{'color': elemento.sconfino  > 0 ? dangerColor : 'black'}">
                                                    {{elemento.sconfino | number: '1.0-0':'it'}} €</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <!--END tabella Valutazione Centrale Rischi-->
                <!--START Crisi impresa-->
                <div class="row sezione-vetrina-tabella larg-max-91-lg "
                    *ngIf="this.show_table == 'Valutazione Crisi di Impresa'">
                    <div class="d-flex">
                        <div id="crisi-impresa-table" class="col-12 padding-top-bottom-10 padding-left-right-15"
                            style="justify-content: center">
                            <div class="row legenda text-center text-align-center font-bold">
                                <div class="col-12">
                                    {{currentBp.nomeAziendaRichiedente}} CASH FLOW PROSPETTICO PER ALLERTA CRISI
                                    D'IMPRESA ANNO {{annoCorrente}}.
                                    &nbsp;&nbsp;Codice Ateco: {{codiceAteco}}.&nbsp;&nbsp; Data di produzione:
                                    {{currentBp.dataModifica}}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Valori espressi in Keuro)
                                </div>
                            </div>
                            <div class="row intestazione text-center ">
                                <div class="col-2 alignLeft border-horizontal font-bold padding-left-right-5">
                                    CASH PLAN PROSPETTICO {{annoCorrente}}
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col  font-bold flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].dataRiferimento
                                            | date:'MM/yyyy'
                                            : 'it'}}
                                        </div>
                                        <div
                                            class="col  font-bold flex-justify-center border-horizontal padding-left-right-5">
                                            Totale
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga  text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    SALDO CASSA INIZIALE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].cassaIniz
                                            |number: '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[this.dettaglioVolatile.crisiImpresa[profiloBP].colonne.length-1].cfProgressivo
                                            |number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    INCASSI CORRENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ricaviCassa
                                            |number: '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ricaviCassa| number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    INCASSI PER LDB
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">

                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ricaviCassa
                                            |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                        <div class="col flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ricaviCassa
                                            | number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    COSTI CORRENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">

                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].costiProduzione
                                            +
                                            this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].costiFissi
                                            + this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].costiFatt +
                                            this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].tasse
                                            + this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].cfDebFinanz +
                                            this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].oneriFinanz|
                                            number: '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].costiProduzione +
                                            this.sommaColonneCrisi[profiloBP].costiFissi
                                            + this.sommaColonneCrisi[profiloBP].costiFatt +
                                            this.sommaColonneCrisi[profiloBP].tasse
                                            + this.sommaColonneCrisi[profiloBP].cfDebFinanz +
                                            this.sommaColonneCrisi[profiloBP].oneriFinanz|
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI PER LDB
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">

                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{
                                            (this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].costiProduzione |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].costiProduzione |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI FISSI (SG&A)
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">

                                        <div class="col flex-justify-center padding-left-right-5 "
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].costiFissi |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].costiFissi |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI DI FATTURATO
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">

                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].costiFatt |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].costiFatt |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    IMPOSTE ORDINARIE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">

                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].tasse | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].tasse |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    FINANZIAMENTI RATEIZZATI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">

                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].cfDebFinanz |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].cfDebFinanz |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    ONERI FINANZIARI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">

                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].oneriFinanz |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].oneriFinanz |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center font-bold ">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    INCASSI NON CORRENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].incasClientStraord
                                            | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].incasClientStraord |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;" *ngIf="false">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    INCASSI CLIENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center padding-left-right-5">
                                        <div class="col flex-justify-center"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].incasClientStraord
                                            | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{ (this.sommaColonneCrisi[profiloBP].incasClientStraord |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row riga text-center" style="font-style: italic;" *ngIf="false">
                                <div class="col-2 alignRight border-horizontal">
                                   ALTRI INCASSI STRAORDINARI
                                </div>
                                <div class="col-10">
                            
                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center" *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].incasClientStraord | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal">
                                            {{ (this.sommaColonneCrisi[profiloBP].incasClientStraord |
                                            number: '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row riga text-center font-bold ">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    COSTI NON CORRENTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ripagDebForn +
                                            this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ripagDebTribut
                                            + this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ripagDebInps +
                                            this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ripagDebAltri
                                            | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebForn +
                                            this.sommaColonneCrisi[profiloBP].ripagDebTribut
                                            + this.sommaColonneCrisi[profiloBP].ripagDebInps +
                                            this.sommaColonneCrisi[profiloBP].ripagDebAltri
                                            | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI FORNITORI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ripagDebForn |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebForn | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">

                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI ERARIO
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ripagDebTribut
                                            | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebTribut | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    COSTI INPS
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ripagDebInps |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebInps | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center" style="font-style: italic;">
                                <div class="col-2 alignRight border-horizontal padding-left-right-5">
                                    ALTRI COSTI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ripagDebAltri |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].ripagDebAltri | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row riga text-center font-bold"
                                *ngIf="this.sommaColonneCrisi[profiloBP] && this.sommaColonneCrisi[profiloBP].cfLiquidInvest > 0">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    INCREMENTO LIQUIDIT&Agrave;
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].cfLiquidInvest
                                            |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                            {{(this.sommaColonneCrisi[profiloBP].cfLiquidInvest | number:
                                            '1.0-0':'it')}} €
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row riga text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    CASSA FINALE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].cfProgressivo |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    FIDI A REVOCA UTILIZZABILI
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].dispRevoca |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center font-bold">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    DISPONIBILIT&Agrave; LIQUIDE
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].cassaFin |
                                            number:
                                            '1.0-0':'it')}} €
                                        </div>
                                        <div class="col   flex-justify-center border-horizontal padding-left-right-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- tabella soglie allerta crisi -->
                    <div class="d-flex">
                        <div id="crisi-impresa-table" class="col-12  padding-top-bottom-10 padding-left-right-15"
                            style="justify-content: center">

                            <div class="row intestazione text-center">
                                <div class="col-2 verticalAlcenter border-horizontal font-bold padding-left-right-5">
                                    <p class="alignLeft">Allerta crisi</p>

                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col  font-bold flex-justify-center padding-left-right-5"
                                            [ngClass]="{'border-horizontal':i==5 || i==11}"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].dataRiferimento
                                            |
                                            date:'MM/yyyy' : 'it'}}
                                        </div>
                                        <div class="col  font-bold flex-justify-center border-right nopadding">
                                            VAL SOGLIA
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    PATRIMONIO NETTO
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index" [ngClass]="{'valoriSottoSoglia':this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].patrNet<=0,
                                        'border-horizontal':i==5 || i==11}">
                                            {{this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].patrNet
                                            ?(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].patrNet
                                            |
                                            number: '1.0-0':'it') +'€' : ''}}
                                        </div>
                                        <div class="col   flex-justify-center border-right padding-left-right-5">
                                            &leq; {{'0'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    DSCR (%)
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center ">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index"
                                            [ngClass]="{'valoriSottoSoglia':this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].dscrSemest<=1,'border-horizontal':i==5 || i==11}">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].dscrSemest
                                            |
                                            number: '1.0-2':'it')}}
                                        </div>
                                        <div class="col   flex-justify-center border-right padding-left-right-5">
                                            &leq; {{'1'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    ONERI FINANZIARI/RICAVI (%)
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index" [ngClass]="{'valoriSottoSoglia':this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].sostOnerFinanz>=soglieCrisi.indiceSostenibilitaOneriFinanziari
                                        ,'border-horizontal':i==5 || i==11 }">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].sostOnerFinanz
                                            |
                                            number: '1.0-2':'it')}}
                                        </div>
                                        <div class="col   flex-justify-center border-right padding-left-right-5">
                                            &GreaterEqual;
                                            {{soglieCrisi.indiceSostenibilitaOneriFinanziari}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    PATR NET/TOT DEBITI (%)
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div [ngClass]="{'valoriSottoSoglia':this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].adegPatr<=soglieCrisi.indiceAdeguatezzaPatrimoniale
                                        ,'border-horizontal':i==5 || i==11}"
                                            class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].adegPatr |
                                            number: '1.0-2':'it')}}
                                        </div>
                                        <div class="col   flex-justify-center border-right">
                                            &leq; {{soglieCrisi.indiceAdeguatezzaPatrimoniale}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    ATTIVIT&Agrave; BT/PASSIVIT&Agrave; BT (%)
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5" [ngClass]="{'valoriSottoSoglia':this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].indLiquid<=soglieCrisi.indiceLiquidita
                                        ,'border-horizontal':i==5 || i==11}"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{ (this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].indLiquid |
                                            number: '1.0-2':'it')}}
                                        </div>
                                        <div class="col   flex-justify-center border-right padding-left-right-5">
                                            &leq; {{soglieCrisi.indiceLiquidita}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    CASH FLOW/TOT ATTIVO (%)
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5"
                                            *ngFor="let item of [].constructor(12); let i = index" [ngClass]="{'valoriSottoSoglia':i!=5 && this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ritLiqAtt<=soglieCrisi.indiceRitornoLiquidoAttivo
                                        ,'border-horizontal':i==5 || i==11}">
                                            {{( i!=5 ?
                                            (this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].ritLiqAtt |
                                            number: '1.0-2':'it'):'N/A')}}
                                        </div>
                                        <div class="col   flex-justify-center border-right">
                                            &leq; {{soglieCrisi.indiceRitornoLiquidoAttivo}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row riga text-center ">
                                <div class="col-2 alignLeft border-horizontal padding-left-right-5">
                                    DEB TRIB E PREV/TOT ATT (%)
                                </div>
                                <div class="col-10">

                                    <div class="row row-cols-13-custom text-center">
                                        <div class="col flex-justify-center padding-left-right-5" [ngClass]="{'valoriSottoSoglia':this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].indebPrevTribut>=soglieCrisi.indiceIndebitamentoPrevidenzialeTributario
                                        ,'border-horizontal':i==5 || i==11}"
                                            *ngFor="let item of [].constructor(12); let i = index">
                                            {{(this.dettaglioVolatile.crisiImpresa[profiloBP].colonne[i].indebPrevTribut
                                            |
                                            number:
                                            '1.0-2':'it')}}
                                        </div>
                                        <div class="col   flex-justify-center border-right padding-left-right-5">
                                            &GreaterEqual;
                                            {{soglieCrisi.indiceIndebitamentoPrevidenzialeTributario}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <!--END tabella crisi impresa-->
                <!--START assunzioni-->
                <app-assunzioni  *ngIf="this.show_table == 'Assunzioni'" [assunzioniSmart]="dettaglioVolatile.assunzioni" [currentBp]="currentBp" [smartBp]="smartBp"
                [ambitoAssunzioni]="ambitoAssunzioni"></app-assunzioni>
                <!--END assunzioni-->
            </div>

            <!--END ACCORDION-->
            <div>
                <!--<app-timeline-risultati-questionario></app-timeline-risultati-questionario>-->
                <app-side-right-navmenu class='desktop-show' (table_show)="this.receiveShowTable($event)"
                    [ancore]="lancetta">
                </app-side-right-navmenu>
            </div>
        </div>
    </div>



    <style>
        html {
            scroll-behavior: smooth;
            overflow-y: hidden !important;
        }
    </style>
</div>